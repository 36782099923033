/* eslint-disable react/prop-types */
import { MenuItem, Select } from "@mui/material";
import React from "react";
import { useState } from "react";
import moment from "moment";

function CustomDropdown({
  labelName,
  options,
  setInputText,
  type,
  reset,
  defaultValue,
  x,
  disabled = false,
  Isrequired,
}) {
  const [names, setName] = useState(labelName);
  const setData = () => {
    if (x === "type") {
      const newArray = options?.filter(function (el) {
        return el._id === defaultValue;
      });
      newArray?.forEach((item) => {
        setName(item.type);
        setInputText(item);
      });
    } else if (x === "en") {
      const newArray = options?.filter(function (el) {
        return el._id === defaultValue;
      });
      newArray?.forEach((item) => {
        setName(item.en.name);
        setInputText(item);
      });
    } else if (x === "order") {
      const newArray = options?.filter(function (el) {
        return el.name === defaultValue;
      });
      newArray?.forEach((item) => {
        setName(item.name);
        setInputText(item);
      });
    } else if (x === "ctype") {
      const newArray = options?.filter(function (el) {
        return el.type === defaultValue;
      });
      newArray?.forEach((item) => {
        setName(item.name);
        setInputText(item);
      });
    } else {
      options?.forEach((item) => {
        if (item._id === defaultValue) {
          setName(item.name);
        }
      });
    }
  };

  React.useEffect(() => {
    if (options?.length > 0) setData();
  }, [defaultValue, options]);
  React.useEffect(() => {
    if (reset === true) setName(labelName);
    else setData();
  }, [reset]);
  const handleChange = (e) => {
    if (type === "type") {
      setName(e.target.value.type);
      setInputText(e.target.value);
    } else {
      if (e.target.value.months) {
        setName(e.target.value.months);
      } else if (e.target.value.date) {
        setName(moment.utc(e.target.value.date).format("DD - MM - YYYY"));
      } else if (e.target.value.en) {
        setName(e.target.value.en.name);
        setInputText(e.target.value);
      } else {
        setName(e.target.value.name);
      }

      setInputText(e.target.value);
    }
  };

  return (
    <div className="d-flex mb-2 flex-fill">
      <Select
        className="w-100 bgwhite"
        defaultValue={names}
        value={names}
        label="name"
        onChange={handleChange}
        disabled={disabled}
        required={Isrequired}
      >
        <MenuItem disabled value={names}>
          {names}
        </MenuItem>

        {options
          ? options.map((item) => (
              <MenuItem key={item._id} value={item}>
                {item?.name
                  ? item?.name
                  : item?.months
                  ? item?.months
                  : item?.type
                  ? item?.type
                  : item?.en?.name
                  ? item?.en?.name
                  : item?.date
                  ? moment.utc(item.date).format("DD - MM - YYYY")
                  : ""}
              </MenuItem>
            ))
          : null}
      </Select>
      {Isrequired ? <span className="required ms-2 fs-6">*</span> : null}
    </div>
  );
}

export default React.memo(CustomDropdown);
