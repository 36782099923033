import "../../App.css";
import React, { Suspense } from "react";
import Sidebar from "../../Module/sidebar";
import { Route, Routes } from "react-router-dom";
import Loading from "../../Component/Loading";
import { AcademicHeadSidebarData } from "../../Module/academicHeadSidebarData";
const DoubtSolve = React.lazy(() => import("./doubtSolveReview"));
// const LeadCount = React.lazy(() => import("./leadCount"));
const LeadDashboard = React.lazy(() =>
  import("../CategoryPanel/leadDashboard")
);
const LeadCount = React.lazy(() => import("../CategoryPanel/leadCount"));
const LeadAnalysis = React.lazy(() => import("../CategoryPanel/leadAnalysis"));
const TargetStatus = React.lazy(() => import("../CategoryPanel/targetStatus"));
const TeacherTaskPlan = React.lazy(() => import("./teacherTaskPlan"));
const ResultCompilation = React.lazy(() => import("./resultCompilation"));
const DatewiseCount = React.lazy(() => import("../Finance/datewiseCount"));
const BatchWiseCount = React.lazy(() => import("../Finance/cxobatchwiseCount"));
const TeacherTaskPlanPending = React.lazy(() =>
  import("./teacherTaskPlanPending")
);
const TeacherTaskPlanComplete = React.lazy(() =>
  import("./teacherTaskPlanCompleted")
);
const TeacherTaskPlanNotComplete = React.lazy(() =>
  import("./teacherTaskPlanNotCompleted")
);
const ExamWiseRevenue = React.lazy(() => import("./examWiseAnalysis"));

function AcademicHeadDashboard() {
  return (
    <div className="AppBox">
      <Sidebar data={AcademicHeadSidebarData} />
      <div className="dashboard">
        <Suspense
          fallback={
            <div className="border p-1">
              <Loading />
            </div>
          }
        >
          <Routes>
            <Route path="/" element={<LeadDashboard />} />
            <Route path="leadDashboard" element={<LeadDashboard />} />
            <Route path="LeadCount" element={<LeadCount />} />
            <Route path="leadAnalysis" element={<LeadAnalysis />} />
            <Route path="targetStatus" element={<TargetStatus />} />
            <Route path="teacherTaskPlan" element={<TeacherTaskPlan />} />
            <Route
              path="teacherTaskPlanPending"
              element={<TeacherTaskPlanPending />}
            />
            <Route
              path="teacherTaskPlanComplete"
              element={<TeacherTaskPlanComplete />}
            />
            <Route
              path="teacherTaskPlanNotComplete"
              element={<TeacherTaskPlanNotComplete />}
            />
            <Route path="doubtsolvereview" element={<DoubtSolve />} />
            <Route path="resultCompilation" element={<ResultCompilation />} />
            <Route path="BatchwiseCount" element={<BatchWiseCount />} />
            <Route path="DatewiseCount" element={<DatewiseCount />} />
            <Route path="ExamWiseRevenue" element={<ExamWiseRevenue />} />
          </Routes>
        </Suspense>
      </div>
    </div>
  );
}

export default AcademicHeadDashboard;
