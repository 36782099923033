/* eslint-disable react/prop-types */
import React from "react";

const LabelField = (props) => {
  return (
    <div className="mb-2">
      <label style={props?.style} className="label">
        {props.labelName}{props?.isRequired ? <span className="required ms-1 fs-6">*</span> : null}
      </label>
    </div>
  );
};
export default LabelField;
