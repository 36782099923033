import React, { Suspense } from "react";
import Sidebar from "../../Module/sidebar";
import { ResultPanelSidebarData } from "../../Module/resultPanelSidebarData";
import Loading from "../../Component/Loading";
import { Route, Routes } from "react-router";
import ResultCompilation from "../CategoryPanel/resultCompilation";
import StudentResult from "../CategoryPanel/studentResult";
const ResultPanelDashboard = () => {
  return (
    <div className="AppBox">
      <Sidebar data={ResultPanelSidebarData} />
      <div className="dashboard">
        {
          <Suspense
            fallback={
              <div className="border p-1">
                <Loading />
              </div>
            }
          >
            <Routes>
              <Route path="/" element={<ResultCompilation />} />
              <Route path="resultCompilation" element={<ResultCompilation />} />
              <Route path="studentResult" element={<StudentResult />} />
            </Routes>
          </Suspense>
        }
      </div>
    </div>
  );
};
export default ResultPanelDashboard;
