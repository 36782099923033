import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import InputField from "../../Component/inputField";
import {
  setParentCourse,
  getExamListNew,
  getParentCourseNew,
  getAllTeachersList,
  getSyllabusList,
  getSyllabusById,
  editSyllabusById,
  deleteSyllabusId,
} from "../../Action/courseAction";
import { showErrorSnackbar } from "../../Action/snackbarAction";
import "../../App.css";
import AdminTable from "../../Component/adminTable";
import CustomDropdown from "../../Component/customDropdown";
import LabelField from "../../Component/labelField";
import PageTitle from "../../Module/pageTitle";
import {
  containsStartsWithSpecialCharsOrStartsWithNumberOrWhiteSpaceErrMsg,
  validateFullNameErrMsg,
} from "../../Utils/errorMsg";
import {
  containsStartsWithSpecialCharsOrStartsWithNumberOrWhiteSpace,
  length_Hundred,
  length_Three,
  validateNameWithoutSpace,
} from "../../Utils/validators";
import TimerClock from "../../Component/timerClock";
import Modal from "@material-ui/core/Modal";
import Fade from "@material-ui/core/Fade";
import Backdrop from "@material-ui/core/Backdrop";
import ClearOutlinedIcon from "@mui/icons-material/ClearOutlined";
import { defaultDuration } from "../../Utils/dataConstants";
import { getSubExam, getSubjectList } from "../../Action/adminPanelAction";

function EditDeleteSyllabus() {
  const dispatch = useDispatch();
  const [isEditMode, setIsEditMode] = useState(false);
  const [parentCourseVal, setparentCourseVal] = useState("");
  const [examName, setExamName] = useState("");
  const [subExamName, setSubExamName] = useState("");
  const [subject, setSubject] = useState("");
  const [lectureName, setLectureName] = useState("");
  const [lectureDuration, setLectureDuration] = useState(defaultDuration);
  const [open, setOpen] = useState(false);
  const [videoName, setVideoName] = useState({
    id: 0,
    name: "",
  });
  const [teacherName, setTeacherName] = useState("");

  const [parentCourseList, setParentCourseList] = useState([]);
  const [examList, setExamList] = useState([]);
  const [subExamList, setsubExamList] = useState([]);
  const [teacherList, setTeacherList] = useState([]);
  const [subjectList, setSubjectList] = useState([]);
  const [sylabusData, setSylabusData] = useState([]);
  const [selectedbatch, setselectedbatch] = useState([]);
  const [videoList, setVideoList] = useState({});

  const [resetParentCourse, setResetParentCourse] = useState(false);
  const [resetExam, setResetExam] = useState(false);
  const [resetSubExam, setResetSubExam] = useState(false);
  const [resetSubject, setResetSubject] = useState(false);
  const [resetVideoList, setResetVideoList] = useState(false);
  const [resetTeacherList, setResetTeacherList] = useState(false);
  const [teacherArr, setTeacherArr] = useState([]);
  const [syllabusIds, setSyllabusIds] = useState("");

  const { globalEditMode } = useSelector((state) => ({
    globalEditMode: state.courseReducer?.isEditMode,
  }));

  useEffect(() => {
    setVideoList([
      { id: 1, name: "Live" },
      { id: 2, name: "Recorded" },
    ]);
    dispatch(
      getExamListNew((res) => {
        setExamList(res);
      })
    );
    dispatch(
      getAllTeachersList(
        {
          skip: 0,
          limit: 100,
        },
        (res) => {
          setTeacherList(res?.data?.data);
        },
        () => {}
      )
    );
  }, []);

  useEffect(() => {
    if (globalEditMode == false) {
      // sync isEditMode to globalEditMode
      handleCancelEditMode();
    }
  }, [globalEditMode]);

  const apiCalls = (reqBody) => {
    dispatch(
      getSyllabusList(
        reqBody,
        async (resp) => {
          setSylabusData(resp?.data);
        },
        () => {
          setSylabusData([]);
        }
      )
    );
  };

  const onListChange = (value) => {
    setExamName(value);
    setResetExam(false);
    setsubExamList([]);
    setResetSubExam(true);
    setResetSubject(true);
    setSylabusData([]);
    dispatch(
      getSubExam(
        {
          examTypeId: value._id,
        },
        (res) => {
          if (res.data.length !== 0) {
            setsubExamList(res.data);
          } else {
            dispatch(
              getParentCourseNew(`${value._id}`, (res) => {
                setParentCourseList(res);
              })
            );
          }
        }
      )
    );
  };

  const onChangeSubExam1 = (value) => {
    setSubExamName(value);
    setResetSubExam(false);
    setResetSubject(true);
    setSylabusData([]);
    dispatch(
      getParentCourseNew(`${examName._id}/${value._id}`, (res) => {
        setParentCourseList(res);
      })
    );
  };

  const changeParentCourseVal = (value) => {
    setparentCourseVal(value);
    setResetParentCourse(false);
    setResetSubject(true);
    setSylabusData([]);
    dispatch(
      getSubjectList(
        {
          baseCourseId: value?._id,
          examTypeId: examName?._id,
          subExamTypeId: subExamName?._id,
        },
        (res) => {
          setSubjectList(res.data);
        }
      )
    );
  };

  const onSubjectChange1 = (value) => {
    setSubject(value);
    setResetSubject(false);
    setSylabusData([]);
  };
  const changeVideoList = (value) => {
    setVideoName(value);
    setResetVideoList(false);
    let reqBody = {
      examTypeId: examName?._id,
      baseCourseId: parentCourseVal?._id,
      subjectId: subject?._id,
      videoType: value?.name,
    };
    if (subExamName) reqBody.subExamTypeId = subExamName?._id;
    !isEditMode && apiCalls(reqBody);
  };
  const changeTeacherList = (value) => {
    setTeacherName(value);
    setResetTeacherList(false);
    let reqBody = {
      examTypeId: examName?._id ? examName?._id : examName,
      baseCourseId: parentCourseVal?._id,
      subjectId: subject?._id,
      videoType: videoName?.name,
      teacherIds: value?._id,
    };
    if (subExamName) reqBody.subExamTypeId = subExamName?._id;
    apiCalls(reqBody);
  };

  const changeLectureTitle = (value) => {
    if (value.length >= length_Hundred) {
      dispatch(
        showErrorSnackbar(
          `Max Length ${length_Hundred - 1} reached for this field`
        )
      );
    } else if (
      containsStartsWithSpecialCharsOrStartsWithNumberOrWhiteSpace(value)
    ) {
      dispatch(
        showErrorSnackbar(
          containsStartsWithSpecialCharsOrStartsWithNumberOrWhiteSpaceErrMsg
        )
      );
      return;
    } else if (!validateNameWithoutSpace(value)) {
      dispatch(showErrorSnackbar(validateFullNameErrMsg));
      return;
    } else {
      setLectureName(value);
    }
  };

  const handleEdit = (value) => {
    setIsEditMode(true);
    setSyllabusIds(value);
    dispatch(
      getSyllabusById(value, async (resp) => {
        setLectureName(resp?.data?.lectureTitle);
        setLectureDuration(resp?.data?.lectureDuration);
        setTeacherArr(resp?.data?.teacherIds);
      })
    );
  };

  const handleDelete = (value) => {
    dispatch(
      deleteSyllabusId(value, async () => {
        let reqBody2 = {
          examTypeId: examName?._id ? examName?._id : examName,
          baseCourseId: parentCourseVal?._id,
          subjectId: subject?._id,
          videoType: videoName?.name,
        };
        apiCalls(reqBody2);
      })
    );
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    let reqBody = {
      videoType: videoName?.name,
      lectureTitle: lectureName,
      lectureDuration: lectureDuration,
      teacherIds: teacherArr,
    };
    dispatch(
      editSyllabusById(syllabusIds, reqBody, async () => {
        setIsEditMode(false);
        let reqBody2 = {
          examTypeId: examName?._id ? examName?._id : examName,
          baseCourseId: parentCourseVal?._id,
          subjectId: subject?._id,
          videoType: videoName?.name,
        };
        apiCalls(reqBody2);
      })
    );
  };

  const handleClose = () => {
    setOpen(false);
  };

  const onBatchCheck = (event, type, index) => {
    let newArray = JSON.parse(JSON.stringify(selectedbatch));
    if (type === "singleCheck") {
      if (event.target.checked) {
        newArray[index]["isSelected"] = true;
        setselectedbatch(newArray);
      } else {
        newArray[index]["isSelected"] = false;
        setselectedbatch(newArray);
      }
    }
  };

  const saveAndUpdateBatchLive = () => {
    // let newArray = JSON.parse(JSON.stringify(selectedbatch));
    // let indexes = newArray
    //   .map((elm, idx) => (elm.isSelected ? idx : ""))
    //   .filter(String);
    // for (var i = indexes?.length - 1; i >= 0; i--) {
    //   newArray.splice(indexes[i], 1);
    // }
    // let removedBatches = [];
    // newArray.map((item) => {
    //   removedBatches.push(item._id);
    // });
    // dispatch(
    //   updateBatchRec(
    //     { courses: removedBatches },
    //     recordedVideoId,
    //     () => {
    //       setOpen(false);
    //       const data = {
    //         baseCourseId: parentCourseVal?._id,
    //         examTypeId: examName?._id,
    //         subjectId: subject._id,
    //         courseId: batch,
    //         topicId: topicName._id,
    //         subTopicId: subTopicName._id,
    //       };
    //       if (subExamName) {
    //         data["subExamTypeId"] = subExamName?._id;
    //       }
    //       dispatch(
    //         getVideoLectExamsList(
    //           data,
    //           (res) => {
    //             setSylabusData(res.data);
    //           },
    //           () => {}
    //         )
    //       );
    //     },
    //     () => {
    //       setOpen(false);
    //     }
    //   )
    // );
  };

  const validations = () => {
    // return (
    //   parentCourseVal === "" ||
    //   examName === "" ||
    //   (subExamList?.length > 0 && subExamName === "") ||
    //   subject === "" ||
    //   topicName === "" ||
    //   subTopicName === "" ||
    //   lectureName === "" ||
    //   lectureName?.length < length_Three ||
    //   lectureDuration === "" ||
    //   aboutLecture === "" ||
    //   aboutLecture?.length < length_Ten ||
    //   awsLectureHigh === "" ||
    //   awsLectureHigh.length < length_Three ||
    //   awsLectureLow === "" ||
    //   awsLectureLow.length < length_Three ||
    //   youtubeLecture === "" ||
    //   youtubeLecture.length < length_Three ||
    //   dpp === "" ||
    //   teacherHandout1 === "" ||
    //   teacherHandout1?.length < length_Three ||
    //   teacherHandout2 === "" ||
    //   teacherHandout2.length < length_Three
    // );
  };

  const renderEditMode = () => {
    return (
      <form className="formDiv" onSubmit={handleSubmit}>
        <div className="col-6">
          <InputField
            labelName={"Lecture Title"}
            placeholder={"Enter Lecture Title"}
            setInputText={(value) => changeLectureTitle(value)}
            value={lectureName}
            Isrequired={true}
            maxLength={length_Hundred}
            minLength={length_Three}
          />
          <div className="d-flex mb-2 align-items-center">
            <LabelField labelName={"Lecture Duration"} />
            {/* <Time
              Isrequired={true}
              value={lectureDuration}
              setInputText={(value) => setLectureDuration(value)}
              defaultValue={lectureDuration}
            /> */}
            <TimerClock
              labelName={""}
              value={lectureDuration}
              placeholder={"Unit Duration (Min)"}
              setInputText={(value) => {
                setLectureDuration(value);
              }}
              Isrequired={true}
            />
          </div>
        </div>
        <div className="col-auto d-flex justify-content-center">
          <div className="col-auto d-flex justify-content-center mt-4">
            <button
              type="submit"
              className={`btn mb-3 submitbtn ${
                validations() ? "btn-secondary" : "btn-primary"
              }`}
              disabled={validations()}
            >
              Submit
            </button>
          </div>
        </div>
      </form>
    );
  };
  const renderListMode = () => {
    return (
      <div>
        <AdminTable
          pagename={"editSyllabusList"}
          tableData={sylabusData ? sylabusData : []}
          tableHead={[
            "S.N",
            "Subject",
            "Topic Name",
            "Subtopic",
            "Title",
            "Preferred Teacher",
            "Duration",
            "Video Type",
            "Actions",
          ]}
          handleEditBatch={(value) => {
            const selectedbatchArrayNew = value.map((item) => {
              return item;
            });
            setselectedbatch(selectedbatchArrayNew);
            setOpen(true);
          }}
          handleEdit={(value) => {
            handleEdit(value);
          }}
          handleDelete={(value) => handleDelete(value)}
          // edit={true}
        />
      </div>
    );
  };

  const onSelect = (event) => {
    let data = teacherArr;
    let i = data.findIndex((item) => item === event.target.id);
    if (i < 0) {
      data.push(event.target.id);
    } else {
      data.splice(i, 1);
    }
    setTeacherArr(data);
  };

  const handleCancelEditMode = () => {
    setIsEditMode(false);
    dispatch(setParentCourse("isEditMode", false));
  };

  return (
    <div className="contentBox">
      <PageTitle
        name={"EDIT / DELETE SYLLABUS"}
        isEditMode={isEditMode}
        onClick={handleCancelEditMode}
      />
      <div className="formDiv">
        <div className="col-12 formWidth">
          <div className="row d-flex">
            <div className="col-6">
              <div className="d-flex align-items-center">
                <LabelField labelName={"Exam Name"} />
                <CustomDropdown
                  setInputText={(value) => onListChange(value)}
                  value={examName}
                  options={examList}
                  labelName="Select Exam Name"
                  reset={resetExam}
                  Isrequired={true}
                  defaultValue={examName?._id}
                  disabled={isEditMode}
                />
              </div>
              {subExamList?.length != 0 ? (
                <div className="d-flex align-items-center me-3">
                  <LabelField labelName={"Sub Exam Name"} />
                  <CustomDropdown
                    setInputText={(value) => onChangeSubExam1(value)}
                    value={subExamName}
                    options={subExamList}
                    labelName="Select Sub Exam Name"
                    reset={resetSubExam}
                    defaultValue={subExamName?._id}
                    disabled={isEditMode}
                  />
                </div>
              ) : null}
              <div className="d-flex align-items-center">
                <LabelField labelName={"Exam Stream"} />
                <CustomDropdown
                  setInputText={(value) => changeParentCourseVal(value)}
                  value={parentCourseVal}
                  options={parentCourseList}
                  labelName="Select Exam Stream"
                  reset={resetParentCourse}
                  Isrequired={true}
                  defaultValue={parentCourseVal?._id}
                  disabled={isEditMode}
                />
              </div>
              <div className="d-flex align-items-center ">
                <LabelField labelName={"Subject Name"} />
                <CustomDropdown
                  setInputText={(value) => onSubjectChange1(value)}
                  value={subject}
                  options={subjectList}
                  labelName="Select Subject Name"
                  reset={resetSubject}
                  Isrequired={true}
                  defaultValue={subject?._id}
                  disabled={isEditMode}
                />
              </div>
              <div className="d-flex align-items-center">
                <LabelField labelName={"Video Type"} />
                <CustomDropdown
                  setInputText={(value) => changeVideoList(value)}
                  value={videoName}
                  options={videoList}
                  labelName="Live and Recorded"
                  reset={resetVideoList}
                  Isrequired={true}
                  defaultValue={videoName?.id}
                />
              </div>
              {isEditMode ? (
                <div className="col-6">
                  <div className="d-flex">
                    <div className="assign-examCont ">
                      <label className="label">Teacher Name</label>
                    </div>
                    {teacherList.length !== 0 ? (
                      <>
                        <div
                          className="assign_container border w-75 rounded p-2 mb-2"
                          style={{ height: "237px", overflow: "auto" }}
                        >
                          <div
                            className=" input-border w-75 rounded p-2"
                            style={{ height: "237px" }}
                          >
                            {teacherList.map((bList, index) => {
                              let a = teacherArr?.findIndex((i) => {
                                return i === bList?._id;
                              });
                              return (
                                <div
                                  key={bList?._id}
                                  className="d-flex center p-1"
                                >
                                  <div>
                                    <div className="e-input d-flex">
                                      <input
                                        key={index}
                                        type="checkbox"
                                        id={bList?._id}
                                        name={bList?.name}
                                        checked={a < 0 ? false : true}
                                        onChange={(event) => onSelect(event)}
                                      />
                                      <span className="checkVal">
                                        {bList?.name}
                                      </span>
                                    </div>
                                  </div>
                                </div>
                              );
                            })}
                          </div>
                        </div>
                        <span className="required ms-2 ">*</span>
                      </>
                    ) : (
                      <>
                        <div className="w-100 p-2 me-3 mb-2 rounded border fw-bold">
                          Please Select Parent Course & Exam Name To view List
                        </div>
                        <span className="required ms-2 ">*</span>
                      </>
                    )}
                  </div>
                </div>
              ) : (
                <div className="d-flex align-items-center">
                  <LabelField labelName={"Teacher Name"} />
                  <CustomDropdown
                    setInputText={(value) => changeTeacherList(value)}
                    value={teacherName}
                    options={teacherList}
                    labelName="Select Teacher Name"
                    reset={resetTeacherList}
                    Isrequired={true}
                    defaultValue={teacherName?._id}
                  />
                </div>
              )}
            </div>
          </div>
          {!isEditMode ? renderListMode() : renderEditMode()}
        </div>
      </div>
      {open ? (
        <Modal
          aria-labelledby="transition-modal-title"
          aria-describedby="transition-modal-description"
          className="model"
          open={open}
          onClose={handleClose}
          closeAfterTransition
          BackdropComponent={Backdrop}
          BackdropProps={{
            timeout: 500,
          }}
        >
          <Fade in={open}>
            <div className="bookstockmodeldiv p-3">
              <div className="col mb-4 d-flex justify-content-between ">
                <p className="fw-bold">
                  <span style={{ color: "#3F51B5" }}> Delete Batch</span>
                </p>
                <ClearOutlinedIcon
                  className="colorPurple cursor"
                  onClick={handleClose}
                />
              </div>
              <div className="form-check">
                <LabelField labelName={"Batch Name"} />
                <div className="border rounded p-2 w-100">
                  {selectedbatch?.map((livevideo, index) => {
                    return (
                      <div key={index}>
                        <input
                          type="checkbox"
                          id={livevideo?._id}
                          defaultChecked={livevideo?.isSelected}
                          onChange={(event) => {
                            onBatchCheck(event, "singleCheck", index);
                          }}
                        />
                        <span className="checkVal">{livevideo?.name}</span>
                      </div>
                    );
                  })}
                </div>

                <div className="col-auto d-flex justify-content-center mt-5">
                  <button
                    onClick={() => saveAndUpdateBatchLive()}
                    type="submit"
                    className={"btn mb-3 submitbtn btn-primary"}
                  >
                    Save and Update
                  </button>
                </div>
              </div>
            </div>
          </Fade>
        </Modal>
      ) : null}
    </div>
  );
}

export default EditDeleteSyllabus;
