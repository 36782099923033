import "../../App.css";
import React from "react";

import PageTitle from "../../Module/pageTitle";

function AdmissionDetails() {
  return (
    <div className="contentBox">
      <PageTitle name={"ADMISSION DETAILS"} />
      <div className="formDiv">
        <div className="d-flex justify-content-end py-2">
          <button className="btn btn-outline-primary rounded-pill">
            Export Details
          </button>
        </div>
        <div>
          <table className="w-100">
            <thead>
              <tr>
                <th>S.N</th>
                <th>First Name</th>
                <th>Last Name</th>
                <th>Mobile No</th>
                <th>State</th>
                <th>City</th>
                <th>Admission Date</th>
                <th>Payment</th>
              </tr>
            </thead>
            <tbody>
              <tr></tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
}

export default AdmissionDetails;
