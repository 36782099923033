import React, { Suspense } from "react";
import Sidebar from "../../Module/sidebar";
import {
  SocialMediaManagerData,
  SocialMediaSideBarData,
} from "../../Module/SocialMediaPanel/socialMediaSidebarData";
import Loading from "../../Component/Loading";
import { Route, Routes } from "react-router";
import SocialAds from "./socialAds";
import ResultCompilation from "../../Module/resultCompilation";
import { useSelector } from "react-redux";

const LeadDashboard = React.lazy(() =>
  import("../CategoryPanel/leadDashboard")
);
const LeadCount = React.lazy(() => import("../CategoryPanel/leadCount"));
const LeadAnalysis = React.lazy(() => import("../CategoryPanel/leadAnalysis"));

const TargetStatus = React.lazy(() => import("../CategoryPanel/targetStatus"));
const CreateTarget = React.lazy(() => import("./createTargetSocialMedia"));
const SocialMediaDashboard = () => {
  const { designation } = useSelector((state) => ({
    designation: state.Auth?.userInfo?.designation,
  }));
  return (
    <div className="AppBox">
      {/* <Header /> */}
      <Sidebar
        data={
          designation === "YOUTUBE GROWTH MANAGER"
            ? SocialMediaManagerData
            : SocialMediaSideBarData
        }
      />
      <div className="dashboard">
        {
          <Suspense
            fallback={
              <div className="border p-1">
                <Loading />
              </div>
            }
          >
            <Routes>
              <Route path="/" element={<SocialAds />} />
              <Route path="socialAds" element={<SocialAds />} />
              <Route path="resultCompilation" element={<ResultCompilation />} />
              <Route path="leadDashboard" element={<LeadDashboard />} />
              <Route path="LeadCount" element={<LeadCount />} />
              <Route path="leadAnalysis" element={<LeadAnalysis />} />
              <Route path="targetStatus" element={<TargetStatus />} />
              <Route path="createTarget" element={<CreateTarget />} />
            </Routes>
          </Suspense>
        }
      </div>
    </div>
  );
};

export default SocialMediaDashboard;
