import React, { useState } from "react";
import InputField from "../../Component/inputField";
import { useDispatch } from "react-redux";
import {
  containsNumber,
  length_Ten,
  validateValueWithoutSpace,
} from "../../Utils/validators";
import { showErrorSnackbar } from "../../Action/snackbarAction";
import { validateIsNumberOnlyErrMsg } from "../../Utils/errorMsg";
import PageTitle from "../../Module/pageTitle";
import { deleteUser, getAllUser } from "../../Action/courseAction";
import NoRecord from "../../Component/noRecord";
import moment from "moment";

export default function UserDetails() {
  const dispatch = useDispatch();
  const [phoneNumber, setPhoneNumber] = useState("");
  const [UserData, setUserData] = useState();
  const onChangePhone = (value) => {
    if (value !== "") {
      if (!containsNumber(value)) {
        dispatch(showErrorSnackbar("Only Numbers are allowed!"));
        return;
      } else if (!validateValueWithoutSpace(value)) {
        dispatch(showErrorSnackbar(validateIsNumberOnlyErrMsg));
        return;
      } else {
        setPhoneNumber(value);
      }
    } else {
      setPhoneNumber(value);
    }
  };
  const phoneValidations = () => {
    return phoneNumber === "" || phoneNumber?.length !== 10;
  };

  const handleSearch = (e) => {
    e.preventDefault();
    dispatch(
      getAllUser(phoneNumber, (res) => {
        setUserData(res?.data);
      })
    );
  };

  const handleDelete = (id) => {
    dispatch(
      deleteUser(id, () => {
        setUserData([]);
        dispatch(
          getAllUser(phoneNumber, (res) => {
            setUserData(res?.data);
          })
        );
      })
    );
  };
  //   const renderUserDetails = () => {
  //     return (
  //       <div>
  //         <AdminTable
  //           tableData={UserData ? UserData : []}
  //           pagename={"userDetails"}
  //           tableHead={[
  //             "S.N",
  //             "First Name",
  //             "Last Name",
  //             "Mob No",
  //             "Email",
  //             "Is Mobile Verified",
  //             "Status",
  //             "Subscription Count",
  //             "Actions",
  //           ]}
  //           handleEdit={(value) => handleView(value)}
  //           handleDelete={(value) => handleView(value)}
  //         />
  //       </div>
  //     );
  //   };

  return (
    <div className="contentBox">
      <PageTitle
        name={"User Details"}
        // isEditMode={isEditMode}
        // onClick={handleCancelEditMode}
      />
      <form className="col-12 formWidth" onSubmit={handleSearch}>
        <div className="d-flex">
          <div className="col-5 me-5">
            <InputField
              labelName={"Phone Number"}
              placeholder={"Enter phone number"}
              value={phoneNumber}
              setInputText={(value) => onChangePhone(value)}
              maxLength={length_Ten}
              minLength={length_Ten}
              Isrequired={true}
            />
          </div>
          <div className="col-auto d-flex justify-content-center">
            <div className="col-auto d-flex justify-content-center">
              <button
                type="submit"
                className={`btn mb-3 submitbtn ${
                  phoneValidations() ? "btn-secondary" : "btn-primary"
                }`}
                disabled={phoneValidations()}
              >
                Search
              </button>
            </div>
          </div>
        </div>
      </form>
      {UserData && UserData?.length !== 0 ? (
        //   renderUserDetails()
        <div>
          {UserData?.map((item, index) => {
            return (
              <div className="boxshadow p-2 m-2" key={index}>
                <div className="mb-3">
                  <div className="fw-bold">User Details</div>
                  <table className="w-100 mb-2">
                    <tr>
                      <th>S.N</th>
                      <th>First Name</th>
                      <th>Last Name</th>
                      <th>Mob No</th>
                      <th>Email</th>
                      <th>Is Mobile Verified</th>
                      <th>Status</th>
                      <th>Subscription Count</th>
                      <th>Actions</th>
                    </tr>
                    <tr>
                      <td>{index + 1}</td>
                      <td>{item?.firstName}</td>
                      <td>{item?.lastName}</td>
                      <td>{item?.mobileNo}</td>
                      <td>{item?.email}</td>
                      <td>{item?.isMobileVerified === true ? "Yes" : "No"}</td>
                      <td>
                        {item?.status === 1
                          ? "Activate"
                          : item?.status === 9
                          ? "Deleted"
                          : "Deactivate"}
                      </td>
                      <td>{item?.userSubscriptions?.length}</td>
                      <td>
                        {" "}
                        <span
                          className="text-danger fw-bold cursor"
                          onClick={() => handleDelete(item?._id)}
                        >
                          {" "}
                          Delete{" "}
                        </span>
                      </td>
                    </tr>
                  </table>
                </div>
                <div className="mb-3">
                  <div className="fw-bold">User Subscription</div>
                  <table className="w-100 mb-2">
                    <tr>
                      <th>S.N</th>
                      <th>Exam Name</th>
                      <th>Sub Exam Name</th>
                      <th>Batch Name</th>
                      <th>Course Type</th>
                      <th>Course Activation</th>
                      <th>Activation End Date</th>
                    </tr>
                    {item?.userSubscriptions?.length > 0 ? (
                      item?.userSubscriptions?.map((subdata, i) => {
                        return (
                          <tr key={i}>
                            <td>{i + 1}</td>
                            <td>{subdata?.examTypeId?.name}</td>
                            <td>
                              {subdata?.subExamTypeId
                                ? subdata?.subExamTypeId?.name
                                : "-"}
                            </td>
                            <td>{subdata?.name}</td>
                            <td>
                              {subdata?.packageType === 1
                                ? "COURSE"
                                : subdata?.packageType === 2
                                ? "STUDY MATERIAL"
                                : subdata?.packageType === 3
                                ? "TEST SERIES"
                                : subdata?.packageType === 4
                                ? "E-BOOK"
                                : subdata?.packageType === 5
                                ? "LECTURE SERIES"
                                : "-"}
                            </td>
                            <td>{subdata?.status === 1 ? "Yes" : "No"}</td>
                            <td>
                              {" "}
                              {moment
                                .utc(subdata?.endDate)
                                .format("DD-MM-YYYY")}
                            </td>
                          </tr>
                        );
                      })
                    ) : (
                      <tr>
                        <td colSpan="7">
                          <NoRecord />
                        </td>
                      </tr>
                    )}
                  </table>
                </div>
              </div>
            );
          })}
        </div>
      ) : (
        <NoRecord />
      )}
    </div>
  );
}
