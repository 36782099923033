import endpoint from "../Constant/endpoint";
import { updateValues } from "./authAction";
import services from "../Constant/service";
import { showErrorSnackbar, showSuccessSnackbar } from "./snackbarAction";

export const postExamTypeSEO = (data, callback) => async (dispatch) => {
  dispatch(updateValues("loader", true));
  services.postApiCall(
    endpoint.seo.addExamTypeSEO,
    data,
    (res) => {
      dispatch(updateValues("loader", false));
      if (res?.statusCode === services?.status_code?.success) {
        dispatch(showSuccessSnackbar(res?.msg));
        callback(res);
      } else {
        dispatch(showErrorSnackbar(res?.msg));
      }
    },
    (error) => {
      dispatch(
        showErrorSnackbar(error?.data?.msg ? error?.data?.msg : error?.msg)
      );
      dispatch(updateValues("loader", false));
    }
  );
};

export const getExamTypeListSEO = (data, callback) => async (dispatch) => {
  dispatch(updateValues("loader", true));
  services.postApiCall(
    endpoint.seo.getExamTypeListSEO,
    data,
    (res) => {
      dispatch(updateValues("loader", false));
      if (res?.statusCode === services?.status_code?.success) {
        dispatch(showSuccessSnackbar(res?.msg));
        callback(res?.data);
      } else {
        dispatch(showErrorSnackbar(res?.msg));
      }
    },
    (error) => {
      dispatch(
        showErrorSnackbar(error?.data?.msg ? error?.data?.msg : error?.msg)
      );
      dispatch(updateValues("loader", false));
    }
  );
};

export const patchExamTypeSEO = (data, id, callback) => async (dispatch) => {
  dispatch(updateValues("loader", true));
  services.patchApiCall(
    endpoint.seo.patchExamTypeSEO(id),
    data,
    (res) => {
      dispatch(updateValues("loader", false));
      if (res?.data?.statusCode === services?.status_code?.success) {
        dispatch(showSuccessSnackbar(res?.data?.msg));
        callback(res?.data.data);
      } else {
        dispatch(showErrorSnackbar(res?.data?.msg));
      }
    },
    (error) => {
      dispatch(
        showErrorSnackbar(error?.data?.msg ? error?.data?.msg : error?.msg)
      );
      dispatch(updateValues("loader", false));
    }
  );
};

export const deleteExamTypeSEO = (id, callback) => async (dispatch) => {
  dispatch(updateValues("loader", true));
  services.deleteApiCall(
    endpoint.seo.deleteExamTypeSEO(id),
    "",
    (res) => {
      dispatch(updateValues("loader", false));
      if (res?.data?.statusCode === services?.status_code?.success) {
        dispatch(showSuccessSnackbar(res?.data?.msg));
        callback(res);
      } else {
        dispatch(showErrorSnackbar(res?.data?.msg));
      }
    },
    (error) => {
      dispatch(
        showErrorSnackbar(error?.data?.msg ? error?.data?.msg : error?.msg)
      );
      dispatch(updateValues("loader", false));
    }
  );
};

export const postCourseSEO = (data, callback) => async (dispatch) => {
  dispatch(updateValues("loader", true));
  services.postApiCall(
    endpoint.seo.addCourseSEO,
    data,
    (res) => {
      dispatch(updateValues("loader", false));
      if (res?.statusCode === services?.status_code?.success) {
        dispatch(showSuccessSnackbar(res?.msg));
        callback(res);
      } else {
        dispatch(showErrorSnackbar(res?.msg));
      }
    },
    (error) => {
      dispatch(
        showErrorSnackbar(error?.data?.msg ? error?.data?.msg : error?.msg)
      );
      dispatch(updateValues("loader", false));
    }
  );
};
export const getCourseListSEO = (data, callback) => async (dispatch) => {
  dispatch(updateValues("loader", true));
  services.postApiCall(
    endpoint.seo.getCourseListSEO,
    data,
    (res) => {
      dispatch(updateValues("loader", false));
      if (res?.statusCode === services?.status_code?.success) {
        dispatch(showSuccessSnackbar(res?.msg));
        callback(res?.data);
      } else {
        dispatch(showErrorSnackbar(res?.msg));
      }
    },
    (error) => {
      dispatch(
        showErrorSnackbar(error?.data?.msg ? error?.data?.msg : error?.msg)
      );
      dispatch(updateValues("loader", false));
    }
  );
};

export const patchCourseSEO = (data, id, callback) => async (dispatch) => {
  dispatch(updateValues("loader", true));
  services.patchApiCall(
    endpoint.seo.patchCourseSEO(id),
    data,
    (res) => {
      dispatch(updateValues("loader", false));
      if (res?.data?.statusCode === services?.status_code?.success) {
        dispatch(showSuccessSnackbar(res?.data?.msg));
        callback(res?.data.data);
      } else {
        dispatch(showErrorSnackbar(res?.data?.msg));
      }
    },
    (error) => {
      dispatch(
        showErrorSnackbar(error?.data?.msg ? error?.data?.msg : error?.msg)
      );
      dispatch(updateValues("loader", false));
    }
  );
};

export const deleteCourseSEO = (id, callback) => async (dispatch) => {
  dispatch(updateValues("loader", true));
  services.deleteApiCall(
    endpoint.seo.deleteCourseSEO(id),
    "",
    (res) => {
      dispatch(updateValues("loader", false));
      if (res?.data?.statusCode === services?.status_code?.success) {
        dispatch(showSuccessSnackbar(res?.data?.msg));
        callback(res);
      } else {
        dispatch(showErrorSnackbar(res?.data?.msg));
      }
    },
    (error) => {
      dispatch(
        showErrorSnackbar(error?.data?.msg ? error?.data?.msg : error?.msg)
      );
      dispatch(updateValues("loader", false));
    }
  );
};

export const postExamStreamSEO = (data, callback) => async (dispatch) => {
  dispatch(updateValues("loader", true));
  services.postApiCall(
    endpoint.seo.addExamStreamSEO,
    data,
    (res) => {
      dispatch(updateValues("loader", false));
      if (res?.statusCode === services?.status_code?.success) {
        dispatch(showSuccessSnackbar(res?.msg));
        callback(res);
      } else {
        dispatch(showErrorSnackbar(res?.msg));
      }
    },
    (error) => {
      dispatch(
        showErrorSnackbar(error?.data?.msg ? error?.data?.msg : error?.msg)
      );
      dispatch(updateValues("loader", false));
    }
  );
};

export const getExamStreamListSEO = (data, callback) => async (dispatch) => {
  dispatch(updateValues("loader", true));
  services.postApiCall(
    endpoint.seo.getExamStreamListSEO,
    data,
    (res) => {
      dispatch(updateValues("loader", false));
      if (res?.statusCode === services?.status_code?.success) {
        dispatch(showSuccessSnackbar(res?.msg));
        callback(res?.data);
      } else {
        dispatch(showErrorSnackbar(res?.msg));
      }
    },
    (error) => {
      dispatch(
        showErrorSnackbar(error?.data?.msg ? error?.data?.msg : error?.msg)
      );
      dispatch(updateValues("loader", false));
    }
  );
};

export const patchExamStreamSEO = (data, id, callback) => async (dispatch) => {
  dispatch(updateValues("loader", true));
  services.patchApiCall(
    endpoint.seo.patchExamStreamSEO(id),
    data,
    (res) => {
      dispatch(updateValues("loader", false));
      if (res?.data?.statusCode === services?.status_code?.success) {
        dispatch(showSuccessSnackbar(res?.data?.msg));
        callback(res?.data.data);
      } else {
        dispatch(showErrorSnackbar(res?.data?.msg));
      }
    },
    (error) => {
      dispatch(
        showErrorSnackbar(error?.data?.msg ? error?.data?.msg : error?.msg)
      );
      dispatch(updateValues("loader", false));
    }
  );
};

export const deleteExamStreamSEO = (id, callback) => async (dispatch) => {
  dispatch(updateValues("loader", true));
  services.deleteApiCall(
    endpoint.seo.deleteExamStreamSEO(id),
    "",
    (res) => {
      dispatch(updateValues("loader", false));
      if (res?.data?.statusCode === services?.status_code?.success) {
        dispatch(showSuccessSnackbar(res?.data?.msg));
        callback(res);
      } else {
        dispatch(showErrorSnackbar(res?.data?.msg));
      }
    },
    (error) => {
      dispatch(
        showErrorSnackbar(error?.data?.msg ? error?.data?.msg : error?.msg)
      );
      dispatch(updateValues("loader", false));
    }
  );
};

export const addFAQType = (data, callback) => async (dispatch) => {
  dispatch(updateValues("loader", true));
  services.postApiCall(
    endpoint.seo.addFAQType,
    data,
    (res) => {
      dispatch(updateValues("loader", false));
      if (res?.statusCode === services?.status_code?.success) {
        dispatch(showSuccessSnackbar(res?.msg));
        callback(res);
      } else {
        dispatch(showErrorSnackbar(res?.msg));
      }
    },
    (error) => {
      dispatch(
        showErrorSnackbar(error?.data?.msg ? error?.data?.msg : error?.msg)
      );
      dispatch(updateValues("loader", false));
    }
  );
};

export const getFAQType = (data, callback) => async (dispatch) => {
  dispatch(updateValues("loader", true));
  services.getApiCall(
    endpoint.seo.getFAQType,
    data,
    (res) => {
      dispatch(updateValues("loader", false));
      if (res?.data?.statusCode === services?.status_code?.success) {
        dispatch(showSuccessSnackbar(res?.data?.msg));
        callback(res?.data?.data);
      } else {
        dispatch(showErrorSnackbar(res?.msg));
      }
    },
    (error) => {
      dispatch(
        showErrorSnackbar(error?.data?.msg ? error?.data?.msg : error?.msg)
      );
      dispatch(updateValues("loader", false));
    }
  );
};

export const addFAQ = (data, callback) => async (dispatch) => {
  dispatch(updateValues("loader", true));
  services.postApiCall(
    endpoint.seo.addFAQ,
    data,
    (res) => {
      dispatch(updateValues("loader", false));
      if (res?.statusCode === services?.status_code?.success) {
        dispatch(showSuccessSnackbar(res?.msg));
        callback(res);
      } else {
        dispatch(showErrorSnackbar(res?.msg));
      }
    },
    (error) => {
      dispatch(
        showErrorSnackbar(error?.data?.msg ? error?.data?.msg : error?.msg)
      );
      dispatch(updateValues("loader", false));
    }
  );
};

export const getFAQList = (data, callback) => async (dispatch) => {
  dispatch(updateValues("loader", true));
  services.postApiCall(
    endpoint.seo.getFAQList,
    data,
    (res) => {
      dispatch(updateValues("loader", false));
      if (res?.statusCode === services?.status_code?.success) {
        dispatch(showSuccessSnackbar(res?.msg));
        callback(res);
      } else {
        dispatch(showErrorSnackbar(res?.msg));
      }
    },
    (error) => {
      dispatch(
        showErrorSnackbar(error?.data?.msg ? error?.data?.msg : error?.msg)
      );
      dispatch(updateValues("loader", false));
    }
  );
};

export const updateFAQ = (data, id, callback) => async (dispatch) => {
  dispatch(updateValues("loader", true));
  services.patchApiCall(
    endpoint.seo.updateFAQ + id,
    data,
    (res) => {
      dispatch(updateValues("loader", false));
      if (res?.data?.statusCode === services?.status_code?.success) {
        dispatch(showSuccessSnackbar(res?.data?.msg));
        callback(res?.data.data);
      } else {
        dispatch(showErrorSnackbar(res?.data?.msg));
      }
    },
    (error) => {
      dispatch(
        showErrorSnackbar(error?.data?.msg ? error?.data?.msg : error?.msg)
      );
      dispatch(updateValues("loader", false));
    }
  );
};
export const deleteFAQ = (id, callback) => async (dispatch) => {
  dispatch(updateValues("loader", true));
  services.deleteApiCall(
    endpoint.seo.deleteFAQ + id,
    "",
    (res) => {
      dispatch(updateValues("loader", false));
      if (res?.data?.statusCode === services?.status_code?.success) {
        dispatch(showSuccessSnackbar(res?.data?.msg));
        callback(res);
      } else {
        dispatch(showErrorSnackbar(res?.data?.msg));
      }
    },
    (error) => {
      dispatch(
        showErrorSnackbar(error?.data?.msg ? error?.data?.msg : error?.msg)
      );
      dispatch(updateValues("loader", false));
    }
  );
};

/***********************addProductSchema************************* */
export const addProductSchema = (data, callback) => async (dispatch) => {
  dispatch(updateValues("loader", true));
  services.postApiCall(
    endpoint.seo.addProductSchema,
    data,
    (res) => {
      dispatch(updateValues("loader", false));
      if (res?.statusCode === services?.status_code?.success) {
        dispatch(showSuccessSnackbar(res?.msg));
        callback(res);
      } else {
        dispatch(showErrorSnackbar(res?.msg));
      }
    },
    (error) => {
      dispatch(
        showErrorSnackbar(error?.data?.msg ? error?.data?.msg : error?.msg)
      );
      dispatch(updateValues("loader", false));
    }
  );
};

export const getProductSchema = (data, callback) => async (dispatch) => {
  dispatch(updateValues("loader", true));
  services.postApiCall(
    endpoint.seo.getProductSchema,
    data,
    (res) => {
      dispatch(updateValues("loader", false));
      if (res?.statusCode === services?.status_code?.success) {
        dispatch(showSuccessSnackbar(res?.msg));
        callback(res);
      } else {
        dispatch(showErrorSnackbar(res?.msg));
      }
    },
    (error) => {
      dispatch(
        showErrorSnackbar(error?.data?.msg ? error?.data?.msg : error?.msg)
      );
      dispatch(updateValues("loader", false));
    }
  );
};

export const updateProductSchema = (data, id, callback) => async (dispatch) => {
  dispatch(updateValues("loader", true));
  services.patchApiCall(
    endpoint.seo.updateProductSchema + id,
    data,
    (res) => {
      dispatch(updateValues("loader", false));
      if (res?.data?.statusCode === services?.status_code?.success) {
        dispatch(showSuccessSnackbar(res?.data?.msg));
        callback(res?.data.data);
      } else {
        dispatch(showErrorSnackbar(res?.data?.msg));
      }
    },
    (error) => {
      dispatch(
        showErrorSnackbar(error?.data?.msg ? error?.data?.msg : error?.msg)
      );
      dispatch(updateValues("loader", false));
    }
  );
};

export const deleteProductSchema = (id, callback) => async (dispatch) => {
  dispatch(updateValues("loader", true));
  services.deleteApiCall(
    endpoint.seo.deleteProductSchema(id),
    "",
    (res) => {
      dispatch(updateValues("loader", false));
      if (res?.data?.statusCode === services?.status_code?.success) {
        dispatch(showSuccessSnackbar(res?.data?.msg));
        callback(res);
      } else {
        dispatch(showErrorSnackbar(res?.data?.msg));
      }
    },
    (error) => {
      dispatch(
        showErrorSnackbar(error?.data?.msg ? error?.data?.msg : error?.msg)
      );
      dispatch(updateValues("loader", false));
    }
  );
};

export const setUploadAuthorImage = (data, callback) => async (dispatch) => {
  dispatch(updateValues("loader", true));
  services.postUploadMedia(
    endpoint.seo.setUploadAuthorImage,
    data,
    (res) => {
      dispatch(updateValues("loader", false));
      if (res?.statusCode === services?.status_code?.success) {
        callback(res.data);
      } else {
        dispatch(showErrorSnackbar(res?.msg));
      }
    },
    (error) => {
      dispatch(
        showErrorSnackbar(error?.data?.msg ? error?.data?.msg : error?.msg)
      );
      dispatch(updateValues("loader", false));
    }
  );
};

export const addAuthor = (data, callback) => async (dispatch) => {
  dispatch(updateValues("loader", true));
  services.postApiCall(
    endpoint.seo.addAuthor,
    data,
    (res) => {
      dispatch(updateValues("loader", false));
      if (res?.statusCode === services?.status_code?.success) {
        dispatch(showSuccessSnackbar(res?.msg));
        callback(res?.data);
      } else {
        dispatch(showErrorSnackbar(res?.msg));
        callback([]);
      }
    },
    (error) => {
      dispatch(
        showErrorSnackbar(error?.data?.msg ? error?.data?.msg : error?.msg)
      );
      dispatch(updateValues("loader", false));
    }
  );
};

export const getAuthor = (data, callback) => async (dispatch) => {
  dispatch(updateValues("loader", true));
  services.getApiCall(
    endpoint.seo.getAuthor,
    data,
    (res) => {
      dispatch(updateValues("loader", false));
      if (res?.data?.statusCode === services?.status_code?.success) {
        dispatch(showSuccessSnackbar(res?.data?.msg));
        callback(res?.data?.data);
      } else {
        dispatch(showErrorSnackbar(res?.msg));
      }
    },
    (error) => {
      dispatch(
        showErrorSnackbar(error?.data?.msg ? error?.data?.msg : error?.msg)
      );
      dispatch(updateValues("loader", false));
    }
  );
};

export const updateAuthorDetails = (data, id, callback) => async (dispatch) => {
  dispatch(updateValues("loader", true));
  services.patchApiCall(
    endpoint.seo.updateAuthorDetails + id,
    data,
    (res) => {
      dispatch(updateValues("loader", false));
      if (res?.data?.statusCode === services?.status_code?.success) {
        dispatch(showSuccessSnackbar(res?.data?.msg));
        callback(res?.data.data);
      } else {
        dispatch(showErrorSnackbar(res?.data?.msg));
      }
    },
    (error) => {
      dispatch(
        showErrorSnackbar(error?.data?.msg ? error?.data?.msg : error?.msg)
      );
      dispatch(updateValues("loader", false));
    }
  );
};

export const deleteAuthor = (id, callback) => async (dispatch) => {
  dispatch(updateValues("loader", true));
  services.deleteApiCall(
    endpoint.seo.deleteAuthor(id),
    "",
    (res) => {
      dispatch(updateValues("loader", false));
      if (res?.data?.statusCode === services?.status_code?.success) {
        dispatch(showSuccessSnackbar(res?.data?.msg));
        callback(res);
      } else {
        dispatch(showErrorSnackbar(res?.data?.msg));
      }
    },
    (error) => {
      dispatch(
        showErrorSnackbar(error?.data?.msg ? error?.data?.msg : error?.msg)
      );
      dispatch(updateValues("loader", false));
    }
  );
};

export const getResourcePage = (data, callback) => async (dispatch) => {
  dispatch(updateValues("loader", true));
  services.getApiCall(
    endpoint.seo.getResourcePage,
    data,
    (res) => {
      dispatch(updateValues("loader", false));
      if (res?.data?.statusCode === services?.status_code?.success) {
        callback(res?.data);
      } else {
        dispatch(showErrorSnackbar(res?.msg));
      }
    },
    (error) => {
      dispatch(
        showErrorSnackbar(error?.data?.msg ? error?.data?.msg : error?.msg)
      );
      dispatch(updateValues("loader", false));
    }
  );
};

export const addResourceSEO = (data, callback) => async (dispatch) => {
  dispatch(updateValues("loader", true));
  services.postApiCall(
    endpoint.seo.addResourceSEO,
    data,
    (res) => {
      dispatch(updateValues("loader", false));
      if (res?.statusCode === services?.status_code?.success) {
        dispatch(showSuccessSnackbar(res?.msg));
        callback(res);
      } else {
        dispatch(showErrorSnackbar(res?.msg));
      }
    },
    (error) => {
      dispatch(
        showErrorSnackbar(error?.data?.msg ? error?.data?.msg : error?.msg)
      );
      dispatch(updateValues("loader", false));
    }
  );
};

export const getResourceSEO = (data, callback) => async (dispatch) => {
  dispatch(updateValues("loader", true));
  services.postApiCall(
    endpoint.seo.getResourceSEO,
    data,
    (res) => {
      dispatch(updateValues("loader", false));
      if (res?.statusCode === services?.status_code?.success) {
        callback(res);
      } else {
        dispatch(showErrorSnackbar(res?.msg));
      }
    },
    (error) => {
      dispatch(
        showErrorSnackbar(error?.data?.msg ? error?.data?.msg : error?.msg)
      );
      dispatch(updateValues("loader", false));
    }
  );
};

export const getOnlineCoachingDetail = (data, callback) => async (dispatch) => {
  dispatch(updateValues("loader", true));
  services.postApiCall(
    endpoint.seo.getOnlineCoachingDetail,
    data,
    (res) => {
      dispatch(updateValues("loader", false));
      if (res?.statusCode === services?.status_code?.success) {
        callback(res);
      } else {
        dispatch(showErrorSnackbar(res?.msg));
      }
    },
    (error) => {
      dispatch(
        showErrorSnackbar(error?.data?.msg ? error?.data?.msg : error?.msg)
      );
      dispatch(updateValues("loader", false));
    }
  );
};
export const addOnlineCoachingDetail = (data, callback) => async (dispatch) => {
  dispatch(updateValues("loader", true));
  services.postApiCall(
    endpoint.seo.addOnlineCoachingDetail,
    data,
    (res) => {
      dispatch(updateValues("loader", false));
      if (res?.statusCode === services?.status_code?.success) {
        callback(res);
      } else {
        dispatch(showErrorSnackbar(res?.msg));
      }
    },
    (error) => {
      dispatch(
        showErrorSnackbar(error?.data?.msg ? error?.data?.msg : error?.msg)
      );
      dispatch(updateValues("loader", false));
    }
  );
};

export const getResourceContent = (data, callback) => async (dispatch) => {
  dispatch(updateValues("loader", true));
  services.postApiCall(
    endpoint.seo.getResourceContent,
    data,
    (res) => {
      dispatch(updateValues("loader", false));
      if (res?.statusCode === services?.status_code?.success) {
        callback(res);
      } else {
        dispatch(showErrorSnackbar(res?.msg));
      }
    },
    (error) => {
      dispatch(
        showErrorSnackbar(error?.data?.msg ? error?.data?.msg : error?.msg)
      );
      dispatch(updateValues("loader", false));
    }
  );
};

export const addResourceContent = (data, callback) => async (dispatch) => {
  dispatch(updateValues("loader", true));
  services.postApiCall(
    endpoint.seo.addResourceContent,
    data,
    (res) => {
      dispatch(updateValues("loader", false));
      if (res?.statusCode === services?.status_code?.success) {
        callback(res);
      } else {
        dispatch(showErrorSnackbar(res?.msg));
      }
    },
    (error) => {
      dispatch(
        showErrorSnackbar(error?.data?.msg ? error?.data?.msg : error?.msg)
      );
      dispatch(updateValues("loader", false));
    }
  );
};
export const getAddExamTypeSEO = (data, callback) => async (dispatch) => {
  dispatch(updateValues("loader", true));
  services.postApiCall(
    endpoint.seo.getAddExamTypeSEO,
    data,
    (res) => {
      dispatch(updateValues("loader", false));
      if (res?.statusCode === services?.status_code?.success) {
        dispatch(showSuccessSnackbar(res?.msg));
        callback(res);
      } else {
        dispatch(showErrorSnackbar(res?.msg));
      }
    },
    (error) => {
      dispatch(
        showErrorSnackbar(error?.data?.msg ? error?.data?.msg : error?.msg)
      );
      dispatch(updateValues("loader", false));
    }
  );
};
export const getAddSubExamTypeSEO = (data, callback) => async (dispatch) => {
  dispatch(updateValues("loader", true));
  services.postApiCall(
    endpoint.seo.getAddSubExamTypeSEO,
    data,
    (res) => {
      dispatch(updateValues("loader", false));
      if (res?.statusCode === services?.status_code?.success) {
        dispatch(showSuccessSnackbar(res?.msg));
        callback(res);
      } else {
        dispatch(showErrorSnackbar(res?.msg));
      }
    },
    (error) => {
      dispatch(
        showErrorSnackbar(error?.data?.msg ? error?.data?.msg : error?.msg)
      );
      dispatch(updateValues("loader", false));
    }
  );
};
export const getAddExamStreamTypeSEO = (data, callback) => async (dispatch) => {
  dispatch(updateValues("loader", true));
  services.postApiCall(
    endpoint.seo.getAddExamStreamTypeSEO,
    data,
    (res) => {
      dispatch(updateValues("loader", false));
      if (res?.statusCode === services?.status_code?.success) {
        dispatch(showSuccessSnackbar(res?.msg));
        callback(res);
      } else {
        dispatch(showErrorSnackbar(res?.msg));
      }
    },
    (error) => {
      dispatch(
        showErrorSnackbar(error?.data?.msg ? error?.data?.msg : error?.msg)
      );
      dispatch(updateValues("loader", false));
    }
  );
};

export const uploadSEOBlogsBanner = (data, callback) => async (dispatch) => {
  dispatch(updateValues("loader", true));
  services.postUploadMedia(
    endpoint.seo.uploadSEOBlogsBanner,
    data,
    (res) => {
      dispatch(updateValues("loader", false));
      if (res?.statusCode === services?.status_code?.success) {
        callback(res?.data);
      } else {
        dispatch(showErrorSnackbar(res?.msg));
      }
    },
    (error) => {
      dispatch(
        showErrorSnackbar(error?.data?.msg ? error?.data?.msg : error?.msg)
      );
      dispatch(updateValues("loader", false));
    }
  );
};

export const addSEOBlogs = (data, callback) => async (dispatch) => {
  dispatch(updateValues("loader", true));
  services.postApiCall(
    endpoint.seo.addSEOBlogs,
    data,
    (res) => {
      dispatch(updateValues("loader", false));
      if (res?.statusCode === services?.status_code?.success) {
        callback(res?.data);
      } else {
        dispatch(showErrorSnackbar(res?.msg));
      }
    },
    (error) => {
      dispatch(
        showErrorSnackbar(error?.data?.msg ? error?.data?.msg : error?.msg)
      );
      dispatch(updateValues("loader", false));
    }
  );
};

export const getSEOBlogs = (data, callback) => async (dispatch) => {
  dispatch(updateValues("loader", true));
  services.postApiCall(
    endpoint.seo.getSEOBlogs,
    data,
    (res) => {
      dispatch(updateValues("loader", false));
      if (res?.statusCode === services?.status_code?.success) {
        dispatch(showSuccessSnackbar(res?.msg));
        callback(res?.data);
      } else {
        dispatch(showErrorSnackbar(res?.msg));
      }
    },
    (error) => {
      dispatch(
        showErrorSnackbar(error?.data?.msg ? error?.data?.msg : error?.msg)
      );
      dispatch(updateValues("loader", false));
    }
  );
};
export const updateSEOBlogs = (id, data, callback) => async (dispatch) => {
  dispatch(updateValues("loader", true));
  services.patchApiCall(
    `${endpoint.seo.updateSEOBlogs}${id}`,
    data,
    (res) => {
      dispatch(updateValues("loader", false));
      if (res?.status === services?.status_code?.success) {
        dispatch(showSuccessSnackbar(res?.data?.msg));
        callback(res?.data);
      } else {
        dispatch(showErrorSnackbar(res?.msg));
      }
    },
    (error) => {
      dispatch(
        showErrorSnackbar(error?.data?.msg ? error?.data?.msg : error?.msg)
      );
      dispatch(updateValues("loader", false));
    }
  );
};
export const deleteSEOBlogs = (id, callback) => async (dispatch) => {
  dispatch(updateValues("loader", true));
  services.deleteApiCall(
    `${endpoint.seo.deleteSEOBlogs}${id}`,
    "",
    (res) => {
      dispatch(updateValues("loader", false));
      if (res?.status === services?.status_code?.success) {
        dispatch(showSuccessSnackbar(res?.data?.msg));
        callback(res);
      } else {
        dispatch(showErrorSnackbar(res?.data?.msg));
      }
    },
    (error) => {
      dispatch(
        showErrorSnackbar(error?.data?.msg ? error?.data?.msg : error?.msg)
      );
      dispatch(updateValues("loader", false));
    }
  );
};
export const addResourseFAQ = (data, callback) => async (dispatch) => {
  dispatch(updateValues("loader", true));
  services.postApiCall(
    endpoint.seo.addResourseFAQ,
    data,
    (res) => {
      dispatch(updateValues("loader", false));
      if (res?.statusCode === services?.status_code?.success) {
        dispatch(showSuccessSnackbar(res?.msg));
        callback(res);
      } else {
        dispatch(showErrorSnackbar(res?.msg));
      }
    },
    (error) => {
      dispatch(
        showErrorSnackbar(error?.data?.msg ? error?.data?.msg : error?.msg)
      );
      dispatch(updateValues("loader", false));
    }
  );
};
export const getResourseFAQList = (data, callback) => async (dispatch) => {
  dispatch(updateValues("loader", true));
  services.postApiCall(
    endpoint.seo.getResourseFAQList,
    data,
    (res) => {
      dispatch(updateValues("loader", false));
      if (res?.statusCode === services?.status_code?.success) {
        dispatch(showSuccessSnackbar(res?.msg));
        callback(res);
      } else {
        dispatch(showErrorSnackbar(res?.msg));
      }
    },
    (error) => {
      dispatch(
        showErrorSnackbar(error?.data?.msg ? error?.data?.msg : error?.msg)
      );
      dispatch(updateValues("loader", false));
    }
  );
};
export const updateResourseFAQ = (data, id, callback) => async (dispatch) => {
  dispatch(updateValues("loader", true));
  services.patchApiCall(
    endpoint.seo.updateResourseFAQ + id,
    data,
    (res) => {
      dispatch(updateValues("loader", false));
      if (res?.data?.statusCode === services?.status_code?.success) {
        dispatch(showSuccessSnackbar(res?.data?.msg));
        callback(res?.data.data);
      } else {
        dispatch(showErrorSnackbar(res?.data?.msg));
      }
    },
    (error) => {
      dispatch(
        showErrorSnackbar(error?.data?.msg ? error?.data?.msg : error?.msg)
      );
      dispatch(updateValues("loader", false));
    }
  );
};

export const deleteResourseFAQ = (id, callback) => async (dispatch) => {
  dispatch(updateValues("loader", true));
  services.deleteApiCall(
    endpoint.seo.deleteResourseFAQ + id,
    "",
    (res) => {
      dispatch(updateValues("loader", false));
      if (res?.data?.statusCode === services?.status_code?.success) {
        dispatch(showSuccessSnackbar(res?.data?.msg));
        callback(res);
      } else {
        dispatch(showErrorSnackbar(res?.data?.msg));
      }
    },
    (error) => {
      dispatch(
        showErrorSnackbar(error?.data?.msg ? error?.data?.msg : error?.msg)
      );
      dispatch(updateValues("loader", false));
    }
  );
};

export const getResourceProduct = (data, callback) => async (dispatch) => {
  dispatch(updateValues("loader", true));
  services.postApiCall(
    endpoint.seo.getResourceProduct,
    data,
    (res) => {
      dispatch(updateValues("loader", false));
      if (res?.statusCode === services?.status_code?.success) {
        dispatch(showSuccessSnackbar(res?.msg));
        callback(res);
      } else {
        dispatch(showErrorSnackbar(res?.msg));
      }
    },
    (error) => {
      dispatch(
        showErrorSnackbar(error?.data?.msg ? error?.data?.msg : error?.msg)
      );
      dispatch(updateValues("loader", false));
    }
  );
};

export const addResourceProduct = (data, callback) => async (dispatch) => {
  dispatch(updateValues("loader", true));
  services.postApiCall(
    endpoint.seo.addResourceProduct,
    data,
    (res) => {
      dispatch(updateValues("loader", false));
      if (res?.statusCode === services?.status_code?.success) {
        dispatch(showSuccessSnackbar(res?.msg));
        callback(res);
      } else {
        dispatch(showErrorSnackbar(res?.msg));
      }
    },
    (error) => {
      dispatch(
        showErrorSnackbar(error?.data?.msg ? error?.data?.msg : error?.msg)
      );
      dispatch(updateValues("loader", false));
    }
  );
};
export const updateResourceProduct =
  (data, id, callback) => async (dispatch) => {
    dispatch(updateValues("loader", true));
    services.patchApiCall(
      endpoint.seo.updateResourceProduct + id,
      data,
      (res) => {
        dispatch(updateValues("loader", false));
        if (res?.data?.statusCode === services?.status_code?.success) {
          dispatch(showSuccessSnackbar(res?.data?.msg));
          callback(res?.data.data);
        } else {
          dispatch(showErrorSnackbar(res?.data?.msg));
        }
      },
      (error) => {
        dispatch(
          showErrorSnackbar(error?.data?.msg ? error?.data?.msg : error?.msg)
        );
        dispatch(updateValues("loader", false));
      }
    );
  };
export const uploadResourceProductImg =
  (data, callback) => async (dispatch) => {
    dispatch(updateValues("loader", true));
    services.postUploadMedia(
      endpoint.seo.uploadResourceProductImg,
      data,
      (res) => {
        dispatch(updateValues("loader", false));
        if (res?.statusCode === services?.status_code?.success) {
          callback(res.data);
        } else {
          dispatch(showErrorSnackbar(res?.msg));
        }
      },
      (error) => {
        dispatch(
          showErrorSnackbar(error?.data?.msg ? error?.data?.msg : error?.msg)
        );
        dispatch(updateValues("loader", false));
      }
    );
  };

export const addSEOExam = (data, callback) => async (dispatch) => {
  dispatch(updateValues("loader", true));
  services.postApiCall(
    endpoint.seo.addSEOExam,
    data,
    (res) => {
      dispatch(updateValues("loader", false));
      if (res?.statusCode === services?.status_code?.success) {
        dispatch(showSuccessSnackbar(res?.msg));
        callback(res?.data);
      } else {
        dispatch(showErrorSnackbar(res?.msg));
        callback([]);
      }
    },
    (error) => {
      dispatch(
        showErrorSnackbar(error?.data?.msg ? error?.data?.msg : error?.msg)
      );
      dispatch(updateValues("loader", false));
    }
  );
};

export const getListSEOExam = (data, callback) => async (dispatch) => {
  dispatch(updateValues("loader", true));
  services.postApiCall(
    endpoint.seo.getListSEOExam,
    data,
    (res) => {
      dispatch(updateValues("loader", false));
      if (res?.statusCode === services?.status_code?.success) {
        dispatch(showSuccessSnackbar(res?.msg));
        callback(res?.data);
      } else {
        dispatch(showErrorSnackbar(res?.msg));
      }
    },
    (error) => {
      dispatch(
        showErrorSnackbar(error?.data?.msg ? error?.data?.msg : error?.msg)
      );
      dispatch(updateValues("loader", false));
    }
  );
};

export const updateSEOExam = (data, id, callback) => async (dispatch) => {
  dispatch(updateValues("loader", true));
  services.patchApiCall(
    endpoint.seo.updateSEOExam + id,
    data,
    (res) => {
      dispatch(updateValues("loader", false));
      if (res?.data?.statusCode === services?.status_code?.success) {
        dispatch(showSuccessSnackbar(res?.data?.msg));
        callback(res?.data.data);
      } else {
        dispatch(showErrorSnackbar(res?.data?.msg));
      }
    },
    (error) => {
      dispatch(
        showErrorSnackbar(error?.data?.msg ? error?.data?.msg : error?.msg)
      );
      dispatch(updateValues("loader", false));
    }
  );
};

export const getResourcePageDetail = (data, callback) => async (dispatch) => {
  dispatch(updateValues("loader", true));
  services.postApiCall(
    endpoint.seo.getResourcePageDetail,
    data,
    (res) => {
      dispatch(updateValues("loader", false));
      if (res?.statusCode === services?.status_code?.success) {
        dispatch(showSuccessSnackbar(res?.msg));
        callback(res);
      } else {
        dispatch(showErrorSnackbar(res?.msg));
      }
    },
    (error) => {
      dispatch(
        showErrorSnackbar(error?.data?.msg ? error?.data?.msg : error?.msg)
      );
      dispatch(updateValues("loader", false));
    }
  );
};
