import React, { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import PageTitle from "../../Module/pageTitle";
import CustomDropdown from "../../Component/customDropdown";
import {
  ResultOtherDetails,
  ScoreCardList,
  yearData,
  monthData,
} from "../../Utils/dataConstants";
import LabelField from "../../Component/labelField";
import InputField from "../../Component/inputField";
import ImagePickerAndViewer from "../../Component/imagePickerAndViewer";
import {
  addResultCompilation,
  getCasteCategoryList,
  getExamListNew,
  getParentCourseNew,
  getVerifyStudentNumber,
  uploadResultImage,
} from "../../Action/courseAction";
import {
  showErrorSnackbar,
  showSuccessSnackbar,
} from "../../Action/snackbarAction";
import {
  validateIsFileImageTypeErrMsg,
  validateIsFilePDFTypeErrMsg,
  validateIsNumberOnlyErrMsg,
} from "../../Utils/errorMsg";
import {
  containsNumber,
  image_size_5MB,
  length_Ten,
  pdf_size_1MB,
  validateIsFileImageType,
  validateIsFilePDFType,
  validateValueWithoutSpace,
} from "../../Utils/validators";
import { useSelector } from "react-redux";

export default function ResultCompilation() {
  const dispatch = useDispatch();

  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [exam, setExam] = useState("");
  const [examStream, setExamStream] = useState("");
  const [examList, setExamList] = useState([]);
  const [examStreamList, setExamStreamList] = useState([]);
  const [rank, setRank] = useState("");
  const [photo, setPhoto] = useState("");
  const [category, setCategory] = useState("");
  const [categoryList, setCategoryList] = useState([]);
  const [mobNumber, setMobNumber] = useState("");
  const [email, setEmail] = useState("");
  const [qualifyYear, setQualifyYear] = useState("");
  const [qualifyMonth, setQualifyMonth] = useState("");
  const [qualifyFor, setQualifyFor] = useState("");
  const [rollNumber, setRollNumber] = useState("");
  const [selectedImage, setSelectedImage] = useState("");
  const [resetParentCourse, setResetParentCourse] = useState(false);
  const [resetExam, setResetExam] = useState(false);
  const [resetQualifyYear, setResetQualifyYear] = useState(false);
  const [resetQualifyMonth, setResetQualifyMonth] = useState(false);
  const [resetQualifyFor, setResetQualifyFor] = useState(false);
  const [resetCategory, setResetCategory] = useState(false);
  const [selectedPDF, setSelectedPDF] = useState("");
  const [resultPDF, setResultPDF] = useState("");
  const [type, setType] = useState("");
  const [resetType, setResetType] = useState(false);
  const [scoreCardImg, setScoreCardImg] = useState("");
  const [scoreCardImgResult, setScoreCardImgResult] = useState("");
  const [scoreCardPdfResult, setScoreCardPdfResult] = useState("");

  const { userId } = useSelector((state) => ({
    userId: state.Auth?.userInfo?._id,
  }));

  useEffect(() => {
    dispatch(
      getExamListNew((res) => {
        setExamList(res);
      })
    );
    dispatch(
      getCasteCategoryList((res) => {
        setCategoryList(res);
      })
    );
  }, []);

  const onClickverify = () => {
    dispatch(
      getVerifyStudentNumber(mobNumber, (res) => {
        dispatch(showSuccessSnackbar(res?.msg));
        setFirstName(res?.data?.firstName);
        setLastName(res?.data?.lastName);
        setEmail(res?.data?.email);
        setExam(res?.data?.examTypes?._id);
        setExamStream(res?.data?.basecourses?._id);
        dispatch(
          getParentCourseNew(`${res?.data?.examTypes?._id}`, (res) => {
            setExamStreamList(res);
          })
        );
      })
    );
  };
  const onChnangeMobileNo = (val) => {
    if (val !== "") {
      if (!containsNumber(val)) {
        dispatch(showErrorSnackbar("Please enter number only"));
        return;
      } else if (!validateValueWithoutSpace(val)) {
        dispatch(showErrorSnackbar(validateIsNumberOnlyErrMsg));
        return;
      } else {
        setMobNumber(val);
      }
    } else {
      setMobNumber(val);
    }
  };
  const onChangeExam = (val) => {
    setExam(val?._id);
    setResetExam(false);

    dispatch(
      getParentCourseNew(`${val._id}`, (res) => {
        setExamStreamList(res);
      })
    );
  };
  const onChangeExamStream = (val) => {
    setExamStream(val?._id);
    setResetParentCourse(false);
  };
  const onChangeQualifyFor = (val) => {
    setQualifyFor(val);
    setResetQualifyYear(false);
    setResetQualifyMonth(false);
  };
  const onChangeType = (val) => {
    setType(val?._id);
  };
  const handleOptionImageChange = (e) => {
    e.preventDefault();
    if (!validateIsFileImageType(e)) {
      dispatch(showErrorSnackbar(validateIsFileImageTypeErrMsg));
      return;
    }
    if (e.target.files[0].size > image_size_5MB) {
      dispatch(showErrorSnackbar("File size should be less than 150 KB!!!"));
    } else {
      const reader = new FileReader();
      const file = e.target.files[0];
      reader.onloadend = () => {
        setPhoto(reader.result);
        setSelectedImage(file);
      };
      reader.readAsDataURL(file);
    }
  };

  const handleOptionScoreCardImageChange = (e) => {
    e.preventDefault();
    if (!validateIsFileImageType(e)) {
      dispatch(showErrorSnackbar(validateIsFileImageTypeErrMsg));
      return;
    }
    if (e.target.files[0].size > image_size_5MB) {
      dispatch(showErrorSnackbar("File size should be less than 150 KB!!!"));
    } else {
      const reader = new FileReader();
      const file = e.target.files[0];
      reader.onloadend = () => {
        setScoreCardImg(reader.result);
      };
      reader.readAsDataURL(file);
      dispatch(
        uploadResultImage(file, rollNumber, (res) => {
          setScoreCardImgResult(res?.fullPath);
        })
      );
    }
  };

  const handlePDFChange = (e) => {
    e.preventDefault();
    if (!validateIsFilePDFType(e)) {
      dispatch(showErrorSnackbar(validateIsFilePDFTypeErrMsg));
      return;
    }
    if (e.target.files[0].size > pdf_size_1MB) {
      dispatch(showErrorSnackbar("File size should be less than 10 MB!!!"));
    } else {
      const reader = new FileReader();
      const file = e.target.files[0];
      reader.onloadend = () => {
        setResultPDF(reader.result);
        setSelectedPDF(file);
      };
      reader.readAsDataURL(file);
      dispatch(
        uploadResultImage(file, rollNumber, (res) => {
          setScoreCardPdfResult(res?.fullPath);
        })
      );
    }
  };

  const onChangesetCategory = (val) => {
    setCategory(val);
  };

  const validations = () => {
    return (
      mobNumber === "" ||
      firstName === "" ||
      lastName === "" ||
      exam === "" ||
      examStream === "" ||
      qualifyYear === "" ||
      qualifyFor === "" ||
      category === "" ||
      rollNumber === "" ||
      rank === "" ||
      photo === ""
    );
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    dispatch(
      uploadResultImage(selectedImage, rollNumber, (res) => {
        if (res) {
          const postData = {
            mobileNo: mobNumber,
            firstName: firstName,
            lastName: lastName,
            email: email,
            examTypeId: exam,
            baseCourseId: examStream,
            qualifyYear: qualifyYear?.name,
            qualifyMonth: qualifyMonth?.value,
            qualifyFor: qualifyFor?.name,
            catagory: category?._id,
            rollNumber: rollNumber,
            rank: rank,
            imageLink: res?.fullPath,
            createdBy: userId,
            scoreCard: type === 1 ? scoreCardImgResult : scoreCardPdfResult,
          };
          if (
            postData?.scoreCard === "" ||
            postData?.scoreCard === undefined ||
            postData?.scoreCard === null
          ) {
            delete postData?.scoreCard;
          }
          dispatch(
            addResultCompilation(postData, () => {
              setMobNumber("");
              setFirstName("");
              setLastName("");
              setEmail("");
              setResetExam(true);
              setResetParentCourse(true);
              setResetQualifyYear(true);
              setResetQualifyMonth(true);
              setResetQualifyFor(true);
              setResetCategory(true);
              setResetType(true);
              setRollNumber("");
              setRank("");
              setPhoto("");
              setScoreCardImg("");
              setResultPDF("");
            })
          );
        }
      })
    );
  };
  const renderSearchMode = () => {
    return (
      <div className=" ms-5 p-2 formDiv">
        <form onSubmit={handleSubmit}>
          <div className="d-flex col-md-6 mb-3">
            <div className="col-md-9 pe-2">
              <InputField
                labelName={"Mobile Number"}
                placeholder="Enter Mobile Number"
                value={mobNumber}
                setInputText={(value) => onChnangeMobileNo(value)}
                Isrequired={true}
                maxLength={length_Ten}
                minLength={length_Ten}
              />
            </div>
            <div className="d-flex col-md-6 mb-3">
              <button
                type="submit"
                className="btn btn-outline-primary searchButton ms-2"
                onClick={onClickverify}
              >
                Verify Number
              </button>
            </div>
          </div>

          <div className=" col-md-6 mb-3">
            <div className="col-md-9 pe-2">
              <InputField
                labelName={"First Name"}
                placeholder="Enter First Name"
                value={firstName}
                setInputText={(value) => setFirstName(value)}
                defaultValue={firstName}
                Isrequired={true}
              />
            </div>
          </div>
          <div className="col-md-6 mb-3">
            <div className="col-md-9   pe-2">
              <InputField
                labelName={"Last Name"}
                placeholder="Enter Last Name"
                value={lastName}
                setInputText={(value) => setLastName(value)}
                defaultValue={lastName}
                Isrequired={true}
              />
            </div>
          </div>
          <div className="col-md-6 mb-3">
            <div className="col-md-9   pe-2">
              <InputField
                labelName={"Email Address"}
                placeholder="Enter Email Address"
                value={email}
                setInputText={(value) => setEmail(value)}
                defaultValue={email}
              />
            </div>
          </div>
          <div className="col-md-4 d-flex mb-3">
            <div className="col-md-3 mt-2 me-5">
              <LabelField labelName={"Exam Name"} />
            </div>
            <div className="col-md-9 ms-2 ">
              <CustomDropdown
                setInputText={(value) => onChangeExam(value)}
                value={exam}
                options={examList}
                reset={resetExam}
                labelName="Select Exam Name"
                defaultValue={examList?.length > 0 ? exam : null}
                Isrequired={true}
              />
            </div>
          </div>
          <div className="col-md-4 d-flex mb-3">
            <div className="col-md-3 mt-2 me-5">
              <LabelField labelName={"Exam Stream"} />
            </div>
            <div className="col-md-9 ms-2">
              <CustomDropdown
                labelName="Select Exam Stream"
                setInputText={(value) => onChangeExamStream(value)}
                value={examStream}
                options={examStreamList}
                reset={resetParentCourse}
                defaultValue={examStreamList?.length > 0 ? examStream : null}
                Isrequired={true}
              />
            </div>
          </div>
          <div className="col-md-4 d-flex mb-3">
            <div className="col-md-3 mt-2 me-5">
              <LabelField labelName={"Qualify Year"} />
            </div>
            <div className="col-md-9 ms-2">
              <CustomDropdown
                labelName="Select Qualify Year"
                setInputText={(value) => setQualifyYear(value)}
                value={qualifyYear}
                options={yearData}
                reset={resetQualifyYear}
                Isrequired={true}
              />
            </div>
          </div>
          <div className="col-md-4 d-flex mb-3">
            <div className="col-md-3 mt-2 me-5">
              <LabelField labelName={"Qualify Month"} />
            </div>
            <div className="col-md-9 ms-2">
              <CustomDropdown
                labelName="Select Qualify Month"
                setInputText={(value) => setQualifyMonth(value)}
                value={qualifyMonth}
                options={monthData}
                reset={resetQualifyMonth}
                Isrequired={true}
              />
            </div>
          </div>
          <div className="col-md-4 d-flex mb-3">
            <div className="col-md-3 mt-2 me-5">
              <LabelField labelName={"Qualified for"} />
            </div>
            <div className="col-md-9 ms-2">
              <CustomDropdown
                labelName="Select Qualify for"
                setInputText={(value) => onChangeQualifyFor(value)}
                value={qualifyFor}
                options={ResultOtherDetails}
                reset={resetQualifyFor}
                Isrequired={true}
              />
            </div>
          </div>
          <div className="col-md-4 d-flex mb-3">
            <div className="col-md-3 mt-2 me-5">
              <LabelField labelName={"Category"} />
            </div>
            <div className="col-md-9 ms-2">
              <CustomDropdown
                labelName="Select Category"
                setInputText={(value) => onChangesetCategory(value)}
                value={category}
                options={categoryList}
                reset={resetCategory}
                Isrequired={true}
              />
            </div>
          </div>
          <div className="col-md-6 mb-3">
            <div className="col-md-9   pe-2">
              <InputField
                labelName={"Roll Number"}
                placeholder="Enter Roll Number"
                value={rollNumber}
                setInputText={(value) => setRollNumber(value)}
                Isrequired={true}
              />
            </div>
          </div>
          <div className="col-md-6 d-flex mb-3">
            <div className="col-md-9   pe-2">
              <InputField
                labelName={"Rank"}
                placeholder="Enter Rank"
                value={rank}
                setInputText={(value) => setRank(value)}
                Isrequired={true}
              />
            </div>
          </div>

          <div className="col-md-4 d-flex mb-3">
            <div className="col-md-3 mt-2">
              <LabelField labelName={"Photo"} />
            </div>
            <div className="col-md-9">
              <ImagePickerAndViewer
                lableName={"Upload Photo"}
                value={photo}
                handleChange={(e) => handleOptionImageChange(e)}
                isRequired={true}
              />
            </div>
          </div>

          <div className="col-md-4 d-flex mb-3">
            <div className="col-md-3 mt-2 me-5">
              <LabelField labelName={"ScoreCard Type"} />
            </div>
            <div className="col-md-9 ms-2">
              <CustomDropdown
                labelName="Select ScoreCard Type"
                setInputText={(value) => onChangeType(value)}
                value={type}
                options={ScoreCardList}
                reset={resetType}
              />
            </div>
          </div>

          {type === 1 ? (
            <div className="col-md-4 d-flex mb-3">
              <div className="col-md-3 mt-2">
                <LabelField labelName={"ScoreCard Image"} />
              </div>
              <div className="col-md-9">
                <ImagePickerAndViewer
                  lableName={"Upload Photo"}
                  value={scoreCardImg}
                  handleChange={(e) => handleOptionScoreCardImageChange(e)}
                />
              </div>
            </div>
          ) : type === 2 ? (
            <div className="col-md-4 d-flex mb-3">
              <div className="col-md-3 mt-2">
                <LabelField labelName={"ScoreCard PDF"} />
              </div>
              <div className="col-md-9">
                <ImagePickerAndViewer
                  lableName={"Upload PDF"}
                  value={resultPDF}
                  handleChange={(e) => handlePDFChange(e)}
                  htmlFor={"pdf-upload"}
                  doctype={"pdf"}
                  selectedPDF={selectedPDF}
                />
                {/* <label className="label">
              {selectedPDF?.name ? selectedPDF?.name : "Sample PDF"}
            </label> */}
              </div>
            </div>
          ) : null}

          <div className=" d-flex mb-3 ms-5 ps-5">
            <button
              type="submit"
              className={`btn mb-3 submitbtn ${
                validations() ? "btn-secondary" : "btn-primary"
              }`}
              disabled={validations()}
            >
              Submit
            </button>
          </div>
        </form>
      </div>
    );
  };

  return (
    <div className="contentBox ">
      <PageTitle name={"RESULT COMPILATION"} />
      <div className="formDiv">
        <div className="col-auto">
          <div className=" w-100">
            <div className="px-3 mt-3">
              <div></div>
            </div>
            {renderSearchMode()}
          </div>
        </div>
      </div>
    </div>
  );
}
