import React, { useEffect, useState } from "react";
import "../../App.css";
import PageTitle from "../../Module/pageTitle";
import InputField from "../../Component/inputField";
import { useDispatch } from "react-redux";
import { getParentCourseNew, getExamListNew } from "../../Action/courseAction";

import CustomDropdown from "../../Component/customDropdown";
import LabelField from "../../Component/labelField";
import {
  containsNumber,
  length_Two,
  validateValueWithoutSpace,
} from "../../Utils/validators";
import { addFAQ, addFAQType, getFAQType } from "../../Action/seoAction";
import HighlightOffIcon from "@mui/icons-material/HighlightOff";
import AddMorebtn from "../../Component/addMoreBtn";
import Modal from "@material-ui/core/Modal";
import Fade from "@material-ui/core/Fade";
import Backdrop from "@material-ui/core/Backdrop";
import ClearOutlinedIcon from "@mui/icons-material/ClearOutlined";
import TextEditor from "../DataEntryPanel/CreateQuestion/textEditor";
import { showErrorSnackbar } from "../../Action/snackbarAction";
import { validateIsNumberOnlyErrMsg } from "../../Utils/errorMsg";
import { getSectionTypeList, getSubExam } from "../../Action/adminPanelAction";

function AddCourseFAQ() {
  const dispatch = useDispatch();
  const [examName, setExamName] = useState("");
  const [subExamName, setSubExamName] = useState("");
  const [examStream, setExamStream] = useState("");
  const [examStreamList, setExamStreamList] = useState([]);
  const [examList, setExamList] = useState([]);
  const [subExamList, setSubExamList] = useState([]);
  const [resetExam, setResetExam] = useState(false);
  const [resetSubExam, setResetSubExam] = useState(false);
  const [resetExamStream, setResetExamStream] = useState(false);
  const [typeList, setTypeList] = useState([]);
  const [FAQtypeList, setFAQtypeList] = useState([]);
  const [FAQType, setFAQType] = useState("");
  const [course, setCourse] = useState("");
  const [open, setOpen] = useState(false);
  const [newFAQType, setNewFAQType] = useState("");
  const [resetCourse, setresetCourse] = useState(false);
  const [resetFAQType, setresetFAQType] = useState(false);
  const [Order, setOrder] = useState("");

  const initialVal = [
    {
      question: "",
      answer: "",
    },
  ];
  const [FAQArr, setFAQArr] = useState(initialVal);

  const onChangeExam = (value) => {
    setExamName(value);
    setResetExam(false);
    setResetSubExam(true);
    setResetExamStream(true);
    setSubExamName("");
    setExamStream("");
    setExamStreamList([]);
    setSubExamList([]);
    setresetCourse(true);
    setresetFAQType(true);
    dispatch(
      getSubExam(
        {
          examTypeId: value._id,
        },
        (res) => {
          if (res.data.length !== 0) setSubExamList(res.data);
          else
            dispatch(
              getParentCourseNew(`${value._id}`, (res) => {
                setExamStreamList(res);
              })
            );
        }
      )
    );
  };
  const onChangeSubExam = (value) => {
    setSubExamName(value);
    setResetSubExam(false);
    setExamStream("");
    setResetSubExam(true);
    setExamStreamList([]);
    dispatch(
      getParentCourseNew(`${examName._id}/${value._id}`, (res) => {
        setExamStreamList(res);
      })
    );
  };

  const onChangeExamStream = (value) => {
    setExamStream(value);
    setResetExamStream(false);
    setresetCourse(true);
    setresetFAQType(true);
  };

  const onChangeCourse = (value) => {
    setCourse(value);
    setresetCourse(false);
    setresetFAQType(true);
  };
  const onChangeFAQType = (value) => {
    setFAQType(value);
    setresetFAQType(false);
  };

  useEffect(() => {
    dispatch(
      getExamListNew((res) => {
        setExamList(res);
      })
    );
    dispatch(
      getSectionTypeList((data) => {
        setTypeList(data);
      })
    );
    getFAQTypeList();
  }, []);

  const handleSubmit = async (e) => {
    e.preventDefault();
    const data = {
      examTypeId: examName._id,
      baseCourseId: examStream._id,
      courseType: course.type,
      faqTypeId: FAQType?._id,
      queAndAns: FAQArr,
    };
    if (subExamName?._id) {
      data["subExamTypeId"] = subExamName._id;
    }
    dispatch(
      addFAQ(data, () => {
        setExamName("");
        setResetExam(true);
        setResetSubExam(true);
        setSubExamName("");
        setExamStreamList([]);
        setExamStream("");
        setCourse("");
        setResetExamStream(true);
        setFAQArr(initialVal);
        setresetCourse(true);
        setresetFAQType(true);
      })
    );
  };

  const validations = () => {
    let result = false;
    for (var i = 0; i < FAQArr.length; i++) {
      if (FAQArr[i]?.question === "" || FAQArr[i]?.answer === "") {
        result = true;
      }
    }
    return (
      examStream?._id === "" ||
      examName?._id === "" ||
      course?._id === "" ||
      result === true ||
      FAQType === ""
    );
  };
  const validationsFAQType = () => {
    return newFAQType === "";
  };

  const onPressAdd = () => {
    setFAQArr([...FAQArr, initialVal[0]]);
  };
  const handleClose = (index) => {
    const newArray = [...FAQArr];
    if (index > -1) {
      newArray.splice(index, 1);
    }
    setFAQArr(newArray);
  };

  const onChangePYQ = (value, index, key) => {
    let newArray = JSON.parse(JSON.stringify(FAQArr));
    newArray[index][key] = value;
    setFAQArr(newArray);
  };
  const handleSubmitFAQ = () => {
    // e.preventDefault();
    const data = {
      type: newFAQType,
    };
    if (Order) {
      data["order"] = Order;
    }
    dispatch(
      addFAQType(data, () => {
        setNewFAQType("");
        getFAQTypeList();
      })
    );
  };
  const getFAQTypeList = () => {
    dispatch(
      getFAQType("", (res) => {
        setFAQtypeList(res);
        setOpen(false);
      })
    );
  };
  const onChangeOrder = (value) => {
    if (value !== "") {
      if (!containsNumber(value)) {
        dispatch(showErrorSnackbar("Only Numbers are allowed!"));
        return;
      } else if (!validateValueWithoutSpace(value)) {
        dispatch(showErrorSnackbar(validateIsNumberOnlyErrMsg));
        return;
      } else setOrder(value);
    }
    setOrder(value);
  };
  return (
    <div className="contentBox">
      <PageTitle name={"ADD COURSE FAQ"} />
      <form className="formWidth" onSubmit={handleSubmit}>
        <div className="col-12">
          <div className="d-flex mb-2 align-items-center">
            <LabelField labelName={"Exam Name"} />
            <CustomDropdown
              setInputText={(value) => onChangeExam(value)}
              value={examName}
              defaultValue={examName._id}
              options={examList}
              labelName="Select Exam Name"
              reset={resetExam}
              Isrequired={true}
            />
          </div>
          {subExamList.length > 0 && (
            <div className="d-flex mb-2 align-items-center">
              <LabelField labelName={"Sub Exam Name"} />
              <CustomDropdown
                setInputText={(value) => onChangeSubExam(value)}
                defaultValue={subExamName._id}
                options={subExamList}
                labelName="Select Sub Exam Name"
                reset={resetSubExam}
                Isrequired={true}
              />
            </div>
          )}
          <div className="d-flex mb-2 align-items-center">
            <LabelField labelName={"Exam Stream"} />
            <CustomDropdown
              setInputText={(value) => onChangeExamStream(value)}
              defaultValue={examStream._id}
              options={examStreamList}
              labelName="Select Exam Name"
              reset={resetExamStream}
              Isrequired={true}
            />
          </div>
          <div className="d-flex mb-2 align-items-center">
            <LabelField labelName={"Course Type"} />
            <CustomDropdown
              setInputText={(value) => onChangeCourse(value)}
              defaultValue={course._id}
              options={typeList}
              labelName="Select Course Type"
              Isrequired={true}
              reset={resetCourse}
            />
          </div>
          <div className="d-flex mb-2 align-items-center">
            <LabelField labelName={"FAQ Type"} />
            <CustomDropdown
              setInputText={(value) => onChangeFAQType(value)}
              // defaultValue={FAQType._id}
              options={FAQtypeList}
              labelName="Select FAQ Type"
              Isrequired={true}
              type="type"
              reset={resetFAQType}
            />
            <div className="col-auto d-flex justify-content-center  mb-2 ms-3">
              <button
                type="button"
                onClick={() => setOpen(true)}
                className={`btn submitbtn btn-primary`}
              >
                Add New FAQ Type
              </button>
            </div>
          </div>
          {FAQArr?.map((item, index) => (
            <div key={index} className="position-relative">
              <span className="d-flex justify-content-end p-2 removeButton">
                {FAQArr.length === 1 ? null : (
                  <HighlightOffIcon
                    className="cursor"
                    color="primary"
                    fontSize="small"
                    onClick={() => handleClose(index)}
                  />
                )}
              </span>
              <InputField
                key={"Question" + index}
                labelName={"Question " + (index + 1)}
                placeholder={"Enter Question " + (index + 1)}
                setInputText={(value) => onChangePYQ(value, index, "question")}
                value={item?.question}
                minLength={length_Two}
                Isrequired={true}
              />
              <div className="d-flex mb-2 align-items-center">
                <LabelField labelName={"Answer " + (index + 1)} />
                <div className="editor_content mb-2">
                  <TextEditor
                    setInputText={(value) =>
                      onChangePYQ(value, index, "answer")
                    }
                    value={item?.answer}
                  />
                </div>
                <span className="required ms-2 ">*</span>
              </div>
            </div>
          ))}
          <div className="col-auto d-flex justify-content-end mt-4">
            <AddMorebtn handleClick={onPressAdd} />
          </div>
        </div>
        <div className="col-auto d-flex justify-content-center">
          <div className="col-auto d-flex justify-content-center mt-4">
            <button
              type="submit"
              className={`btn mb-3 submitbtn ${
                validations() ? "btn-secondary" : "btn-primary"
              }`}
              disabled={validations()}
            >
              Submit
            </button>
          </div>
        </div>
      </form>

      {open ? (
        <Modal
          aria-labelledby="transition-modal-title"
          aria-describedby="transition-modal-description"
          className="model"
          open={open}
          onClose={() => setOpen(false)}
          closeAfterTransition
          BackdropComponent={Backdrop}
          BackdropProps={{
            timeout: 500,
          }}
        >
          <Fade in={open}>
            <div className="bookstockmodeldiv p-3">
              <div className="col mb-4 d-flex justify-content-between ">
                <p className="fw-bold">
                  <span style={{ color: "#3F51B5" }}>
                    {" "}
                    Server Status Update
                  </span>
                </p>
                <ClearOutlinedIcon
                  className="colorPurple cursor"
                  onClick={() => setOpen(false)}
                />
              </div>
              <div className="form-check">
                <InputField
                  labelName={"New FAQ Type"}
                  placeholder={"Enter New FAQ Type"}
                  setInputText={(value) => setNewFAQType(value)}
                  value={newFAQType}
                  Isrequired={true}
                />
                <InputField
                  labelName={"Order"}
                  placeholder={"Enter Order"}
                  setInputText={(value) => onChangeOrder(value)}
                  value={Order}
                />

                <div className="col-auto d-flex justify-content-center mt-5">
                  <button
                    onClick={() => handleSubmitFAQ()}
                    type="button"
                    className={`btn mb-3 submitbtn ${
                      validationsFAQType() ? "btn-secondary" : "btn-primary"
                    }`}
                    disabled={validationsFAQType()}
                  >
                    Save and Update
                  </button>
                </div>
              </div>
            </div>
          </Fade>
        </Modal>
      ) : null}
    </div>
  );
}

export default AddCourseFAQ;
