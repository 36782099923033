/* eslint-disable react/prop-types */
import React from "react";

function DatePicker({
  value,
  setInputText,
  Isrequired,
  defaultValue,
  maxdate,
  mindate,
  disabled,
  type = "date",
  pickerStyle,
}) {
  return (
    <div className="d-flex mb-2 align-items-center w-100" style={pickerStyle}>
      <input
        className="form-control"
        type={type}
        value={value}
        onChange={(e) => setInputText(e.target.value)}
        defaultValue={defaultValue?.substring(0, 10)}
        max={maxdate}
        min={mindate}
        disabled={disabled}
        required={Isrequired}
        onKeyDown={(e) => e.preventDefault()} // To avoid entering the date
      />
      {Isrequired ? <span className="required ms-2 fs-6">*</span> : null}
    </div>
  );
}
export default DatePicker;
