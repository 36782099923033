export const SEOSidebarData = [
  // {
  //   title: "SEO EXAM NAME",
  //   subNav: [
  //     {
  //       title: "ADD SEO EXAM NAME",
  //       path: "addSEOExamName",
  //       cName: "sub-nav",
  //     },
  //     {
  //       title: "EDIT DELETE SEO EXAM",
  //       path: "editDeleteSEOExam",
  //       cName: "sub-nav",
  //     },
  //   ],
  // },
  // {
  //   title: "SEO SUB EXAM NAME",
  //   subNav: [
  //     {
  //       title: "ADD SEO SUB EXAM NAME",
  //       path: "addSEOSubExamName",
  //       cName: "sub-nav",
  //     },
  //     {
  //       title: "EDIT DELETE SEO SUB EXAM",
  //       path: "editDeleteSEOSubExam",
  //       cName: "sub-nav",
  //     },
  //   ],
  // },
  // {
  //   title: "SEO EXAM STREAM",
  //   subNav: [
  //     {
  //       title: "ADD SEO EXAM STREAM",
  //       path: "addSEOExamStream",
  //       cName: "sub-nav",
  //     },
  //     {
  //       title: "EDIT DELETE SEO EXAM STREAM",
  //       path: "editDeleteSEOExamStream",
  //       cName: "sub-nav",
  //     },
  //   ],
  // },
  {
    title: "SEO EXAM",
    link: "addEditSEOExam",
  },
  {
    title: "SEO SUB EXAM",
    link: "addEditSEOSubExam",
  },
  {
    title: "SEO EXAM STREAM",
    link: "addEditSEOExamStream",
  },
  // {
  //   title: "SEO EXAM NAME",
  //   link: "addSEOExamName",
  // },
  // {
  //   title: "SEO SUB EXAM NAME",
  //   link: "addSEOSubExamName",
  // },
  // {
  //   title: "SEO EXAM STREAM",
  //   link: "addSEOExamStream",
  // },
  {
    title: "SEO COURSE",
    subNav: [
      {
        title: "ADD SEO COURSE",
        path: "addSEOCourse",
        cName: "sub-nav",
      },
      {
        title: "EDIT DELETE SEO COURSE",
        path: "editDeleteSEOCourse",
        cName: "sub-nav",
      },
    ],
  },
  {
    title: "COURSE FAQ",
    subNav: [
      {
        title: "ADD FAQ",
        path: "addCourseFAQ",
        cName: "sub-nav",
      },
      {
        title: "EDIT DELETE FAQ",
        path: "editDeleteCourseFAQ",
        cName: "sub-nav",
      },
    ],
  },
  {
    title: "EXAM FAQ",
    subNav: [
      {
        title: "ADD EXAM FAQ",
        path: "addExamFAQ",
        cName: "sub-nav",
      },
      {
        title: "EDIT DELETE EXAM FAQ",
        path: "editDeleteExamFAQ",
        cName: "sub-nav",
      },
    ],
  },
  {
    title: "EXAM STREAM FAQ",
    subNav: [
      {
        title: "ADD EXAM STREAM FAQ",
        path: "addExamStreamFAQ",
        cName: "sub-nav",
      },
      {
        title: "EDIT DELETE EXAM STREAM FAQ",
        path: "editDeleteExamStreamFAQ",
        cName: "sub-nav",
      },
    ],
  },
  {
    title: "PRODUCT SCHEMA",
    subNav: [
      {
        title: "ADD PRODUCT SCHEMA",
        path: "addProductSchema",
        cName: "sub-nav",
      },
      {
        title: "EDIT PRODUCT SCHEMA",
        path: "editDeleteProductSchema",
        cName: "sub-nav",
      },
    ],
  },
  {
    title: "EXAM DETAILS",
    subNav: [
      {
        title: "ADD EXAM DETAILS",
        path: "AddExamDetails",
        cName: "sub-nav",
      },
      {
        title: "EDIT EXAM DETAILS",
        path: "EditDeleteExamDetails",
        cName: "sub-nav",
      },
    ],
  },
  {
    title: "AUTHOR DETAILS",
    subNav: [
      {
        title: "ADD AUTHOR DETAILS",
        path: "addAuthorDetails",
        cName: "sub-nav",
      },
      {
        title: "EDIT AUTHOR DETAILS",
        path: "editDeleteAuthorDetails",
        cName: "sub-nav",
      },
    ],
  },
  {
    title: "RESOURCE PAGE DETAILS",
    link: "resourcePageDetails",
  },
  // {
  //   title: "SEO RESOURSES",
  //   link: "addresourcesseo",
  // },
  // {
  //   title: "RESOURCE DETAILS",
  //   link: "resourceDetail",
  // },
  {
    title: "RESOURCE PAGE PRODUCT",
    link: "resourcePageProduct",
  },
  // {
  //   title: "FAQ OF RESOURCE PAGE",
  //   subNav: [
  //     {
  //       title: "ADD FAQ OF RESOURCE PAGE",
  //       path: "addResourcesFAQ",
  //       cName: "sub-nav",
  //     },
  //     {
  //       title: "EDIT DELETE ",
  //       path: "editDeleteResourcesFAQ",
  //       cName: "sub-nav",
  //     },
  //   ],
  // },
  {
    title: "BLOGS",
    subNav: [
      {
        title: "ADD",
        path: "addSEOBlogs",
        cName: "sub-nav",
      },
      {
        title: "EDIT / DELETE",
        path: "editDeleteSEOBlogs",
        cName: "sub-nav",
      },
    ],
  },
  // {
  //   title: "ONLINE COACHING",
  //   link: "createOnlineCoaching",
  // },

  // {
  //   title: "ELIGIBILITY",
  //   link: "createEligibility",
  // },

  // {
  //   title: "APPLICATION FEES",
  //   link: "createApplicationFees",
  // },

  // {
  //   title: "ADMIT CARD",
  //   link: "createAdmitCard",
  // },
];
