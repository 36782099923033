import React, { useEffect, useState } from "react";
import Box from "@mui/material/Box";
import Rating from "@mui/material/Rating";
import { useLocation } from "react-router-dom";
import { useDispatch } from "react-redux";
import { getSupportCount } from "../Action/courseAction";

function PageTitle(Props) {
  const dispatch = useDispatch();
  const currentURLarr = useLocation().pathname;
  const [supportCount, setSupportCount] = useState({
    newIssue: 0,
    inProgress: 0,
    closed: 0,
  });

  useEffect(() => {
    if (Props?.isSupportCount) {
      dispatch(
        getSupportCount((res) => {
          setSupportCount(res);
        })
      );
    }
  }, []);

  return (
    <div className="d-flex justify-content-between">
      <h5 className="Title">
        {Props.name}
        {currentURLarr === "/TeacherPanel/feedback" ||
        currentURLarr === "/TeacherPanel/lectureFeedback" ||
        currentURLarr === "/CXOPanel/teacherfeedback" ||
        currentURLarr === "/CXOPanel/teacherLectureFeedback" ? (
          <div className="d-flex justify-content-between">
            <span className="font-12 text-dark d-flex pt-3">
              overall rating:
            </span>
            <span className="d-flex font-12 pt-3" style={{ color: "#3F51B5" }}>
              {Props?.overallRating
                ? Math.round(
                    (parseFloat(Props?.overallRating) + Number.EPSILON) * 10
                  ) / 10
                : "0"}
            </span>
            <span className="text-secondary pt-3 font-10 d-flex">
              ({Props?.reviewCount ? Props?.reviewCount : "0"})
            </span>
            <span className=" pt-2 ">
              <Box
                sx={{
                  "& > legend": { mt: 2 },
                }}
              >
                <Rating
                  name="simple-controlled size-small"
                  value={Props?.overallRating}
                />
              </Box>
            </span>
          </div>
        ) : null}
        {Props?.isSupportCount ? (
          <div className="d-flex justify-content-between">
            <div className="position-relative d-flex">
              <div className="btn-div">New Issue</div>
              <div className="number-div">{supportCount?.newIssue}</div>
            </div>
            <div className="position-relative d-flex">
              <div className="btn-div">In Progress</div>
              <div className="number-div">{supportCount?.inProgress}</div>
            </div>
            <div className="position-relative d-flex">
              <div className="btn-div">Resolved</div>
              <div className="number-div">{supportCount?.closed}</div>
            </div>
          </div>
        ) : null}
        {Props.isEditMode ? (
          <div>
            <button className="btn btn-outline-primary" onClick={Props.onClick}>
              Cancel Edit
            </button>
          </div>
        ) : null}
        {Props.isGoBack ? (
          <div>
            <button className="btn btn-outline-primary" onClick={Props.onClick}>
              Go Back
            </button>
          </div>
        ) : null}
      </h5>
    </div>
  );
}

export default PageTitle;
