/* eslint-disable react/prop-types */
import React from "react";
import "../App.css";

function AddMorebtn(props) {
  return (
    <button
      disabled={props?.disable}
      type="button"
      className="btn btn-primary mb-3 addMorebtn"
      onClick={props.handleClick}
    >
      Add More
    </button>
  );
}

export default AddMorebtn;
