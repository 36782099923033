import React, { useEffect, useState } from "react";
import "../../App.css";
import PageTitle from "../../Module/pageTitle";
import InputField from "../../Component/inputField";
import { useDispatch } from "react-redux";
import {
  getExamListNew,
  getParentCourseNew,
  getSectionList,
  setParentCourse,
} from "../../Action/courseAction";

import CustomDropdown from "../../Component/customDropdown";
import LabelField from "../../Component/labelField";
import {
  deleteProductSchema,
  getProductSchema,
  updateProductSchema,
} from "../../Action/seoAction";
import { useSelector } from "react-redux";
import AdminTable from "../../Component/adminTable";
import { getSubExam } from "../../Action/adminPanelAction";

function EditDeleteProductSchema() {
  const dispatch = useDispatch();
  const [examName, setExamName] = useState("");
  const [id, setId] = useState("");
  const [subExamName, setSubExamName] = useState("");
  const [examList, setExamList] = useState([]);
  const [subExamList, setSubExamList] = useState([]);
  const [resetExam, setResetExam] = useState(false);
  const [resetSubExam, setResetSubExam] = useState(false);
  const [examStream, setExamStream] = useState("");
  const [examStreamList, setExamStreamList] = useState([]);
  const [resetExamStream, setResetExamStream] = useState(false);
  const [list, setList] = useState([]);
  const [typeList, setTypeList] = useState([]);
  const [course, setCourse] = useState("");
  const [usercount, setusercount] = useState("");
  const [rating, setrating] = useState("");
  const [resetCourseType, setresetCourseType] = useState(false);
  const [mainCategory, setMainCategory] = useState("");
  const [Batch, setBatch] = useState("");

  const { isEditMode } = useSelector((state) => ({
    isEditMode: state.courseReducer?.isEditMode,
  }));

  const onChangeExam = (value) => {
    setExamName(value);
    setResetExam(false);
    setResetSubExam(true);
    setResetExamStream(true);
    setresetCourseType(true);
    setSubExamName("");
    setExamStream("");
    setExamStreamList([]);
    setSubExamList([]);
    setList([]);
    dispatch(
      getSubExam(
        {
          examTypeId: value._id,
        },
        (res) => {
          if (res.data.length !== 0) setSubExamList(res.data);
          else
            dispatch(
              getParentCourseNew(`${value._id}`, (res) => {
                setExamStreamList(res);
              })
            );
        }
      )
    );
  };

  useEffect(() => {
    dispatch(
      getExamListNew((res) => {
        setExamList(res);
      })
    );
    dispatch(
      getSectionList(
        "",
        (res) => {
          setTypeList(res?.data);
        },
        () => {}
      )
    );
  }, []);

  const onChangeSubExam = (value) => {
    setSubExamName(value);
    setResetSubExam(false);
    setExamStream("");
    setList([]);
    setResetExamStream(true);
    setresetCourseType(true);
    setExamStreamList([]);
    dispatch(
      getParentCourseNew(`${examName._id}/${value._id}`, (res) => {
        setExamStreamList(res);
      })
    );
  };
  const onChangeExamStream = async (value) => {
    await setExamStream(value);
    await setResetExamStream(false);
    setresetCourseType(true);
    listAPI(value?._id, "");
  };

  const listAPI = (examStreamId, course) => {
    const dataRequest = {
      examTypeId: examName._id,
      baseCourseId: examStreamId,
    };
    if (subExamName?._id) dataRequest["subExamTypeId"] = subExamName._id;
    if (course?._id) dataRequest["sectionId"] = course.type;
    dispatch(
      getProductSchema(dataRequest, (data) => {
        setList(data?.data);
      })
    );
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const data = {
      examTypeId: examName._id,
      baseCourseId: examStream._id,
      sectionId: course.type,
      courseId: Batch,
      rating: rating,
      count: usercount,
    };
    if (subExamName?._id) {
      data["subExamTypeId"] = subExamName._id;
    }
    if (mainCategory) {
      data["mainCategoryId"] = mainCategory;
    }

    dispatch(
      updateProductSchema(data, id, () => {
        setCourse("");
        setId("");
        listAPI(examStream?._id, course);
        dispatch(setParentCourse("isEditMode", false));
      })
    );
  };

  const validations = () => {
    return usercount === "" || rating === "";
  };

  const renderEditMode = () => (
    <form className="formWidth" onSubmit={handleSubmit}>
      <div className="col-7">
        <InputField
          labelName={"Rating"}
          placeholder={"Enter Rating"}
          setInputText={(value) => setrating(value)}
          value={rating}
          Isrequired={true}
        />
        <InputField
          labelName={"Count"}
          placeholder={"Enter Count"}
          setInputText={(value) => setusercount(value)}
          value={usercount}
          Isrequired={true}
        />
      </div>
      <div className="col-auto d-flex justify-content-center">
        <div className="col-auto d-flex justify-content-center mt-4">
          <button
            type="submit"
            className={`btn mb-3 submitbtn ${
              validations() ? "btn-secondary" : "btn-primary"
            }`}
            disabled={validations()}
          >
            Submit
          </button>
        </div>
      </div>
    </form>
  );

  const getData = (data) => {
    const { _id, count, rating, courseId, mainCategoryId, sectionId } = data;
    setrating(rating ? rating : "");
    setusercount(count ? count : "");
    setMainCategory(mainCategoryId ? mainCategoryId : "");
    setBatch(courseId);
    setCourse(typeList.find((dataNew) => dataNew.type === sectionId));
    setId(_id);
    dispatch(setParentCourse("isEditMode", true));
  };

  const renderListMode = () => {
    return (
      <AdminTable
        pagename={"productSchema"}
        tableData={list}
        tableHead={[
          "S.N",
          "Exam Name",
          "Sub Exam Name",
          "Course Type",
          "Batch Name",
          "Rating",
          "User Count",
          "Actions",
        ]}
        courseTypeList={typeList ? typeList : []}
        handleEdit={(data) => getData(data)}
        handleDelete={(data) => handleDelete(data)}
        edit={true}
      />
    );
  };

  const handleDelete = (value) => {
    dispatch(
      deleteProductSchema(`${value?._id}`, () => {
        dispatch(setParentCourse("isEditMode", false));
        listAPI(examStream?._id, course);
      })
    );
  };

  const onChangeCourseType = async (value) => {
    await setCourse(value);
    await setresetCourseType(false);
    listAPI(examStream?._id, value);
  };

  return (
    <>
      <div className="contentBox">
        <PageTitle
          name={"EDIT PRODUCT SCHEMA"}
          isEditMode={isEditMode}
          onClick={() => dispatch(setParentCourse("isEditMode", false))}
        />
        <div className="col-auto formWidth">
          <div className="row">
            <div className="col-md-6 d-flex mb-2 align-items-center">
              <LabelField labelName={"Exam Name"} />
              <CustomDropdown
                setInputText={(value) => onChangeExam(value)}
                value={examName}
                options={examList}
                labelName="Select Exam Name"
                reset={resetExam}
                disabled={isEditMode}
              />
            </div>
            {subExamList.length > 0 && (
              <div className="col-md-6 d-flex mb-2 align-items-center">
                <LabelField labelName={"Sub Exam Name"} />
                <CustomDropdown
                  setInputText={(value) => onChangeSubExam(value)}
                  defaultValue={subExamName._id}
                  options={subExamList}
                  labelName="Select Sub Exam Name"
                  reset={resetSubExam}
                  Isrequired={true}
                  disabled={isEditMode}
                />
              </div>
            )}
            <div className="col-md-6 d-flex mb-2 align-items-center">
              <LabelField labelName={"Exam Stream"} />
              <CustomDropdown
                setInputText={(value) => onChangeExamStream(value)}
                options={examStreamList}
                labelName="Select Exam Name"
                reset={resetExamStream}
                disabled={isEditMode}
              />
            </div>
            <div className="col-md-6 d-flex mb-2 align-items-center">
              <LabelField labelName={"Course Type"} />
              <CustomDropdown
                setInputText={(value) => onChangeCourseType(value)}
                options={typeList}
                labelName="Select Course Type"
                reset={resetCourseType}
                disabled={isEditMode}
              />
            </div>
          </div>
          {!isEditMode ? renderListMode() : renderEditMode()}
        </div>
      </div>
    </>
  );
}

export default EditDeleteProductSchema;
