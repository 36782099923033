import React, { useEffect, useRef, useState } from "react";
import PageTitle from "../../Module/pageTitle";
import moment from "moment";
import { DownloadTableExcel } from "react-export-table-to-excel";
import LabelField from "../../Component/labelField";
import CustomDropdown from "../../Component/customDropdown";
import DatePicker from "../../Component/datePicker";
import { useDispatch } from "react-redux";
import { showErrorSnackbar } from "../../Action/snackbarAction";
import {
  getAllTeachersList,
  getExamList,
  getExamListNew,
  getParentCourseNew,
  getYTAnalytics,
} from "../../Action/courseAction";
import { youtubeTypeList } from "../../Utils/dataConstants";
import AdminTable from "../../Component/adminTable";

const TeacherPerformance = () => {
  const dispatch = useDispatch();
  const tableRef = useRef(null);
  const [startDate, setStartDate] = useState(
    moment.utc(moment.utc(new Date())).subtract(1, "days").format("YYYY-MM-DD")
  );
  const [endDate, setEndDate] = useState(
    moment.utc(moment.utc(new Date())).subtract(1, "days").format("YYYY-MM-DD")
  );
  const [examName, setExamName] = useState("");
  const [teacherName, setTeacherName] = useState("");
  const [parentCourseVal, setParentCourseVal] = useState("");
  const [videoType, setVideoType] = useState("");

  const [resetExam, setResetExam] = useState(false);
  const [resetParentCourse, setResetParentCourse] = useState(false);
  const [resetTeacher, setResetTeacher] = useState(false);
  const [resetTaskType, setResetTaskType] = useState(false);

  const [examList, setExamList] = useState([]);
  const [parentCourseList, setParentCourseList] = useState([]);
  const [teacherList, setTeacherList] = useState([]);
  const [teacherPerformList, setTeacherPerformList] = useState([]);

  const onChangeData = (value, key) => {
    if (key === "startDate") {
      setStartDate(value);
    } else if (key === "endDate") {
      setEndDate(value);
      setResetParentCourse(true);
      setResetTeacher(true);
      setResetTaskType(true);
      setTeacherPerformList([]);
      if (value < startDate) {
        dispatch(showErrorSnackbar("End date must be greater than start date"));
      }
    } else if (key === "examName") {
      setExamName(value);
      setResetExam(false);
      setParentCourseList([]);
      setResetParentCourse(true);
      setResetTeacher(true);
      setResetTaskType(true);
      setTeacherPerformList([]);
      dispatch(
        getParentCourseNew(`${value._id}`, (res) => {
          setParentCourseList(res);
        })
      );
    } else if (key === "examStream") {
      setParentCourseVal(value);
      setResetParentCourse(false);
      setResetTeacher(true);
      setResetTaskType(true);
      setTeacherPerformList([]);
      dispatch(
        getExamList(value._id, (res) => {
          setExamList(res);
        })
      );
    } else if (key === "teacherName") {
      setTeacherName(value);
      setResetTeacher(false);
      setResetTaskType(true);
    } else if (key === "lectureType") {
      setVideoType(value);
    }
  };

  const validations = () => {
    return false;
  };

  const renderSearchMode = () => {
    let maxD = moment(startDate).add(1, "months").calendar();
    return (
      <div className="align-items-center justify-content-between">
        <div className="d-flex" style={{ justifyContent: "space-between" }}>
          <div className="d-flex align-items-center mb-2 me-5">
            <LabelField labelName={"Start Date"} />
            <DatePicker
              setInputText={(value) => onChangeData(value, "startDate")}
              value={startDate}
              Isrequired={true}
            />
          </div>
          <div className="d-flex align-items-center mb-2 me-5">
            <LabelField labelName={"End Date"} />
            <DatePicker
              setInputText={(value) => onChangeData(value, "endDate")}
              value={endDate}
              Isrequired={true}
              mindate={startDate}
              maxdate={moment(maxD).format("YYYY-MM-DD")}
            />
          </div>
          <div className="d-flex align-items-center mb-2 me-5">
            <LabelField labelName={"Exam Name"} />
            <CustomDropdown
              setInputText={(value) => onChangeData(value, "examName")}
              value={examName}
              options={examList}
              labelName="Select Exam Name"
              reset={resetExam}
              defaultValue={examName?._id}
            />
          </div>
        </div>
        <div className="d-flex" style={{ justifyContent: "space-between" }}>
          <div className="d-flex mb-2 align-items-center me-5">
            <LabelField labelName={"Exam Stream"} />
            <CustomDropdown
              setInputText={(value) => onChangeData(value, "examStream")}
              value={parentCourseVal}
              options={parentCourseList}
              labelName="Select Exam Stream"
              reset={resetParentCourse}
              defaultValue={parentCourseVal?._id}
            />
          </div>
          <div className="d-flex mb-2 align-items-center me-5">
            <LabelField labelName={"Teacher Name"} />
            <CustomDropdown
              setInputText={(value) => onChangeData(value, "teacherName")}
              value={teacherName}
              options={teacherList}
              labelName="select Teacher Name"
              reset={resetTeacher}
            />
          </div>
          <div className="d-flex mb-2 align-items-center me-5">
            <LabelField labelName={"Lecture Type"} />
            <CustomDropdown
              labelName="Select Lecture Type"
              setInputText={(value) => onChangeData(value, "lectureType")}
              options={youtubeTypeList}
              reset={resetTaskType}
              defaultValue={videoType?._id}
            />
          </div>
        </div>
        <div className="d-flex">
          <div className="d-flex">
            <div className="d-flex align-items-center mb-2 col-4 pe-4 mx-5">
              <button
                onClick={handleSubmit}
                type="submit"
                className={`btn mb-3 submitbtn ${
                  validations() ? "btn-secondary" : "btn-primary"
                }`}
                disabled={validations()}
              >
                Submit
              </button>
            </div>
            {teacherPerformList?.length !== 0 ? (
              <div className="d-flex mb-2 align-items-center">
                <DownloadTableExcel
                  filename="Teacher Task List"
                  sheet="Teacher Task List"
                  currentTableRef={tableRef.current}
                >
                  <button className="btn btn-outline-primary mb-3 submitbtn py-1">
                    Export Details
                  </button>
                </DownloadTableExcel>
              </div>
            ) : null}
          </div>
        </div>
      </div>
    );
  };

  const renderListMode = () => {
    return (
      <>
        <AdminTable
          pagename={"teacherPerformance"}
          tableHead={[
            "S.N",
            "DATE",
            "LECTURE TYPE",
            "EXAM",
            "DEPARTMENT",
            "TEACHER NAME",
            "YT LINK",
            "DURATION",
            "VIEWS",
            "LIKE",
            "CONCURRENT PEAK",
          ]}
          tableData={teacherPerformList ? teacherPerformList : []}
        />
      </>
    );
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    let data = {
      startDate,
      endDate,
    };
    if (examName) {
      data.examTypeId = examName?._id;
    }
    if (parentCourseVal) {
      data.baseCourseId = parentCourseVal?._id;
    }
    if (teacherName) {
      data.teacherId = teacherName?._id;
    }
    if (videoType) {
      data.type = videoType?.name;
    }
    dispatch(
      getYTAnalytics(data, (resp) => {
        let filter_data = resp?.data?.filter((item) => {
          return item?.taskType === "YOUTUBE_LIVE";
        });
        setTeacherPerformList(filter_data);
      })
    );
  };

  useEffect(() => {
    dispatch(
      getExamListNew((res) => {
        setExamList(res);
      })
    );
    dispatch(
      getAllTeachersList(
        {
          skip: 0,
          limit: 100,
        },
        (res) => {
          setTeacherList(res?.data?.data);
        },
        () => {}
      )
    );
    let data = {
      startDate,
      endDate,
    };
    dispatch(
      getYTAnalytics(data, (res) => {
        let filter_data = res?.data?.filter((item) => {
          return item?.taskType === "YOUTUBE_LIVE";
        });
        setTeacherPerformList(filter_data);
      })
    );
  }, []);
  return (
    <div className="contentBox">
      <PageTitle name={"TEACHER PERFORMANCE"} />
      <div className="formDiv">
        <div className="col-12 formWidth">
          {renderSearchMode()}
          {renderListMode()}
        </div>
      </div>
    </div>
  );
};

export default TeacherPerformance;
