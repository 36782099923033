/* eslint-disable react/prop-types */
import React from "react";
import NotInterestedIcon from "@mui/icons-material/NotInterested";

function Dropdown({ labelName, setInputText, options, value, disabled }) {
  const onHandleChange = (e) => {
    if (e.target.value) {
      setInputText(JSON.parse(e.target.value));
    }
  };

  const onChangeClear = () => {
    setInputText("");
  };

  return (
    <div className="d-flex mb-2">
      <select
        className="form-select w-100 border rounded p-2"
        value={JSON.stringify(value)}
        onChange={(e) => {
          onHandleChange(e);
        }}
        disabled={disabled}
      >
        <option value="">{labelName}</option>
        {options?.length > 0
          ? options?.map((item) => {
              return (
                <option key={item?._id} value={JSON.stringify(item)}>
                  {item?.name}
                </option>
              );
            })
          : null}
      </select>
      <button
        type="button"
        className={`ms-1 rounded border ${
          value && disabled
            ? "bg-light text-dark"
            : value
            ? "bg-success text-white"
            : ""
        }`}
        onClick={onChangeClear}
        disabled={disabled}
      >
        <span className="d-flex align-items-center">
          <NotInterestedIcon className="fs-6 me-1" />
          Clear
        </span>
      </button>
    </div>
  );
}

export default React.memo(Dropdown);
