import { combineReducers } from "redux";
import Auth from "./authReducer";
import uiReducer from "./uiReducer";
import courseReducer from "./courseReducer";
import { RESET_STORE } from "../Types/authTypes";

const appReducer = combineReducers({
  Auth,
  uiReducer,
  courseReducer,
});

const rootReducer = (state, action) => {
  if (action.type === RESET_STORE) {
    state = undefined;
  }
  return appReducer(state, action);
};

export default rootReducer;
