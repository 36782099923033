import moment from "moment";
import React, { useState, useEffect, useRef } from "react";
import { useDispatch } from "react-redux";
import PageTitle from "../../Module/pageTitle";
import { DownloadTableExcel } from "react-export-table-to-excel";
import CustomDropdown from "../../Component/customDropdown";
import DatePicker from "../../Component/datePicker";
import usePagination from "../../Component/usePagination";
import PaginationView from "../../Component/paginationView";
import PaginationTable from "../../Component/paginationTable";
import { Pagination } from "@mui/material";
import { getTableDataSlots } from "../../Utils/dataConstants";
import {
  getExamListNew,
  getParentCourseNew,
  getSocialMediaAd,
} from "../../Action/courseAction";

const SocialAds = () => {
  const dispatch = useDispatch();
  const tableRef = useRef(null);
  const [page, setPage] = useState(0);
  const [count, setCount] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [currentPage, setCurrentPage] = useState(1);
  const totalPages = Math.ceil(count / rowsPerPage);
  const _DATA = usePagination(count, rowsPerPage);
  const [selectedStatus, setSelectedStatus] = useState("");

  //states
  const [startDate, setStartDate] = useState(
    moment.utc(new Date()).local().format("YYYY-MM-DD")
  );
  const [endDate, setEndDate] = useState(
    moment.utc(new Date()).local().format("YYYY-MM-DD")
  );
  //state names
  const [examName, setExamName] = useState("");
  const [parentCourseVal, setparentCourseVal] = useState("");
  const [sourceName, setSourceName] = useState("");
  //state lists
  const [examList, setExamList] = useState([]);
  const [parentCourseList, setParentCourseList] = useState([]);
  //state reset
  const [resetExam, setResetExam] = useState(false);
  const [resetParentCourse, setResetParentCourse] = useState(false);
  const [resetSource, setResetSource] = useState(false);
  //data
  const [socialAdList, setSocialAdList] = useState([]);

  const source = [
    { _id: 6, name: "FACEBOOK" },
    { _id: 7, name: "INSTAGRAM" },
    { _id: 8, name: "TELEGRAM" },
    { _id: 9, name: "YOUTUBE" },
    { _id: 10, name: "LINKEDIN" },
  ];
  //handle function
  const handleSubmit = (e) => {
    e.preventDefault();
    let reqBody = {
      startDate: startDate,
      endDate: endDate,
      skip: 0,
      limit: 10,
    };
    if (examName) reqBody.examTypeId = examName?._id;
    if (parentCourseVal) reqBody.baseCourseId = parentCourseVal?._id;
    if (sourceName) reqBody.mediaId = sourceName?._id;
    dispatch(
      getSocialMediaAd(reqBody, (resp) => {
        setSocialAdList(resp?.data);
        setCount(resp?.data?.count ? resp?.data?.count : 0);
        setCurrentPage(1);
      })
    );
  };

  const handleChange = (e, page) => {
    onPageChange(page, rowsPerPage, 1);
    setPage(page - 1);
    setCurrentPage(page);
    _DATA.jump(page);
  };

  //all list change function

  const onChangeStartDate = (val) => {
    setStartDate(val);
  };
  const onChangeEndDate = (val) => {
    setEndDate(val);
    let reqBody = {
      startDate: startDate,
      endDate: val,
      skip: 0,
      limit: 10,
    };
    if (examName) reqBody.examTypeId = examName?._id;
    if (parentCourseVal) reqBody.baseCourseId = parentCourseVal?._id;
    if (sourceName) reqBody.mediaId = sourceName?._id;
    dispatch(
      getSocialMediaAd(reqBody, (resp) => {
        setSocialAdList(resp?.data);
        setCount(resp?.data?.count ? resp?.data?.count : 0);
        setCurrentPage(1);
      })
    );
  };

  const onListChange = (value) => {
    setExamName(value);
    setResetExam(false);
    setparentCourseVal("");
    setParentCourseList([]);
    setResetParentCourse(true);
    setSourceName("");
    setResetSource(true);
    dispatch(
      getParentCourseNew(`${value._id}`, (res) => {
        setParentCourseList(res);
      })
    );
  };

  const changeParentCourseVal = (value) => {
    setparentCourseVal(value);
    setResetParentCourse(false);
  };

  const onChangeSource = (value) => {
    setSourceName(value);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
    setCurrentPage(newPage + 1);
  };

  const handleChangeRowsPerPage = (event) => {
    const value = event.target.value;
    if (value === "ALL") {
      setRowsPerPage(-1);
      setPage(0);
      setCurrentPage(1);
      return;
    }
    setRowsPerPage(parseInt(value, 10));
    setPage(0);
    setCurrentPage(1);
    onPageChange(page, value, 2);
  };

  const onPageChange = (page, rowsPerPage, flag) => {
    let data = "";
    if (flag === 1) {
      data = {
        skip: (page - 1) * rowsPerPage,
        limit: rowsPerPage,
        startDate: startDate,
        endDate: endDate,
      };
      if (examName) data.examTypeId = examName?._id;
      if (parentCourseVal) data.baseCourseId = parentCourseVal?._id;
      if (sourceName) data.mediaId = sourceName?._id;
    } else if (flag === 2) {
      data = {
        startDate: startDate,
        endDate: endDate,
        skip: 0,
        limit: rowsPerPage,
      };
      if (examName) data.examTypeId = examName?._id;
      if (parentCourseVal) data.baseCourseId = parentCourseVal?._id;
      if (sourceName) data.mediaId = sourceName?._id;
    } else {
      data = {
        startDate: startDate,
        endDate: endDate,
        skip: 0,
        limit: count,
      };
      if (examName) data.examTypeId = examName?._id;
      if (parentCourseVal) data.baseCourseId = parentCourseVal?._id;
      if (sourceName) data.mediaId = sourceName?._id;
    }
    dispatch(
      getSocialMediaAd(data, (resp) => {
        setSocialAdList(resp?.data);
        setCount(resp?.data?.count ? resp?.data?.count : 0);
      })
    );
  };

  //render list
  const renderListMode = () => {
    return (
      <>
        <div className="d-flex paginationdiv justify-content-end align-items-center">
          <Pagination
            count={totalPages}
            size="large"
            page={currentPage}
            variant="outlined"
            shape="rounded"
            color={"primary"}
            onChange={handleChange}
          />
        </div>
        <PaginationView
          count={count}
          tableData={socialAdList?.data ? socialAdList?.data : []}
          // onTabChange={(e, index) => showData(e, index)}
          selectedIndex={selectedStatus === 2 ? 1 : 2}
          page={page}
          rowsPerPage={rowsPerPage}
          handleChangePage={handleChangePage}
          handleChangeRowsPerPage={handleChangeRowsPerPage}
          rowsPerPageOptions={getTableDataSlots(count)}
        />
        <div ref={tableRef}>
          <PaginationTable
            tableData={socialAdList?.data ? socialAdList?.data : []}
            pagename={"socialAds"}
            rowsPerPage={rowsPerPage}
            page={page}
            tableHead={[
              "S.NO",
              "FULL NAME",
              "EXAM NAME",
              "EXAM STREAM",
              "MOBILE VERIFIED",
              "SOURCE",
              "DATE",
            ]}
          />
        </div>
      </>
    );
  };
  //useeffect hooks
  useEffect(() => {
    setSelectedStatus(2);
    dispatch(
      getExamListNew((res) => {
        setExamList(res);
      })
    );
    let reqBody = {
      startDate: startDate,
      endDate: endDate,
      skip: 0,
      limit: 10,
    };
    dispatch(
      getSocialMediaAd(reqBody, (resp) => {
        setSocialAdList(resp?.data);
        setCount(resp?.data?.count ? resp?.data?.count : 0);
      })
    );
  }, []);
  return (
    <div className="contentBox">
      <PageTitle name={"SOCIAL MEDIA ADS"} />
      <div className="formDiv">
        <div className="col-12 formWidth">
          <form onSubmit={(e) => handleSubmit(e)}>
            <div className="d-flex pt-2 flex-wrap">
              <div className="d-flex align-items-center mb-2 col-4 pe-4">
                <label className="me-5 fw-bold">Start Date:</label>
                <DatePicker
                  setInputText={(value) => {
                    onChangeStartDate(value);
                  }}
                  value={startDate}
                  Isrequired={true}
                />
              </div>
              <div className="d-flex align-items-center mb-2 col-4 pe-4">
                <label className="me-5 fw-bold">End Date:</label>
                <DatePicker
                  setInputText={(value) => {
                    onChangeEndDate(value);
                  }}
                  value={endDate}
                  Isrequired={true}
                />
              </div>
              <div className="d-flex align-items-center mb-2 col-4 pe-4">
                <label className="me-3 fw-bold"> Exam Name</label>
                <CustomDropdown
                  setInputText={(value) => onListChange(value)}
                  value={examName}
                  options={examList}
                  labelName="Select Exam Name"
                  reset={resetExam}
                />
              </div>
              <div className="d-flex align-items-center mb-2 col-4 pe-4">
                <label className="me-3 fw-bold"> Exam Stream</label>
                <CustomDropdown
                  setInputText={(value) => changeParentCourseVal(value)}
                  value={parentCourseVal}
                  options={parentCourseList}
                  labelName="Select Exam Stream"
                  reset={resetParentCourse}
                />
              </div>
              <div className="d-flex align-items-center mb-2 col-4 pe-4">
                <label className="me-3 fw-bold"> Source</label>
                <CustomDropdown
                  setInputText={(value) => onChangeSource(value)}
                  value={sourceName}
                  options={source}
                  labelName="Select Source"
                  reset={resetSource}
                />
              </div>
              <div className="d-flex align-items-center mb-2 col-4 pe-4">
                <button
                  type="submit"
                  className={`btn mb-3 submitbtn ${"btn-primary"}`}
                >
                  Submit
                </button>
              </div>
              <div className="d-flex align-items-center mb-2 col-4 pe-4">
                <DownloadTableExcel
                  filename="PAID ADS"
                  sheet="PAID ADS"
                  currentTableRef={tableRef.current}
                >
                  <button
                    type="button"
                    className="btn mb-3 submitbtn btn-primary rounded-pill font-12"
                  >
                    Export Details
                  </button>
                </DownloadTableExcel>
              </div>
            </div>
          </form>
          {renderListMode()}
        </div>
      </div>
    </div>
  );
};

export default SocialAds;
