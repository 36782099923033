import React, { useEffect, useState } from "react";
import "../../App.css";
import PageTitle from "../../Module/pageTitle";
import DatePicker from "../../Component/datePicker";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import {
  issueTypeList,
  teacherRaiseIssueList,
} from "../../Action/courseAction";
import PaginationView from "../../Component/paginationView";
import usePagination from "../../Component/usePagination";
import Pagination from "@mui/material/Pagination";
import PaginationTable from "../../Component/paginationTable";
import moment from "moment";
import { getTableDataSlots } from "../../Utils/dataConstants";
const issue = 0;
function Leads() {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [startDate, setStartDate] = useState(
    moment.utc(new Date()).format("YYYY-MM-DD")
  );
  const [endDate, setEndDate] = useState(
    moment.utc(new Date()).format("YYYY-MM-DD")
  );
  const [supportList, setSupportList] = useState([]);

  // pagination
  const [count, setCount] = useState(0);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [currentPage, setCurrentPage] = useState(1);
  const totalPages = Math.ceil(count / rowsPerPage);
  const _DATA = usePagination(count, rowsPerPage);

  const handleChange = (e, page) => {
    onPageChange(page, rowsPerPage, 1);
    setPage(page - 1);
    setCurrentPage(page);
    _DATA.jump(page);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
    setCurrentPage(newPage + 1);
  };

  const handleChangeRowsPerPage = (event) => {
    const value = event.target.value;
    if (value === "All") {
      setRowsPerPage(-1);
      setPage(0);
      setCurrentPage(1);
      return;
    }
    setRowsPerPage(parseInt(value, 10));
    setPage(0);
    setCurrentPage(1);
    onPageChange(page, value, 2);
  };

  const onPageChange = (page, rowsPerPage, flag) => {
    /************ page change****************** */
    let data = "";
    if (flag === 1) {
      data = {
        skip: (page - 1) * rowsPerPage,
        limit: rowsPerPage,
        startDate,
        endDate,
      };
    } else if (flag === 2) {
      data = {
        skip: 0,
        limit: rowsPerPage,
        startDate,
        endDate,
      };
    } else {
      data = {
        skip: 0,
        limit: count,
        startDate,
        endDate,
      };
    }

    if (issue) {
      data["issueTypeId"] = issue._id;
    }

    dispatch(
      teacherRaiseIssueList(data, (res) => {
        let price = 0;
        res?.data?.forEach((item) => {
          price = price + item.issueTypeId.price;
        });
        setCount(res?.count);
        setSupportList(res?.data);
      })
    );
  };

  const chat = (id, userId, status, query) => {
    navigate("../chat", {
      state: {
        id: id,
        userId,
        status,
        screeName: "raiseIssueTeacher",
        query,
        isTeacher: true,
      },
    });
  };

  useEffect(() => {
    dispatch(
      teacherRaiseIssueList(
        {
          skip: 0,
          limit: rowsPerPage,
          startDate,
          endDate,
          status: 1,
        },
        (res) => {
          let price = 0;
          res?.data?.forEach((item) => {
            price = price + item.issueTypeId.price;
          });
          // setFine(price);
          setCount(res?.count);
          setSupportList(res?.data);
        }
      )
    );
    dispatch(issueTypeList(() => {}));
  }, []);

  const validations = () => {
    return startDate === "" || endDate === "" || issue === "";
  };

  const APICall = (val) => {
    if (issue) {
      let data = {
        skip: 0,
        limit: rowsPerPage,
        startDate,
        endDate,
        issueTypeId: issue._id,
        status: val === 2 ? val + 1 : val,
      };
      if (issue) {
        data["issueTypeId"] = issue._id;
      }
      dispatch(
        teacherRaiseIssueList(data, (res) => {
          let price = 0;
          res?.data?.forEach((item) => {
            price = price + item.issueTypeId.price;
          });
          // setFine(price);
          setCount(res?.count);
          setSupportList(res?.data);
        })
      );
    }
  };

  const changeEndDate = (value) => {
    setEndDate(value);
    let data = {
      skip: 0,
      limit: rowsPerPage,
      startDate,
      endDate: value,
      status: 1,
    };
    if (issue) {
      data["issueTypeId"] = issue._id;
    }
    dispatch(
      teacherRaiseIssueList(data, (res) => {
        let price = 0;
        res?.data?.forEach((item) => {
          price = price + item.issueTypeId.price;
        });
        setCount(res?.count);
        setSupportList(res?.data);
      })
    );
    dispatch(issueTypeList(() => {}));
  };

  const Submit = (e) => {
    e.preventDefault();
    APICall(1);
  };

  return (
    <div className="contentBox">
      <PageTitle name={"LEADS"} />
      <div className="row d-flex p-3 px-2 mx-0">
        <form className="col-12 formWidth" onSubmit={Submit}>
          <div className="d-flex align-items-center justify-content-between">
            <div className="d-flex">
              <div className="d-flex align-items-center me-5">
                {/* <LabelField labelName={"Start Date"} /> */}
                <label className="fw-bold me-2">Start Date</label>
                <DatePicker
                  setInputText={(value) => {
                    setStartDate(value);
                  }}
                  Isrequired={true}
                  value={startDate}
                />
              </div>
              <div className="d-flex align-items-center me-5">
                {/* <LabelField labelName={"End Date"} /> */}
                <label className="fw-bold me-2">End Date</label>
                <DatePicker
                  setInputText={(value) => {
                    changeEndDate(value);
                  }}
                  Isrequired={true}
                  value={endDate}
                />
              </div>
            </div>
            <div>
              <div className="col-auto d-flex justify-content-center">
                <div className="d-flex align-items-center mb-2 col-4 pe-4">
                  <button
                    type="submit"
                    className={`btn mb-3 submitbtn ${
                      validations() ? "btn-secondary" : "btn-primary"
                    }`}
                    disabled={validations()}
                  >
                    Search
                  </button>
                </div>
              </div>
            </div>
          </div>
        </form>

        <div className="d-flex paginationdiv justify-content-end align-items-center">
          <Pagination
            count={totalPages}
            size="large"
            page={currentPage}
            variant="outlined"
            shape="rounded"
            color={"primary"}
            onChange={handleChange}
          />
        </div>
        <PaginationView
          tableData={supportList ? supportList : []}
          page={page}
          rowsPerPage={rowsPerPage}
          handleChangePage={handleChangePage}
          handleChangeRowsPerPage={handleChangeRowsPerPage}
          rowsPerPageOptions={getTableDataSlots(count)}
        />
        <PaginationTable
          tableData={supportList ? supportList : []}
          pagename={"raiseTeacherIssue"}
          rowsPerPage={rowsPerPage}
          page={page}
          tableHead={[
            "S.N",
            "Query No.",
            "Issue Type",
            "Parent Course",
            "Price",
            "Issue",
            "Issue Date And Time",
            "Status",
          ]}
          handleAddOnButton={(id, user, status, query) =>
            chat(id, user, status, query)
          }
          edit={true}
        />
      </div>
    </div>
  );
}

export default Leads;
