import React, { useEffect, useState } from "react";
import "../../App.css";
import PageTitle from "../../Module/pageTitle";
import { useDispatch } from "react-redux";
import LabelField from "../../Component/labelField";
import moment from "moment";
import DatePicker from "../../Component/datePicker";

import {
  getMonthlyRevenue,
  getTransactionForecast,
} from "../../Action/courseAction";
import CustomDropdown from "../../Component/customDropdown";
import { Line } from "react-chartjs-2";
function RevenueProjection() {
  const dispatch = useDispatch();
  const today = new Date();
  const startOfMonth = new Date(today.getFullYear(), today.getMonth(), 1);
  const endOfMonth = new Date(today.getFullYear(), today.getMonth() + 1, 0);
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");

  const [companyType, setcompanyType] = useState({
    _id: 1,
    name: "Edutech",
  });
  const [transDetailData, setTransDetailData] = useState();
  const [selectedTypeData, setselectedTypeData] = useState({
    avgSales: "0",
    saleForecast: "0",
  });
  const [monthlyRevenuData, setmonthlyRevenuData] = useState();
  const [labelDataArr, setlabelDataArr] = useState([]);
  const [avgSaleDataArr, setavgSaleDataArr] = useState([]);
  const [totalSaleDataArr, settotalSaleDataArr] = useState([]);
  useEffect(() => {
    setStartDate(moment(startOfMonth).format("YYYY-MM-DD"));
    setEndDate(moment(endOfMonth).format("YYYY-MM-DD"));
    dispatch(
      getTransactionForecast((res) => {
        if (res?.length > 0) {
          setTransDetailData(res[0]);
          setselectedTypeData(res[0]?.edutech);
        }
      })
    );
    dispatch(
      getMonthlyRevenue((res) => {
        setmonthlyRevenuData(res);
        if (res?.length > 0) {
          let labelArr = [];
          let avgArr = [];
          let totalArr = [];
          res?.map((item) => {
            labelArr.push(item?.monthYear);
            avgArr.push(item?.edutech?.avgSales);
            totalArr.push(item?.edutech?.totalSale);
          });
          setlabelDataArr(labelArr);
          settotalSaleDataArr(totalArr);
          setavgSaleDataArr(avgArr);
        }
      })
    );
  }, []);

  const options = {
    responsive: true,
    plugins: {
      legend: {
        position: "top",
      },
      title: {
        display: true,
        text: "",
      },
    },
  };

  const AverageSaledata = {
    labels: labelDataArr,
    datasets: [
      {
        label: "Average Sale",
        data: avgSaleDataArr,
        borderColor: "rgb(53, 162, 235)",
        backgroundColor: "rgba(53, 162, 235, 0.5)",
      },
    ],
  };
  const TotalSaledata = {
    labels: labelDataArr,
    datasets: [
      {
        label: "Total Sale",
        data: totalSaleDataArr,
        borderColor: "rgb(53, 162, 235)",
        backgroundColor: "rgba(53, 162, 235, 0.5)",
      },
    ],
  };
  const renderListMode = () => {
    return (
      <div className="row mx-0 mt-2">
        <div className="row d-flex mx-0">
          <div className={`boxshadow card cardBox cursor`}>
            <h3 className="count fw-bold">{`\u20B9 ${
              selectedTypeData?.avgSales !== "NaN"
                ? selectedTypeData?.avgSales
                : 0
            }`}</h3>
            <h5 className="card-title">Average Sale</h5>
          </div>
          <div className={`boxshadow card cardBox cursor`}>
            <h3 className="count fw-bold">{`\u20B9 ${
              selectedTypeData?.saleForecast !== "NaN"
                ? selectedTypeData?.saleForecast
                : 0
            }`}</h3>
            <h5 className="card-title">Sale Forecast</h5>
          </div>
        </div>
        <div className="col-12">
          <div className="chart-Box rounded">
            <Line options={options} data={AverageSaledata} />
          </div>
          <div className="chart-Box rounded">
            <Line options={options} data={TotalSaledata} />
          </div>
        </div>
      </div>
    );
  };
  const companyTypeList = [
    {
      _id: 1,
      name: "Edutech",
    },
    {
      _id: 2,
      name: "Publication",
    },
  ];
  const onChangeType = (val) => {
    setcompanyType(val);
    if (val?._id === 1) {
      setselectedTypeData(transDetailData?.edutech);
      let labelArr = [];
      let avgArr = [];
      let totalArr = [];
      monthlyRevenuData?.map((item) => {
        labelArr.push(item?.monthYear);
        avgArr.push(item?.edutech?.avgSales);
        totalArr.push(item?.edutech?.totalSale);
      });
      setlabelDataArr(labelArr);
      settotalSaleDataArr(totalArr);
      setavgSaleDataArr(avgArr);
    } else {
      setselectedTypeData(transDetailData?.publication);
      let labelArr = [];
      let avgArr = [];
      let totalArr = [];
      monthlyRevenuData?.map((item) => {
        labelArr.push(item?.monthYear);
        avgArr.push(item?.publication?.avgSales);
        totalArr.push(item?.publication?.totalSale);
      });
      setlabelDataArr(labelArr);
      settotalSaleDataArr(totalArr);
      setavgSaleDataArr(avgArr);
    }
  };
  return (
    <div className="contentBox">
      <PageTitle name={"REVENUE PROJECTION"} />
      <div className="formDiv ">
        <div className="col-12 formWidth">
          <div className="col-auto">
            <div className="d-flex align-items-center">
              <div className="d-flex mb-2 align-items-center me-3">
                <LabelField labelName={"Start Date"} />
                <DatePicker value={startDate} disabled={true} />
              </div>
              <div className="d-flex mb-2 align-items-center">
                <LabelField labelName={"End Date"} />
                <DatePicker value={endDate} disabled={true} />
              </div>
              <div className="d-flex mb-2 align-items-center ms-3">
                <LabelField labelName={"Company Type"} />
                <CustomDropdown
                  setInputText={(value) => onChangeType(value)}
                  value={companyType}
                  options={companyTypeList}
                  labelName="Select Type"
                  defaultValue={companyType?._id}
                />
              </div>
            </div>
          </div>

          {renderListMode()}
        </div>
      </div>
    </div>
  );
}

export default RevenueProjection;
