export const SalesUpsellingPanelSidebarData = [
  {
    title: "TRANSACTION EDUTECH",
    link: "salestransactionedutech", 
  },
  {
    title: "TRANSACTION PUBLICATION",
    link: "transactionpublication",
  },
  {
    title: "EXAM RESULTS",
    link: "examresults",
  },
  {
    title: "SECOND INSTALLMENT",
    link: "secondinstallment",
  },
];
