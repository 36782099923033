import "../../App.css";
import React, { Suspense } from "react";
import Sidebar from "../../Module/sidebar";
import { Route, Routes } from "react-router-dom";
import Loading from "../../Component/Loading";
import { ManagerSidebarData } from "../../Module/ManagerSidebarData";

const RegistrationDetails = React.lazy(() => import("./registrationDetails"));
const MultiDeviceTokenClear = React.lazy(() =>
  import("../CounsellorPanel/MultiDeviceTokenClear")
);

function ManagerPanelDashboard() {
  return (
    <div className="AppBox">
      {/* <Header /> */}
      <Sidebar data={ManagerSidebarData} />
      <div className="dashboard">
        {
          <Suspense
            fallback={
              <div className="border p-1">
                <Loading />
              </div>
            }
          >
            <Routes>
              <Route path="/" element={<RegistrationDetails />} />
              <Route
                path="registrationDetails"
                element={<RegistrationDetails />}
              />
              <Route
                path="MultiDeviceTokenClear"
                element={<MultiDeviceTokenClear />}
              />
            </Routes>
          </Suspense>
        }
      </div>
    </div>
  );
}

export default ManagerPanelDashboard;
