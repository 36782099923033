/* eslint-disable react/prop-types */

import React, { useEffect, useState, useRef } from "react";
import ChatBody from "./ChatBody";

const ChatRTMP = ({ socket }) => {
  const [messages, setMessages] = useState([]);
  const lastMessageRef = useRef(null);

  useEffect(() => {
    socket.on("messageResponse", (data) => {
      setMessages((prev) => [...prev, data]);
    });
  }, []);

  useEffect(() => {
    // 👇️ scroll to bottom every time messages change
    lastMessageRef.current?.scrollIntoView({ behavior: "smooth" });
  }, [messages]);

  return (
    <div className={"chat"}>
      <div className="chat__main">
        <ChatBody messages={messages} lastMessageRef={lastMessageRef} />
      </div>
    </div>
  );
};

export default ChatRTMP;
