/* eslint-disable react/prop-types */
import React from "react";

function ToggleSwitchBtn(props) {
  return (
    <div className="form-check form-switch d-flex">
      <label
        className="form-check-label label"
        htmlFor="flexSwitchCheckDefault"
      >
        {props?.labelName ? props?.labelName : ""}
      </label>
      <input
        id={props.id}
        className="form-check-input"
        type="checkbox"
        role="switch"
        checked={props.isActive}
        disabled={props.disabled}
        defaultChecked={props.isActive}
        onChange={props.getToggleval}
        required={props.Isrequired}
      />
      {props.Isrequired ? <span className="required ms-2 fs-6">*</span> : null}
    </div>
  );
}

export default ToggleSwitchBtn;
