/* eslint-disable react/prop-types */
import React from "react";

function DateTime({
  value,
  setInputText,
  Isrequired,
  defaultValue,
  maxdate,
  mindate,
  disabled,
}) {
  return (
    <div className="d-flex mb-2 align-items-center w-100">
      <input
        className="form-control"
        type="datetime-local"
        value={value}
        onChange={(e) => setInputText(e.target.value)}
        defaultValue={defaultValue?.substring(0, 19)}
        max={maxdate}
        min={mindate}
        required={Isrequired}
        disabled={disabled}
        step="1"
        onKeyDown={(e) => {
          e.preventDefault();
        }} // To avoid entering the date
      />
      {Isrequired ? <span className="required ms-2 fs-6">*</span> : null}
    </div>
  );
}

export default DateTime;
