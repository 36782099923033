import React, { useEffect, useState } from "react";
import "../../App.css";
import PageTitle from "../../Module/pageTitle";
import InputField from "../../Component/inputField";
import { useDispatch } from "react-redux";
import {
  deleteExamDetails,
  getExamDetails,
  getExamListNew,
  getTeacherByExam,
  setParentCourse,
  updateExamDetails,
  uploadExamBanner,
} from "../../Action/courseAction";

import CustomDropdown from "../../Component/customDropdown";
import LabelField from "../../Component/labelField";
import { pdf_size_1MB, validateIsFileImageType } from "../../Utils/validators";
import { useSelector } from "react-redux";
import AdminTable from "../../Component/adminTable";
import TextEditor from "../DataEntryPanel/CreateQuestion/textEditor";
import DateTime from "../../Component/dateTime";
import ImagePickerAndViewer from "../../Component/imagePickerAndViewer";
import { validateIsFileImageTypeErrMsg } from "../../Utils/errorMsg";
import { showErrorSnackbar } from "../../Action/snackbarAction";
import { getSubExam } from "../../Action/adminPanelAction";

function EditDeleteExamDetails() {
  const dispatch = useDispatch();
  const [examName, setExamName] = useState("");
  const [examList, setExamList] = useState([]);
  const [resetExam, setResetExam] = useState(false);
  const [heading, setHeading] = useState("");
  const [packageBanner, setPackageBanner] = useState("");
  const [selectedImage, setSelectedImage] = useState("");
  const [teacherList, setTeacherList] = useState([]);
  const [teacherName, setTeacherName] = useState("");
  const [resetTeacherName, setResetTeacherName] = useState(false);
  const [teacherURL, setTeacherURL] = useState("");
  const [updateDate, setUpdateDate] = useState("");
  const [examDetails, setExamDetails] = useState("");
  const [examDetailsList, setExamDetailsList] = useState([]);
  const [id, setId] = useState("");
  const [subExamList, setSubExamList] = useState([]);
  const [subExamName, setSubExamName] = useState("");
  const [resetSubExam, setResetSubExam] = useState(false);

  const { isEditMode } = useSelector((state) => ({
    isEditMode: state.courseReducer?.isEditMode,
  }));

  const onChangeExam = (value) => {
    setExamName(value);
    setResetExam(false);
    setResetTeacherName(true);
    setResetSubExam(true);
    setTeacherList([]);
    setSubExamList([]);
    setTeacherName("");
    dispatch(
      getTeacherByExam({ examTypeId: value?._id }, (res) => {
        setTeacherList(res);
      })
    );
    dispatch(
      getSubExam(
        {
          examTypeId: value._id,
        },
        (res) => {
          if (res.data.length !== 0) setSubExamList(res.data);
        }
      )
    );
    listAPI(value?._id, "");
  };
  const onChangeSubExam = (value) => {
    setSubExamName(value);
    setResetSubExam(false);
    listAPI(examName?._id, value);
  };
  useEffect(() => {
    dispatch(
      getExamListNew((res) => {
        setExamList(res);
      })
    );
  }, []);

  const listAPI = (examId, subExam) => {
    const dataRequest = {
      examTypeId: examId,
    };
    if (subExam?._id) {
      dataRequest["subExamTypeId"] = subExam._id;
    }
    dispatch(
      getExamDetails(dataRequest, (data) => {
        setExamDetailsList(data);
      })
    );
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (selectedImage) {
      dispatch(
        uploadExamBanner(selectedImage, (imageRes) => {
          const postData = {
            examTypeId: examName?._id,
            updatedDateByTeacher: updateDate,
            teacherId: teacherName?._id,
            headingBanner: imageRes?.file,
            teacherURL: teacherURL,
            heading: heading,
            details: examDetails,
          };
          if (subExamName?._id) {
            postData["subExamTypeId"] = subExamName._id;
          }
          dispatch(
            updateExamDetails(postData, id, () => {
              listAPI(examName?._id, subExamName);
              setId("");
              dispatch(setParentCourse("isEditMode", false));
            })
          );
        })
      );
    } else {
      const postData = {
        examTypeId: examName?._id,
        updatedDateByTeacher: updateDate,
        teacherId: teacherName?._id,
        teacherURL: teacherURL,
        heading: heading,
        details: examDetails,
      };
      if (subExamName?._id) {
        postData["subExamTypeId"] = subExamName._id;
      }
      dispatch(
        updateExamDetails(postData, id, () => {
          listAPI(examName?._id, subExamName);
          setId("");
          dispatch(setParentCourse("isEditMode", false));
        })
      );
    }
  };

  const validations = () => {
    return (
      heading === "" ||
      packageBanner === "" ||
      teacherName === "" ||
      teacherURL === "" ||
      examDetails === "" ||
      updateDate === ""
    );
  };
  const handleBannerChange = (e) => {
    e.preventDefault();
    const reader = new FileReader();
    const file = e.target.files[0];
    if (!validateIsFileImageType(e)) {
      dispatch(showErrorSnackbar(validateIsFileImageTypeErrMsg));
      return;
    } else if (file.size > pdf_size_1MB) {
      dispatch(showErrorSnackbar("File size should be less than 1 MB!!!"));
    } else {
      reader.onloadend = () => {
        setPackageBanner(reader.result);
        setSelectedImage(file);
      };
      reader.readAsDataURL(file);
    }
  };
  const onTeacherNameChange = (value) => {
    setTeacherName(value);
    setResetTeacherName(false);
  };
  const getData = (data) => {
    setHeading(data?.heading);
    setPackageBanner(data?.headingBanner);
    setResetTeacherName(false);
    setTeacherName(data?.teacherId);
    setUpdateDate(data?.updatedDateByTeacher);
    setTeacherURL(data?.teacherURL);
    setExamDetails(data?.details);
    setId(data?._id);
    dispatch(setParentCourse("isEditMode", true));
  };
  const renderEditMode = () => (
    <form className="formWidth" onSubmit={handleSubmit}>
      <div className="col-7">
        <InputField
          labelName={"Heading"}
          placeholder={"Enter Heading"}
          setInputText={(value) => setHeading(value)}
          value={heading}
          Isrequired={true}
        />
        <div className="d-flex mb-2 align-items-center">
          <ImagePickerAndViewer
            labelName={"Banner Image"}
            value={packageBanner}
            handleChange={(e) => handleBannerChange(e)}
            htmlFor={"photo-upload"}
          />
        </div>
        <div className="d-flex mb-2 align-items-center">
          <LabelField labelName={"Teacher Name"} />
          <CustomDropdown
            labelName={"select Teacher Name"}
            setInputText={(value) => onTeacherNameChange(value)}
            options={teacherList}
            reset={resetTeacherName}
            Isrequired={true}
            defaultValue={teacherName ? teacherName?._id : ""}
          />
        </div>
        <InputField
          labelName={"Teacher URL"}
          placeholder={"Enter Teacher URL"}
          setInputText={(value) => setTeacherURL(value)}
          value={teacherURL}
          Isrequired={true}
        />

        <div className=" d-flex mb-2 align-items-center">
          <LabelField labelName={"Update Date"} />
          <DateTime
            setInputText={(value) => {
              setUpdateDate(value);
            }}
            Isrequired={true}
            defaultValue={updateDate}
          />
        </div>
        <div className="d-flex mb-2 align-items-center">
          <LabelField labelName={"Exam Details"} />
          <div className="editor_content mb-2">
            <TextEditor
              setInputText={(value) => setExamDetails(value)}
              value={examDetails}
            />
          </div>
          <span className="required ms-2 ">*</span>
        </div>
      </div>
      <div className="col-auto d-flex justify-content-center">
        <div className="col-auto d-flex justify-content-center mt-4">
          <button
            type="submit"
            className={`btn mb-3 submitbtn ${
              validations() ? "btn-secondary" : "btn-primary"
            }`}
            disabled={validations()}
          >
            Submit
          </button>
        </div>
      </div>
    </form>
  );

  const renderListMode = () => {
    return (
      <AdminTable
        pagename={"examDetails"}
        tableData={examDetailsList}
        tableHead={[
          "S.N",
          "Exam Name",
          "Heading",
          "Banner Image",
          "Teacher Name",
          "Teacher Url",
          "Update Date",
          "Actions",
        ]}
        handleEdit={(data) => getData(data)}
        handleDelete={(data) => handleDelete(data)}
        edit={true}
      />
    );
  };

  const handleDelete = (value) => {
    dispatch(
      deleteExamDetails(`${value?._id}`, () => {
        dispatch(setParentCourse("isEditMode", false));
        listAPI(examName?._id, subExamName);
      })
    );
  };

  return (
    <>
      <div className="contentBox">
        <PageTitle
          name={"EDIT EXAM DETAILS"}
          isEditMode={isEditMode}
          onClick={() => dispatch(setParentCourse("isEditMode", false))}
        />
        <div className="col-auto formWidth formDiv">
          <div className="row mx-0">
            <div className="col-md-6 d-flex mb-2 align-items-center">
              <LabelField labelName={"Exam Name"} />
              <CustomDropdown
                setInputText={(value) => onChangeExam(value)}
                value={examName}
                options={examList}
                labelName="Select Exam Name"
                reset={resetExam}
                disabled={isEditMode}
              />
            </div>
            {subExamList.length > 0 && (
              <div className="col-md-6 d-flex mb-2 align-items-center">
                <LabelField labelName={"Sub Exam Name"} />
                <CustomDropdown
                  setInputText={(value) => onChangeSubExam(value)}
                  defaultValue={subExamName._id}
                  options={subExamList}
                  labelName="Select Sub Exam Name"
                  reset={resetSubExam}
                  disabled={isEditMode}
                />
              </div>
            )}
          </div>
          {!isEditMode ? renderListMode() : renderEditMode()}
        </div>
      </div>
    </>
  );
}

export default EditDeleteExamDetails;
