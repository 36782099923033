export const SocialMediaManagerData = [
  {
    title: "LEAD DASHBOARD",
    subNav: [
      {
        title: "LEAD DASHBOARD",
        path: "leadDashboard",
        cName: "sub-nav",
      },
      {
        title: "LEAD COUNT",
        path: "LeadCount",
        cName: "sub-nav",
      },
      {
        title: "LEAD ANALYSIS",
        path: "leadAnalysis",
        cName: "sub-nav",
      },
    ],
  },
  {
    title: "TARGET DASHBOARD",
    subNav: [
      {
        title: "CREATE TARGET",
        path: "createTarget",
        cName: "sub-nav",
      },
      {
        title: "TARGET STATUS",
        path: "targetStatus",
        cName: "sub-nav",
      },
    ],
  },
  {
    title: "SOCIAL MEDIA ADS",
    link: "socialAds",
  },
];
export const SocialMediaSideBarData = [
  {
    title: "LEAD DASHBOARD",
    subNav: [
      {
        title: "LEAD DASHBOARD",
        path: "leadDashboard",
        cName: "sub-nav",
      },
      {
        title: "LEAD COUNT",
        path: "LeadCount",
        cName: "sub-nav",
      },
      {
        title: "LEAD ANALYSIS",
        path: "leadAnalysis",
        cName: "sub-nav",
      },
    ],
  },
  {
    title: "TARGET STATUS",
    link: "targetStatus",
  },
  {
    title: "SOCIAL MEDIA ADS",
    link: "socialAds",
  },
  // {
  //   title: "COMPETITOR ANALYSIS",
  //   subNav: [
  //     {
  //       title: "ADD",
  //       path: "addCompetitor",
  //       cName: "sub-nav",
  //     },
  //     {
  //       title: "EDIT/DELETE",
  //       path: "editCompetitor",
  //       cName: "sub-nav",
  //     },
  //   ],
  // },
  // {
  //   title: "RESULT COMPILATION",
  //   link: "resultCompilation",
  // },
  // {
  //   title: "SOCIAL MEDIA LINKS",
  //   subNav: [
  //     {
  //       title: "ADD LINKS ",
  //       path: "socialMediaLinks",
  //       cName: "sub-nav",
  //     },
  //     {
  //       title: "EDIT/DELETE",
  //       path: "EditDeleteSocialMediaLink",
  //       cName: "sub-nav",
  //     },
  //   ],
  // },
];
