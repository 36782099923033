/* eslint-disable react/prop-types */
import React from "react";
import MessageIcon from "@mui/icons-material/Message";
import moment from "moment";
function SupportCard(props) {
  const { data, clickhandle, selectedSupportID } = props;
  return (
    <div
      className={`font12  p-2 bgwhite border cursor ${
        selectedSupportID === data?._id ? "border-primary" : ""
      }`}
      onClick={clickhandle}
      key={data?.issueId}
    >
      <div className="d-flex">
        <div className="flex-fill ps-2">
          <div className="">
            <p className="mb-0 text-primary">{data?.name}</p>
          </div>
          <p className="mb-0">{data?.topicId?.name}</p>
          <p className="mb-0">
            Issue ID: #{data?.issueId} |{" "}
            {moment(data?.createdAt).format("D MMMM YYYY, h:mm a")}
          </p>
          {data?.status === 1 ? (
            <div className="d-flex colorgrey">
              <MessageIcon fontSize="small" />
              <p className="successcolor mb-0 ms-1">
                {moment(data?.lastMessageReceivedDate).format(
                  "D MMMM YYYY, h:mm a"
                )}
              </p>
            </div>
          ) : (
            <div className="d-flex colorgrey">
              <MessageIcon fontSize="small" />
              <p className="mb-0 ms-1">
                {moment(data?.lastMessageReceivedDate).format(
                  "D MMMM YYYY, h:mm a"
                )}
              </p>
            </div>
          )}
        </div>
      </div>
    </div>
  );
}

export default SupportCard;
