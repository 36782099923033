import "../../App.css";
import React, { Suspense } from "react";
import Sidebar from "../../Module/sidebar";
import { Route, Routes } from "react-router-dom";
import Loading from "../../Component/Loading";
import { SalesUpsellingPanelSidebarData } from "../../Module/salesUpsellingPanelSidebarData";
import ScholarshipExamResult from "./scholarshipExamResult";
import Leads from "./leads";

const SalesTransactionEdutech = React.lazy(() =>
  import("../SalesUpsellingPanel/salesTransactionEdutech")
);
const TransactionPublication = React.lazy(() =>
  import("../Finance/transactionPublication")
);
const ExamResults = React.lazy(() => import("./examResults"));
const RegisteredUser = React.lazy(() => import("../CXOPanel/registeredUser"));
const AssuredRevenueSales = React.lazy(() =>
  import("../Finance/assuredRevenueSales")
);

function SalesDashboard() {
  return (
    <div className="AppBox">
      {/* <Header /> */}
      <Sidebar data={SalesUpsellingPanelSidebarData} />
      <div className="dashboard">
        {
          <Suspense
            fallback={
              <div className="border p-1">
                <Loading />
              </div>
            }
          >
            <Routes>
              <Route path="/" element={<SalesTransactionEdutech />} />
              <Route
                path="salesTransactionEdutech"
                element={<SalesTransactionEdutech />}
              />
              <Route
                path="transactionPublication"
                element={<TransactionPublication />}
              />
              <Route path="examResults" element={<ExamResults />} />
              <Route
                path="ScholarshipExamResult"
                element={<ScholarshipExamResult />}
              />
              <Route path="leads" element={<Leads />} />
              <Route path="registeredUser" element={<RegisteredUser />} />
              <Route
                path="secondinstallment"
                element={<AssuredRevenueSales />}
              />
            </Routes>
          </Suspense>
        }
      </div>
    </div>
  );
}

export default SalesDashboard;
