/* eslint-disable react/prop-types */
import React from "react";
import { useSelector } from "react-redux";

const ChatBody = ({ messages, lastMessageRef }) => {
  const { _id } = useSelector((state) => ({
    _id: state?.AuthReducer?._id,
  }));

  return (
    <>
      <div className="msgheader bg-blue border p-2 rounded-top">
        <div className="d-flex Title">
          <h5 className="">RTMP Chat</h5>
        </div>
      </div>
      <div className="message__container border">
        {messages.map((message) =>
          message.userId === _id ? (
            <div className="message__chats" key={message.id}>
              <div className="message__sender">
                <p className="mb-0">{message.text[0].text}</p>
              </div>
              <p className="sender__name mb-0">You</p>
            </div>
          ) : (
            <div className="message__chats" key={message.id}>
              <div className="message__recipient">
                <p className="mb-0">{message.text[0].text}</p>
              </div>
              <p className="mb-0 text-end chatOwnerName">{message.name}</p>
            </div>
          )
        )}

        <div className="message__status"></div>
        <div ref={lastMessageRef} />
      </div>
    </>
  );
};

export default ChatBody;
