export const YoutubeSidebarData = [
  {
    title: "TARGET DASHBOARD",
    subNav: [
      {
        title: "CREATE TARGET",
        path: "createTargetYoutube",
        cName: "sub-nav",
      },
      {
        title: "TARGET STATUS",
        path: "targetStatusYoutube",
        cName: "sub-nav",
      },
    ],
  },
];
