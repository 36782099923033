/* eslint-disable react/prop-types */
import TablePagination from "@mui/material/TablePagination";
import React from "react";
import "../App.css";
import { DownloadTableExcel } from "react-export-table-to-excel";

function PaginationView({
  count,
  tabs,
  onTabChange,
  selectedIndex,
  title,
  tableData,
  page,
  rowsPerPage,
  handleChangePage,
  handleChangeRowsPerPage,
  rowsPerPageOptions,
  handleClick,
  pagename,
  exportRef,
}) {
  return (
    <div className="CourseSupport">
      <h5 className="font-weight-bold">{title}</h5>
      {/* <div className="d-flex justify-content-end align-items-center paginateno"> */}

      <div className="d-flex justify-content-between align-items-center paginateno">
        {pagename === "ManageEmployee" ? (
          <div className="d-flex align-items-center me-4">
            <DownloadTableExcel
              filename={"Manage Employee"}
              sheet="Manage Employee"
              currentTableRef={exportRef.current}
            >
              <button
                type="button"
                className="btn btn-primary rounded-pill font-12"
              >
                Export Details
              </button>
            </DownloadTableExcel>
          </div>
        ) : null}

        <div className="d-flex align-items-center">
          {tabs?.map((data, index) => {
            return (
              <button
                key={index}
                className={`add_Question-btn btn btn btn-sm me-4 ${
                  selectedIndex === index + 1 ? "btn-success" : "btn-secondary"
                }`}
                onClick={(e) => onTabChange(e, index + 1)}
              >
                {data}
              </button>
            );
          })}
        </div>
        <div className="d-flex align-items-center">
          {pagename === "ManageEmployee" ? (
            <>
              {selectedIndex === 1 ? (
                <button
                  className="add_Question-btn btn btn btn-sm btn-primary me-4"
                  onClick={() => handleClick()}
                >
                  {" "}
                  Add Employee
                </button>
              ) : (
                ""
              )}
            </>
          ) : (
            ""
          )}
          {pagename === "transactionPublication" ||
          pagename === "paymentPublication" ? null : (
            <div className="mt-4">
              <TablePagination
                component="div"
                count={count ? count : tableData?.length}
                //   count={tableData?.length}
                page={page}
                onPageChange={handleChangePage}
                rowsPerPage={rowsPerPage}
                onRowsPerPageChange={handleChangeRowsPerPage}
                rowsPerPageOptions={rowsPerPageOptions}
              />
            </div>
          )}
        </div>
      </div>
    </div>
  );
}

export default PaginationView;
