// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `nav{
    width: 300px;
    height: 100vh;
    border-right: 1px solid #c6c6c6;
}`, "",{"version":3,"sources":["webpack://./src/Module/sidebar.css"],"names":[],"mappings":"AAAA;IACI,YAAY;IACZ,aAAa;IACb,+BAA+B;AACnC","sourcesContent":["nav{\r\n    width: 300px;\r\n    height: 100vh;\r\n    border-right: 1px solid #c6c6c6;\r\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
