import React, { Suspense } from "react";
import Sidebar from "../../Module/sidebar";
import Loading from "../../Component/Loading";
import { Route, Routes } from "react-router";
import { PaidAdsSideBarData } from "../../Module/paidAdsSidebarData";
const PaidAds = React.lazy(() => import("./paidAds"));
const LeadDashboard = React.lazy(() =>
  import("../CategoryPanel/leadDashboard")
);
const LeadCount = React.lazy(() => import("../CategoryPanel/leadCount"));
const LeadAnalysis = React.lazy(() => import("../CategoryPanel/leadAnalysis"));

const TargetStatus = React.lazy(() => import("../CategoryPanel/targetStatus"));

const PaidAdsDashboard = () => {
  return (
    <div className="AppBox">
      {/* <Header /> */}
      <Sidebar data={PaidAdsSideBarData} />
      <div className="dashboard">
        {
          <Suspense
            fallback={
              <div className="border p-1">
                <Loading />
              </div>
            }
          >
            <Routes>
              <Route path="/" element={<PaidAds />} />
              <Route path="paidAds" element={<PaidAds />} />
              <Route path="leadDashboard" element={<LeadDashboard />} />
              <Route path="LeadCount" element={<LeadCount />} />
              <Route path="leadAnalysis" element={<LeadAnalysis />} />
              <Route path="targetStatus" element={<TargetStatus />} />
            </Routes>
          </Suspense>
        }
      </div>
    </div>
  );
};

export default PaidAdsDashboard;
