import React, { useEffect, useState } from "react";
import "../../App.css";
import PageTitle from "../../Module/pageTitle";
import StarIcon from "@mui/icons-material/Star";
import { useLocation } from "react-router-dom";
import { getCourseTeacherFeedback } from "../../Action/courseAction";
import { useDispatch } from "react-redux";
import moment from "moment";

function TeacherLectureFeedback() {
  const location = useLocation();
  const dispatch = useDispatch();
  const [lectureFeedback, setLectureFeedback] = useState([]);
  const recordedLectureId = location.state?.id.toString();
  const lectureName = location.state?.val.toString();
  const teacherName = location.state.teacherName;

  useEffect(() => {
    dispatch(
      getCourseTeacherFeedback(
        recordedLectureId,
        (res) => {
          setLectureFeedback(res.data);
        },
        () => {}
      )
    );
  }, []);

  const overallRating = () => {
    let rating = 0;
    lectureFeedback.map((item) => {
      rating = rating + item?.rating;
    });
    return rating / lectureFeedback.length;
  };

  return (
    <div className="contentBox">
      <PageTitle
        name={"LECTURE FEEDBACK"}
        reviewCount={lectureFeedback.length ? lectureFeedback.length : "0"}
        overallRating={overallRating()}
      />
      <div className="formDiv p-2">
        <div className="col-12 formWidth">
          <div className="p-3">
            <div className="d-flex justify-content-between">
              <div>
                <span className="fw-bold">Lecture name: </span>
                <span className="fw-bold" style={{ color: "#3F51B5" }}>
                  {" "}
                  {lectureName}{" "}
                </span>
              </div>
              <div>
                <span className="fw-bold">Teacher name: </span>
                <span className="fw-bold" style={{ color: "#3F51B5" }}>
                  {" "}
                  {teacherName.name}{" "}
                </span>
              </div>
            </div>

            {lectureFeedback.map((item, index) => {
              return (
                <div
                  key={index}
                  className="pt-4 shadow p-3 mb-5 bg-body rounded "
                >
                  <div className="d-flex  justify-content-between">
                    <div className="d-flex">
                      <span
                        className="me-3 fw-bold"
                        style={{ color: "#3F51B5" }}
                      >
                        #{index + 1}.
                      </span>
                      <span
                        className="me-2 fw-bold"
                        style={{ color: "#3F51B5" }}
                      >
                        {`${item?.userFirstName} ${item?.userLastName}`}
                      </span>
                      {item?.userMobileNo}

                      <div className="ms-3 me-3 d-flex justify-content-between">
                        <span
                          className="me-1 fw-bold"
                          style={{ color: "#3F51B5" }}
                        >
                          {item?.rating}
                        </span>
                        <StarIcon
                          fontSize="small"
                          style={{ color: "#faaf00" }}
                        />
                      </div>
                    </div>

                    <div>
                      <span>
                        {moment.utc(item?.createdAt).format("DD/MM/YYYY LTS")}
                      </span>
                    </div>
                  </div>
                  <div className="d-flex">
                    <div className="me-3 d-flex justify-content-between">
                      <span
                        className="me-1 fw-bold"
                        style={{ color: "#3F51B5" }}
                      >
                        Subject :
                      </span>
                      {item?.subjectName}

                      <span
                        className="ms-5 me-1 fw-bold"
                        style={{ color: "#3F51B5" }}
                      >
                        Topic :
                      </span>
                      {item?.topicName}
                    </div>
                  </div>
                  <div className="pt-2">
                    <p> {item?.feedback}</p>
                  </div>
                </div>
              );
            })}
          </div>
        </div>
      </div>
    </div>
  );
}

export default TeacherLectureFeedback;
