import "../../App.css";
import React, { Suspense } from "react";
import Sidebar from "../../Module/sidebar";
import { Route, Routes } from "react-router-dom";
import Loading from "../../Component/Loading";
import Chat from "../TeacherPanel/chat";
import { VpOperationsSidebarData } from "../../Module/vpOperationsSidebarData";

const TechnicalSupport = React.lazy(() => import("./technicalSupport"));
const CxoBatchwiseCount = React.lazy(() =>
  import("../CXOPanel/cxoBatchWiseCount")
);
const DatewiseCount = React.lazy(() => import("../Finance/datewiseCount"));
const RevenueDetails = React.lazy(() => import("../Finance/revenueDetails"));
const DoubtSoleve = React.lazy(() => import("./doubtSolveReview"));
const TransactionEdutech = React.lazy(() =>
  import("../CXOPanel/transactionEdutech")
);
const TransactionEdutechStudyMaterial = React.lazy(() =>
  import("../VpOperationsPanel/transactionEdutechStudyMaterial")
);

const RegistrationDetails = React.lazy(() =>
  import("../TechnicalSupportPanel/registrationDetails")
);
const OldPackageExist = React.lazy(() =>
  import("../CXOPanel/oldPackageExists")
);
const YoutubeDuplicateUrl = React.lazy(() => import("./youtubeDuplicateUrl"));

function VpOperationsPanelDashboard() {
  return (
    <div className="AppBox">
      {/* <Header /> */}
      <Sidebar data={VpOperationsSidebarData} />
      <div className="dashboard">
        {
          <Suspense
            fallback={
              <div className="border p-1">
                <Loading />
              </div>
            }
          >
            <Routes>
              <Route path="/" element={<TechnicalSupport />} />
              <Route path="technicalSupport" element={<TechnicalSupport />} />
              <Route path="/Chat" element={<Chat />} />
              <Route path="cxobatchwiseCount" element={<CxoBatchwiseCount />} />
              <Route path="datewiseCount" element={<DatewiseCount />} />
              <Route path="revenueDetails" element={<RevenueDetails />} />
              <Route path="doubtsolvereview" element={<DoubtSoleve />} />
              <Route
                path="transactionEdutech"
                element={<TransactionEdutech />}
              />
              <Route
                path="transactionEdutechStudyMaterial"
                element={<TransactionEdutechStudyMaterial />}
              />
              <Route
                path="registrationDetails"
                element={<RegistrationDetails />}
              />
              <Route path="oldPackageExist" element={<OldPackageExist />} />
              <Route
                path="youtubeDuplicateUrl"
                element={<YoutubeDuplicateUrl />}
              />
            </Routes>
          </Suspense>
        }
      </div>
    </div>
  );
}

export default VpOperationsPanelDashboard;
