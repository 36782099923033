class AuthReducerModal {
  authToken = "";
  loader = false;
}
class CourseReducerModal {
  isFirstTimeLoggedIn = true;
  isEditMode = false;
  parentCourse = [];
  state = [];
  examList = [];
  subExamList = [];
  booksList = [];
  sectionList = [];
  examsetList = [];
  examSubjectList = [];
  partList = [];
  parentExamName = [];
  sidebarTitle = "";
  transaction = [];
}

class SnakbarReducerModal {
  successSnackbarOpen = false;
  errorSnackbarOpen = false;
  infoSnackbarOpen = false;
  message = "";
}

export { AuthReducerModal, SnakbarReducerModal, CourseReducerModal };
