import React, { useEffect, useState } from "react";
import "../../App.css";
import PageTitle from "../../Module/pageTitle";
import LabelField from "../../Component/labelField";
import { useDispatch } from "react-redux";
import { getParentCourseNew, getExamListNew } from "../../Action/courseAction";
import CustomDropdown from "../../Component/customDropdown";
import HtmlTextEditor from "../../Component/HtmlTextEditor";
import InputField from "../../Component/inputField";
import {
  addResourceContent,
  getResourceContent,
  getResourcePage,
} from "../../Action/seoAction";
import { getSubExam } from "../../Action/adminPanelAction";

function CreateResourceDetail() {
  const dispatch = useDispatch();
  const [parentCourseVal, setparentCourseVal] = useState("");
  const [examName, setExamName] = useState("");
  const [subExamName, setSubExamName] = useState("");
  const [details, setDetails] = useState("");
  const [parentCourseList, setParentCourseList] = useState([]);
  const [examList, setExamList] = useState([]);
  const [subExamList, setsubExamList] = useState([]);
  const [resetParentCourse, setResetParentCourse] = useState(false);
  const [resetExam, setResetExam] = useState(false);
  const [resetSubExam, setResetSubExam] = useState(false);
  const [titleHeading, setTitleHeading] = useState("");
  const [titleDesc, setTitleDesc] = useState("");
  const [resourceList, setResourceList] = useState([]);
  const [resetResource, setResetResource] = useState(false);
  const [resource, setResource] = useState("");
  useEffect(() => {
    dispatch(
      getExamListNew((res) => {
        setExamList(res);
      })
    );
    dispatch(
      getResourcePage("", (res) => {
        setResourceList(res?.data);
      })
    );
  }, []);

  const onChangeExam = (value) => {
    setExamName(value);
    setResetExam(false);
    setsubExamList([]);
    setResetSubExam(true);
    setParentCourseList([]);
    setResetParentCourse(true);
    setResetResource(true);
    setDetails("");
    setTitleHeading("");
    setTitleDesc("");
    dispatch(
      getSubExam(
        {
          examTypeId: value._id,
        },
        (res) => {
          if (res?.data?.length > 0) {
            setsubExamList(res.data);
          } else {
            dispatch(
              getParentCourseNew(`${value._id}`, (res) => {
                setParentCourseList(res);
              })
            );
          }
        }
      )
    );
  };

  const onChangeSubExam = (value) => {
    setSubExamName(value);
    setResetSubExam(false);
    setParentCourseList([]);
    setResetParentCourse(true);
    setResetResource(true);
    setDetails("");
    setTitleHeading("");
    setTitleDesc("");
    dispatch(
      getParentCourseNew(`${examName._id}/${value._id}`, (res) => {
        setParentCourseList(res);
      })
    );
  };

  const changeParentCourse = (value) => {
    setparentCourseVal(value);
    setResetParentCourse(false);
    setResetResource(true);
    setDetails("");
    setTitleHeading("");
    setTitleDesc("");
  };

  const onChangeResource = (value) => {
    setResource(value);
    setResetResource(false);
    setDetails("");
    setTitleHeading("");
    setTitleDesc("");
    const data = {
      examTypeId: examName._id,
      baseCourseId: parentCourseVal._id,
      pageId: value._id,
    };
    if (subExamName?._id) data["subExamTypeId"] = subExamName._id;
    dispatch(
      getResourceContent(data, (res) => {
        setTitleHeading(res?.data?.title);
        setTitleDesc(res?.data?.description);
        setDetails(res?.data?.details);
      })
    );
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const postData = {
      baseCourseId: parentCourseVal._id,
      examTypeId: examName._id,
      title: titleHeading,
      description: titleDesc,
      pageId: resource._id,
      details: details,
    };
    if (subExamName) {
      postData["subExamTypeId"] = subExamName?._id;
    }

    dispatch(
      addResourceContent(postData, () => {
        setResetExam(true);
        setsubExamList([]);
        setResetSubExam(true);
        setParentCourseList([]);
        setResetParentCourse(true);
        setResetResource(true);
        setDetails("");
        setTitleHeading("");
        setTitleDesc("");
      })
    );
  };

  const validations = () => {
    return (
      parentCourseVal === "" ||
      examName === "" ||
      (subExamList?.length > 0 && subExamName === "") ||
      titleHeading === "" ||
      titleDesc === ""
    );
  };

  return (
    <div className="contentBox">
      <PageTitle name={"RESOURCE DETAILS"} />
      <div className="formDiv">
        <form className="formWidth" onSubmit={handleSubmit}>
          <div className="col-auto">
            <div className="d-flex">
              <LabelField labelName={"Exam Name"} />
              <CustomDropdown
                setInputText={(value) => onChangeExam(value)}
                value={examName}
                options={examList}
                Isrequired={true}
                labelName="Select Exam Name"
                reset={resetExam}
              />
            </div>
            {subExamList?.length > 0 ? (
              <div className="d-flex">
                <LabelField labelName={"Sub Exam Name"} />
                <CustomDropdown
                  setInputText={(value) => onChangeSubExam(value)}
                  value={subExamName}
                  options={subExamList}
                  Isrequired={true}
                  labelName="Select Sub Exam Name"
                  reset={resetSubExam}
                />
              </div>
            ) : null}
            <div className="d-flex">
              <LabelField labelName={"Exam Stream"} />
              <CustomDropdown
                setInputText={(value) => changeParentCourse(value)}
                value={parentCourseVal}
                options={parentCourseList}
                Isrequired={true}
                labelName="Select Exam Stream"
                reset={resetParentCourse}
              />
            </div>
            <div className="d-flex mb-2 align-items-center">
              <LabelField labelName={"Resource Pages"} />
              <CustomDropdown
                setInputText={(value) => onChangeResource(value)}
                // defaultValue={resource._id}
                options={resourceList}
                labelName="Select Resource Page"
                Isrequired={true}
                reset={resetResource}
              />
            </div>
            <InputField
              labelName={"Title"}
              placeholder={"Enter Title"}
              setInputText={(value) => setTitleHeading(value)}
              value={titleHeading}
              Isrequired={true}
            />
            <div className="d-flex mb-2">
              <LabelField labelName={"Description"} />
              <HtmlTextEditor
                onChange={(value) => setTitleDesc(value)}
                value={titleDesc}
                height={"10vh"}
              />
            </div>
            {resource?.name === "Online Coaching" ? null : (
              <div className="d-flex mb-2">
                <LabelField labelName={"Content"} />
                <HtmlTextEditor
                  onChange={(value) => setDetails(value)}
                  value={details}
                  height={"10vh"}
                />
              </div>
            )}
          </div>

          <div className="col-auto d-flex justify-content-center">
            <div className="col-auto d-flex justify-content-center mt-4">
              <button
                type="submit"
                className={`btn mb-3 submitbtn ${
                  validations() ? "btn-secondary" : "btn-primary"
                }`}
                disabled={validations()}
              >
                Update
              </button>
            </div>
          </div>
        </form>
      </div>
    </div>
  );
}

export default CreateResourceDetail;
