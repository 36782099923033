import React, { useState } from "react";
import "../../App.css";
import PageTitle from "../../Module/pageTitle";
import InputField from "../../Component/inputField";
import {
  containsNumber,
  containsStartsWithSpecialCharsOrStartsWithNumberOrWhiteSpace,
  length_Hundred,
  validateValueWithoutSpace,
} from "../../Utils/validators";
import { useDispatch } from "react-redux";
import { showErrorSnackbar } from "../../Action/snackbarAction";
import {
  containsStartsWithSpecialCharsOrStartsWithNumberOrWhiteSpaceErrMsg,
  validateIsNumberOnlyErrMsg,
} from "../../Utils/errorMsg";

import { addIssueType } from "../../Action/courseAction";

function AddIssueType() {
  const dispatch = useDispatch();
  const [name, setName] = useState("");
  const [price, setPrice] = useState("");
  const validations = () => {
    return name === "" || price === "";
  };

  const onChange = (value, key) => {
    if (key === "name") {
      if (value.length === length_Hundred) {
        dispatch(
          showErrorSnackbar(
            `Max Length ${length_Hundred - 1} reached for this field`
          )
        );
      } else if (
        containsStartsWithSpecialCharsOrStartsWithNumberOrWhiteSpace(value)
      ) {
        dispatch(
          showErrorSnackbar(
            containsStartsWithSpecialCharsOrStartsWithNumberOrWhiteSpaceErrMsg
          )
        );
        return;
      } else setName(value);
    } else if (key === "price") {
      if (value !== "") {
        if (!containsNumber(value)) {
          dispatch(showErrorSnackbar("Only Numbers are allowed!"));
          return;
        } else if (!validateValueWithoutSpace(value)) {
          dispatch(showErrorSnackbar(validateIsNumberOnlyErrMsg));
          return;
        } else setPrice(value);
      } else setPrice(value);
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    dispatch(
      addIssueType({ name, price: parseInt(price) }, () => {
        setName("");
        setPrice("");
      })
    );
  };

  return (
    <div className="contentBox">
      <PageTitle name={"Add Issue Type"} />
      <form className="formWidth" onSubmit={handleSubmit}>
        <div className="col-auto">
          <InputField
            labelName={"Issue"}
            placeholder={"Enter Issue"}
            setInputText={(value) => onChange(value, "name")}
            value={name}
            Isrequired={true}
          />
          <InputField
            labelName={"Penalty"}
            placeholder={"Enter Price"}
            setInputText={(value) => onChange(value, "price")}
            value={price}
            Isrequired={true}
          />
        </div>
        <div className="col-auto d-flex justify-content-center">
          <div className="col-auto d-flex justify-content-center mt-4">
            <button
              type="submit"
              className={`btn mb-3 submitbtn ${
                validations() ? "btn-secondary" : "btn-primary"
              }`}
              disabled={validations()}
            >
              {" "}
              Save
            </button>
          </div>
        </div>
      </form>
    </div>
  );
}

export default AddIssueType;
