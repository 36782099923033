import React from "react";
import { Document, Page, pdfjs } from "react-pdf";
import { useState } from "react";

pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;
function PdfViewer() {
  const link = sessionStorage.getItem("pdfLink");

  const [numPages, setNumPages] = useState(null);

  const onDocumentLoadSuccess = ({ numPages }) => {
    setNumPages(numPages);
  };

  return (
    <div className="pdfDiv mx-auto">
      <Document
        file={link}
        onLoadSuccess={onDocumentLoadSuccess}
        onContextMenu={(e) => e.preventDefault()}
        className="pdf-container"
      >
        {Array.apply(null, Array(numPages))
          .map((x, i) => i + 1)
          .map((page, j) => (
            <Page key={j} pageNumber={page} />
          ))}
      </Document>
    </div>
  );
}

export default PdfViewer;
