export const TeacherPanelSidebarData = [
  {
    title: "DASHBOARD",
    link: "dashboard",
  },
  {
    title: "UPCOMING LIVE SCHEDULE",
    link: "upcomingLiveSchedule",
  },
  {
    title: "MY DAILY PLAN",
    link: "teacherTaskPlan",
  },
  {
    title: "SOLVE STUDENTS DOUBTS",
    link: "CourseSupport",
  },
  {
    title: "PENALTY",
    link: "issue",
  },
  {
    title: "STUDENTS FEEDBACK",
    link: "feedback",
  },

  // {
  //   title: "PPT FORMAT",
  //   subNav: [
  //     {
  //       title: "FOR PEN DISPLAY",
  //       path: "pptForPenDisplay",
  //       cName: "sub-nav",
  //     },
  //     {
  //       title: "FOR SMART BOARD",
  //       path: "pptForSmartBoard",
  //       cName: "sub-nav",
  //     },
  //   ],
  // },
  {
    title: "SOP",
    link: "teacherSOP",
  },
  {
    title: "ASK FOR HELP",
    link: "helpdesk",
  },
  {
    title: "SYLLABUS",
    link: "syllabusDashboard",
  },
  {
    title: "QUESTION",
    link: "question",
  },
  {
    title: "UPLOAD LIVE VIDEO",
    link: "uploadLiveVideo",
  },
  {
    title: "UPLOAD BBB Meeting PDF",
    link: "uploadLivePDF",
  },
  {
    title: "UPLOAD RECORDED VIDEO",
    link: "uploadRecordedVideo",
  },
  {
    title: "UPLOAD TEST DISCUSSION VIDEO",
    link: "uploadTestDiscussionVideo",
  },
  {
    title: "YOUTUBE SESSION",
    subNav: [
      {
        title: "CREATE",
        path: "addTeacherYTData",
        cName: "sub-nav",
      },
      {
        title: "EDIT/DELETE",
        path: "editTeacherYTData",
        cName: "sub-nav",
      },
    ],
  },
  {
    title: "QUESTION POLL",
    subNav: [
      {
        title: "CREATE",
        path: "addQuestionPoll",
        cName: "sub-nav",
      },
      {
        title: "EDIT/DELETE",
        path: "editQuestionPoll",
        cName: "sub-nav",
      },
    ],
  },
  // {
  //   title: "LECTURE DELAYED",
  //   link: "lectureDelayed",
  // },
  {
    title: "QUESTION DOUBT",
    link: "questionDoubtList",
  },
  {
    title: "STUDENT ANALYSIS",
    link: "studentAnalysisDashboard",
  },
];
