import "../../App.css";
import React, { Suspense } from "react";
import Sidebar from "../../Module/sidebar";
import { Route, Routes } from "react-router-dom";
import Loading from "../../Component/Loading";
import { upSellingPanelSidebarData } from "../../Module/upSellingPanelSidebarData";

const UpcomingExpiry = React.lazy(() => import("./upcomingExpiry"));
const ExpiredAccount = React.lazy(() => import("./expiredAccount"));

function UpSellingDashboard() {
  return (
    <div className="AppBox">
      {/* <Header /> */}
      <Sidebar data={upSellingPanelSidebarData} />
      <div className="dashboard">
        {
          <Suspense
            fallback={
              <div className="border p-1">
                <Loading />
              </div>
            }
          >
            <Routes>
              <Route path="/" element={<UpcomingExpiry />} />
              <Route path="upcomingExpiry" element={<UpcomingExpiry />} />
              <Route path="expiredAccount" element={<ExpiredAccount />} />
            </Routes>
          </Suspense>
        }
      </div>
    </div>
  );
}

export default UpSellingDashboard;
