import React, { useState } from "react";
import "../../App.css";
import PageTitle from "../../Module/pageTitle";
import InputField from "../../Component/inputField";
import { useDispatch } from "react-redux";

import LabelField from "../../Component/labelField";
import {
  containsNumber,
  containsStartsWithSpecialCharsOrStartsWithNumberOrWhiteSpace,
  pdf_size_1MB,
  validateEmailAddress,
  validateFullName,
  validateIsFileImageType,
  validateNameWithoutSpace,
  validateValueWithoutSpace,
} from "../../Utils/validators";
import { showErrorSnackbar } from "../../Action/snackbarAction";
import {
  containsStartsWithSpecialCharsOrStartsWithNumberOrWhiteSpaceErrMsg,
  validateExamNameErrMsg,
  validateIsFileImageTypeErrMsg,
  validateIsNumberOnlyErrMsg,
} from "../../Utils/errorMsg";
import ImagePickerAndViewer from "../../Component/imagePickerAndViewer";
import { addAuthor, setUploadAuthorImage } from "../../Action/seoAction";

function AddAuthorDetails() {
  const dispatch = useDispatch();
  const [name, setName] = useState("");
  const [designation, setDesignation] = useState("");
  const [about, setAbout] = useState("");
  const [packageUpload, setPackageUpload] = useState("");
  const [selectedImage, setSelectedImage] = useState("");
  const [emailAddress, setEmailAddress] = useState("");
  const [contactNo, setContactNo] = useState("");
  const [linkedinURL, setLinkedinURL] = useState("");
  const [facebookURL, setFacebookURL] = useState("");
  const [instagramURL, setInstagramURL] = useState("");
  const [googleLink, setGoogleLink] = useState("");
  const [youtubeLink, setYoutubeLink] = useState("");
  const [location, setLocation] = useState("");
  const [authorUniqueName, setAuthorUniqueName] = useState("");
  const [authorUniqueLink, setAuthorUniqueLink] = useState("");
  const onChangeName = (value) => {
    if (!validateFullName(value)) {
      dispatch(showErrorSnackbar("Please enter valid name"));
      return;
    } else {
      setName(value);
    }
  };

  const resetForm = () => {
    setAbout("");
    setName("");
    setDesignation("");
    setAbout("");
    setPackageUpload("");
    setSelectedImage("");
    setEmailAddress("");
    setContactNo("");
    setLinkedinURL("");
    setFacebookURL("");
    setInstagramURL("");
    setGoogleLink("");
    setYoutubeLink("");
    setLocation("");
    setAuthorUniqueName("");
  };
  const handleSubmit = async (e) => {
    e.preventDefault();
    if (selectedImage) {
      dispatch(
        setUploadAuthorImage(
          { uniqueName: authorUniqueName, authorImage: selectedImage },
          (imageRes) => {
            const postData = {
              name: name,
              designation: designation,
              about: about,
              image: imageRes?.imageLogo,
              phoneNo: contactNo,
              email: emailAddress,
              linkedIn: linkedinURL,
              facebook: facebookURL,
              instagram: instagramURL,
              google: googleLink,
              youtube: youtubeLink,
              location: location,
              uniqueName: authorUniqueName,
            };
            for (let key in postData) {
              if (postData[key] === "") {
                delete postData[key];
              }
            }
            dispatch(
              addAuthor(postData, (res) => {
                setAuthorUniqueLink(res?.AuthorLink);
                resetForm();
              })
            );
          }
        )
      );
    }
  };

  const validations = () => {
    return (
      name === "" ||
      (emailAddress !== "" && !validateEmailAddress(emailAddress)) ||
      designation === "" ||
      about === "" ||
      packageUpload === "" ||
      authorUniqueName === ""
    );
  };
  const handleBannerChange = (e) => {
    e.preventDefault();
    const reader = new FileReader();
    const file = e.target.files[0];
    if (!validateIsFileImageType(e)) {
      dispatch(showErrorSnackbar(validateIsFileImageTypeErrMsg));
      return;
    } else if (file.size > pdf_size_1MB) {
      dispatch(showErrorSnackbar("File size should be less than 1 MB!!!"));
    } else {
      reader.onloadend = () => {
        setPackageUpload(reader.result);
        setSelectedImage(file);
      };
      reader.readAsDataURL(file);
    }
  };
  const onchangeMob = (value) => {
    if (value !== "") {
      if (!containsNumber(value)) {
        dispatch(showErrorSnackbar("Only Numbers are allowed!"));
        return;
      } else if (!validateValueWithoutSpace(value)) {
        dispatch(showErrorSnackbar(validateIsNumberOnlyErrMsg));
        return;
      } else setContactNo(value);
    } else {
      setContactNo(value);
    }
  };
  const onChangeUniqueName = (value) => {
    if (containsStartsWithSpecialCharsOrStartsWithNumberOrWhiteSpace(value)) {
      dispatch(
        showErrorSnackbar(
          containsStartsWithSpecialCharsOrStartsWithNumberOrWhiteSpaceErrMsg
        )
      );
      return;
    } else if (!validateNameWithoutSpace(value)) {
      dispatch(showErrorSnackbar(validateExamNameErrMsg));
      return;
    }
    setAuthorUniqueName(value);
  };
  return (
    <div className="contentBox">
      <PageTitle name={"ADD AUTHOR DETAILS"} />
      <div className="formDiv">
        <form className="formWidth" onSubmit={handleSubmit}>
          <div className="col-8">
            <div className="d-flex mb-2 align-items-center">
              <InputField
                labelName={"Name"}
                placeholder={"Enter Name"}
                setInputText={(value) => onChangeName(value)}
                value={name}
                Isrequired={true}
              />
            </div>
            <div className="d-flex mb-2 align-items-center">
              <InputField
                labelName={"Designation"}
                placeholder={"Enter Designation"}
                setInputText={(value) => setDesignation(value)}
                value={designation}
                Isrequired={true}
              />
            </div>

            <div className="d-flex mb-2 align-items-center w-100">
              <LabelField labelName={"About"} />
              <textarea
                className="form-control w-100"
                placeholder={"Enter About"}
                onChange={(value) => setAbout(value.target.value)}
                value={about}
              />
              <span className="required ms-2 fs-6">*</span>
            </div>
            <div className="d-flex mb-2 align-items-center">
              <ImagePickerAndViewer
                labelName={"Upload Image"}
                value={packageUpload}
                handleChange={(e) => handleBannerChange(e)}
                htmlFor={"photo-upload"}
              />
            </div>
            <div className="d-flex mb-2 align-items-center">
              <p>
                <b>Social Media Links</b>
              </p>
            </div>
            <div className="d-flex mb-2 align-items-center">
              <InputField
                labelName={"Email Address"}
                placeholder={"Enter Email Address"}
                setInputText={(value) => setEmailAddress(value)}
                value={emailAddress}
              />
            </div>
            <div className="d-flex mb-2 align-items-center">
              <InputField
                labelName={"Contact No"}
                placeholder={"Enter Contact No"}
                setInputText={(value) => onchangeMob(value)}
                value={contactNo}
                maxLength={10}
              />
            </div>
            <div className="d-flex mb-2 lign-items-center">
              <InputField
                labelName={"Linkedin URL"}
                placeholder={"Enter Linkedin URL"}
                setInputText={(value) => setLinkedinURL(value)}
                value={linkedinURL}
              />
            </div>
            <div className="d-flex mb-2 lign-items-center">
              <InputField
                labelName={"Facebook URL"}
                placeholder={"Enter Facebook URL"}
                setInputText={(value) => setFacebookURL(value)}
                value={facebookURL}
              />
            </div>
            <div className="d-flex mb-2 lign-items-center">
              <InputField
                labelName={"Instagram URL"}
                placeholder={"Enter Instagram URL"}
                setInputText={(value) => setInstagramURL(value)}
                value={instagramURL}
              />
            </div>
            <div className="d-flex mb-2 lign-items-center">
              <InputField
                labelName={"Google Link"}
                placeholder={"Enter Google Link"}
                setInputText={(value) => setGoogleLink(value)}
                value={googleLink}
              />
            </div>
            <div className="d-flex mb-2 lign-items-center">
              <InputField
                labelName={"Youtube Link"}
                placeholder={"Enter Youtube Link"}
                setInputText={(value) => setYoutubeLink(value)}
                value={youtubeLink}
              />
            </div>
            <div className="d-flex mb-2 lign-items-center">
              <InputField
                labelName={"Location"}
                placeholder={"Enter Location"}
                setInputText={(value) => setLocation(value)}
                value={location}
              />
            </div>
            <div className="d-flex mb-2 lign-items-center">
              <InputField
                labelName={"Author Unique Name"}
                placeholder={"Enter Author Unique Name"}
                setInputText={(value) => onChangeUniqueName(value)}
                value={authorUniqueName}
                Isrequired={true}
              />
            </div>
          </div>
          <div className="col-auto d-flex justify-content-center">
            <div className="col-auto d-flex justify-content-center mt-4">
              <button
                type="submit"
                className={`btn mb-3 submitbtn ${
                  validations() ? "btn-secondary" : "btn-primary"
                }`}
                disabled={validations()}
              >
                Submit
              </button>
            </div>
          </div>
          <div className="d-flex mb-2 lign-items-center">
            <InputField
              labelName={"Author Unique Link"}
              value={authorUniqueLink}
              Isrequired={true}
              disabled={true}
            />
          </div>
        </form>
      </div>
    </div>
  );
}

export default AddAuthorDetails;
