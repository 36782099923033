import React, { useState, useEffect } from "react";
const IndiaPostAPi = () => {
  const url = "http://data.cept.gov.in/customer/api/BulkCustomer/download";
  const [callAPI, setCallAPI] = useState();
  const currentDate = new Date();
  currentDate.setDate(currentDate.getDate() - 1);
  const yesterdayDate = currentDate;

  const day = yesterdayDate.getDate();
  const month = yesterdayDate.getMonth() + 1;
  const year = yesterdayDate.getFullYear();

  const getDay = day < 10 ? "0" + day : day;
  const getMonth = month < 10 ? "0" + month : month;

  const getYesterdayDate = `${getDay}${getMonth}${year}`;

  useEffect(() => {
    fetch(url, {
      method: "POST",
      body: JSON.stringify({
        Cust_Id: "3000053909",
        Event_Code: "LE",
        Event_Date: getYesterdayDate,
      }),
      headers: {
        "Content-type": "application/json",
      },
    })
      .then(function (response) {
        return response.json();
      })
      .then(function (data) {
        setCallAPI(data);
      })
      .catch((error) => console.error("Error:", error));
  }, []);
  return (
    <>
      <div className="" style={{ width: "100%", backgroundColor: "#7ab5f1" }}>
        <div
          style={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
          }}
        >
          {callAPI ? (
            <h1>India Post Data Fetched</h1>
          ) : (
            <h1>India Post Data Not Fetched</h1>
          )}
        </div>
      </div>
    </>
  );
};

export default IndiaPostAPi;
