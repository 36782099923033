import React, { Suspense, lazy } from "react";
import Sidebar from "../../Module/sidebar";
import Loading from "../../Component/Loading";
import { Route, Routes } from "react-router";
import { TeacherTaskSideBarData } from "../../Module/TeacherTask/teacherTaskSidebarData";
import TeacherPerformance from "../CXOPanel/teacherPerformance";

const AddTeacherTask = lazy(() => import("../CategoryPanel/addTeacherTask"));
const EditDeleteTeacherTask = lazy(() =>
  import("../CategoryPanel/editDeleteTeacherTask")
);
const YoutubeDuplicateUrl = lazy(() =>
  import("../VpOperationsPanel/youtubeDuplicateUrl")
);

const TeacherTaskDashboard = () => {
  return (
    <div className="AppBox">
      <Sidebar data={TeacherTaskSideBarData} />
      <div className="dashboard">
        {
          <Suspense
            fallback={
              <div className="border p-1">
                <Loading />
              </div>
            }
          >
            <Routes>
              <Route path="/" element={<AddTeacherTask />} />
              <Route path="addTeacherTask" element={<AddTeacherTask />} />
              <Route
                path="editDeleteTeacherTask"
                element={<EditDeleteTeacherTask />}
              />
              <Route
                path="youtubeDuplicateUrl"
                element={<YoutubeDuplicateUrl />}
              />
              <Route
                path="teacherPerformance"
                element={<TeacherPerformance />}
              />
            </Routes>
          </Suspense>
        }
      </div>
    </div>
  );
};

export default TeacherTaskDashboard;
