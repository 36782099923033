export const CounsellorSidebarData = [
  {
    title: "REGISTRATION DETAILS",
    link: "registrationdetails",
  },
  {
    title: "MULTI-DEVICE TOKEN CLEAR",
    link: "MultiDeviceTokenClear",
  },
];
