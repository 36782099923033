/* eslint-disable react/prop-types */
import React from "react";
import "../App.css";
import LabelField from "./labelField";

function SubmitButton(props) {
  const { name, validations, type, onClick } = props;
  const handleClick = (e) => {
    if (type === "button" && onClick) {
      e.preventDefault(); // Prevent default form submission if button type is "button"
      onClick();
    }
  };
  return (
    <div className="d-flex">
      <LabelField labelName={""} />
      <div className="col-auto d-flex justify-content-center mt-2">
        <button
          type={type ? type : "submit"}
          className={`btn mb-3 submitbtn ${
            validations ? "btn-secondary" : "btn-primary"
          }`}
          onClick={handleClick}
          disabled={validations}
        >
          {name}
        </button>
      </div>
    </div>
  );
}

export default SubmitButton;
