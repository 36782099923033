import React, { useState, useEffect, useRef } from "react";
import { useDispatch } from "react-redux";
import PageTitle from "../../Module/pageTitle";
import CustomDropdown from "../../Component/customDropdown";
import LabelField from "../../Component/labelField";
import InputField from "../../Component/inputField";
import ImagePickerAndViewer from "../../Component/imagePickerAndViewer";
import {
  addUpdateResultCompilation,
  getExamListNew,
  getParentCourseNew,
  getResultCompilation,
} from "../../Action/courseAction";
import DatePicker from "../../Component/datePicker";
import Pagination from "@mui/material/Pagination";
import usePagination from "../../Component/usePagination";
import PaginationTable from "../../Component/paginationTable";
import PaginationView from "../../Component/paginationView";
import { getTableDataSlots, monthData } from "../../Utils/dataConstants";
import { DownloadTableExcel } from "react-export-table-to-excel";
import Modal from "@material-ui/core/Modal";
import Backdrop from "@material-ui/core/Backdrop";
import Fade from "@material-ui/core/Fade";
import ClearOutlinedIcon from "@mui/icons-material/ClearOutlined";
import moment from "moment";
import { getSubExam } from "../../Action/adminPanelAction";
import YearPicker from "../../Component/yearPicker";
import SubmitButton from "../../Component/SubmitButton";
import { Link } from "react-router-dom";

export default function StudentResult() {
  const dispatch = useDispatch();
  const tableRef = useRef(null);
  const [exam, setExam] = useState("");
  const [subExam, setSubExam] = useState("");
  const [month, setMonth] = useState("");
  const [year, setYear] = useState("");
  const [resetMonth, setResetMonth] = useState(false);
  const [examStream, setExamStream] = useState("");
  const [examList, setExamList] = useState([]);
  const [subExamList, setSubExamList] = useState([]);
  const [examStreamList, setExamStreamList] = useState([]);
  const [startDate, setStartDate] = useState(
    moment.utc(moment.utc(new Date())).format("YYYY-MM-DD")
  );
  const [endDate, setEndDate] = useState(
    moment.utc(moment.utc(new Date())).format("YYYY-MM-DD")
  );
  const [resultCompilation, setResultCompilation] = useState([]);
  const [studentDetailData, setStudentDetailData] = useState("");
  const [open, setOpen] = useState(false);
  const [comment, setComment] = useState("");

  // pagination
  const [count, setCount] = useState(0);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [currentPage, setCurrentPage] = useState(1);
  const totalPages = Math.ceil(count / rowsPerPage);
  const _DATA = usePagination(count, rowsPerPage);

  const handleChange = (e, page) => {
    onPageChange(page, rowsPerPage, 1);
    setPage(page - 1);
    setCurrentPage(page);
    _DATA.jump(page);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
    setCurrentPage(newPage + 1);
  };

  const handleChangeRowsPerPage = (event) => {
    const value = event.target.value;
    if (value === "All") {
      setRowsPerPage(-1);
      setPage(0);
      setCurrentPage(1);
      return;
    }
    setRowsPerPage(parseInt(value, 10));
    setPage(0);
    setCurrentPage(1);
    onPageChange(page, value, 2);
  };

  const onPageChange = (page, rowsPerPage, flag) => {
    /************ page change****************** */
    let data = "";
    if (flag === 1) {
      data = {
        startDate,
        endDate,
        examTypeId: exam,
        baseCourseId: examStream,
        skip: (page - 1) * rowsPerPage,
        limit: rowsPerPage,
      };
    } else if (flag === 2) {
      data = {
        startDate,
        endDate,
        examTypeId: exam,
        baseCourseId: examStream,
        skip: 0,
        limit: rowsPerPage,
      };
    } else {
      data = {
        startDate,
        endDate,
        examTypeId: exam,
        baseCourseId: examStream,
        skip: 0,
        limit: count,
      };
    }
    if (
      data?.examTypeId === "" ||
      data?.examTypeId === undefined ||
      data?.examTypeId === null
    ) {
      delete data?.examTypeId;
    }
    if (
      data?.baseCourseId === "" ||
      data?.baseCourseId === undefined ||
      data?.examTbaseCourseIdypeId === null
    ) {
      delete data?.baseCourseId;
    }
    dispatch(
      getResultCompilation(data, (res) => {
        setCount(res?.count);
        setResultCompilation(res?.results);
      })
    );
  };

  useEffect(() => {
    dispatch(
      getExamListNew((res) => {
        setExamList(res);
      })
    );
  }, []);

  const handleClose = () => {
    setOpen(false);
  };

  const onChangeExam = (val) => {
    setExam(val?._id);
    setSubExam("");
    setSubExamList([]);
    setExamStreamList([]);
    setYear("");
    setResetMonth(true);
    dispatch(
      getSubExam({ examTypeId: val?._id }, (res) => {
        if (res?.data?.length > 0) {
          setSubExamList(res?.data);
        } else {
          dispatch(
            getParentCourseNew(`${val._id}`, (res) => {
              setExamStreamList(res);
            })
          );
        }
      })
    );
  };
  const onChangeSubExam = (val) => {
    setSubExam(val?._id);
    setExamStream("");
    setExamStreamList([]);
    setYear("");
    setResetMonth(true);
    dispatch(
      getParentCourseNew(`${val._id}`, (res) => {
        setExamStreamList(res);
      })
    );
  };
  const onChangeExamStream = (val) => {
    setExamStream(val?._id);
    setYear("");
    setResetMonth(true);
  };
  const onChangeMonth = (value) => {
    setMonth(value._id);
    setResetMonth(false);
  };

  const handleOptionScoreCardImageChange = (e) => {
    e.preventDefault();
  };

  const handleNavigate = (data) => {
    console.log("the data is", data?.comment);
    setStudentDetailData(data);
    setComment(data?.comment ? data?.comment : "");
    setOpen(true);
  };

  const handleAddDetail = (e) => {
    e.preventDefault();
    const reqBody = {
      comment: comment,
    };
    dispatch(
      addUpdateResultCompilation(studentDetailData?._id, reqBody, (resp) => {
        if (resp) {
          setOpen(false);
          setComment("");
          getAllResults();
        }
      })
    );
  };

  const getAllResults = () => {
    const postData = {
      startDate: startDate,
      endDate: endDate,
      examTypeId: exam,
      subExamTypeId: subExam,
      baseCourseId: examStream,
      skip: 0,
      limit: rowsPerPage,
      qualifyYear: year,
      qualifyMonth: month,
    };
    if (subExam === "") delete postData?.subExamTypeId;
    if (year === "") delete postData?.qualifyYear;
    if (month === "") delete postData?.qualifyMonth;
    if (
      postData?.examTypeId === "" ||
      postData?.examTypeId === undefined ||
      postData?.examTypeId === null
    ) {
      delete postData?.examTypeId;
    }
    if (
      postData?.baseCourseId === "" ||
      postData?.baseCourseId === undefined ||
      postData?.examTbaseCourseIdypeId === null
    ) {
      delete postData?.baseCourseId;
    }
    dispatch(
      getResultCompilation(postData, (res) => {
        setCount(res?.count);
        setResultCompilation(res?.results);
      })
    );
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    getAllResults();
  };
  const renderSearchMode = () => {
    return (
      <div className="p-2">
        <form onSubmit={handleSubmit}>
          <div className="d-flex">
            <div className="col-md-3 d-flex mb-3 justify-content-center align-items-center">
              <LabelField labelName={"Start Date"} />
              <DatePicker
                setInputText={(value) => {
                  setStartDate(value);
                }}
                value={startDate}
              />
            </div>
            <div className="col-md-3 d-flex mb-3 justify-content-center align-items-center mx-4">
              <LabelField labelName={"End Date"} />
              <DatePicker
                setInputText={(value) => setEndDate(value)}
                value={endDate}
              />
            </div>
            <div className="col-md-3 d-flex mb-3 justify-content-center align-items-center mx-4">
              <LabelField labelName={"Exam Name"} />
              <CustomDropdown
                setInputText={(value) => onChangeExam(value)}
                value={exam}
                options={examList}
                labelName="Select Exam Name"
              />
            </div>
          </div>
          <div className="d-flex">
            {subExamList?.length > 0 && (
              <div className="d-flex mb-3">
                <div className="col-md-3 mt-2 me-5">
                  <LabelField labelName={"Sub Exam Name"} />
                </div>
                <div className="col-md-5  ">
                  <CustomDropdown
                    setInputText={(value) => onChangeSubExam(value)}
                    value={subExam}
                    options={subExamList}
                    labelName="Select Sub Exam Name"
                  />
                </div>
              </div>
            )}
            <div className="d-flex mb-3">
              <div className="col-md-3 mt-2 me-5">
                <LabelField labelName={"Exam Stream"} />
              </div>
              <div className="col-md-5">
                <CustomDropdown
                  labelName="Select Exam Stream"
                  setInputText={(value) => onChangeExamStream(value)}
                  value={examStream}
                  options={examStreamList}
                />
              </div>
            </div>
            <div className="d-flex mb-2 align-items-center col-md-3">
              <LabelField
                labelName={"Select Year"}
                style={{ width: "100px" }}
              />
              <YearPicker
                handleYearChange={(year) => setYear(year)}
                isRequired
              />
            </div>
            <div className="d-flex mb-2 align-items-center col-md-3">
              <LabelField labelName={"Month"} style={{ width: "100px" }} />
              <CustomDropdown
                labelName="Select Month"
                setInputText={(value) => onChangeMonth(value)}
                value={month}
                options={monthData}
                reset={resetMonth}
              />
            </div>
          </div>
          <div className="d-flex col-12">
            <div className="mb-3" style={{ marginLeft: 30 }}>
              <button type="submit" className="btn btn-primary  searchButton">
                Search
              </button>
            </div>
          </div>
        </form>
      </div>
    );
  };

  const renderListMode = () => {
    return (
      <>
        <div className="d-flex justify-content-end mt-4">
          <div className="mt-4">
            {resultCompilation?.length !== 0 &&
            resultCompilation?.data?.length !== 0 ? (
              <div className="d-flex mb-2 align-items-center">
                <DownloadTableExcel
                  filename="student_count"
                  sheet="Lead Count"
                  currentTableRef={tableRef.current}
                >
                  <button className="btn btn-outline-primary mb-3 submitbtn py-1">
                    Export Details
                  </button>
                </DownloadTableExcel>
              </div>
            ) : null}
          </div>

          <div className="d-flex paginationdiv justify-content-end align-items-center ms-5">
            <Pagination
              count={totalPages}
              size="large"
              page={currentPage}
              variant="outlined"
              shape="rounded"
              color={"primary"}
              onChange={handleChange}
            />
          </div>
        </div>

        <PaginationView
          pagename={"showResult"}
          count={count}
          tableData={resultCompilation ? resultCompilation : []}
          page={page}
          rowsPerPage={rowsPerPage}
          handleChangePage={handleChangePage}
          handleChangeRowsPerPage={handleChangeRowsPerPage}
          rowsPerPageOptions={getTableDataSlots(count)}
        />
        <div>
          <PaginationTable
            tableData={resultCompilation ? resultCompilation : []}
            pagename={"showResult"}
            rowsPerPage={rowsPerPage}
            page={page}
            tableHead={[
              "S.N",
              "Student Name",
              "Exam Name",
              "Exam Stream",
              "Qualification",
              "Qualified Year",
              "Added date",
              "Details",
            ]}
            handleNavigate={(value) => handleNavigate(value)}
          />
        </div>

        <div ref={tableRef} className="d-none">
          <PaginationTable
            tableData={resultCompilation ? resultCompilation : []}
            pagename={"showResultExport"}
            rowsPerPage={rowsPerPage}
            page={page}
            tableHead={[
              "S.N",
              "Student Name",
              "Exam Name",
              "Exam Stream",
              "Qualification",
              "Qualified Year",
              "Added date",
              "Rank",
              "Roll Number",
            ]}
          />
        </div>
      </>
    );
  };
  console.log(studentDetailData);
  return (
    <div className="contentBox ">
      <PageTitle name={"STUDENT RESULT"} />
      <div className="formDiv">
        <div className="col-auto">
          <div className=" w-100">
            <div className="px-3 mt-3">
              <div></div>
            </div>
            {renderSearchMode()}
            {renderListMode()}
          </div>
        </div>
      </div>

      {open ? (
        <Modal
          aria-labelledby="transition-modal-title"
          aria-describedby="transition-modal-description"
          className="model"
          open={open}
          onClose={handleClose}
          closeAfterTransition
          BackdropComponent={Backdrop}
          BackdropProps={{
            timeout: 500,
          }}
        >
          <Fade in={open}>
            <div className="bookstockmodeldiv p-3">
              <div className="col mb-4 d-flex justify-content-between ">
                <p className="fw-bold">
                  <span style={{ color: "#3F51B5" }}> Student Details</span>
                </p>
                <ClearOutlinedIcon
                  className="colorPurple cursor"
                  onClick={handleClose}
                />
              </div>
              <div className="form-check">
                <form onSubmit={handleAddDetail}>
                  <div className=" col-md-12 mb-3">
                    <InputField
                      labelName={"Student Name"}
                      placeholder="Enter First Name"
                      value={`${studentDetailData?.firstName} ${studentDetailData?.lastName}`}
                      disabled={true}
                    />
                  </div>
                  <div className=" col-md-12 mb-3">
                    <InputField
                      labelName={"Exam Name"}
                      placeholder="Enter Exam Name"
                      value={studentDetailData?.examTypes?.name}
                      disabled={true}
                    />
                  </div>
                  <div className=" col-md-12 mb-3">
                    <InputField
                      labelName={"Exam Stream Name"}
                      placeholder="Enter ExamStream Name"
                      value={studentDetailData?.basecourses?.name}
                      disabled={true}
                    />
                  </div>
                  <div className="col-md-8 d-flex mb-3">
                    <div className="col-md-2 mt-2">
                      <LabelField labelName={"Image"} />
                    </div>
                    <div className="col-md-9">
                      <ImagePickerAndViewer
                        lableName={"Upload Photo"}
                        value={studentDetailData?.imageLink}
                        disabled={true}
                        handleChange={(e) =>
                          handleOptionScoreCardImageChange(e)
                        }
                      />
                    </div>
                  </div>
                  <div className=" col-md-12 mb-3">
                    <InputField
                      labelName={"Qualification"}
                      placeholder="Enter Qualification"
                      value={studentDetailData?.qualifyFor}
                      disabled={true}
                    />
                  </div>
                  <div className=" col-md-12 mb-3">
                    <InputField
                      labelName={"Qualified Year"}
                      placeholder="Enter Qualified Year"
                      value={studentDetailData?.qualifyYear}
                      disabled={true}
                    />
                  </div>

                  <div className=" col-md-12 mb-3">
                    <InputField
                      labelName={"Mobile Number"}
                      value={studentDetailData?.mobileNo}
                      disabled={true}
                    />
                  </div>
                  <div className=" col-md-12 mb-3">
                    <InputField
                      labelName={"Rank"}
                      value={studentDetailData?.rank}
                      disabled={true}
                    />
                  </div>
                  <div className=" col-md-12 mb-3">
                    <InputField
                      labelName={"Roll Number"}
                      value={studentDetailData?.rollNumber}
                      disabled={true}
                    />
                  </div>
                  <div className=" col-md-12 mb-3">
                    <InputField
                      labelName={"Added At"}
                      value={moment(studentDetailData?.createdAt)
                        .utc()
                        .format("DD-MM-YYYY")}
                      disabled={true}
                    />
                  </div>
                  <div className=" col-md-12 mb-3 d-flex">
                    <LabelField labelName={"View PDF"} />
                    <Link to={studentDetailData?.scoreCard} target="_blank">
                      <p>View PDF</p>
                    </Link>
                  </div>
                  <div className=" col-md-12 mb-3">
                    <div className="d-flex align-items-center">
                      <LabelField labelName={"Comment"} />
                      <textarea
                        className="form-control"
                        placeholder={"Enter Comment"}
                        onChange={(e) => setComment(e.target.value)}
                        value={comment}
                        defaultValue={comment}
                      ></textarea>
                    </div>
                  </div>
                  <SubmitButton name="Submit" />
                </form>
              </div>
            </div>
          </Fade>
        </Modal>
      ) : null}
    </div>
  );
}
