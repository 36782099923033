import React, { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import PageTitle from "../../Module/pageTitle";
import CustomDropdown from "../../Component/customDropdown";
import {
  ResultOtherDetails,
  categoryListData,
} from "../../Utils/dataConstants";
import LabelField from "../../Component/labelField";
import InputField from "../../Component/inputField";
import ImagePickerAndViewer from "../../Component/imagePickerAndViewer";
import { getExamListNew, getParentCourseNew } from "../../Action/courseAction";
import { showErrorSnackbar } from "../../Action/snackbarAction";
import { pdf_size_1MB, validateIsFileImageType } from "../../Utils/validators";
import { validateIsFileImageTypeErrMsg } from "../../Utils/errorMsg";

export default function ResultCompilation() {
  const dispatch = useDispatch();

  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [mob, setMob] = useState("");
  const [exam, setExam] = useState("");
  const [examStream, setExamStream] = useState("");
  const [examList, setExamList] = useState([]);
  const [examStreamList, setExamStreamList] = useState([]);
  const [details, setDetails] = useState("");
  const [rank, setRank] = useState("");
  const [photo, setPhoto] = useState("");
  const [category, setCategory] = useState("");

  useEffect(() => {
    dispatch(
      getExamListNew((res) => {
        setExamList(res);
      })
    );
  }, []);

  const onChangeExam = (val) => {
    setExam(val);

    dispatch(
      getParentCourseNew(`${val._id}`, (res) => {
        setExamStreamList(res);
      })
    );
  };
  const onChangeExamStream = (val) => {
    setExamStream(val);
  };
  const onChangeDetails = (val) => {
    setDetails(val);
  };

  const handleOptionImageChange = (e) => {
    e.preventDefault();
    if (!validateIsFileImageType(e)) {
      dispatch(showErrorSnackbar(validateIsFileImageTypeErrMsg));
      return;
    }
    if (e.target.files[0].size > pdf_size_1MB) {
      dispatch(showErrorSnackbar("File size should be less than 1 MB!!!"));
    } else {
      const reader = new FileReader();
      const file = e.target.files[0];
      reader.onloadend = () => {
        setPhoto(reader.result);
      };
      reader.readAsDataURL(file);
    }
  };

  const onChangesetCategory = (val) => {
    setCategory(val);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
  };

  const renderSearchMode = () => {
    return (
      <div className=" ms-5 p-2 formDiv">
        <form onSubmit={handleSubmit}>
          <div className="col-md-4 d-flex mb-3">
            <div className="col-md-3 mt-2">
              <LabelField labelName={"First Name"} />
            </div>
            <div className="col-md-9 ms-2 pe-2">
              <InputField
                placeholder="Enter First Name"
                value={firstName}
                onChangeInput={(value) => setFirstName(value)}
              />
            </div>
          </div>
          <div className="col-md-4 d-flex mb-3">
            <div className="col-md-3 mt-2">
              <LabelField labelName={"Last Name"} />
            </div>
            <div className="col-md-9 ms-2 pe-2">
              <InputField
                placeholder="Enter Last Name"
                value={lastName}
                onChangeInput={(value) => setLastName(value)}
              />
            </div>
          </div>
          <div className="col-md-4 d-flex mb-3">
            <div className="col-md-3 mt-2">
              <LabelField labelName={"Mobile Number"} />
            </div>
            <div className="col-md-9 ms-2 pe-2">
              <InputField
                placeholder="Enter Mobile Number"
                value={mob}
                onChangeInput={(value) => setMob(value)}
              />
            </div>
          </div>
          <div className="col-md-4 d-flex mb-3">
            <div className="col-md-6 mt-2 me-5">
              <LabelField labelName={"Exam Name"} />
            </div>
            <div className="col-md-5 ms-2 ">
              <CustomDropdown
                setInputText={(value) => onChangeExam(value)}
                value={exam}
                options={examList}
                labelName="Select Exam Name"
              />
            </div>
          </div>
          <div className="col-md-4 d-flex mb-3">
            <div className="col-md-6 mt-2 me-5">
              <LabelField labelName={"Exam Stream"} />
            </div>
            <div className="col-md-5 ms-2">
              <CustomDropdown
                labelName="Select Exam Stream"
                setInputText={(value) => onChangeExamStream(value)}
                value={examStream}
                options={examStreamList}
              />
            </div>
          </div>
          <div className="col-md-4 d-flex mb-3">
            <div className="col-md-6 mt-2 me-5">
              <LabelField labelName={"Other"} />
            </div>
            <div className="col-md-5 ms-2">
              <CustomDropdown
                labelName="Select Details"
                setInputText={(value) => onChangeDetails(value)}
                value={details}
                options={ResultOtherDetails}
              />
            </div>
          </div>
          <div className="col-md-4 d-flex mb-3">
            <div className="col-md-3 mt-2">
              <LabelField labelName={"Rank"} />
            </div>
            <div className="col-md-9 ms-2 pe-2">
              <InputField
                placeholder="Enter Rank"
                value={rank}
                onChangeInput={(value) => setRank(value)}
              />
            </div>
          </div>
          <div className="col-md-5 d-flex mb-3">
            <div className="col-md-3 mt-2">
              <LabelField labelName={"Photo"} />
            </div>
            <div className="col-md-9">
              <ImagePickerAndViewer
                lableName={"Upload Photo"}
                value={photo}
                handleChange={(e) => handleOptionImageChange(e)}
              />
            </div>
          </div>
          <div className="col-md-4 d-flex mb-3">
            <div className="col-md-6 mt-2 me-5">
              <LabelField labelName={"Category"} />
            </div>
            <div className="col-md-5 ms-2">
              <CustomDropdown
                labelName="Select Category"
                setInputText={(value) => onChangesetCategory(value)}
                value={category}
                options={categoryListData}
              />
            </div>
          </div>
          <div className=" d-flex mb-3 ms-5 ps-5">
            <button
              type="submit"
              className="btn btn-outline-primary px-5 searchButton"
            >
              Submit
            </button>
          </div>
        </form>
      </div>
    );
  };

  return (
    <div className="contentBox ">
      <PageTitle name={"RESULT COMPILATION"} />
      <div className="  p-3 formDiv">
        <div className="">
          <div className="row d-flex w-100">
            <div className="px-3 col-12 mt-3">
              <div></div>
            </div>
            {renderSearchMode()}
          </div>
        </div>
      </div>
    </div>
  );
}
