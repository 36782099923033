export const ResultPanelSidebarData = [
  {
    title: "ADD RESULT",
    link: "resultCompilation",
  },
  {
    title: "STUDENT RESULT",
    link: "studentResult",
  },
];
