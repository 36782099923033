export const SMTSideBarData = [
  {
    title: "YOUTUBE LIVE",
    subNav: [
      {
        title: "ADD VIDEO",
        path: "addyoutubelive",
        cName: "sub-nav",
      },
      {
        title: "EDIT / DELETE",
        path: "editdeleteyoutubelive",
        cName: "sub-nav",
      },
    ],
  },
  {
    title: "YOUTUBE RECORDED",
    subNav: [
      {
        title: "ADD VIDEO",
        path: "addYoutubeRecordedVideo",
        cName: "sub-nav",
      },
      {
        title: "EDIT / DELETE",
        path: "editDeleteYoutubeRecordedVideo",
        cName: "sub-nav",
      },
    ],
  },
  {
    title: "PRIME CLASS",
    subNav: [
      {
        title: "ADD VIDEO",
        path: "addFreeVideo",
        cName: "sub-nav",
      },
      {
        title: "EDIT / DELETE",
        path: "editFreeVideo",
        cName: "sub-nav",
      },
    ],
  },
  {
    title: "YOUTUBE SESSION",
    link: "youtubeSession",
  },
  {
    title: "EXAM SHORTS VIDEO",
    subNav: [
      {
        title: "ADD",
        path: "addExamSrcVideo",
        cName: "sub-nav",
      },
      {
        title: "EDIT / DELETE",
        path: "editExamSrcVideo",
        cName: "sub-nav",
      },
    ],
  },
];
