import React, { useState, useEffect, useRef } from "react";
import { useDispatch } from "react-redux";
import "../../App.css";
import PageTitle from "../../Module/pageTitle";
import LabelField from "../../Component/labelField";
import CustomDropdown from "../../Component/customDropdown";
import {
  getExamSet,
  getScholarshipExamResult,
  getExamListNew,
  getParentCourseNew,
} from "../../Action/courseAction";
import { DownloadTableExcel } from "react-export-table-to-excel";
import DatePicker from "../../Component/datePicker";
import { showErrorSnackbar } from "../../Action/snackbarAction";
import { Pagination } from "@mui/material";
import usePagination from "../../Component/usePagination";
import PaginationView from "../../Component/paginationView";
import { getTableDataSlots } from "../../Utils/dataConstants";
import PaginationTable from "../../Component/paginationTable";
import { getSubExam } from "../../Action/adminPanelAction";
function ScholarshipExamResult() {
  const dispatch = useDispatch();
  const tableRef = useRef(null);
  //pagination
  const [page, setPage] = useState(0);
  const [count, setCount] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [currentPage, setCurrentPage] = useState(1);
  const totalPages = Math.ceil(count / rowsPerPage);
  const _DATA = usePagination(count, rowsPerPage);
  const [selectedStatus, setSelectedStatus] = useState("");
  const [parentCourseVal, setparentCourseVal] = useState("");
  const [examName, setExamName] = useState("");
  const [subExamName, setSubExamName] = useState(0);
  const [batchName, setBatchName] = useState("");
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [examList, setExamList] = useState([]);
  const [subExamList, setSubExamList] = useState([]);
  const [examSetList, setExamSetList] = useState([]);
  const [parentCourseList, setParentCourseList] = useState([]);

  const showdetails = false;
  const [enrolluser, setenrolluser] = useState();

  const [UserExamResultDetails, setUserExamResultDetails] = useState([]);
  const [no, setno] = useState("");

  const changeEndDate = (value) => {
    setEndDate(value);

    if (value < startDate) {
      dispatch(showErrorSnackbar("End date must be greater than start date"));
    }
  };

  useEffect(() => {
    setSelectedStatus(2);
    dispatch(
      getExamListNew((res) => {
        setExamList(res);
      })
    );
  }, []);

  const changeParentCourseVal = (value) => {
    setparentCourseVal(value);
    dispatch(
      getExamSet(
        {
          baseCourseId: value?._id,
          examTypeId: examName?._id,
          subExamTypeId: subExamName?._id,
          examType: 5,
        },
        (res) => {
          setExamSetList(res?.data?.exams);
        },
        () => {}
      )
    );
  };

  const onListChange = (value) => {
    setExamName(value);
    dispatch(
      getSubExam(
        {
          examTypeId: value._id,
        },
        (res) => {
          if (res?.data.length > 0) {
            setSubExamList(res.data);
          } else {
            dispatch(
              getParentCourseNew(`${value._id}`, (res) => {
                setParentCourseList(res);
              })
            );
          }
        }
      )
    );
  };

  const onSubExamChange = (value) => {
    setSubExamName(value);
    dispatch(
      getParentCourseNew(`${examName._id}/${value._id}`, (res) => {
        setParentCourseList(res);
      })
    );
  };
  const handleSubmit = async (e) => {
    e.preventDefault();
    const postData = {
      baseCourseId: parentCourseVal?._id,
      examTypeId: examName?._id,
      examId: batchName?._id,
      skip: 0,
      limit: 10,
    };
    if (subExamName) {
      postData.subExamTypeId = subExamName?._id;
    }
    if (startDate && endDate) {
      postData.startDate = startDate;
      postData.endDate = endDate;
    }
    dispatch(
      getScholarshipExamResult(postData, (res) => {
        setCount(res?.data?.count);
        setenrolluser(res?.data?._response);
      })
    );
  };

  const showData = (val) => {
    setno(val);
    let details = [];
    details = []?.filter((exam) => exam?.examType === val);

    setUserExamResultDetails(details);
  };

  const handleChange = (e, page) => {
    onPageChange(page, rowsPerPage, 1);
    setPage(page - 1);
    setCurrentPage(page);
    _DATA.jump(page);
  };
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
    setCurrentPage(newPage + 1);
  };

  const handleChangeRowsPerPage = (event) => {
    const value = event.target.value;
    if (value === "ALL") {
      setRowsPerPage(-1);
      setPage(0);
      setCurrentPage(1);
      return;
    }
    setRowsPerPage(parseInt(value, 10));
    setPage(0);
    setCurrentPage(1);
    onPageChange(page, value, 2);
  };

  const onPageChange = (page, rowsPerPage, flag) => {
    let data = "";
    if (flag === 1) {
      data = {
        skip: (page - 1) * rowsPerPage,
        limit: rowsPerPage,
      };
      if (examName) data.examTypeId = examName?._id;
      if (parentCourseVal) data.baseCourseId = parentCourseVal?._id;
      if (batchName) data.examId = batchName?._id;
      if (subExamName) data.subExamTypeId = subExamName?._id;
      if (startDate) data.startDate = startDate;
      if (endDate) data.endDate = endDate;
    } else if (flag === 2) {
      data = {
        skip: 0,
        limit: rowsPerPage,
      };
      if (examName) data.examTypeId = examName?._id;
      if (parentCourseVal) data.baseCourseId = parentCourseVal?._id;
      if (batchName) data.examId = batchName?._id;
      if (subExamName) data.subExamTypeId = subExamName?._id;
      if (startDate) data.startDate = startDate;
      if (endDate) data.endDate = endDate;
    } else {
      data = {
        skip: 0,
        limit: count,
      };
      if (examName) data.examTypeId = examName?._id;
      if (parentCourseVal) data.baseCourseId = parentCourseVal?._id;
      if (batchName) data.examId = batchName?._id;
      if (subExamName) data.subExamTypeId = subExamName?._id;
      if (startDate) data.startDate = startDate;
      if (endDate) data.endDate = endDate;
    }
    dispatch(
      getScholarshipExamResult(data, (resp) => {
        setCount(resp?.data?.count ? resp?.data?.count : 0);
        setenrolluser(resp?.data?._response);
      })
    );
  };

  const renderListMode = () => {
    return (
      <>
        <div className="d-flex paginationdiv justify-content-end align-items-center">
          <Pagination
            count={totalPages}
            size="large"
            page={currentPage}
            variant="outlined"
            shape="rounded"
            color={"primary"}
            onChange={handleChange}
          />
        </div>
        <PaginationView
          count={count}
          tableData={enrolluser ? enrolluser : []}
          // onTabChange={(e, index) => showData(e, index)}
          selectedIndex={selectedStatus === 2 ? 1 : 2}
          page={page}
          rowsPerPage={rowsPerPage}
          handleChangePage={handleChangePage}
          handleChangeRowsPerPage={handleChangeRowsPerPage}
          rowsPerPageOptions={getTableDataSlots(count)}
        />
        <div ref={tableRef}>
          <PaginationTable
            tableData={enrolluser ? enrolluser : []}
            pagename={"scholarshipExamResult"}
            rowsPerPage={rowsPerPage}
            page={page}
            tableHead={[
              "S.NO",
              "FIRST NAME",
              "LAST NAME",
              "ACCOUNT CREATED DATE",
              "TEST ATTEMPTED DATE",
              "MARKS OBTAIN",
            ]}
          />
        </div>
      </>
    );
  };

  const validations = () => {
    return parentCourseVal == "" || examName === "" || batchName === "";
  };
  return (
    <div className="contentBox">
      <PageTitle name={"Exam Result"} />
      <div className="row m-0">
        <div className="col formDiv ">
          {!showdetails ? (
            <div>
              <form className="formWidth w-100">
                <div className="row">
                  <div className="col-5 d-flex mb-2 align-items-center">
                    <LabelField labelName={"Exam Name"} />
                    <CustomDropdown
                      setInputText={(value) => onListChange(value)}
                      value={examName}
                      options={examList}
                      labelName="Select Exam Name"
                    />
                  </div>
                  {subExamList?.length > 0 ? (
                    <div className="col-5 d-flex mb-2 align-items-center">
                      <LabelField labelName={"Sub Exam Name"} />
                      <CustomDropdown
                        setInputText={(value) => onSubExamChange(value)}
                        value={subExamName}
                        options={subExamList}
                        labelName="Select Sub Exam Name"
                      />
                    </div>
                  ) : null}
                  <div className="col-5 d-flex mb-2 align-items-center">
                    <LabelField labelName={"Exam Stream"} />
                    <CustomDropdown
                      setInputText={(value) => changeParentCourseVal(value)}
                      value={parentCourseVal}
                      options={parentCourseList}
                      labelName="Select Exam Stream"
                    />
                  </div>
                  <div className="col-5 d-flex mb-2 align-items-center">
                    <LabelField labelName={"Exam Set"} />
                    <CustomDropdown
                      labelName="Select Exam Set"
                      setInputText={(value) => setBatchName(value)}
                      value={batchName}
                      options={examSetList}
                      reset={false}
                    />
                  </div>

                  <div className="col-5 d-flex mb-2 align-items-center">
                    <LabelField labelName={"Start Date"} />
                    <DatePicker
                      setInputText={(value) => {
                        setStartDate(value);
                      }}
                      value={startDate}
                    />
                  </div>
                  <div className="col-5 d-flex mb-2 align-items-center">
                    <LabelField labelName={"End Date"} />
                    <DatePicker
                      setInputText={(value) => changeEndDate(value)}
                      value={endDate}
                    />
                  </div>

                  <div className="col-2 d-flex justify-content-center">
                    <div className="d-flex justify-content-center">
                      <button
                        type="submit"
                        className={`btn mb-3 submitbtn ${
                          validations() ? "btn-secondary" : "btn-primary"
                        }`}
                        disabled={validations()}
                        onClick={handleSubmit}
                      >
                        Submit
                      </button>
                    </div>
                  </div>
                </div>
              </form>
              {enrolluser?.length > 0 ? (
                <div>
                  <DownloadTableExcel
                    filename="Result table"
                    sheet="Result"
                    currentTableRef={tableRef.current}
                  >
                    <button className="btn btn-primary mb-3 submitbtn py-1 px-3">
                      Export Report
                    </button>
                  </DownloadTableExcel>
                </div>
              ) : null}
              {renderListMode()}
              {/* <div className="m-2">
                <table className="w-100" ref={tableRef}>
                  <thead className="font-12">
                    <tr>
                      <th>
                        <label>S.N</label>
                      </th>
                      <th>
                        <label>FIRST NAME</label>
                      </th>
                      <th>
                        <label>LAST NAME</label>
                      </th>
                      <th>
                        <label>MOBILE NUMBER</label>
                      </th>
                      <th>
                        <label>CREATED</label>
                      </th>
                      <th>
                        <label>MARKS OBTAIN</label>
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {enrolluser?.map((item, index) => {
                      return (
                        <tr
                          className="cursor"
                          key={index}
                          // onClick={() => getUserDetails(item.userData._id)}
                        >
                          <td>{index + 1}</td>
                          <td>{item?.firstName}</td>
                          <td>{item?.lastName}</td>
                          <td>{item?.mobileNo}</td>
                          <td>
                            {moment.utc(item?.createdAt).format("DD-MM-YYYY")}
                          </td>
                          <td>{item?.markObtain}</td>
                        </tr>
                      );
                    })}
                  </tbody>
                </table>
              </div> */}
            </div>
          ) : (
            <div>
              <div className="shadow-sm p-3 mt-2">
                <div className="p-2 userDetailHeading mb-2">
                  Personnel & Contact
                </div>
                <div className="row px-4">
                  <div className="col-6 mb-1">
                    <span className="headlabel">First Name</span>{" "}
                    {UserExamResultDetails[0]?.userData?.firstName}
                  </div>
                  <div className="col-6 mb-1">
                    <span className="headlabel">Last Name</span>{" "}
                    {UserExamResultDetails[0]?.userData?.lastName}
                  </div>
                  <div className="col-6 mb-1">
                    <span className="headlabel">Contact</span>{" "}
                    {UserExamResultDetails[0]?.userData?.mobileNo}
                  </div>

                  <div className="col-6 mb-1">
                    <span className="headlabel">Session</span>
                  </div>
                  <div className="col-6 mb-1">
                    <span className="headlabel">Email Address</span>{" "}
                    {UserExamResultDetails[0]?.userData?.mobileNo}
                  </div>
                  <div className="col-6 mb-1">
                    <span className="headlabel">Expiry Date</span>
                  </div>

                  <div className="col-6 mb-1">
                    <span className="headlabel">State</span>
                  </div>
                  <div className="col-6 mb-1">
                    <span className="headlabel">City</span>
                  </div>
                </div>
              </div>
              <div className="my-2 px-3">
                <h5 className=" fw-bold d-inline-block mb-0">
                  <span className="colorPurple">Batch Name:</span> CSIR NET Jan
                  2023
                </h5>
              </div>
              <div
                className="d-flex justify-content-between p-2"
                style={{ backgroundColor: "#E8EAF5" }}
              >
                <div>
                  <button
                    type="button"
                    className={`btn rounded-pill me-1 font-12 ${
                      no === "3" ? "btn-success" : "btn-secondary"
                    }`}
                    onClick={() => showData("3")}
                  >
                    TOPIC TEST
                  </button>
                  <button
                    type="button"
                    className={`btn rounded-pill me-1 font-12 ${
                      no === "1" ? "btn-success" : "btn-secondary"
                    }`}
                    onClick={() => showData("1")}
                  >
                    Unit Test
                  </button>
                  <button
                    type="button"
                    className={`btn rounded-pill me-1 font-12 ${
                      no === "2" ? "btn-success" : "btn-secondary"
                    }`}
                    onClick={() => showData("2")}
                  >
                    Mock test
                  </button>
                  <button
                    type="button"
                    className={`btn rounded-pill me-1 font-12 ${
                      no === "4" ? "btn-success" : "btn-secondary"
                    }`}
                    onClick={() => showData("4")}
                  >
                    PYQ
                  </button>
                </div>
              </div>
              <div className="">
                <table className="w-100">
                  <thead className="">
                    <tr>
                      <th>
                        <label>S.N</label>
                      </th>
                      <th>
                        <label>Exam Set Name</label>
                      </th>
                      <th>
                        <label>Exam Duration</label>
                      </th>
                      <th>
                        <label>Status</label>
                      </th>
                      <th>
                        <label>Total Question</label>
                      </th>
                      <th>
                        <label>Correct Question</label>
                      </th>
                      <th>
                        <label>Wrong Question</label>
                      </th>
                      <th>
                        <label>Skipped Question</label>
                      </th>
                      <th>
                        <label>Gross Marks</label>
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {UserExamResultDetails?.map((item, index) => {
                      return (
                        <tr key={index}>
                          <td>{index + 1}</td>
                          <td>{item?.examData[0]?.en?.name}</td>
                          <td>{item?.examData[0]?.duration}</td>
                          <td>
                            {item?.examData[0]?.status === 1
                              ? "Submitted"
                              : "Not Submitted"}
                          </td>
                          <td>{item?.examData[0]?.totalAddedQuestion}</td>
                          <td>{item?.examStats?.correct}</td>
                          <td>{item?.examStats?.incorrect}</td>
                          <td>{item?.examStats?.skipped}</td>
                          <td>{item?.examStats?.marksObtained}</td>
                        </tr>
                      );
                    })}
                  </tbody>
                </table>
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
}

export default ScholarshipExamResult;
