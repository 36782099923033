import React, { useEffect, useState } from "react";
import "../../App.css";
import PageTitle from "../../Module/pageTitle";
import InputField from "../../Component/inputField";
import { useDispatch } from "react-redux";
import {
  getParentCourseNew,
  getExamListNew,
  getPackageListWithFilter,
  getSectionList,
} from "../../Action/courseAction";

import CustomDropdown from "../../Component/customDropdown";
import LabelField from "../../Component/labelField";
import { addProductSchema } from "../../Action/seoAction";
import { getMainCategory, getSubExam } from "../../Action/adminPanelAction";

function AddProductSchema() {
  const dispatch = useDispatch();
  const [examName, setExamName] = useState("");
  const [subExamName, setSubExamName] = useState("");
  const [examStream, setExamStream] = useState("");
  const [examStreamList, setExamStreamList] = useState([]);
  const [examList, setExamList] = useState([]);
  const [subExamList, setSubExamList] = useState([]);
  const [resetExam, setResetExam] = useState(false);
  const [resetSubExam, setResetSubExam] = useState(false);
  const [resetExamStream, setResetExamStream] = useState(false);
  const [typeList, setTypeList] = useState([]);
  const [course, setCourse] = useState("");
  const [mainCategoryList, setMainCategoryList] = useState([]);
  const [mainCategory, setMainCategory] = useState("");
  const [resetMainCategory, setResetMainCategory] = useState(false);
  const [packageList, setPackageList] = useState([]);
  const [Batch, setBatch] = useState("");
  const [usercount, setusercount] = useState("");
  const [rating, setrating] = useState("");
  const [resetCourseType, setresetCourseType] = useState(false);
  const [resetBatch, setresetBatch] = useState(false);

  const onChangeExam = (value) => {
    setExamName(value);
    setResetExam(false);
    setResetSubExam(true);
    setResetExamStream(true);
    setresetCourseType(true);
    setResetMainCategory(true);
    setresetBatch(true);
    setSubExamName("");
    setExamStream("");
    setExamStreamList([]);
    setSubExamList([]);
    dispatch(
      getSubExam(
        {
          examTypeId: value._id,
        },
        (res) => {
          if (res.data.length !== 0) setSubExamList(res.data);
          else
            dispatch(
              getParentCourseNew(`${value._id}`, (res) => {
                setExamStreamList(res);
              })
            );
        }
      )
    );
  };
  const onChangeSubExam = (value) => {
    setSubExamName(value);
    setResetSubExam(false);
    setExamStream("");
    setResetExamStream(true);
    setresetCourseType(true);
    setResetMainCategory(true);
    setresetBatch(true);
    setExamStreamList([]);
    dispatch(
      getParentCourseNew(`${examName._id}/${value._id}`, (res) => {
        setExamStreamList(res);
      })
    );
  };

  const onChangeExamStream = (value) => {
    setExamStream(value);
    setResetExamStream(false);
    setresetCourseType(true);
    setResetMainCategory(true);
    setresetBatch(true);
  };
  const changeMainCategory = (value) => {
    setMainCategory(value);
    setResetMainCategory(false);
    setresetBatch(true);

    setPackageList([]);
    const data = {
      // skip: 0,
      // limit: rowsPerPage,
      sectionType: course?.type,
      baseCourseId: examStream?._id,
      examTypeId: examName?._id,
      mainCategoryId: value?._id,
    };
    if (subExamName) {
      data["subExamTypeId"] = subExamName?._id;
    }
    dispatch(
      getPackageListWithFilter(
        data,
        (res) => {
          // setCount(res?.count);
          setPackageList(res?.data);
        },
        () => {}
      )
    );
  };

  useEffect(() => {
    dispatch(
      getExamListNew((res) => {
        setExamList(res);
      })
    );
    dispatch(
      getSectionList(
        "",
        (res) => {
          setTypeList(res?.data);
        },
        () => {}
      )
    );
  }, []);

  const handleSubmit = async (e) => {
    e.preventDefault();
    const data = {
      examTypeId: examName._id,
      baseCourseId: examStream._id,
      sectionId: course.type,
      courseId: Batch?._id,
      rating: rating,
      count: usercount,
    };
    if (subExamName?._id) {
      data["subExamTypeId"] = subExamName._id;
    }
    if (mainCategory?._id) {
      data["mainCategoryId"] = mainCategory?._id;
    }

    dispatch(
      addProductSchema(data, () => {
        setExamName("");
        setResetExam(true);
        setResetSubExam(true);
        setSubExamName("");
        setExamStreamList([]);
        setExamStream("");
        setCourse("");
        setResetExamStream(true);
        setresetCourseType(true);
        setResetMainCategory(true);
        setresetBatch(true);
        setrating("");
        setusercount("");
      })
    );
  };

  const validations = () => {
    return (
      examStream?._id === "" ||
      examName?._id === "" ||
      rating === "" ||
      usercount === "" ||
      course?._id === "" ||
      Batch?._id === ""
    );
  };

  const onChangeCourseType = (value) => {
    setCourse(value);
    setresetCourseType(false);
    setResetMainCategory(true);
    setresetBatch(true);
    setMainCategoryList([]);
    setPackageList([]);
    if (value?.type === 1) {
      dispatch(
        getMainCategory(
          {
            baseCourseId: examStream?._id,
            examTypeId: examName?._id,
            subExamTypeId: subExamName?._id,
          },
          (res) => {
            let filteredList = res?.data?.filter(
              (item) => item?.primarySubCatType === value?.type
            );
            setMainCategoryList(filteredList);
          }
        )
      );
    } else {
      const data = {
        sectionType: value?.type,
        baseCourseId: examStream?._id,
        examTypeId: examName?._id,
      };
      if (subExamName) {
        data["subExamTypeId"] = subExamName?._id;
      }
      dispatch(
        getPackageListWithFilter(
          data,
          (res) => {
            // setCount(res?.count);
            setPackageList(res?.data);
          },
          () => {}
        )
      );
    }
  };

  const onChangeBatch = (value) => {
    setBatch(value);
    setresetBatch(false);
  };
  return (
    <div className="contentBox">
      <PageTitle name={"ADD PRODUCT SCHEMA"} />
      <form className="formWidth" onSubmit={handleSubmit}>
        <div className="col-10">
          <div className="d-flex mb-2 align-items-center">
            <LabelField labelName={"Exam Name"} />
            <CustomDropdown
              setInputText={(value) => onChangeExam(value)}
              value={examName}
              defaultValue={examName._id}
              options={examList}
              labelName="Select Exam Name"
              reset={resetExam}
              Isrequired={true}
            />
          </div>
          {subExamList.length > 0 && (
            <div className="d-flex mb-2 align-items-center">
              <LabelField labelName={"Sub Exam Name"} />
              <CustomDropdown
                setInputText={(value) => onChangeSubExam(value)}
                defaultValue={subExamName._id}
                options={subExamList}
                labelName="Select Sub Exam Name"
                reset={resetSubExam}
                Isrequired={true}
              />
            </div>
          )}
          <div className="d-flex mb-2 align-items-center">
            <LabelField labelName={"Exam Stream"} />
            <CustomDropdown
              setInputText={(value) => onChangeExamStream(value)}
              defaultValue={examStream._id}
              options={examStreamList}
              labelName="Select Exam Name"
              reset={resetExamStream}
              Isrequired={true}
            />
          </div>
          <div className="d-flex mb-2 align-items-center">
            <LabelField labelName={"Course Type"} />
            <CustomDropdown
              setInputText={(value) => onChangeCourseType(value)}
              defaultValue={course._id}
              options={typeList}
              labelName="Select Course Type"
              Isrequired={true}
              reset={resetCourseType}
            />
          </div>
          {course?.type === 1 ? (
            <div className="d-flex mb-2 align-items-center">
              <LabelField labelName={"Main Category"} />
              <CustomDropdown
                labelName="Select Main Category"
                setInputText={(value) => changeMainCategory(value)}
                value={mainCategory}
                options={mainCategoryList}
                reset={resetMainCategory}
                Isrequired={true}
              />
            </div>
          ) : null}
          <div className="d-flex mb-2 align-items-center">
            <LabelField labelName={"Batch"} />
            <CustomDropdown
              setInputText={(value) => onChangeBatch(value)}
              options={packageList}
              labelName="Select Batch"
              Isrequired={true}
              value={Batch}
              reset={resetBatch}
            />
          </div>
          <InputField
            labelName={"Rating"}
            placeholder={"Enter Rating"}
            setInputText={(value) => setrating(value)}
            value={rating}
            Isrequired={true}
          />
          <InputField
            labelName={"Count"}
            placeholder={"Enter Count"}
            setInputText={(value) => setusercount(value)}
            value={usercount}
            Isrequired={true}
          />
        </div>
        <div className="col-auto d-flex justify-content-center">
          <div className="col-auto d-flex justify-content-center mt-4">
            <button
              type="submit"
              className={`btn mb-3 submitbtn ${
                validations() ? "btn-secondary" : "btn-primary"
              }`}
              disabled={validations()}
            >
              Submit
            </button>
          </div>
        </div>
      </form>
    </div>
  );
}

export default AddProductSchema;
