import "../../App.css";
import React, { Suspense } from "react";
import Sidebar from "../../Module/sidebar";
import { Route, Routes } from "react-router-dom";
import Loading from "../../Component/Loading";
import { PublicationSidebarData } from "../../Module/publicationSidebarData";

const StudyMaterialOrders = React.lazy(() => import("./studyMaterialOrders"));

const TransactionPublication = React.lazy(() =>
  import("../Finance/transactionPublication")
);

const CreateCouponCodePublication = React.lazy(() =>
  import("./createCouponCodePublication")
);
const CreateCouponCodePublicationMultipleExam = React.lazy(() =>
  import("./createCouponCodePublicationMultipleExam")
);
const EditDeleteCouponCode = React.lazy(() => import("./editDeleteCouponCode"));

const CreateCouponCodeExam = React.lazy(() => import("./createCouponCodeExam"));
const CreateCouponCodeNew = React.lazy(() => import("./createCouponCodeNew"));
const EditDeleteCouponCodePublication = React.lazy(() =>
  import("./editDeleteCouponCodePublication")
);

const CreatePubliCounsellorCouponNew = React.lazy(() =>
  import("./createPubliCounsellorCouponNew")
);
const EditDeletePubliCounsellorCouponNew = React.lazy(() =>
  import("./editDeletePubliCounsellorCouponNew")
);
const CopyCounsellorCoupon = React.lazy(() =>
  import("../AdminPanel/copyCounsellorCoupon")
);

const StockManagement = React.lazy(() => import("./stockManagement"));
const StockOut = React.lazy(() => import("./stockOut"));
const BookHistory = React.lazy(() => import("./bookHistory"));
const OrderReturned = React.lazy(() => import("./orderReturned"));

const EditBookHistory = React.lazy(() => import("./editBookHistory"));
const UpdateStock = React.lazy(() => import("./updateStock"));

const CreateBooks = React.lazy(() => import("./createBooks"));
const EditDeleteBooks = React.lazy(() => import("./editDeleteBooks"));

const CreateAssignBooks = React.lazy(() => import("./createAssignBooks"));
const EditDeleteAssignBooks = React.lazy(() =>
  import("./editDeleteAssignBooks")
);

const CreateClassroomBatch = React.lazy(() => import("./createClassroomBatch"));
const EditDeleteClassroomBatch = React.lazy(() =>
  import("./editDeleteClassroomBatch")
);
const AddDispatchCharges = React.lazy(() =>
  import("./DispatchCharges/addDispatchCharges")
);
const EditDispatchCharges = React.lazy(() =>
  import("./DispatchCharges/editDispatchCharges")
);

function PublicationDashboard() {
  return (
    <div className="AppBox">
      <Sidebar data={PublicationSidebarData} />
      <div className="dashboard">
        {
          <Suspense
            fallback={
              <div className="border p-1">
                <Loading />
              </div>
            }
          >
            <Routes>
              <Route path="/" element={<StudyMaterialOrders />} />
              <Route
                path="studyMaterialOrders"
                element={<StudyMaterialOrders />}
              />
              <Route
                path="transactionPublication"
                element={<TransactionPublication />}
              />
              <Route
                path="createCouponCodePublication"
                element={<CreateCouponCodePublication />}
              />
              <Route
                path="createCouponCodePublicationMultipleExam"
                element={<CreateCouponCodePublicationMultipleExam />}
              />
              <Route
                path="editDeleteCouponCode"
                element={<EditDeleteCouponCode />}
              />

              <Route
                path="createCouponCodeExam"
                element={<CreateCouponCodeExam />}
              />
              <Route
                path="createCouponCodeNew"
                element={<CreateCouponCodeNew />}
              />
              <Route
                path="editDeleteCouponCodePublication"
                element={<EditDeleteCouponCodePublication />}
              />

              <Route
                path="createPubliCounsellorCouponNew"
                element={<CreatePubliCounsellorCouponNew />}
              />
              <Route
                path="editDeletePubliCounsellorCouponNew"
                element={<EditDeletePubliCounsellorCouponNew />}
              />
              <Route
                path="copyCounsellorCoupon"
                element={<CopyCounsellorCoupon />}
              />

              {/* 
              <Route
                path="createPubliCounsellorCoupon"
                element={<CreatePubliCounsellorCoupon />}
              />
              <Route
                path="editDeletePubliCounsellorCoupon"
                element={<EditDeletePubliCounsellorCoupon />}
              /> */}

              <Route path="stockManagement" element={<StockManagement />} />
              <Route path="stockOut" element={<StockOut />} />
              <Route path="orderReturned" element={<OrderReturned />} />
              <Route path="bookHistory" element={<BookHistory />} />
              <Route path="editBookHistory" element={<EditBookHistory />} />
              <Route path="updateStock" element={<UpdateStock />} />
              <Route path="createBooks" element={<CreateBooks />} />
              <Route path="editDeleteBooks" element={<EditDeleteBooks />} />

              <Route path="createAssignBooks" element={<CreateAssignBooks />} />
              <Route
                path="EditDeleteAssignBooks"
                element={<EditDeleteAssignBooks />}
              />

              <Route
                path="createClassroomBatch"
                element={<CreateClassroomBatch />}
              />
              <Route
                path="editDeleteClassroomBatch"
                element={<EditDeleteClassroomBatch />}
              />
              <Route
                path="addDispatchCharges"
                element={<AddDispatchCharges />}
              />
              <Route
                path="editDispatchCharges"
                element={<EditDispatchCharges />}
              />
            </Routes>
          </Suspense>
        }
      </div>
    </div>
  );
}

export default PublicationDashboard;
