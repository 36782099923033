import services from "../Constant/service";
import endpoint from "../Constant/endpoint";
import { RESET_STORE, SET } from "../Types/authTypes";
import { showSuccessSnackbar } from "./snackbarAction";
import { showErrorSnackbar } from "./snackbarAction";

export const updateValues = (key, value) => (dispatch) => {
  dispatch({
    type: SET,
    payload: {
      [key]: value,
    },
  });
};

export const updateMoreValues = (values) => (dispatch) => {
  dispatch({
    type: SET,
    payload: values,
  });
};

export const setInitialState = () => async (dispatch) => {
  dispatch({
    type: RESET_STORE,
  });
};

export const postLogin =
  (data, callback, errorCallback) => async (dispatch) => {
    dispatch(updateValues("loader", true));
    services.postApiCall(
      endpoint.auth.login,
      data,
      (res) => {
        dispatch(updateValues("loader", false));
        if (res.statusCode === services.status_code.success) {
          localStorage.setItem("token", res.data.token.refresh.token);

          dispatch(updateValues("authToken", res.data.token.access.token));
          services.setAuthorizationToken(res.data.token.access.token);

          dispatch(showSuccessSnackbar("Success!"));
          callback(res);
        } else if (res.statusCode !== services.status_code.success) {
          errorCallback(res);

          dispatch(showErrorSnackbar(res.msg));
        }
      },
      (error) => {
        dispatch(
          showErrorSnackbar(error?.data?.msg ? error?.data?.msg : error?.msg)
        );
        dispatch(updateValues("loader", false));
        errorCallback(error);
      }
    );
  };

export const Logout = (callback, errorCallback) => async (dispatch) => {
  dispatch(updateValues("loader", true));
  services.postApiCall(
    endpoint.auth.logout,
    {},
    (res) => {
      dispatch(updateValues("loader", false));
      if (res.statusCode === services.status_code.success) {
        localStorage.setItem("token", "");
        dispatch(updateValues("authToken", ""));
        services.setAuthorizationToken("");
        callback(res);
      } else if (res.statusCode !== services.status_code.success) {
        errorCallback(res);

        dispatch(showErrorSnackbar(res.msg));
      }
    },
    (error) => {
      dispatch(
        showErrorSnackbar(error?.data?.msg ? error?.data?.msg : error?.msg)
      );
      dispatch(updateValues("loader", false));
      errorCallback(error);
    }
  );
};
