/* eslint-disable no-useless-escape */
import React from "react";
import ReactHtmlParser from "react-html-parser";

export const getStringQuestionType = (questionType) => {
  /**
   * returns string/descriptive name of question type from number`
   * @param {number} questionType
   */
  const hashmap = {
    1: "MCQ",
    2: "MSQ",
    3: "NAT",
  };
  return questionType in hashmap ? hashmap[questionType] : "";
};

export const renderQuestions = (questions) => {
  return questions.map((item) => {
    const data = String(item.description);
    const removeData = data.replace(/\\n/g, " ");
    const modifiedHtml = removeData.replace(/\\t/g, " ");
    return (
      <>
        {item.descType === "text" ? (
          ReactHtmlParser(modifiedHtml)
        ) : (
          <img
            src={item.description}
            style={{ maxWidth: "100%", maxHeight: 100 }}
            alt="images"
          />
        )}
      </>
    );
  });
};

export const renderCorrectAns = (answers) => {
  let newArr = answers?.filter((item) => item?.isCorrect);
  return newArr.map((item) => {
    const data = String(item.description);
    const removeData = data.replace(/\\n/g, " ");
    const modifiedHtml = removeData.replace(/\\t/g, " ");
    return (
      <>
        {item.descType === "text" ? (
          ReactHtmlParser(modifiedHtml)
        ) : (
          <img
            src={item.description}
            style={{ maxWidth: "100%", maxHeight: 100 }}
            alt="images"
          />
        )}
      </>
    );
  });
};

export const madeCorrectURL = (imageUrl) => {
  let key = imageUrl;
  key = key.replace(/\%28/g, "(");
  key = key.replace(/\%29/g, ")");
  key = key.replace(/\%21/g, "!");
  key = key.replace(/\%7E/g, "~");
  key = key.replace(/\%20/g, "+");
  key = key.replace(/\%26/g, "AND");
  return key;
};
