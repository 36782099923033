export const upSellingPanelSidebarData = [
  {
    title: "UPCOMING EXPIRY",
    link: "upcomingexpiry",
  },
  {
    title: "EXPIRED ACCOUNT",
    link: "expiredaccount",
  },
];
