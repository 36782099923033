/* eslint-disable react/prop-types */
import React, { useEffect, useState } from "react";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "ckeditor5-build-classic-mathtype";

const TextEditor = (props) => {
  const [ckData, setCkData] = useState(props?.value);

  useEffect(() => {
    // The effect will run after the initial render
  }, []);
  useEffect(() => {
    // The effect will run after the initial render
    setCkData(props?.value);
  }, [props?.value]);

  const handleEditorChange = (event, editor) => {
    const data = editor.getData();
    // Check if the change is not due to the initial setting
    // if (!initialLoad) {
    setCkData(data);
    props.setInputText(data);
    // }
  };

  return (
    <React.Fragment>
      <CKEditor
        domContainer={false}
        editor={ClassicEditor}
        config={{
          toolbar: {
            shouldNotGroupWhenFull: true,
            items: [
              "heading",
              "fontsize",
              "alignment",
              "fontColor",
              "fontBackgroundColor",
              "outdent",
              "indent",
              "|",
              "bold",
              "italic",
              "link",
              "bulletedList",
              "numberedList",
              "insertTable",
              "blockQuote",
              "|",
              "MathType",
              "ChemType",
              "|",
              "undo",
              "redo",
            ],
          },
        }}
        data={ckData}
        onChange={handleEditorChange}
        onError={() => {}}
        onReady={(editor) => {
          editor.editing.view.change((writer) => {
            writer.setStyle(
              //use max-height(for scroll) or min-height(static)
              "min-height",
              "150px",
              editor.editing.view.document.getRoot()
            );
          });
        }}
      />
    </React.Fragment>
  );
};

export default TextEditor;
