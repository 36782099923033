import React from "react";
import Lottie from "react-lottie";
import norecord from "../Images/GIF/no_record_found.json";

function NoRecord() {
  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: norecord,
    renderer: "svg",
  };
  return (
    <div className="col justify-content-center bgwhite boxshadow py-5 ">
      <Lottie options={defaultOptions} height={130} width={200}></Lottie>
      <p className="text-center">
        <b>No Result Found</b>
      </p>
    </div>
  );
}

export default NoRecord;
