import React, { useEffect, useState } from "react";
import "../../App.css";
import PageTitle from "../../Module/pageTitle";
import LabelField from "../../Component/labelField";
import { useDispatch } from "react-redux";
import { getParentCourseNew, getExamListNew } from "../../Action/courseAction";
import CustomDropdown from "../../Component/customDropdown";
import HtmlTextEditor from "../../Component/HtmlTextEditor";
import InputField from "../../Component/inputField";
import {
  addOnlineCoachingDetail,
  getOnlineCoachingDetail,
} from "../../Action/seoAction";
import { getSubExam } from "../../Action/adminPanelAction";

function CreateOnlineCoaching() {
  const dispatch = useDispatch();
  const [parentCourseVal, setparentCourseVal] = useState("");
  const [examName, setExamName] = useState("");
  const [subExamName, setSubExamName] = useState("");
  const [parentCourseList, setParentCourseList] = useState([]);
  const [examList, setExamList] = useState([]);
  const [subExamList, setsubExamList] = useState([]);
  const [resetParentCourse, setResetParentCourse] = useState(false);
  const [resetExam, setResetExam] = useState(false);
  const [resetSubExam, setResetSubExam] = useState(false);
  const [addFlag, setAddFlag] = useState(false);
  const [titleHeading, setTitleHeading] = useState("");
  const [titleDesc, setTitleDesc] = useState("");

  useEffect(() => {
    dispatch(
      getExamListNew((res) => {
        setExamList(res);
      })
    );
  }, []);

  const onChangeExam = (value) => {
    setExamName(value);
    setResetExam(false);
    setsubExamList([]);
    setResetSubExam(true);
    setParentCourseList([]);
    setResetParentCourse(true);
    dispatch(
      getSubExam(
        {
          examTypeId: value._id,
        },
        (res) => {
          if (res?.data?.length > 0) {
            setsubExamList(res.data);
          } else {
            dispatch(
              getParentCourseNew(`${value._id}`, (res) => {
                setParentCourseList(res);
              })
            );
          }
        }
      )
    );
  };

  const onChangeSubExam = (value) => {
    setSubExamName(value);
    setResetSubExam(false);
    setParentCourseList([]);
    setResetParentCourse(true);
    dispatch(
      getParentCourseNew(`${examName._id}/${value._id}`, (res) => {
        setParentCourseList(res);
      })
    );
  };

  const changeParentCourse = (value) => {
    setparentCourseVal(value);
    setResetParentCourse(false);
    const data = {
      baseCourseId: value?._id,
      examTypeId: examName?._id,
    };
    if (subExamName?._id) data["subExamTypeId"] = subExamName._id;
    dispatch(
      getOnlineCoachingDetail(data, (res) => {
        setTitleHeading(res?.data?.title);
        setTitleDesc(res?.data?.description);
      })
    );
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const postData = {
      baseCourseId: parentCourseVal._id,
      examTypeId: examName._id,
      title: titleHeading,
      description: titleDesc,
    };
    if (subExamName) {
      postData["subExamTypeId"] = subExamName?._id;
    }
    dispatch(
      addOnlineCoachingDetail(postData, () => {
        setResetParentCourse(true);
        setResetExam(true);
        setsubExamList([]);
        setResetSubExam(true);
        setTitleDesc("");
        setTitleHeading("");
        setAddFlag(false);
      })
    );
  };

  const validations = () => {
    return (
      parentCourseVal === "" ||
      examName === "" ||
      (subExamList?.length > 0 && subExamName === "") ||
      titleHeading === "" ||
      titleDesc === ""
    );
  };

  return (
    <div className="contentBox">
      <PageTitle name={"ONLINE COACHING"} />
      <form className="formWidth" onSubmit={handleSubmit}>
        <div className="col-auto">
          <div className="d-flex">
            <LabelField labelName={"Exam Name"} />
            <CustomDropdown
              setInputText={(value) => onChangeExam(value)}
              value={examName}
              options={examList}
              Isrequired={true}
              labelName="Select Exam Name"
              reset={resetExam}
            />
          </div>
          {subExamList?.length > 0 ? (
            <div className="d-flex">
              <LabelField labelName={"Sub Exam Name"} />
              <CustomDropdown
                setInputText={(value) => onChangeSubExam(value)}
                value={subExamName}
                options={subExamList}
                Isrequired={true}
                labelName="Select Sub Exam Name"
                reset={resetSubExam}
              />
            </div>
          ) : null}
          <div className="d-flex">
            <LabelField labelName={"Exam Stream"} />
            <CustomDropdown
              setInputText={(value) => changeParentCourse(value)}
              value={parentCourseVal}
              options={parentCourseList}
              Isrequired={true}
              labelName="Select Exam Stream"
              reset={resetParentCourse}
            />
          </div>
          <InputField
            labelName={"Title"}
            placeholder={"Enter Title"}
            setInputText={(value) => setTitleHeading(value)}
            value={titleHeading}
            Isrequired={true}
          />
          <div className="d-flex mb-2">
            <LabelField labelName={"Description"} />
            <HtmlTextEditor
              onChange={(value) => setTitleDesc(value)}
              value={titleDesc}
              height={"200px"}
            />
          </div>
        </div>

        <div className="col-auto d-flex justify-content-center">
          <div className="col-auto d-flex justify-content-center mt-4">
            <button
              type="submit"
              className={`btn mb-3 submitbtn ${
                validations() ? "btn-secondary" : "btn-primary"
              }`}
              disabled={validations()}
            >
              {!addFlag ? "Update" : "Create Cut-Off"}
            </button>
          </div>
        </div>
      </form>
    </div>
  );
}

export default CreateOnlineCoaching;
