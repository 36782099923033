export const AcademicHeadSidebarData = [
  {
    title: "LEAD DASHBOARD",
    subNav: [
      {
        title: "LEAD DASHBOARD",
        path: "leadDashboard",
        cName: "sub-nav",
      },
      {
        title: "LEAD COUNT",
        path: "LeadCount",
        cName: "sub-nav",
      },
      {
        title: "LEAD ANALYSIS",
        path: "leadAnalysis",
        cName: "sub-nav",
      },
    ],
  },
  {
    title: "TARGET STATUS",
    link: "targetStatus",
  },
  {
    title: "TEACHER DETAILS",
    subNav: [
      {
        title: "TEACHER TASK PLAN",
        path: "teacherTaskPlan",
        cName: "sub-nav",
      },
      {
        title: "TEACHER TASK PLAN PENDING",
        path: "teacherTaskPlanPending",
        cName: "sub-nav",
      },
      {
        title: "TEACHER TASK PLAN COMPLETED",
        path: "teacherTaskPlanComplete",
        cName: "sub-nav",
      },
      {
        title: "TEACHER TASK PLAN NOT COMPLETED",
        path: "teacherTaskPlanNotComplete",
        cName: "sub-nav",
      },
    ],
  },
  // {
  //   title: "TEACHER TASK PLAN",
  //   link: "teacherTaskPlan",
  // },
  // {
  //   title: "TEACHER TASK PLAN PENDING",
  //   link: "teacherTaskPlanPending",
  // },
  // {
  //   title: "TEACHER TASK PLAN COMPLETED",
  //   link: "teacherTaskPlanComplete",
  // },
  // {
  //   title: "TEACHER TASK PLAN NOT COMPLETED",
  //   link: "teacherTaskPlanNotComplete",
  // },
  {
    title: "DOUBT SOLVE REVIEW",
    link: "doubtsolvereview",
  },
  {
    title: "ADMISSION ANALYSIS",
    subNav: [
      {
        title: "BATCHWISE COUNT",
        path: "BatchwiseCount",
        cName: "sub-nav",
      },
      {
        title: "DATEWISE COUNT",
        path: "DatewiseCount",
        cName: "sub-nav",
      },
    ],
  },
  {
    title: "REVENUE ANALYSIS",
    link: "ExamWiseRevenue",
  },
  // {
  //   title: "RESULT COMPILATION",
  //   link: "resultCompilation",
  // },
];
