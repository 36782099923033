/* eslint-disable react/prop-types */
import React, { memo, useRef } from "react";
import SunEditor from "suneditor-react";
import "suneditor/dist/css/suneditor.min.css";

const HtmlTextEditor = (props) => {
  /**
   * @type {React.MutableRefObject<SunEditor>} get type definitions for editor
   */
  const editor = useRef();
  // The sunEditor parameter will be set to the core suneditor instance when this function is called
  const getSunEditorInstance = (sunEditor) => {
    editor.current = sunEditor;
  };

  const defaultFonts = [
    "Arial",
    "Comic Sans MS",
    "Courier New",
    "Impact",
    "Georgia",
    "Tahoma",
    "Trebuchet MS",
    "Verdana",
  ];

  const sortedFontOptions = [
    "Logical",
    "Salesforce Sans",
    "Garamond",
    "Sans-Serif",
    "Serif",
    "Times New Roman",
    "Helvetica",
    ...defaultFonts,
  ].sort();

  return (
    <>
      <SunEditor
        setOptions={{
          buttonList: [
            ["font", "fontSize", "formatBlock"],
            [
              "bold",
              "underline",
              "italic",
              "strike",
              "subscript",
              "superscript",
            ],
            ["fontColor", "hiliteColor"],
            ["align", "horizontalRule", "list", "table"],
            ["outdent", "indent"],
            ["undo", "redo"],
            ["removeFormat"],
            ["outdent", "indent"],
            ["link", "image"],
            ["showBlocks", "codeView", "save"],
          ],
          defaultTag: "div",
          showPathLabel: false,
          font: sortedFontOptions,
        }}
        defaultValue={props?.value}
        setContents={props?.value}
        onSave={(value) => props?.onChange(value)}
        onChange={(value) => props?.onChange(value)}
        getSunEditorInstance={getSunEditorInstance}
        height={props?.height ? props?.height : "60vh"}
        width={props?.width ? props?.width : "60vw"}
        disable={props?.isDisable}
      />
    </>
  );
};
export default memo(HtmlTextEditor);
