import React, { useEffect, useState } from "react";
import "../../App.css";
import PageTitle from "../../Module/pageTitle";
import InputField from "../../Component/inputField";
import { useDispatch } from "react-redux";
import { setParentCourse } from "../../Action/courseAction";
import LabelField from "../../Component/labelField";
import {
  containsNumber,
  pdf_size_1MB,
  validateEmailAddress,
  validateFullName,
  validateIsFileImageType,
  validateValueWithoutSpace,
} from "../../Utils/validators";
import { showErrorSnackbar } from "../../Action/snackbarAction";
import {
  validateIsFileImageTypeErrMsg,
  validateIsNumberOnlyErrMsg,
} from "../../Utils/errorMsg";
import {
  deleteAuthor,
  getAuthor,
  setUploadAuthorImage,
  updateAuthorDetails,
} from "../../Action/seoAction";
import { useSelector } from "react-redux";
import AdminTable from "../../Component/adminTable";
import ImagePickerAndViewer from "../../Component/imagePickerAndViewer";

function EditDeleteAuthorDetails() {
  const dispatch = useDispatch();
  const [name, setName] = useState("");
  const [designation, setDesignation] = useState("");
  const [about, setAbout] = useState("");
  const [packageUpload, setPackageUpload] = useState("");
  const [selectedImage, setSelectedImage] = useState("");
  const [emailAddress, setEmailAddress] = useState("");
  const [contactNo, setContactNo] = useState("");
  const [linkedinURL, setLinkedinURL] = useState("");
  const [facebookURL, setFacebookURL] = useState("");
  const [instagramURL, setInstagramURL] = useState("");
  const [googleLink, setGoogleLink] = useState("");
  const [youtubeLink, setYoutubeLink] = useState("");
  const [location, setLocation] = useState("");
  const [authorUniqueName, setAuthorUniqueName] = useState("");
  const [id, setId] = useState("");
  const [authorList, setAuthorList] = useState([]);

  const { isEditMode } = useSelector((state) => ({
    isEditMode: state.courseReducer?.isEditMode,
  }));

  const onChangeName = (value) => {
    if (!validateFullName(value)) {
      dispatch(showErrorSnackbar("Please enter valid name"));
      return;
    } else {
      setName(value);
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (selectedImage) {
      dispatch(
        setUploadAuthorImage(
          { uniqueName: authorUniqueName, authorImage: selectedImage },
          (imageRes) => {
            const postData = {
              name: name,
              designation: designation,
              about: about,
              image: imageRes?.imageLogo,
              phoneNo: contactNo,
              email: emailAddress,
              linkedIn: linkedinURL,
              facebook: facebookURL,
              instagram: instagramURL,
              google: googleLink,
              youtube: youtubeLink,
              location: location,
            };
            for (let key in postData) {
              if (postData[key] === "") {
                delete postData[key];
              }
            }
            dispatch(
              updateAuthorDetails(postData, id, () => {
                listAPI();
                setId("");
                dispatch(setParentCourse("isEditMode", false));
              })
            );
          }
        )
      );
    } else {
      const postData = {
        name: name,
        designation: designation,
        about: about,
        phoneNo: contactNo,
        email: emailAddress,
        linkedIn: linkedinURL,
        facebook: facebookURL,
        instagram: instagramURL,
        google: googleLink,
        youtube: youtubeLink,
        location: location,
      };
      for (let key in postData) {
        if (postData[key] === "") {
          delete postData[key];
        }
      }
      dispatch(
        updateAuthorDetails(postData, id, () => {
          listAPI();
          setId("");
          dispatch(setParentCourse("isEditMode", false));
        })
      );
    }
  };

  const validations = () => {
    return (
      name === "" ||
      (emailAddress !== "" && !validateEmailAddress(emailAddress)) ||
      designation === "" ||
      about === "" ||
      packageUpload === ""
    );
  };
  const handleBannerChange = (e) => {
    e.preventDefault();
    const reader = new FileReader();
    const file = e.target.files[0];
    if (!validateIsFileImageType(e)) {
      dispatch(showErrorSnackbar(validateIsFileImageTypeErrMsg));
      return;
    } else if (file.size > pdf_size_1MB) {
      dispatch(showErrorSnackbar("File size should be less than 1 MB!!!"));
    } else {
      reader.onloadend = () => {
        setPackageUpload(reader.result);
        setSelectedImage(file);
      };
      reader.readAsDataURL(file);
    }
  };
  const onchangeMob = (value) => {
    if (value !== "") {
      if (!containsNumber(value)) {
        dispatch(showErrorSnackbar("Only Numbers are allowed!"));
        return;
      } else if (!validateValueWithoutSpace(value)) {
        dispatch(showErrorSnackbar(validateIsNumberOnlyErrMsg));
        return;
      } else setContactNo(value);
    } else {
      setContactNo(value);
    }
  };

  useEffect(() => {
    listAPI();
  }, []);

  const listAPI = () => {
    dispatch(
      getAuthor("", (res) => {
        setAuthorList(res);
      })
    );
  };

  const getData = (data) => {
    setName(data?.name);
    setDesignation(data?.designation);
    setAbout(data?.about);
    setEmailAddress(data?.email);
    setContactNo(data?.phoneNo);
    setLinkedinURL(data?.linkedIn);
    setFacebookURL(data?.facebook);
    setInstagramURL(data?.instagram);
    setGoogleLink(data?.google);
    setYoutubeLink(data?.youtube);
    setLocation(data?.location);
    setPackageUpload(data?.image);
    setAuthorUniqueName(data?.uniqueName);
    setId(data?._id);
    dispatch(setParentCourse("isEditMode", true));
  };

  const renderEditMode = () => (
    <form className="formWidth" onSubmit={handleSubmit}>
      <div className="col-8">
        <div className="d-flex mb-2 align-items-center">
          <InputField
            labelName={"Name"}
            placeholder={"Enter Name"}
            setInputText={(value) => onChangeName(value)}
            value={name}
            Isrequired={true}
          />
        </div>
        <div className="d-flex mb-2 align-items-center">
          <InputField
            labelName={"Designation"}
            placeholder={"Enter Designation"}
            setInputText={(value) => setDesignation(value)}
            value={designation}
            Isrequired={true}
          />
        </div>

        <div className="d-flex mb-2 align-items-center w-100">
          <LabelField labelName={"About"} />
          <textarea
            className="form-control w-100"
            placeholder={"Enter About"}
            onChange={(value) => setAbout(value.target.value)}
            value={about}
          />
          <span className="required ms-2 fs-6">*</span>
        </div>
        <div className="d-flex mb-2 align-items-center">
          <ImagePickerAndViewer
            labelName={"Upload Image"}
            value={packageUpload}
            handleChange={(e) => handleBannerChange(e)}
            htmlFor={"photo-upload"}
          />
        </div>
        <div className="d-flex mb-2 align-items-center">
          <p>
            <b>Social Media Links</b>
          </p>
        </div>
        <div className="d-flex mb-2 align-items-center">
          <InputField
            labelName={"Email Address"}
            placeholder={"Enter Email Address"}
            setInputText={(value) => setEmailAddress(value)}
            value={emailAddress}
          />
        </div>
        <div className="d-flex mb-2 align-items-center">
          <InputField
            labelName={"Contact No"}
            placeholder={"Enter Contact No"}
            setInputText={(value) => onchangeMob(value)}
            value={contactNo}
            maxLength={10}
          />
        </div>
        <div className="d-flex mb-2 lign-items-center">
          <InputField
            labelName={"Linkedin URL"}
            placeholder={"Enter Linkedin URL"}
            setInputText={(value) => setLinkedinURL(value)}
            value={linkedinURL}
          />
        </div>
        <div className="d-flex mb-2 lign-items-center">
          <InputField
            labelName={"Facebook URL"}
            placeholder={"Enter Facebook URL"}
            setInputText={(value) => setFacebookURL(value)}
            value={facebookURL}
          />
        </div>
        <div className="d-flex mb-2 lign-items-center">
          <InputField
            labelName={"Instagram URL"}
            placeholder={"Enter Instagram URL"}
            setInputText={(value) => setInstagramURL(value)}
            value={instagramURL}
          />
        </div>
        <div className="d-flex mb-2 lign-items-center">
          <InputField
            labelName={"Google Link"}
            placeholder={"Enter Google Link"}
            setInputText={(value) => setGoogleLink(value)}
            value={googleLink}
          />
        </div>
        <div className="d-flex mb-2 lign-items-center">
          <InputField
            labelName={"Youtube Link"}
            placeholder={"Enter Youtube Link"}
            setInputText={(value) => setYoutubeLink(value)}
            value={youtubeLink}
          />
        </div>
        <div className="d-flex mb-2 lign-items-center">
          <InputField
            labelName={"Location"}
            placeholder={"Enter Location"}
            setInputText={(value) => setLocation(value)}
            value={location}
          />
        </div>
      </div>
      <div className="col-auto d-flex justify-content-center">
        <div className="col-auto d-flex justify-content-center mt-4">
          <button
            type="submit"
            className={`btn mb-3 submitbtn ${
              validations() ? "btn-secondary" : "btn-primary"
            }`}
            disabled={validations()}
          >
            Submit
          </button>
        </div>
      </div>
    </form>
  );

  const renderListMode = () => {
    return (
      <AdminTable
        pagename={"author"}
        tableData={authorList}
        tableHead={[
          "S.N",
          "Name",
          "DESIGNATION",
          "AUTHOR UNIQUE NAME",
          "AUTHOR UNIQUE LINK",
          "Actions",
        ]}
        handleEdit={(data) => getData(data)}
        handleDelete={(data) => handleDelete(data)}
        edit={true}
      />
    );
  };

  const handleDelete = (value) => {
    dispatch(
      deleteAuthor(`${value?._id}`, () => {
        dispatch(setParentCourse("isEditMode", false));
        listAPI();
      })
    );
  };

  return (
    <>
      <div className="contentBox">
        <PageTitle
          name={"EDIT AUTHOR DETAILS"}
          isEditMode={isEditMode}
          onClick={() => dispatch(setParentCourse("isEditMode", false))}
        />
        <div className="col-auto formWidth formDiv">
          {!isEditMode ? renderListMode() : renderEditMode()}
        </div>
      </div>
    </>
  );
}

export default EditDeleteAuthorDetails;
