import React, { useEffect, useState } from "react";
import "../../App.css";
import PageTitle from "../../Module/pageTitle";
import { useDispatch } from "react-redux";
import {
  deleteIssueType,
  issueTypeList,
  setParentCourse,
  updateIssueType,
} from "../../Action/courseAction";
import { useSelector } from "react-redux";
import AdminTable from "../../Component/adminTable";
import InputField from "../../Component/inputField";
import { showErrorSnackbar } from "../../Action/snackbarAction";
import {
  containsNumber,
  containsStartsWithSpecialCharsOrStartsWithNumberOrWhiteSpace,
  length_Hundred,
  validateValueWithoutSpace,
} from "../../Utils/validators";
import {
  containsStartsWithSpecialCharsOrStartsWithNumberOrWhiteSpaceErrMsg,
  validateIsNumberOnlyErrMsg,
} from "../../Utils/errorMsg";

function EditDeleteIssueType() {
  const dispatch = useDispatch();
  const [list, setList] = useState([]);
  const { isEditMode } = useSelector((state) => ({
    isEditMode: state.courseReducer?.isEditMode,
  }));

  const [name, setName] = useState("");
  const [price, setPrice] = useState("");
  const [id, setID] = useState("");

  const validations = () => {
    return name === "" || price === "";
  };
  const handleCancelEditMode = () => {
    dispatch(setParentCourse("isEditMode", false));
  };

  useEffect(() => {
    listAPI();
  }, []);

  const listAPI = () => {
    dispatch(
      issueTypeList((res) => {
        setList(res);
      })
    );
  };

  const handleEdit = (value) => {
    setName(value?.name);
    setPrice((value?.price).toString());
    setID(value?._id);
    dispatch(setParentCourse("isEditMode", true));
  };

  const handleDelete = (value) => {
    dispatch(
      deleteIssueType(`${value?._id}`, () => {
        listAPI();
      })
    );
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    dispatch(
      updateIssueType(`${id}`, { name, price: parseInt(price) }, () => {
        setName("");
        setPrice("");
        listAPI();
        dispatch(setParentCourse("isEditMode", false));
      })
    );
  };

  const onChange = (value, key) => {
    if (key === "name") {
      if (value.length === length_Hundred) {
        dispatch(
          showErrorSnackbar(
            `Max Length ${length_Hundred - 1} reached for this field`
          )
        );
      } else if (
        containsStartsWithSpecialCharsOrStartsWithNumberOrWhiteSpace(value)
      ) {
        dispatch(
          showErrorSnackbar(
            containsStartsWithSpecialCharsOrStartsWithNumberOrWhiteSpaceErrMsg
          )
        );
        return;
      } else setName(value);
    } else if (key === "price") {
      if (value !== "") {
        if (!containsNumber(value)) {
          dispatch(showErrorSnackbar("Only Numbers are allowed!"));
          return;
        } else if (!validateValueWithoutSpace(value)) {
          dispatch(showErrorSnackbar(validateIsNumberOnlyErrMsg));
          return;
        } else setPrice(value);
      } else setPrice(value);
    }
  };

  const renderEditMode = () => {
    return (
      <form className="formWidth" onSubmit={handleSubmit}>
        <div className="col-auto">
          <InputField
            labelName={"Issue"}
            placeholder={"Enter Issue"}
            setInputText={(value) => onChange(value, "name")}
            value={name}
            Isrequired={true}
          />
          <InputField
            labelName={"Price"}
            placeholder={"Enter Price"}
            setInputText={(value) => onChange(value, "price")}
            value={price}
            Isrequired={true}
          />
        </div>
        <div className="col-auto d-flex justify-content-center">
          <div className="col-auto d-flex justify-content-center mt-4">
            <button
              type="submit"
              className={`btn mb-3 submitbtn ${
                validations() ? "btn-secondary" : "btn-primary"
              }`}
              disabled={validations()}
            >
              {" "}
              Edit
            </button>
          </div>
        </div>
      </form>
    );
  };

  const renderListMode = () => {
    return (
      <div className="col-12 formWidth">
        <AdminTable
          pagename={"IssueType"}
          tableHead={["S.N", "Issue Name", "Penalty", "Actions"]}
          tableData={list ? list : []}
          handleEdit={(value) => handleEdit(value)}
          handleDelete={(value) => handleDelete(value)}
          edit={true}
        />
      </div>
    );
  };

  return (
    <div className="contentBox">
      <PageTitle
        name={"Edit Delete ISSUE Type"}
        isEditMode={isEditMode}
        onClick={handleCancelEditMode}
      />
      <div className="formDiv">
        {!isEditMode ? renderListMode() : renderEditMode()}
      </div>
    </div>
  );
}

export default EditDeleteIssueType;
