import "../../App.css";
import React, { Suspense } from "react";
import Sidebar from "../../Module/sidebar";
import { Route, Routes } from "react-router-dom";
import Loading from "../../Component/Loading";
import { TeacherPanelSidebarData } from "../../Module/teacherPanelSidebarData";
import PdfViewer from "../../Component/pdfViewer";

const Dashboard = React.lazy(() => import("./dashboard"));
const UploadLiveVideo = React.lazy(() => import("./uploadLiveVideo"));
const UploadLivePDF = React.lazy(() => import("./uploadLivePDF"));
const UploadRecordedVideo = React.lazy(() => import("./uploadRecordedVideo"));
const UpcomingLiveSchedule = React.lazy(() => import("./upcomingLiveSchedule"));
const CourseSupport = React.lazy(() => import("./courseSupport"));
const Helpdesk = React.lazy(() => import("./helpdesk"));
const WorkHoursDetails = React.lazy(() => import("./workHoursDetails"));
const Chat = React.lazy(() => import("./chat"));
const Issue = React.lazy(() => import("./issue"));
const Feedback = React.lazy(() => import("./feedback"));
const LectureFeedback = React.lazy(() => import("./lectureFeedback"));
const TeacherTaskPlan = React.lazy(() => import("./teacherTaskPlan"));
const PptForPenDisplay = React.lazy(() => import("./pptForPenDisplay"));
const PptForSmartBoard = React.lazy(() => import("./pptForSmartBoard"));
const TeacherSOP = React.lazy(() => import("./teacherSOP"));
const Syllabus = React.lazy(() => import("./syllabusDashboard"));
const Question = React.lazy(() => import("./editDeleteQuestionBank"));
const AddTeacherYTData = React.lazy(() => import("./addTeacherYoutubeData"));
const EditTeacherYTData = React.lazy(() => import("./editTeacherYoutubeData"));
const LectureDelayed = React.lazy(() => import("./lectureDelayed"));
const QuestionDoubtList = React.lazy(() => import("./questionDoubtList"));
const StudentAnalysisDashboard = React.lazy(() =>
  import("./StudentAnalysis/studentAnalysisDashboard")
);
const UploadTestDiscussionVideo = React.lazy(() =>
  import("./uploadTestDiscussionVideo")
);
const AddQuestionPoll = React.lazy(() =>
  import("./QuestionPoll/addQuestionPoll")
);
const EditQuestionPoll = React.lazy(() =>
  import("./QuestionPoll/editQuestionPoll")
);

function TeacherPanelDashboard() {
  return (
    <div className="AppBox">
      {/* <Header /> */}
      <Sidebar data={TeacherPanelSidebarData} />
      <div className="dashboard">
        {
          <Suspense
            fallback={
              <div className="border p-1">
                <Loading />
              </div>
            }
          >
            <Routes>
              <Route path="/" element={<Dashboard />} />
              <Route path="dashboard" element={<Dashboard />} />
              <Route
                path="upcomingLiveSchedule"
                element={<UpcomingLiveSchedule />}
              />
              <Route path="uploadLiveVideo" element={<UploadLiveVideo />} />
              <Route path="uploadLivePDF" element={<UploadLivePDF />} />
              <Route
                path="uploadRecordedVideo"
                element={<UploadRecordedVideo />}
              />
              <Route path="CourseSupport" element={<CourseSupport />} />
              <Route path="helpdesk" element={<Helpdesk />} />
              <Route path="workHoursDetails" element={<WorkHoursDetails />} />
              <Route path="Chat" element={<Chat />} />
              <Route path="issue" element={<Issue />} />
              <Route path="feedback" element={<Feedback />} />
              <Route path="lectureFeedback" element={<LectureFeedback />} />
              <Route path="teacherTaskPlan" element={<TeacherTaskPlan />} />
              <Route path="pptForPenDisplay" element={<PptForPenDisplay />} />
              <Route path="pptForSmartBoard" element={<PptForSmartBoard />} />
              <Route path="teacherSOP" element={<TeacherSOP />} />
              <Route path="PdfViewer" element={<PdfViewer />} />
              <Route path="PdfViewer" element={<PdfViewer />} />
              <Route path="syllabusDashboard" element={<Syllabus />} />
              <Route path="question" element={<Question />} />
              <Route path="addTeacherYTData" element={<AddTeacherYTData />} />
              <Route path="editTeacherYTData" element={<EditTeacherYTData />} />
              <Route path="lectureDelayed" element={<LectureDelayed />} />
              <Route path="questionDoubtList" element={<QuestionDoubtList />} />
              <Route
                path="studentAnalysisDashboard"
                element={<StudentAnalysisDashboard />}
              />
              <Route
                path="uploadTestDiscussionVideo"
                element={<UploadTestDiscussionVideo />}
              />
              <Route path="addQuestionPoll" element={<AddQuestionPoll />} />
              <Route path="editQuestionPoll" element={<EditQuestionPoll />} />
            </Routes>
          </Suspense>
        }
      </div>
    </div>
  );
}

export default TeacherPanelDashboard;
