export const PaidAdsSideBarData = [
  {
    title: "LEAD DASHBOARD",
    subNav: [
      {
        title: "LEAD DASHBOARD",
        path: "leadDashboard",
        cName: "sub-nav",
      },
      {
        title: "LEAD COUNT",
        path: "LeadCount",
        cName: "sub-nav",
      },
      {
        title: "LEAD ANALYSIS",
        path: "leadAnalysis",
        cName: "sub-nav",
      },
    ],
  },
  {
    title: "TARGET STATUS",
    link: "targetStatus",
  },
  {
    title: "PAID ADS",
    link: "paidAds",
  },
];
