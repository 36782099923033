import React, { useEffect, useState } from "react";
import "../../App.css";
import PageTitle from "../../Module/pageTitle";
import InputField from "../../Component/inputField";
import { useDispatch } from "react-redux";
import { getParentCourseNew, getExamListNew } from "../../Action/courseAction";

import CustomDropdown from "../../Component/customDropdown";
import LabelField from "../../Component/labelField";
import { length_Two } from "../../Utils/validators";
import { postCourseSEO } from "../../Action/seoAction";
import { getSectionTypeList, getSubExam } from "../../Action/adminPanelAction";

function AddResourcesPages() {
  const dispatch = useDispatch();
  const [metaTitle, setMetaTitle] = useState("");
  const [metaDescription, setMetaDescription] = useState("");
  const [examName, setExamName] = useState("");
  const [subExamName, setSubExamName] = useState("");
  const [subject, setSubject] = useState("");
  const [subjectList, setSubjectList] = useState([]);
  const [examList, setExamList] = useState([]);
  const [subExamList, setSubExamList] = useState([]);
  const [resetExam, setResetExam] = useState(false);
  const [resetSubExam, setResetSubExam] = useState(false);
  const [resetSubject, setResetSubject] = useState(false);
  const [typeList, setTypeList] = useState([]);
  const [resourcePages, setResourcePages] = useState("");

  const onChangeExam = (value) => {
    setExamName(value);
    setResetExam(false);
    setResetSubExam(true);
    setResetSubject(true);
    setSubExamName("");
    setSubject("");
    setSubjectList([]);
    setSubExamList([]);
    dispatch(
      getSubExam(
        {
          examTypeId: value._id,
        },
        (res) => {
          if (res.data.length !== 0) setSubExamList(res.data);
          else
            dispatch(
              getParentCourseNew(`${value._id}`, (res) => {
                setSubjectList(res);
              })
            );
        }
      )
    );
  };
  const onChangeSubExam = (value) => {
    setSubExamName(value);
    setResetSubExam(false);
    setSubject("");
    setResetSubExam(true);
    setSubjectList([]);
    dispatch(
      getParentCourseNew(`${examName._id}/${value._id}`, (res) => {
        setSubjectList(res);
      })
    );
  };

  const onChangeSubject = (value) => {
    setSubject(value);
    setResetSubExam(false);
  };

  useEffect(() => {
    dispatch(
      getExamListNew((res) => {
        setExamList(res);
      })
    );
    dispatch(
      getSectionTypeList((data) => {
        setTypeList(data);
      })
    );
  }, []);

  const handleSubmit = async (e) => {
    e.preventDefault();
    const data = {
      examTypeId: examName._id,
      metaTitle,
      metaDescrition: metaDescription,
      baseCourseId: subject._id,
      resourcePagesType: resourcePages.type,
    };
    if (subExamName?._id) data.subExamTypeId = subExamName._id;
    dispatch(
      postCourseSEO(data, () => {
        setMetaTitle("");
        setMetaDescription("");
        setExamName("");
        setResetExam(true);
        setResetSubExam(true);
        setSubExamName("");
        setSubjectList([]);
        setSubject("");
        setResourcePages("");
        setResetSubject(true);
      })
    );
  };

  const validations = () => {
    return (
      subject?._id === "" ||
      examName?._id === "" ||
      metaTitle === "" ||
      metaDescription === "" ||
      resourcePages?._id === ""
    );
  };

  return (
    <div className="contentBox">
      <PageTitle name={"ADD RESOURCE PAGES"} />
      <form className="formWidth" onSubmit={handleSubmit}>
        <div className="col-7">
          <div className="d-flex mb-2 align-items-center">
            <LabelField labelName={"Exam Name"} />
            <CustomDropdown
              setInputText={(value) => onChangeExam(value)}
              value={examName}
              defaultValue={examName._id}
              options={examList}
              labelName="Select Exam Name"
              reset={resetExam}
              Isrequired={true}
            />
          </div>
          {subExamList.length > 0 && (
            <div className="d-flex mb-2 align-items-center">
              <LabelField labelName={"Sub Exam Name"} />
              <CustomDropdown
                setInputText={(value) => onChangeSubExam(value)}
                defaultValue={subExamName._id}
                options={subExamList}
                labelName="Select Sub Exam Name"
                reset={resetSubExam}
                Isrequired={true}
              />
            </div>
          )}
          <div className="d-flex mb-2 align-items-center">
            <LabelField labelName={"Subject"} />
            <CustomDropdown
              setInputText={(value) => onChangeSubject(value)}
              defaultValue={subject._id}
              options={subjectList}
              labelName="Select Subject Name"
              reset={resetSubject}
              Isrequired={true}
            />
          </div>
          <div className="d-flex mb-2 align-items-center">
            <LabelField labelName={"Resources Pages"} />
            <CustomDropdown
              setInputText={(value) => setResourcePages(value)}
              defaultValue={resourcePages._id}
              options={typeList}
              labelName="Select Resources Pages"
              Isrequired={true}
            />
          </div>
          <InputField
            key={"metaTitle"}
            labelName={"Meta Title"}
            placeholder={"Enter Meta Title"}
            setInputText={(value) => setMetaTitle(value)}
            value={metaTitle}
            minLength={length_Two}
            Isrequired={true}
          />
          <InputField
            key={"metaDescription"}
            labelName={"Meta Description"}
            placeholder={"Enter Meta Description"}
            setInputText={(value) => setMetaDescription(value)}
            value={metaDescription}
            minLength={length_Two}
            Isrequired={true}
          />
        </div>
        <div className="col-auto d-flex justify-content-center">
          <div className="col-auto d-flex justify-content-center mt-4">
            <button
              type="submit"
              className={`btn mb-3 submitbtn ${
                validations() ? "btn-secondary" : "btn-primary"
              }`}
              disabled={validations()}
            >
              Submit
            </button>
          </div>
        </div>
      </form>
    </div>
  );
}

export default AddResourcesPages;
