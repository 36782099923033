import React, { useEffect, useState } from "react";
import "../../App.css";
import PageTitle from "../../Module/pageTitle";
import InputField from "../../Component/inputField";
import { useDispatch } from "react-redux";
import {
  getExamListNew,
  getParentCourseNew,
  setParentCourse,
} from "../../Action/courseAction";

import CustomDropdown from "../../Component/customDropdown";
import LabelField from "../../Component/labelField";
import { length_Two } from "../../Utils/validators";
import {
  deleteFAQ,
  getFAQList,
  getFAQType,
  updateFAQ,
} from "../../Action/seoAction";
import { useSelector } from "react-redux";
import AdminTable from "../../Component/adminTable";
import TextEditor from "../DataEntryPanel/CreateQuestion/textEditor";
import { getSectionTypeList, getSubExam } from "../../Action/adminPanelAction";

function EditDeleteExamStreamFAQ() {
  const dispatch = useDispatch();
  const [examName, setExamName] = useState("");
  const [subExamName, setSubExamName] = useState("");
  const [examStream, setExamStream] = useState("");
  const [examStreamList, setExamStreamList] = useState([]);
  const [examList, setExamList] = useState([]);
  const [subExamList, setSubExamList] = useState([]);
  const [resetExam, setResetExam] = useState(false);
  const [resetSubExam, setResetSubExam] = useState(false);
  const [resetExamStream, setResetExamStream] = useState(false);
  const [typeList, setTypeList] = useState([]);
  const [FAQtypeList, setFAQtypeList] = useState([]);
  const [FAQType, setFAQType] = useState("");
  const [resetFAQType, setresetFAQType] = useState(false);
  const [question, setquestion] = useState("");
  const [answer, setanswer] = useState("");
  const [FAQID, setFAQID] = useState("");

  const { isEditMode } = useSelector((state) => ({
    isEditMode: state.courseReducer?.isEditMode,
  }));
  const [FAQArr, setFAQArr] = useState([]);

  const onChangeExam = (value) => {
    setExamName(value);
    setResetExam(false);
    setResetSubExam(true);
    setResetExamStream(true);
    setSubExamName("");
    setExamStream("");
    setExamStreamList([]);
    setSubExamList([]);
    setresetFAQType(true);
    dispatch(
      getSubExam(
        {
          examTypeId: value._id,
        },
        (res) => {
          if (res.data.length !== 0) setSubExamList(res.data);
          else
            dispatch(
              getParentCourseNew(`${value._id}`, (res) => {
                setExamStreamList(res);
              })
            );
        }
      )
    );
  };
  const onChangeSubExam = (value) => {
    setSubExamName(value);
    setResetSubExam(false);
    setExamStream("");
    setResetSubExam(true);
    setExamStreamList([]);
    dispatch(
      getParentCourseNew(`${examName._id}/${value._id}`, (res) => {
        setExamStreamList(res);
      })
    );
  };

  const onChangeExamStream = (value) => {
    setExamStream(value);
    setResetExamStream(false);
    setresetFAQType(true);
  };

  const onChangeFAQType = (value) => {
    setFAQType(value);
    setresetFAQType(false);
    fetchData(value?._id);
  };

  useEffect(() => {
    dispatch(
      getExamListNew((res) => {
        setExamList(res);
      })
    );
    dispatch(
      getSectionTypeList((data) => {
        setTypeList(data);
      })
    );
    getFAQTypeList();
  }, []);

  const handleSubmit = async (e) => {
    e.preventDefault();
    const data = {
      examTypeId: examName._id,
      baseCourseId: examStream._id,
      faqTypeId: FAQType?._id,
      question,
      answer,
    };
    if (subExamName?._id) {
      data["subExamTypeId"] = subExamName._id;
    }
    dispatch(
      updateFAQ(data, FAQID, () => {
        dispatch(setParentCourse("isEditMode", false));
        fetchData(FAQType?._id);
      })
    );
  };

  const validations = () => {
    return answer === "" || question === "";
  };

  const getFAQTypeList = () => {
    dispatch(
      getFAQType("", (res) => {
        setFAQtypeList(res);
      })
    );
  };
  const renderEditMode = () => (
    <form className="" onSubmit={handleSubmit}>
      <div className="col-7">
        <InputField
          labelName={"Question"}
          placeholder={"Enter Question"}
          setInputText={(value) => setquestion(value)}
          value={question}
          minLength={length_Two}
          Isrequired={true}
        />
        <div className="d-flex mb-2 align-items-center">
          <LabelField labelName={"Answer"} />
          <div className="editor_content mb-2">
            <TextEditor
              setInputText={(value) => setanswer(value)}
              value={answer}
            />
          </div>
        </div>
      </div>
      <div className="col-auto d-flex justify-content-center">
        <div className="col-auto d-flex justify-content-center mt-4">
          <button
            type="submit"
            className={`btn mb-3 submitbtn ${
              validations() ? "btn-secondary" : "btn-primary"
            }`}
            disabled={validations()}
          >
            Submit
          </button>
        </div>
      </div>
    </form>
  );

  const getData = (data) => {
    setquestion(data?.question);
    setanswer(data?.answer);
    setFAQID(data?._id);
    dispatch(setParentCourse("isEditMode", true));
  };

  const renderListMode = () => {
    return (
      <AdminTable
        pagename={"courseFAQ"}
        tableData={FAQArr}
        tableHead={["S.N", "Question", "Answer", "Actions"]}
        courseTypeList={typeList ? typeList : []}
        handleEdit={(data) => getData(data)}
        handleDelete={(data) => handleDelete(data)}
        edit={true}
      />
    );
  };

  const handleDelete = (value) => {
    dispatch(
      deleteFAQ(`${value?._id}`, () => {
        dispatch(setParentCourse("isEditMode", false));
        fetchData(FAQType?._id);
      })
    );
  };

  const fetchData = (FAQTypeId) => {
    const data = {
      examTypeId: examName._id,
      baseCourseId: examStream._id,
      faqTypeId: FAQTypeId,
    };
    if (subExamName?._id) {
      data["subExamTypeId"] = subExamName._id;
    }
    dispatch(
      getFAQList(data, (res) => {
        setFAQArr(res?.data);
      })
    );
  };

  return (
    <>
      <div className="contentBox">
        <PageTitle
          name={"EDIT DELETE EXAM STREAM FAQ"}
          isEditMode={isEditMode}
          onClick={() => dispatch(setParentCourse("isEditMode", false))}
        />
        <div className="col-auto formWidth">
          <div className="col-7">
            <div className="d-flex mb-2 align-items-center">
              <LabelField labelName={"Exam Name"} />
              <CustomDropdown
                setInputText={(value) => onChangeExam(value)}
                value={examName}
                defaultValue={examName._id}
                options={examList}
                labelName="Select Exam Name"
                reset={resetExam}
                Isrequired={true}
                disabled={isEditMode}
              />
            </div>
            {subExamList.length > 0 && (
              <div className="d-flex mb-2 align-items-center">
                <LabelField labelName={"Sub Exam Name"} />
                <CustomDropdown
                  setInputText={(value) => onChangeSubExam(value)}
                  defaultValue={subExamName._id}
                  options={subExamList}
                  labelName="Select Sub Exam Name"
                  reset={resetSubExam}
                  Isrequired={true}
                  disabled={isEditMode}
                />
              </div>
            )}
            <div className="d-flex mb-2 align-items-center">
              <LabelField labelName={"Exam Stream"} />
              <CustomDropdown
                setInputText={(value) => onChangeExamStream(value)}
                defaultValue={examStream._id}
                options={examStreamList}
                labelName="Select Exam Name"
                reset={resetExamStream}
                Isrequired={true}
                disabled={isEditMode}
              />
            </div>
            <div className="d-flex mb-2 align-items-center">
              <LabelField labelName={"FAQ Type"} />
              <CustomDropdown
                setInputText={(value) => onChangeFAQType(value)}
                // defaultValue={FAQType._id}
                options={FAQtypeList}
                labelName="Select FAQ Type"
                Isrequired={true}
                type="type"
                reset={resetFAQType}
                disabled={isEditMode}
              />
            </div>
          </div>
          {!isEditMode ? renderListMode() : renderEditMode()}
        </div>
      </div>
    </>
  );
}

export default EditDeleteExamStreamFAQ;
