import React from "react";
import Loader from "react-js-loader";

const Loading = () => {
  return (
    <>
      <Loader
        type="spinner-circle"
        bgColor={"#3f51b5"}
        title={"spinner-circle"}
        color={"#FFFFFF"}
        size={80}
      />
    </>
  );
};

export default Loading;
