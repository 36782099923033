import React from "react";
import { useSelector } from "react-redux";
import { io } from "socket.io-client";
import ChatRTMP from "./ChatRTMP";

const ChatPage = () => {
  const { streamKey } = useSelector((state) => ({
    streamKey: state?.Auth?.streamKey,
  }));

  const socket = io.connect('https://stagingbackend.ifasonline.com', {
    secure: true,
    query: {
      room: streamKey
    },
    withCredentials: true
  });

  return (
      <ChatRTMP socket={socket}/>
  );
};

export default ChatPage;
