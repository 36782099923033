import "../../App.css";
import React, { Suspense } from "react";
import Sidebar from "../../Module/sidebar";
import { Route, Routes } from "react-router-dom";
import Loading from "../../Component/Loading";
import { InfraPanelSidebarData } from "../../Module/infraPanelSidebarData";
import Chat from "../TeacherPanel/chat";

const CreateIssue = React.lazy(() => import("../InfraPanel/createIssue"));
const EditDeleteIssue = React.lazy(() =>
  import("../InfraPanel/editDeleteIssue")
);
const InfraIssue = React.lazy(() => import("../InfraPanel/infraIssue"));
const AddAsset = React.lazy(() => import("./Asset/addAsset"));
const GetAsset = React.lazy(() => import("./Asset/getAsset"));
function InfraPanelDashboard() {
  return (
    <div className="AppBox">
      <Sidebar data={InfraPanelSidebarData} />
      <div className="dashboard">
        {
          <Suspense
            fallback={
              <div className="border p-1">
                <Loading />
              </div>
            }
          >
            <Routes>
              <Route path="/" element={<CreateIssue />} />
              <Route path="createIssue" element={<CreateIssue />} />
              <Route path="editDeleteIssue" element={<EditDeleteIssue />} />
              <Route path="infraIssue" element={<InfraIssue />} />
              <Route path="/Chat" element={<Chat />} />
              <Route path="/addAsset" element={<AddAsset />} />
              <Route path="/getAsset" element={<GetAsset />} />
            </Routes>
          </Suspense>
        }
      </div>
    </div>
  );
}

export default InfraPanelDashboard;
