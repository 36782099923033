/* eslint-disable no-case-declarations */
/* eslint-disable no-unused-vars */
import { CourseReducerModal } from "../Modals";
import * as Actions from "../Types/courseTypes";
const courseReducer = (state = new CourseReducerModal(), action) => {
  switch (action.type) {
    case Actions.SET:
      return {
        ...state,
        ...action.payload,
      };
    case Actions.REMOVE:
      const leftCourses = state.parentCourse.filter(
        (c) => c._id !== action.payload.state
      );
      return {
        ...state,
        parentCourse: leftCourses,
      };
    case Actions.UPDATE:
      const unmodifiedCourse = state.parentCourse.find(
        (c) => c._id === action.payload.state.id
      );

      return {
        ...state,
        // ...action.payload,
      };
    default:
      return state;
  }
};

export default courseReducer;
