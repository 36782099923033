import React, { useEffect, useState } from "react";
import "../../App.css";
import PageTitle from "../../Module/pageTitle";
import InputField from "../../Component/inputField";
import { useDispatch } from "react-redux";
import {
  getExamListNew,
  getTeacherByExam,
  uploadExamBanner,
  addExamDetails,
} from "../../Action/courseAction";

import CustomDropdown from "../../Component/customDropdown";
import LabelField from "../../Component/labelField";
import { pdf_size_1MB, validateIsFileImageType } from "../../Utils/validators";
import TextEditor from "../DataEntryPanel/CreateQuestion/textEditor";
import { showErrorSnackbar } from "../../Action/snackbarAction";
import { validateIsFileImageTypeErrMsg } from "../../Utils/errorMsg";
import ImagePickerAndViewer from "../../Component/imagePickerAndViewer";
import DateTime from "../../Component/dateTime";
import { getSubExam } from "../../Action/adminPanelAction";

function AddExamDetails() {
  const dispatch = useDispatch();
  const [examName, setExamName] = useState("");
  const [examList, setExamList] = useState([]);
  const [resetExam, setResetExam] = useState(false);
  const [heading, setHeading] = useState("");
  const [packageBanner, setPackageBanner] = useState("");
  const [selectedImage, setSelectedImage] = useState("");
  const [teacherList, setTeacherList] = useState([]);
  const [teacherName, setTeacherName] = useState("");
  const [resetTeacherName, setResetTeacherName] = useState(false);
  const [teacherURL, setTeacherURL] = useState("");
  const [updateDate, setUpdateDate] = useState("");
  const [examDetails, setExamDetails] = useState("");
  const [subExamList, setSubExamList] = useState([]);
  const [subExamName, setSubExamName] = useState("");
  const [resetSubExam, setResetSubExam] = useState(false);
  const onChangeExam = (value) => {
    setExamName(value);
    setResetExam(false);
    setResetTeacherName(true);
    setResetSubExam(true);
    setTeacherList([]);
    setSubExamList([]);
    setTeacherName("");
    dispatch(
      getTeacherByExam({ examTypeId: value?._id }, (res) => {
        setTeacherList(res);
      })
    );
    dispatch(
      getSubExam(
        {
          examTypeId: value._id,
        },
        (res) => {
          if (res.data.length !== 0) setSubExamList(res.data);
        }
      )
    );
  };

  const onChangeSubExam = (value) => {
    setSubExamName(value);
    setResetSubExam(false);
  };

  useEffect(() => {
    dispatch(
      getExamListNew((res) => {
        setExamList(res);
      })
    );
  }, []);

  const resetForm = () => {
    setResetExam(true);
    setExamName("");
    setHeading("");
    setPackageBanner("");
    setSelectedImage("");
    setResetTeacherName(true);
    setTeacherName("");
    setTeacherURL("");
    setUpdateDate("");
    setExamDetails("");
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (selectedImage) {
      dispatch(
        uploadExamBanner(selectedImage, (imageRes) => {
          const postData = {
            examTypeId: examName?._id,
            updatedDateByTeacher: updateDate,
            teacherId: teacherName?._id,
            headingBanner: imageRes?.file,
            teacherURL: teacherURL,
            heading: heading,
            details: examDetails,
          };
          if (subExamName?._id) {
            postData["subExamTypeId"] = subExamName._id;
          }
          dispatch(
            addExamDetails(postData, () => {
              resetForm();
            })
          );
        })
      );
    }
  };

  const validations = () => {
    return (
      examName?._id === "" ||
      heading === "" ||
      packageBanner === "" ||
      teacherName === "" ||
      teacherURL === "" ||
      examDetails === "" ||
      updateDate === ""
    );
  };

  const handleBannerChange = (e) => {
    e.preventDefault();
    const reader = new FileReader();
    const file = e.target.files[0];
    if (!validateIsFileImageType(e)) {
      dispatch(showErrorSnackbar(validateIsFileImageTypeErrMsg));
      return;
    } else if (file.size > pdf_size_1MB) {
      dispatch(showErrorSnackbar("File size should be less than 1 MB!!!"));
    } else {
      reader.onloadend = () => {
        setPackageBanner(reader.result);
        setSelectedImage(file);
      };
      reader.readAsDataURL(file);
    }
  };
  const onTeacherNameChange = (value) => {
    setTeacherName(value);
    setResetTeacherName(false);
  };
  return (
    <div className="contentBox">
      <PageTitle name={"ADD EXAM DETAILS"} />
      <div className="formDiv">
        <form className="formWidth" onSubmit={handleSubmit}>
          <div className="col-8">
            <div className="d-flex mb-2 align-items-center">
              <LabelField labelName={"Exam Name"} />
              <CustomDropdown
                setInputText={(value) => onChangeExam(value)}
                value={examName}
                defaultValue={examName._id}
                options={examList}
                labelName="Select Exam Name"
                reset={resetExam}
                Isrequired={true}
              />
            </div>

            {subExamList.length > 0 && (
              <div className="d-flex mb-2 align-items-center">
                <LabelField labelName={"Sub Exam Name"} />
                <CustomDropdown
                  setInputText={(value) => onChangeSubExam(value)}
                  defaultValue={subExamName._id}
                  options={subExamList}
                  labelName="Select Sub Exam Name"
                  reset={resetSubExam}
                  Isrequired={true}
                />
              </div>
            )}
            <InputField
              labelName={"Heading"}
              placeholder={"Enter Heading"}
              setInputText={(value) => setHeading(value)}
              value={heading}
              Isrequired={true}
            />
            <div className="d-flex mb-2 align-items-center">
              <ImagePickerAndViewer
                labelName={"Banner Image"}
                value={packageBanner}
                handleChange={(e) => handleBannerChange(e)}
                htmlFor={"photo-upload"}
              />
            </div>
            <div className="d-flex mb-2 align-items-center">
              <LabelField labelName={"Teacher Name"} />
              <CustomDropdown
                labelName={"select Teacher Name"}
                setInputText={(value) => onTeacherNameChange(value)}
                defaultValue={teacherName._id}
                options={teacherList}
                reset={resetTeacherName}
                Isrequired={true}
              />
            </div>
            <InputField
              labelName={"Teacher URL"}
              placeholder={"Enter Teacher URL"}
              setInputText={(value) => setTeacherURL(value)}
              value={teacherURL}
              Isrequired={true}
            />

            <div className=" d-flex mb-2 align-items-center">
              <LabelField labelName={"Update Date"} />
              <DateTime
                setInputText={(value) => {
                  setUpdateDate(value);
                }}
                Isrequired={true}
              />
            </div>
            <div className="d-flex mb-2 align-items-center">
              <LabelField labelName={"Exam Details"} />
              <div className="editor_content mb-2">
                <TextEditor
                  setInputText={(value) => setExamDetails(value)}
                  value={examDetails}
                />
              </div>
              <span className="required ms-2 ">*</span>
            </div>
          </div>
          <div className="col-auto d-flex justify-content-center">
            <div className="col-auto d-flex justify-content-center mt-4">
              <button
                type="submit"
                className={`btn mb-3 submitbtn ${
                  validations() ? "btn-secondary" : "btn-primary"
                }`}
                disabled={validations()}
              >
                Submit
              </button>
            </div>
          </div>
        </form>
      </div>
    </div>
  );
}

export default AddExamDetails;
