import React, { useEffect, useState } from "react";
import "../../App.css";
import PageTitle from "../../Module/pageTitle";
import InputField from "../../Component/inputField";
import { useDispatch } from "react-redux";
import { getParentCourseNew, getExamListNew } from "../../Action/courseAction";

import CustomDropdown from "../../Component/customDropdown";
import LabelField from "../../Component/labelField";
import { length_Two } from "../../Utils/validators";
import {
  addResourceSEO,
  getResourcePage,
  getResourceSEO,
} from "../../Action/seoAction";
import { getSubExam } from "../../Action/adminPanelAction";

function Addresourcesseo() {
  const dispatch = useDispatch();
  const [metaTitle, setMetaTitle] = useState("");
  const [metaDescription, setMetaDescription] = useState("");
  const [examName, setExamName] = useState("");
  const [subExamName, setSubExamName] = useState("");
  const [examStream, setExamStream] = useState("");
  const [examStreamList, setExamStreamList] = useState([]);
  const [examList, setExamList] = useState([]);
  const [subExamList, setSubExamList] = useState([]);
  const [resetExam, setResetExam] = useState(false);
  const [resetSubExam, setResetSubExam] = useState(false);
  const [resetExamStream, setResetExamStream] = useState(false);
  const [resource, setResource] = useState("");
  const [resourceList, setResourceList] = useState([]);
  const [resetResource, setResetResource] = useState(false);

  const onChangeExam = (value) => {
    setExamName(value);
    setResetExam(false);
    setResetSubExam(true);
    setResetExamStream(true);
    setResetResource(true);
    setSubExamName("");
    setExamStream("");
    setExamStreamList([]);
    setSubExamList([]);
    setMetaTitle("");
    setMetaDescription("");
    dispatch(
      getSubExam(
        {
          examTypeId: value._id,
        },
        (res) => {
          if (res.data.length !== 0) setSubExamList(res.data);
          else
            dispatch(
              getParentCourseNew(`${value._id}`, (res) => {
                setExamStreamList(res);
              })
            );
        }
      )
    );
  };
  const onChangeSubExam = (value) => {
    setSubExamName(value);
    setResetSubExam(false);
    setResetExamStream(true);
    setExamStream("");
    setResetResource(true);
    setExamStreamList([]);
    setMetaTitle("");
    setMetaDescription("");
    dispatch(
      getParentCourseNew(`${examName._id}/${value._id}`, (res) => {
        setExamStreamList(res);
      })
    );
  };

  const onChangeExamStream = (value) => {
    setExamStream(value);
    setResetExamStream(false);
    setResetResource(true);
    setMetaTitle("");
    setMetaDescription("");
  };
  const onChangeResource = (value) => {
    setResource(value);
    setResetResource(false);
    setMetaTitle("");
    setMetaDescription("");
    const data = {
      examTypeId: examName._id,
      baseCourseId: examStream._id,
      pageId: value._id,
    };
    if (subExamName?._id) data["subExamTypeId"] = subExamName._id;
    dispatch(
      getResourceSEO(data, (res) => {
        setMetaTitle(res?.data?.metaTitle);
        setMetaDescription(res?.data?.metaDescription);
      })
    );
  };

  useEffect(() => {
    dispatch(
      getExamListNew((res) => {
        setExamList(res);
      })
    );

    dispatch(
      getResourcePage("", (res) => {
        setResourceList(res?.data);
      })
    );
  }, []);

  const handleSubmit = async (e) => {
    e.preventDefault();
    const data = {
      examTypeId: examName._id,
      metaTitle,
      metaDescription: metaDescription,
      baseCourseId: examStream._id,
      pageId: resource._id,
    };
    if (subExamName?._id) data["subExamTypeId"] = subExamName._id;
    dispatch(
      addResourceSEO(data, () => {
        setMetaTitle("");
        setMetaDescription("");
        setExamName("");
        setResetExam(true);
        setResetSubExam(true);
        setSubExamName("");
        setExamStreamList([]);
        setExamStream("");
        setResetExamStream(true);
        setResetResource(true);
      })
    );
  };

  const validations = () => {
    return (
      examStream?._id === "" ||
      examName?._id === "" ||
      metaTitle === "" ||
      metaDescription === "" ||
      resource === ""
    );
  };

  return (
    <div className="contentBox">
      <PageTitle name={"ADD SEO RESOURSES"} />
      <form className="formWidth" onSubmit={handleSubmit}>
        <div className="col-7">
          <div className="d-flex mb-2 align-items-center">
            <LabelField labelName={"Exam Name"} />
            <CustomDropdown
              setInputText={(value) => onChangeExam(value)}
              value={examName}
              defaultValue={examName._id}
              options={examList}
              labelName="Select Exam Name"
              reset={resetExam}
              Isrequired={true}
            />
          </div>
          {subExamList.length > 0 && (
            <div className="d-flex mb-2 align-items-center">
              <LabelField labelName={"Sub Exam Name"} />
              <CustomDropdown
                setInputText={(value) => onChangeSubExam(value)}
                defaultValue={subExamName._id}
                options={subExamList}
                labelName="Select Sub Exam Name"
                reset={resetSubExam}
                Isrequired={true}
              />
            </div>
          )}
          <div className="d-flex mb-2 align-items-center">
            <LabelField labelName={"Exam Stream"} />
            <CustomDropdown
              setInputText={(value) => onChangeExamStream(value)}
              defaultValue={examStream._id}
              options={examStreamList}
              labelName="Select Exam Name"
              reset={resetExamStream}
              Isrequired={true}
            />
          </div>
          <div className="d-flex mb-2 align-items-center">
            <LabelField labelName={"Resource Pages"} />
            <CustomDropdown
              setInputText={(value) => onChangeResource(value)}
              // defaultValue={resource._id}
              options={resourceList}
              labelName="Select Resource Page"
              Isrequired={true}
              reset={resetResource}
            />
          </div>
          <InputField
            key={"metaTitle"}
            labelName={"Meta Title"}
            placeholder={"Enter Meta Title"}
            setInputText={(value) => setMetaTitle(value)}
            value={metaTitle}
            minLength={length_Two}
            Isrequired={true}
          />
          <InputField
            key={"metaDescription"}
            labelName={"Meta Description"}
            placeholder={"Enter Meta Description"}
            setInputText={(value) => setMetaDescription(value)}
            value={metaDescription}
            minLength={length_Two}
            Isrequired={true}
          />
        </div>
        <div className="col-auto d-flex justify-content-center">
          <div className="col-auto d-flex justify-content-center mt-4">
            <button
              type="submit"
              className={`btn mb-3 submitbtn ${
                validations() ? "btn-secondary" : "btn-primary"
              }`}
              disabled={validations()}
            >
              Submit
            </button>
          </div>
        </div>
      </form>
    </div>
  );
}

export default Addresourcesseo;
