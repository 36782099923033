import React, { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import "../../App.css";
import PageTitle from "../../Module/pageTitle";
import {
  getExamListNew,
  getParentCourseNew,
  questionIssueList,
  updateQuestionIssue,
} from "../../Action/courseAction";
import LabelField from "../../Component/labelField";
import CustomDropdown from "../../Component/customDropdown";
import moment from "moment";
import DatePicker from "../../Component/datePicker";
import PaginationView from "../../Component/paginationView";
import usePagination from "../../Component/usePagination";
import Pagination from "@mui/material/Pagination";
import PaginationTable from "../../Component/paginationTable";
import { getTableDataSlots } from "../../Utils/dataConstants";
import CheckIcon from "@mui/icons-material/Check";
import { getSubExam } from "../../Action/adminPanelAction";

function QuestionDoubt() {
  const dispatch = useDispatch();
  //! value
  const [examName, setExamName] = useState("");
  const [subExamName, setSubExamName] = useState("");
  const [parentCourseVal, setParentCourseVal] = useState("");
  //! list
  const [examList, setExamList] = useState([]);
  const [subExamList, setSubExamList] = useState([]);
  const [parentCourseList, setParentCourseList] = useState([]);
  //!reset
  const [resetExam, setResetExam] = useState(false);
  const [resetSubExam, setResetSubExam] = useState(false);
  const [resetParentCourse, setResetParentCourse] = useState(false);
  //! other state
  const [startDate, setStartDate] = useState(
    moment.utc(new Date()).format("YYYY-MM-DD")
  );
  const [endDate, setEndDate] = useState(
    moment.utc(new Date()).format("YYYY-MM-DD")
  );

  const [questionDoubtList, setQuestionDoubtList] = useState(false);
  const [selectedStatus, setSelectedStatus] = useState(1);
  const [isViewMode, setIsViewMode] = useState(false);
  const [viewData, setViewData] = useState({});

  // pagination
  const [count, setCount] = useState(0);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [currentPage, setCurrentPage] = useState(1);
  const totalPages = Math.ceil(count / rowsPerPage);
  const _DATA = usePagination(count, rowsPerPage);

  const handleChange = (e, page) => {
    onPageChange(page, rowsPerPage, 1);
    setPage(page - 1);
    setCurrentPage(page);
    _DATA.jump(page);
  };

  const handleChangeRowsPerPage = (event) => {
    const value = event.target.value;
    if (value === "All") {
      setRowsPerPage(-1);
      setPage(0);
      setCurrentPage(1);
      return;
    }
    setRowsPerPage(parseInt(value, 10));
    setPage(0);
    setCurrentPage(1);
    onPageChange(page, value, 2);
  };

  const onPageChange = (page, rowsPerPage, flag) => {
    /************ page change****************** */
    let data = "";
    if (flag === 1) {
      data = {
        skip: (page - 1) * rowsPerPage,
        limit: rowsPerPage,
        startDate,
        endDate,
        status: selectedStatus === 2 ? selectedStatus + 1 : selectedStatus,
      };
      if (subExamName) {
        data.subExamTypeId = subExamName?._id;
      }
      if (parentCourseVal) {
        data.baseCourseId = parentCourseVal?._id;
      }
      if (examName) {
        data.examTypeId = examName?._id;
      }
    } else if (flag === 2) {
      data = {
        skip: 0,
        limit: rowsPerPage,
        startDate,
        endDate,
        status: selectedStatus === 2 ? selectedStatus + 1 : selectedStatus,
      };
      if (subExamName) {
        data.subExamTypeId = subExamName?._id;
      }
      if (parentCourseVal) {
        data.baseCourseId = parentCourseVal?._id;
      }
      if (examName) {
        data.examTypeId = examName?._id;
      }
    } else {
      data = {
        skip: 0,
        limit: count,
        startDate,
        endDate,
        status: selectedStatus === 2 ? selectedStatus + 1 : selectedStatus,
      };
      if (subExamName) {
        data.subExamTypeId = subExamName?._id;
      }
      if (parentCourseVal) {
        data.baseCourseId = parentCourseVal?._id;
      }
      if (examName) {
        data.examTypeId = examName?._id;
      }
    }
    dispatch(
      questionIssueList(data, (res) => {
        setCount(res?.count);
        setQuestionDoubtList(res?.exam);
      })
    );
  };
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
    setCurrentPage(newPage + 1);
  };
  const showData = (e, val) => {
    setSelectedStatus(val);
    getAllIssueList(val);
  };

  const getAllIssueList = (val) => {
    const postData = {
      skip: 0,
      limit: rowsPerPage,
      startDate,
      endDate,
      status: val,
    };
    if (subExamName) {
      postData.subExamTypeId = subExamName?._id;
    }
    if (parentCourseVal) {
      postData.baseCourseId = parentCourseVal?._id;
    }
    if (examName) {
      postData.examTypeId = examName?._id;
    }

    dispatch(
      questionIssueList(postData, (res) => {
        setCount(res?.count);
        setQuestionDoubtList(res?.exam);
      })
    );
  };

  useEffect(() => {
    dispatch(
      getExamListNew((res) => {
        setExamList(res);
      })
    );
    const postData = {
      skip: 0,
      limit: rowsPerPage,
      startDate,
      endDate,
      status: selectedStatus === 2 ? selectedStatus + 1 : selectedStatus,
    };

    dispatch(
      questionIssueList(postData, (res) => {
        setCount(res?.count);
        setQuestionDoubtList(res?.exam);
      })
    );
  }, []);

  const changeParentCourseVal = (value) => {
    setParentCourseVal(value);
    setResetParentCourse(false);
  };

  const onChangeExam = (value) => {
    setExamName(value);
    setResetExam(false);
    setParentCourseVal("");
    setSubExamList([]);
    setResetSubExam(true);
    setParentCourseList([]);
    setResetParentCourse(true);
    dispatch(
      getSubExam(
        {
          examTypeId: value._id,
        },
        (res) => {
          if (res.data.length > 0) setSubExamList(res.data);
          else
            dispatch(
              getParentCourseNew(`${value._id}`, (res) => {
                setParentCourseList(res);
              })
            );
        }
      )
    );
  };

  const onChangeSubExam = (value) => {
    setSubExamName(value);
    setResetSubExam(false);
    setParentCourseVal("");
    setParentCourseList([]);
    setResetParentCourse(true);
    dispatch(
      getParentCourseNew(`${examName._id}/${value._id}`, (res) => {
        setParentCourseList(res);
      })
    );
  };

  const submitData = async (e) => {
    e.preventDefault();
    const postData = {
      skip: 0,
      limit: rowsPerPage,
      startDate,
      endDate,
      status: selectedStatus,
    };
    if (subExamName) {
      postData.subExamTypeId = subExamName?._id;
    }
    if (parentCourseVal) {
      postData.baseCourseId = parentCourseVal?._id;
    }
    if (examName) {
      postData.examTypeId = examName?._id;
    }

    dispatch(
      questionIssueList(postData, (res) => {
        setCount(res?.count);
        setQuestionDoubtList(res?.exam);
      })
    );
  };

  const changeIntoIst = (date) => {
    date = new Date(date);
    date.setHours(date.getHours() + 5);
    date.setMinutes(date.getMinutes() + 30);
    return date;
  };

  const handleIssue = (val, key) => {
    let postData = {};
    if (key === "resolved") {
      postData.status = 2;
    } else if (key === "noIssue") {
      postData.status = 3;
    }
    dispatch(
      updateQuestionIssue(postData, val?._id, () => {
        getAllIssueList(1);
        setIsViewMode(false);
      })
    );
  };

  //!render components
  const renderSearch = () => {
    return (
      <form onSubmit={submitData}>
        <div className="d-flex pt-2 justify-content-between flex-wrap">
          <div className="d-flex align-items-center mb-2 col-4 pe-4">
            <LabelField labelName={"Exam Name:"} />
            <CustomDropdown
              setInputText={(value) => onChangeExam(value)}
              value={examName}
              options={examList}
              labelName="Select Exam Name"
              reset={resetExam}
            />
          </div>
          {subExamList?.length > 0 && (
            <div className="d-flex align-items-center mb-2 col-4 pe-4">
              <LabelField labelName={"Sub Exam Name:"} />
              <CustomDropdown
                setInputText={(value) => onChangeSubExam(value)}
                value={subExamName}
                options={subExamList}
                labelName="Select Sub Exam Name"
                reset={resetSubExam}
              />
            </div>
          )}
          <div className="d-flex align-items-center mb-2 col-4 pe-4">
            <LabelField labelName={"Exam Stream:"} />
            <CustomDropdown
              setInputText={(value) => changeParentCourseVal(value)}
              value={parentCourseVal}
              options={parentCourseList}
              labelName="Select Exam Stream"
              reset={resetParentCourse}
            />
          </div>
          <div className="d-flex align-items-center mb-2 col-4 pe-4"></div>
        </div>
        <div className="d-flex pt-2 justify-content-between flex-wrap">
          <div className="d-flex align-items-center mb-2 col-4 pe-4">
            <LabelField labelName={"Start Date"} isRequired={true} />
            {/* <label className="fw-bold me-4">Start Date:</label> */}
            <DatePicker
              setInputText={(value) => {
                setStartDate(value);
              }}
              value={startDate}
            />
          </div>
          <div className="d-flex align-items-center mb-2 col-4 pe-4">
            <LabelField labelName={"End Date"} isRequired={true} />
            {/* <label className="fw-bold me-4">End Date:</label> */}
            <DatePicker
              setInputText={(value) => setEndDate(value)}
              value={endDate}
            />
          </div>

          <div className=" col-4" >
            <button type="submit" className="btn btn-primary submitbtn">
              Search
            </button>
          </div>
        </div>
      </form>
    );
  };
  const renderList = () => {
    return (
      <div>
        <div className="d-flex paginationdiv justify-content-end align-items-center">
          <Pagination
            count={totalPages}
            size="large"
            page={currentPage}
            variant="outlined"
            shape="rounded"
            color={"primary"}
            onChange={handleChange}
          />
        </div>
        <PaginationView
          tableData={questionDoubtList ? questionDoubtList : []}
          tabs={["New Issue", "Resolved"]}
          onTabChange={(e, index) => showData(e, index)}
          selectedIndex={selectedStatus}
          page={page}
          rowsPerPage={rowsPerPage}
          handleChangePage={handleChangePage}
          handleChangeRowsPerPage={handleChangeRowsPerPage}
          rowsPerPageOptions={getTableDataSlots(count)}
        />
        <PaginationTable
          tableData={questionDoubtList ? questionDoubtList : []}
          pagename={"questionDoubt"}
          rowsPerPage={rowsPerPage}
          page={page}
          tableHead={[
            "S.N",
            "Test Name",
            "Exam Name",
            "Sub Exam",
            "Exam Stream",
            "Question Number",
            "Raised By",
            "Raised Date",
            "Action",
          ]}
          handleEdit={(val) => {
            setViewData(val);
            setIsViewMode(true);
          }}
          edit={true}
        />
      </div>
    );
  };
  const renderView = () => {
    return (
      <div>
        <div className="row d-flex justify-content-between font14 border p-3 mx-5 mt-4">
          <div className="col-md-1 text-center">
            <h6 className="fw-bold">Test Name</h6>
            <div className="text-break">
              {viewData?.examTypeId?.name ? viewData?.examTypeId?.name : "-"}
            </div>
          </div>
          <div className="col-md-1 text-center">
            <h6 className="fw-bold">Exam Name</h6>
            <div className=" text-break">
              {viewData?.examTypeId?.name ? viewData?.examTypeId?.name : "-"}
            </div>
          </div>
          <div className="col-md-1 text-center">
            <h6 className="fw-bold">Sub Exam</h6>
            <div className="text-break">
              {viewData?.subExamTypeId?.name
                ? viewData?.subExamTypeId?.name
                : "-"}
            </div>
          </div>
          <div className="col-md-3 text-center">
            <h6 className="fw-bold">Exam Stream</h6>
            <div className="text-break">
              {viewData?.baseCourseId?.name
                ? viewData?.baseCourseId?.name
                : "-"}
            </div>
          </div>
          <div className="col-md-1 text-center">
            <h6 className="fw-bold">Question Number</h6>
            <div className="text-break">
              {viewData?.questionNo ? viewData?.questionNo : "-"}
            </div>
          </div>
          <div className="col-md-2 text-center">
            <h6 className="fw-bold">Raised By</h6>
            <div className="text-break">
              {`${viewData?.userId?.firstName} ${viewData?.userId?.lastName}`}
            </div>
          </div>
          <div className="col-md-3 text-center">
            <h6 className="fw-bold">Raised Date</h6>
            <div className="text-break">
              {moment(changeIntoIst(viewData?.createdAt)).format(
                "DD-MM-YYYY HH:MM"
              )}
            </div>
          </div>
        </div>
        {renderViewList()}
      </div>
    );
  };
  const renderViewList = () => {
    return (
      <div
        className="col-md-11 mt-3 ms-5"
        style={{ borderRadius: 2, boxShadow: "0px 0px 2px 0px", padding: 30 }}
      >
        <div>
          <LabelField style={{ fontSize: 13 }} labelName={"Question Details"} />
        </div>
        <div className="d-flex mt-3">
          <div
            style={{
              width: "40px",
              height: "40px",
              backgroundColor: "#B5B2B2",
              borderRadius: 5,
              opacity: 1,
              color: "#ffffff",
              justifyContent: "center",
              alignItems: "center",
              display: "flex",
              fontWeight: "bold",
              fontSize: 16,
            }}
          >
            1
          </div>
          <div className="align-items-center justify-content-center d-flex ms-5">
            {viewData?.questionId?.en?.title[0]?.descType === "img" ? (
              <img
                className="qusimg"
                src={viewData?.questionId?.en?.title[0]?.description}
                alt="Description"
              />
            ) : (
              <h6>
                {viewData?.questionId?._id
                  ? viewData?.questionId?.en?.title[0]?.description
                  : "-"}
              </h6>
            )}
          </div>
        </div>
        <div className="d-flex mt-3">
          <div>
            <LabelField
              style={{ fontSize: 14, color: "#016FE9" }}
              labelName={"Options"}
            />
          </div>
          <div>
            {viewData?.questionId?.en?.options?.map((item) => (
              <>
                {item?.descType === "img" ? (
                  <div
                    className="d-flex"
                    style={{
                      color: item?.isCorrect === true ? "#158803" : "#000000",
                      fontSize: 14,
                    }}
                  >
                    {item?.isCorrect === true ? (
                      <span className="mt-3 me-2">
                        <CheckIcon />
                      </span>
                    ) : (
                      <span></span>
                    )}
                    <span className="me-2 mt-3">
                      {item?.QO_OPTIONS_NUMBER}.
                    </span>

                    <img
                      className="qusimg"
                      src={item?.description}
                      alt="Description"
                    />
                  </div>
                ) : (
                  <>
                    <h6
                      style={{
                        color: item?.isCorrect === true ? "#158803" : "#000000",
                        fontSize: 14,
                      }}
                    >{`${item?.QO_OPTIONS_NUMBER}. ${item?.description}`}</h6>
                  </>
                )}
              </>
            ))}
          </div>
        </div>
        {/* <div className="">
          <LabelField
            style={{ fontSize: 13, color: "#158803" }}
            labelName={"Answer Description"}
          />
          <div
            style={{
              borderRadius: 2,
              border: "0.2px solid blue ",
              padding: 15,
            }}
          >
            <h6>
              {viewData?.questionId?.en?.options?.map((item) => (
                <>
                  {item?.descType === "img" ? (
                    <>
                      {item?.isCorrect === true ? (
                        <>
                          <img
                            className="qusimg"
                            src={item?.description}
                            alt="Description"
                          />
                        </>
                      ) : null}
                    </>
                  ) : (
                    <h6 style={{ fontSize: 13, color: "#016FE9" }}>
                      {item?.isCorrect === true ? item?.description : null}
                    </h6>
                  )}
                </>
              ))}
            </h6>
          </div>
        </div> */}
        <div className="d-flex mt-4">
          <LabelField
            style={{ fontSize: 14, color: "#016FE9" }}
            labelName={"Student Option Selected: "}
          />
          <div className="ms-2 fs-6">
            <span>{viewData?.issueType}</span>
          </div>
        </div>
        <div className="">
          <LabelField
            style={{ fontSize: 13, color: "#016FE9" }}
            labelName={"Student Remark"}
          />
          <div
            style={{
              borderRadius: 2,
              border: "0.2px solid blue ",
              padding: 15,
            }}
          >
            <h6>{viewData?.remark}</h6>
          </div>
        </div>
        {viewData?.status === 1 && (
          <div
            className="d-flex justify-content-center"
            style={{ marginTop: 20 }}
          >
            <button
              onClick={() => handleIssue(viewData, "resolved")}
              type="submit"
              className="btn mb-3"
              style={{
                marginRight: 20,
                backgroundColor: "#158833",
                color: "#ffffff",
              }}
            >
              Issue Resolved
            </button>
            <button
              onClick={() => handleIssue(viewData, "noIssue")}
              type="submit"
              className="btn mb-3"
              style={{ backgroundColor: "#158833", color: "#ffffff" }}
            >
              No Issue Found
            </button>
          </div>
        )}
      </div>
    );
  };

  const handleBackBtn = () => {
    setIsViewMode(false);
    setViewData({});
  };

  return (
    <div className="contentBox">
      <PageTitle
        name={"QUESTION DOUBT"}
        isEditMode={isViewMode}
        onClick={handleBackBtn}
      />

      {isViewMode ? (
        <>{renderView()}</>
      ) : (
        <div className="formDiv contentScrollbar">
          <div className="col-12 formWidth">
            {renderSearch()}
            {renderList()}
          </div>
        </div>
      )}
    </div>
  );
}

export default QuestionDoubt;
