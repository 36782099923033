import services from "../Constant/service";
import endpoint from "../Constant/endpoint";
import { showSuccessSnackbar } from "./snackbarAction";
import { showErrorSnackbar } from "./snackbarAction";
import { SET, UPDATE } from "../Types/courseTypes";
import { updateValues } from "./authAction";

export const setParentCourse = (key, value) => (dispatch) => {
  dispatch({
    type: SET,
    payload: {
      [key]: value,
    },
  });
};

export const modifyParentCourse = (key, value) => (dispatch) => {
  dispatch({
    type: UPDATE,
    payload: {
      [key]: value,
    },
  });
};

//******************************************************
// Exam Name
// *****************************************************
export const CreateNewExam = (data, callback) => async (dispatch) => {
  dispatch(updateValues("loader", true));
  services.postApiCall(
    endpoint.exam.createExam,
    data,
    (res) => {
      dispatch(updateValues("loader", false));
      if (res?.statusCode === services.status_code.success) {
        dispatch(showSuccessSnackbar(res?.msg));
        callback(res?.data?.data);
      } else {
        dispatch(showErrorSnackbar(res?.msg));
      }
    },
    (error) => {
      dispatch(
        showErrorSnackbar(error?.data?.msg ? error?.data?.msg : error?.msg)
      );
      dispatch(updateValues("loader", false));
    }
  );
};

export const uploadExamLogo = (data, callback) => async (dispatch) => {
  dispatch(updateValues("loader", true));
  let formData = new FormData();
  formData.append("file", data);
  services.postUploadMedia(
    endpoint.exam.uploadExamLogo,
    formData,
    (res) => {
      dispatch(updateValues("loader", false));
      if (res?.statusCode === services.status_code.success) {
        callback(res.data);
      } else {
        dispatch(showErrorSnackbar(res?.msg));
      }
    },
    (error) => {
      dispatch(
        showErrorSnackbar(error?.data?.msg ? error?.data?.msg : error?.msg)
      );
      dispatch(updateValues("loader", false));
    }
  );
};

export const getExamWithState = (callback) => async (dispatch) => {
  dispatch(updateValues("loader", true));
  services.getApiCall(
    endpoint.exam.getExamWithState,
    "",
    (res) => {
      dispatch(updateValues("loader", false));
      if (res?.data?.statusCode === services.status_code.success) {
        // dispatch(showSuccessSnackbar(res?.data?.msg));
        callback(res?.data?.data);
      } else {
        dispatch(showErrorSnackbar(res?.msg));
      }
    },
    (error) => {
      dispatch(
        showErrorSnackbar(error?.data?.msg ? error?.data?.msg : error?.msg)
      );
      dispatch(updateValues("loader", false));
    }
  );
};

export const UpdateExamNew = (examId, data, callback) => async (dispatch) => {
  dispatch(updateValues("loader", true));
  services.patchApiCall(
    endpoint.exam.updateExamNew + examId,
    data,
    (res) => {
      dispatch(updateValues("loader", false));
      if (res?.data?.statusCode === services.status_code.success) {
        dispatch(showSuccessSnackbar(res?.data?.msg));
        callback(res);
      } else {
        if (res?.code) {
          dispatch(showErrorSnackbar(res?.response?.data?.msg));
        } else {
          dispatch(showErrorSnackbar(res?.data?.msg));
        }
      }
    },
    (error) => {
      dispatch(
        showErrorSnackbar(error?.data?.msg ? error?.data?.msg : error?.msg)
      );
      dispatch(updateValues("loader", false));
    }
  );
};

export const deleteExam = (data, callback) => async (dispatch) => {
  dispatch(updateValues("loader", true));
  services.deleteApiCall(
    endpoint.exam.deleteExam,
    data,
    (res) => {
      dispatch(updateValues("loader", false));
      if (res?.data?.statusCode === services.status_code.success) {
        dispatch(showSuccessSnackbar(res?.data?.msg));
        callback(res);
      } else {
        dispatch(showErrorSnackbar(res?.data?.msg));
      }
    },
    (error) => {
      dispatch(
        showErrorSnackbar(error?.data?.msg ? error?.data?.msg : error?.msg)
      );
      dispatch(updateValues("loader", false));
    }
  );
};

export const getExam = (callback) => async (dispatch) => {
  dispatch(updateValues("loader", true));
  services.getApiCall(
    endpoint.exam.getExam,
    "",
    (res) => {
      dispatch(updateValues("loader", false));
      if (res?.data?.statusCode === services.status_code.success) {
        callback(res?.data?.data);
      } else {
        dispatch(showErrorSnackbar(res?.msg));
      }
    },
    (error) => {
      dispatch(
        showErrorSnackbar(error?.data?.msg ? error?.data?.msg : error?.msg)
      );
      dispatch(updateValues("loader", false));
    }
  );
};

// *****************************************************
// Sub Exam
// *****************************************************

export const addSubExam = (data, callback) => async (dispatch) => {
  dispatch(updateValues("loader", true));
  services.postApiCall(
    endpoint.subExam.addSubExam,
    data,
    (res) => {
      dispatch(updateValues("loader", false));
      if (res?.statusCode === services.status_code.success) {
        dispatch(showSuccessSnackbar(res?.msg));
        callback(res);
      } else {
        if (res?.msgCode === "511") {
          dispatch(
            showErrorSnackbar("subExamNames contains a duplicate value")
          );
        } else if (res?.msgCode === "717") {
          dispatch(showErrorSnackbar(res?.msg));
        } else {
          dispatch(showErrorSnackbar(res?.data?.msg));
        }
      }
    },
    (error) => {
      dispatch(
        showErrorSnackbar(error?.data?.msg ? error?.data?.msg : error?.msg)
      );
      dispatch(updateValues("loader", false));
    }
  );
};

export const getSubExamNew = (data, callback) => async (dispatch) => {
  dispatch(updateValues("loader", true));
  services.postApiCall(
    endpoint.subExam.getSubExamNew,
    data,
    (res) => {
      dispatch(updateValues("loader", false));
      if (res?.statusCode === services.status_code.success) {
        callback(res);
      } else {
        dispatch(showErrorSnackbar(res?.msg));
      }
    },
    (error) => {
      dispatch(
        showErrorSnackbar(error?.data?.msg ? error?.data?.msg : error?.msg)
      );
      dispatch(updateValues("loader", false));
    }
  );
};

export const deleteSubExam = (data, callback) => async (dispatch) => {
  dispatch(updateValues("loader", true));
  services.deleteApiCall(
    endpoint.subExam.deleteSubExam + data,
    "",
    (res) => {
      dispatch(updateValues("loader", false));
      if (res?.data?.statusCode === services.status_code.success) {
        dispatch(showSuccessSnackbar(res?.data?.msg));
        callback(res);
      } else {
        dispatch(showErrorSnackbar(res?.data?.msg));
      }
    },
    (error) => {
      dispatch(
        showErrorSnackbar(error?.data?.msg ? error?.data?.msg : error?.msg)
      );
      dispatch(updateValues("loader", false));
    }
  );
};

export const updateSubExamNew = (data, id, callback) => async (dispatch) => {
  dispatch(updateValues("loader", true));
  services.patchApiCall(
    endpoint.subExam.updateSubExamNew + id,
    data,
    (res) => {
      dispatch(updateValues("loader", false));
      if (res?.data?.statusCode === services.status_code.success) {
        dispatch(showSuccessSnackbar(res?.data?.msg));
        callback(res?.data?.data);
      } else {
        if (res?.code) {
          dispatch(showErrorSnackbar(res?.response?.data?.msg));
        } else {
          dispatch(showErrorSnackbar(res?.data?.msg));
        }
      }
    },
    (error) => {
      dispatch(
        showErrorSnackbar(error?.data?.msg ? error?.data?.msg : error?.msg)
      );
      dispatch(updateValues("loader", false));
    }
  );
};

export const getSubExamListNew = (callback) => async (dispatch) => {
  dispatch(updateValues("loader", true));
  services.getApiCall(
    endpoint.subExam.getSubExamListNew,
    "",
    (res) => {
      dispatch(updateValues("loader", false));
      if (res?.data?.statusCode === services.status_code.success) {
        callback(res?.data?.data);
      } else {
        dispatch(showErrorSnackbar(res?.msg));
      }
    },
    (error) => {
      dispatch(
        showErrorSnackbar(error?.data?.msg ? error?.data?.msg : error?.msg)
      );
      dispatch(updateValues("loader", false));
    }
  );
};

export const getSubExam = (data, callback) => async (dispatch) => {
  dispatch(updateValues("loader", true));
  services.postApiCall(
    endpoint.subExam.getSubExam,
    data,
    (res) => {
      dispatch(updateValues("loader", false));
      if (res?.statusCode === services.status_code.success) {
        callback(res);
      } else {
        dispatch(showErrorSnackbar(res?.msg));
      }
    },
    (error) => {
      dispatch(
        showErrorSnackbar(error?.data?.msg ? error?.data?.msg : error?.msg)
      );
      dispatch(updateValues("loader", false));
    }
  );
};

export const getSubExamTeacher = (data, callback) => async (dispatch) => {
  dispatch(updateValues("loader", true));
  services.postApiCall(
    endpoint.subExam.getSubExam,
    data,
    (res) => {
      dispatch(updateValues("loader", false));
      if (res?.statusCode === services.status_code.success) {
        callback(res);
      } else {
        dispatch(showErrorSnackbar(res?.msg));
      }
    },
    (error) => {
      dispatch(
        showErrorSnackbar(error?.data?.msg ? error?.data?.msg : error?.msg)
      );
      dispatch(updateValues("loader", false));
    }
  );
};

// *****************************************************
// Exam Stream
// *****************************************************
export const addExamStream = (data, callback) => async (dispatch) => {
  dispatch(updateValues("loader", true));
  services.postApiCall(
    endpoint.examStream.addExamStream,
    data,
    (res) => {
      dispatch(updateValues("loader", false));
      if (res?.statusCode === services.status_code.success) {
        dispatch(showSuccessSnackbar(res?.msg));
        callback(res);
      } else {
        dispatch(showErrorSnackbar(res?.msg));
      }
    },
    (error) => {
      dispatch(
        showErrorSnackbar(error?.data?.msg ? error?.data?.msg : error?.msg)
      );
      dispatch(updateValues("loader", false));
    }
  );
};

export const uploadExamStreamLogo = (data, callback) => async (dispatch) => {
  dispatch(updateValues("loader", true));
  let formData = new FormData();
  formData.append("file", data);
  services.postUploadMedia(
    endpoint.examStream.uploadExamStreamLogo,
    formData,
    (res) => {
      dispatch(updateValues("loader", false));
      if (res?.statusCode === services.status_code.success) {
        // dispatch(showSuccessSnackbar(res?.msg));
        callback(res.data);
      } else {
        dispatch(showErrorSnackbar(res?.msg));
      }
    },
    (error) => {
      dispatch(
        showErrorSnackbar(error?.data?.msg ? error?.data?.msg : error?.msg)
      );
      dispatch(updateValues("loader", false));
    }
  );
};

export const getExamStream = (callback) => async (dispatch) => {
  dispatch(updateValues("loader", true));
  services.getApiCall(
    endpoint.examStream.getExamStream,
    "",
    (res) => {
      dispatch(updateValues("loader", false));
      if (res?.data?.statusCode === services.status_code.success) {
        callback(res?.data?.data);
      } else {
        dispatch(showErrorSnackbar(res?.msg));
      }
    },
    (error) => {
      dispatch(
        showErrorSnackbar(error?.data?.msg ? error?.data?.msg : error?.msg)
      );
      dispatch(updateValues("loader", false));
    }
  );
};

export const updateExamStream = (data, id, callback) => async (dispatch) => {
  dispatch(updateValues("loader", true));
  services.patchApiCall(
    endpoint.examStream.updateExamStream + id,
    data,
    (res) => {
      dispatch(updateValues("loader", false));
      if (res?.data?.statusCode === services.status_code.success) {
        dispatch(showSuccessSnackbar(res?.data?.msg));
        callback(res);
      } else {
        if (res?.code) {
          dispatch(showErrorSnackbar(res?.response?.data?.msg));
        } else {
          dispatch(showErrorSnackbar(res?.data?.msg));
        }
      }
    },
    (error) => {
      dispatch(
        showErrorSnackbar(error?.data?.msg ? error?.data?.msg : error?.msg)
      );
      dispatch(updateValues("loader", false));
    }
  );
};

export const deleteExamStream = (data, callback) => async (dispatch) => {
  dispatch(updateValues("loader", true));
  services.deleteApiCall(
    endpoint.examStream.deleteExamStream,
    data,
    (res) => {
      dispatch(updateValues("loader", false));
      if (res?.data?.statusCode === services.status_code.success) {
        dispatch(showSuccessSnackbar(res?.data?.msg));
        callback(res);
      } else {
        dispatch(showErrorSnackbar(res?.data?.msg));
      }
    },
    (error) => {
      dispatch(
        showErrorSnackbar(error?.data?.msg ? error?.data?.msg : error?.msg)
      );
      dispatch(updateValues("loader", false));
    }
  );
};

// *****************************************************
// Language
// *****************************************************

export const uploadLanguageLogo = (data, callback) => async (dispatch) => {
  dispatch(updateValues("loader", true));
  let formData = new FormData();
  formData.append("file", data);
  services.postUploadMedia(
    endpoint.language.uploadLanguageLogo,
    formData,
    (res) => {
      dispatch(updateValues("loader", false));
      if (res?.statusCode === services.status_code.success) {
        callback(res.data);
      } else {
        dispatch(showErrorSnackbar(res?.msg));
      }
    },
    (error) => {
      dispatch(
        showErrorSnackbar(error?.data?.msg ? error?.data?.msg : error?.msg)
      );
      dispatch(updateValues("loader", false));
    }
  );
};

export const addLanguage = (data, callback) => async (dispatch) => {
  dispatch(updateValues("loader", true));
  services.postApiCall(
    endpoint.language.addLanguage,
    data,
    (res) => {
      dispatch(updateValues("loader", false));
      if (res?.statusCode === services.status_code.success) {
        dispatch(showSuccessSnackbar(res?.msg));
        callback(res);
      } else {
        dispatch(showErrorSnackbar(res?.msg));
      }
    },
    (error) => {
      dispatch(
        showErrorSnackbar(error?.data?.msg ? error?.data?.msg : error?.msg)
      );
      dispatch(updateValues("loader", false));
    }
  );
};

export const getLanguage = (callback) => async (dispatch) => {
  dispatch(updateValues("loader", true));
  services.getApiCall(
    endpoint.language.getLanguage,
    "",
    (res) => {
      dispatch(updateValues("loader", false));
      if (res?.data?.statusCode === services.status_code.success) {
        callback(res);
      } else {
        dispatch(showErrorSnackbar(res?.msg));
      }
    },
    (error) => {
      dispatch(
        showErrorSnackbar(error?.data?.msg ? error?.data?.msg : error?.msg)
      );
      dispatch(updateValues("loader", false));
    }
  );
};

export const deleteLanguage = (data, callback) => async (dispatch) => {
  dispatch(updateValues("loader", true));
  services.deleteApiCall(
    endpoint.language.deleteLanguage,
    data,
    (res) => {
      dispatch(updateValues("loader", false));
      if (res?.data?.statusCode === services.status_code.success) {
        dispatch(showSuccessSnackbar(res?.data?.msg));
        callback(res);
      } else {
        dispatch(showErrorSnackbar(res?.data?.msg));
      }
    },
    (error) => {
      dispatch(
        showErrorSnackbar(error?.data?.msg ? error?.data?.msg : error?.msg)
      );
      dispatch(updateValues("loader", false));
    }
  );
};

export const updateLanguage = (data, id, callback) => async (dispatch) => {
  dispatch(updateValues("loader", true));
  services.patchApiCall(
    endpoint.language.updateLanguage + id,
    data,
    (res) => {
      dispatch(updateValues("loader", false));
      if (res?.data?.statusCode === services.status_code.success) {
        dispatch(showSuccessSnackbar(res?.data?.msg));
        callback(res);
      } else {
        if (res?.code) {
          dispatch(showErrorSnackbar(res?.response?.data?.msg));
        } else {
          dispatch(showErrorSnackbar(res?.data?.msg));
        }
      }
    },
    (error) => {
      dispatch(
        showErrorSnackbar(error?.data?.msg ? error?.data?.msg : error?.msg)
      );
      dispatch(updateValues("loader", false));
    }
  );
};

// *****************************************************
// Sub Category
// *****************************************************

export const uploadSubCategoryIcon = (data, callback) => async (dispatch) => {
  dispatch(updateValues("loader", true));
  let formData = new FormData();
  formData.append("file", data);
  services.postUploadMedia(
    endpoint.subCategory.uploadSubcategoryIcon,
    formData,
    (res) => {
      dispatch(updateValues("loader", false));
      if (res?.statusCode === services.status_code.success) {
        callback(res?.data);
      } else {
        dispatch(showErrorSnackbar(res?.msg));
      }
    },
    (error) => {
      dispatch(
        showErrorSnackbar(error?.data?.msg ? error?.data?.msg : error?.msg)
      );
      dispatch(updateValues("loader", false));
    }
  );
};

export const addSubCategory = (data, callback) => async (dispatch) => {
  dispatch(updateValues("loader", true));
  services.postApiCall(
    endpoint.subCategory.addSubCategory,
    data,
    (res) => {
      dispatch(updateValues("loader", false));
      if (res?.statusCode === services.status_code.success) {
        dispatch(showSuccessSnackbar(res?.msg));
        callback(res);
      } else {
        dispatch(showErrorSnackbar(res?.msg));
      }
    },
    (error) => {
      dispatch(
        showErrorSnackbar(error?.data?.msg ? error?.data?.msg : error?.msg)
      );
      dispatch(updateValues("loader", false));
    }
  );
};

export const getSubCategory = (data, callback) => async (dispatch) => {
  dispatch(updateValues("loader", true));
  services.getApiCall(
    endpoint.subCategory.getSubCategory,
    data,
    (res) => {
      dispatch(updateValues("loader", false));
      if (res?.data?.statusCode === services.status_code.success) {
        callback(res?.data);
      } else {
        dispatch(showErrorSnackbar(res?.data?.msg));
      }
    },
    (error) => {
      dispatch(
        showErrorSnackbar(error?.data?.msg ? error?.data?.msg : error?.msg)
      );
      dispatch(updateValues("loader", false));
    }
  );
};
export const deleteSubCategory = (data, callback) => async (dispatch) => {
  dispatch(updateValues("loader", true));
  services.deleteApiCall(
    endpoint.subCategory.deleteSubCategory,
    data,
    (res) => {
      dispatch(updateValues("loader", false));
      if (res?.data?.statusCode === services.status_code.success) {
        dispatch(showSuccessSnackbar(res?.data?.msg));
        callback(res);
      } else {
        dispatch(showErrorSnackbar(res?.data?.msg));
      }
    },
    (error) => {
      dispatch(
        showErrorSnackbar(error?.data?.msg ? error?.data?.msg : error?.msg)
      );
      dispatch(updateValues("loader", false));
    }
  );
};

export const updateSubCategory = (data, id, callback) => async (dispatch) => {
  dispatch(updateValues("loader", true));
  services.patchApiCall(
    endpoint.subCategory.uploadSubcategory + id,
    data,
    (res) => {
      dispatch(updateValues("loader", false));
      if (res?.data?.statusCode === services.status_code.success) {
        dispatch(showSuccessSnackbar(res?.data?.msg));
        callback(res.data);
      } else {
        dispatch(showErrorSnackbar(res?.data?.msg));
      }
    },
    (error) => {
      dispatch(
        showErrorSnackbar(error?.data?.msg ? error?.data?.msg : error?.msg)
      );
      dispatch(updateValues("loader", false));
    }
  );
};

// *****************************************************
// Subject
// *****************************************************

export const AddSubject = (data, callback) => async (dispatch) => {
  dispatch(updateValues("loader", true));
  services.postApiCall(
    endpoint.subject.addSubject,
    data,
    (res) => {
      dispatch(updateValues("loader", false));
      if (res?.statusCode === services.status_code.success) {
        dispatch(showSuccessSnackbar(res?.msg));
        callback(res);
      } else {
        dispatch(showErrorSnackbar(res?.msg));
      }
    },
    (error) => {
      dispatch(
        showErrorSnackbar(error?.data?.msg ? error?.data?.msg : error?.msg)
      );
      dispatch(updateValues("loader", false));
    }
  );
};

export const getSubjectList = (data, callback) => async (dispatch) => {
  dispatch(updateValues("loader", true));
  services.postApiCall(
    endpoint.subject.getSubjectList,
    data,
    (res) => {
      dispatch(updateValues("loader", false));
      if (res?.statusCode === services.status_code.success) {
        callback(res);
      } else {
        dispatch(showErrorSnackbar(res?.msg));
      }
    },
    (error) => {
      dispatch(
        showErrorSnackbar(error?.data?.msg ? error?.data?.msg : error?.msg)
      );
      dispatch(updateValues("loader", false));
    }
  );
};

export const deleteSubjectList = (data, callback) => async (dispatch) => {
  dispatch(updateValues("loader", true));
  services.deleteApiCall(
    endpoint.subject.deleteSubjectList,
    data,
    (res) => {
      dispatch(updateValues("loader", false));
      if (res?.data?.statusCode === services.status_code.success) {
        dispatch(showSuccessSnackbar(res?.data?.msg));
        callback(res);
      } else {
        dispatch(showErrorSnackbar(res?.data?.msg));
      }
    },
    (error) => {
      dispatch(
        showErrorSnackbar(error?.data?.msg ? error?.data?.msg : error?.msg)
      );
      dispatch(updateValues("loader", false));
    }
  );
};

export const updateSubject = (data, id, callback) => async (dispatch) => {
  dispatch(updateValues("loader", true));
  services.patchApiCall(
    endpoint.subject.updateSubject + id,
    data,
    (res) => {
      dispatch(updateValues("loader", false));
      if (res?.data?.statusCode === services.status_code.success) {
        dispatch(showSuccessSnackbar(res?.data?.msg));
        callback(res);
      } else {
        dispatch(showErrorSnackbar(res?.msg));
      }
    },
    (error) => {
      dispatch(
        showErrorSnackbar(error?.data?.msg ? error?.data?.msg : error?.msg)
      );
      dispatch(updateValues("loader", false));
    }
  );
};

//  **************************************************************
//  Main Category
//  ************************************************************
export const addMainCategory = (data, callback) => async (dispatch) => {
  dispatch(updateValues("loader", true));
  services.postApiCall(
    endpoint.mainCategory.addMainCategory,
    data,
    (res) => {
      dispatch(updateValues("loader", false));
      if (res?.statusCode === services.status_code.success) {
        dispatch(showSuccessSnackbar(res?.msg));
        callback(res);
      } else {
        dispatch(showErrorSnackbar(res?.msg));
      }
    },
    (error) => {
      dispatch(
        showErrorSnackbar(error?.data?.msg ? error?.data?.msg : error?.msg)
      );
      dispatch(updateValues("loader", false));
    }
  );
};

export const getMainCategory = (data, callback) => async (dispatch) => {
  dispatch(updateValues("loader", true));
  services.postApiCall(
    endpoint.mainCategory.getMainCategory,
    data,
    (res) => {
      dispatch(updateValues("loader", false));
      if (res?.statusCode === services.status_code.success) {
        // dispatch(showSuccessSnackbar(res?.msg));
        callback(res);
      } else {
        dispatch(showErrorSnackbar(res?.data?.msg));
      }
    },
    (error) => {
      dispatch(
        showErrorSnackbar(error?.data?.msg ? error?.data?.msg : error?.msg)
      );
      dispatch(updateValues("loader", false));
    }
  );
};

export const getSubCategoryByMainCat = (data, callback) => async (dispatch) => {
  dispatch(updateValues("loader", true));
  services.getApiCall(
    endpoint.mainCategory.getSubCategoryByMainCat,
    data,
    (res) => {
      dispatch(updateValues("loader", false));
      if (res?.data?.statusCode === services.status_code.success) {
        dispatch(
          showSuccessSnackbar(res?.data?.msg ? res?.data?.msg : res?.msg)
        );
        callback(res?.data?.data);
      } else {
        callback([]);
        dispatch(showErrorSnackbar(res?.msg));
      }
    },
    (error) => {
      callback([]);
      dispatch(updateValues("loader", false));
      dispatch(
        showErrorSnackbar(error?.data?.msg ? error?.data?.msg : error?.msg)
      );
    }
  );
};

export const deleteMainCategory =
  (data, callback, errorCallback) => async (dispatch) => {
    dispatch(updateValues("loader", true));
    services.deleteApiCall(
      endpoint.mainCategory.deleteMainCategory,
      data,
      (res) => {
        dispatch(updateValues("loader", false));
        if (res?.data?.statusCode === services.status_code.success) {
          dispatch(showSuccessSnackbar(res?.data?.msg));
          callback(res);
        } else {
          errorCallback(res);
          dispatch(showErrorSnackbar(res?.data?.msg));
        }
      },
      (error) => {
        dispatch(
          showErrorSnackbar(error?.data?.msg ? error?.data?.msg : error?.msg)
        );
        dispatch(updateValues("loader", false));
        errorCallback(error);
      }
    );
  };

export const updateMainCategory =
  (data, id, callback, errorCallback) => async (dispatch) => {
    dispatch(updateValues("loader", true));
    services.patchApiCall(
      endpoint.mainCategory.updateMainCategory + id,
      data,
      (res) => {
        dispatch(updateValues("loader", false));
        if (res?.data?.statusCode === services.status_code.success) {
          dispatch(showSuccessSnackbar(res?.data?.msg));
          callback(res);
        } else {
          dispatch(showErrorSnackbar(res?.data?.msg));
        }
      },
      (error) => {
        dispatch(
          showErrorSnackbar(error?.data?.msg ? error?.data?.msg : error?.msg)
        );
        dispatch(updateValues("loader", false));
        errorCallback(error);
      }
    );
  };

// *****************************************************
// Section
// *****************************************************
export const getSectionTypeList = (callback) => async (dispatch) => {
  dispatch(updateValues("loader", true));
  services.getApiCall(
    endpoint.section.getSectionTypeList,
    "",
    (res) => {
      dispatch(updateValues("loader", false));
      if (res?.data?.statusCode === services.status_code.success) {
        callback(res?.data?.data);
      } else {
        dispatch(showErrorSnackbar(res?.msg));
      }
    },
    (error) => {
      dispatch(
        showErrorSnackbar(error?.data?.msg ? error?.data?.msg : error?.msg)
      );
      dispatch(updateValues("loader", false));
    }
  );
};

export const addSection =
  (data, callback, errorCallback) => async (dispatch) => {
    dispatch(updateValues("loader", true));
    services.postApiCall(
      endpoint.section.addSection,
      data,
      (res) => {
        dispatch(updateValues("loader", false));
        if (res?.statusCode === services.status_code.success) {
          dispatch(showSuccessSnackbar(res?.msg));
          callback(res);
        } else {
          errorCallback(res);
          dispatch(showErrorSnackbar(res?.msg));
        }
      },
      (error) => {
        dispatch(
          showErrorSnackbar(error?.data?.msg ? error?.data?.msg : error?.msg)
        );
        dispatch(updateValues("loader", false));
        errorCallback(error);
      }
    );
  };

export const deleteSection =
  (data, callback, errorCallback) => async (dispatch) => {
    dispatch(updateValues("loader", true));
    services.deleteApiCall(
      endpoint.section.deleteSection,
      data,
      (res) => {
        dispatch(updateValues("loader", false));
        if (res?.data?.statusCode === services.status_code.success) {
          dispatch(showSuccessSnackbar(res?.data?.msg));
          callback(res);
        } else {
          errorCallback(res);
          dispatch(showErrorSnackbar(res?.data?.msg));
        }
      },
      (error) => {
        dispatch(
          showErrorSnackbar(error?.data?.msg ? error?.data?.msg : error?.msg)
        );
        dispatch(updateValues("loader", false));
        errorCallback(error);
      }
    );
  };

export const updateSection =
  (data, id, callback, errorCallback) => async (dispatch) => {
    dispatch(updateValues("loader", true));
    services.patchApiCall(
      endpoint.section.updateSection + id,
      data,
      (res) => {
        dispatch(updateValues("loader", false));
        if (res?.data?.statusCode === services.status_code.success) {
          dispatch(showSuccessSnackbar(res?.data?.msg));
          callback(res);
        } else {
          errorCallback(res);
          dispatch(showErrorSnackbar(res?.msg));
        }
      },
      (error) => {
        dispatch(
          showErrorSnackbar(error?.data?.msg ? error?.data?.msg : error?.msg)
        );
        dispatch(updateValues("loader", false));
        errorCallback(error);
      }
    );
  };
