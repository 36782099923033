/* eslint-disable react/prop-types */
import moment from "moment";
import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { changeActiveStatusExamset } from "../Action/courseAction";
import "../App.css";
import ToggleSwitchBtn from "./toggleSwitchBtn";
import ReactHtmlParser from "react-html-parser";
import secondsToTimestamp from "seconds-to-timestamp";
import ImagePickerAndViewer from "./imagePickerAndViewer";
import { Link } from "react-router-dom";
import { renderCorrectAns, renderQuestions } from "../Utils/utils";
import DeletePopup from "./deletePopup";

function AdminTable({
  tableData,
  tableHead,
  handleEdit,
  handleEditBatch,
  handleViewMore,
  handleDelete,
  pagename,
  edit,
  addOnButton,
  type,
  className,
  handleAddOnButton,
  addOnButtonLabel,
  handleCheck,
  adddeleteButton,
  handleStatus,
  totalExpectDuration,
  handleRefund,
  courseTypeList,
  handleEditDuration,
}) {
  const dispatch = useDispatch();
  const [deletePopup, setDeletePopup] = useState(false);
  const [deleteData, setDeleteData] = useState({});

  const getSubCategories = (subCategories) => {
    var categoryArray = subCategories
      .map((item) => {
        return item.name;
      })
      .join(", ");
    return <td className="col-2">{categoryArray}</td>;
  };

  const getSMBookList = (courses) => {
    var BookListArray = courses
      ?.map((item) => {
        return item?.booksList
          ?.map((subItem) => {
            return subItem.name;
          })
          .join(", ");
      })
      .join(", ");
    return <td className="col-2">{BookListArray}</td>;
  };

  function getHoursAndMinutesDifference(date1, date2) {
    // Difference in milliseconds
    var difference = Math.abs(date2 - date1);
    var days = Math.floor(difference / (1000 * 60 * 60 * 24));
    // Convert milliseconds to hours and minutes
    var hours = Math.floor(difference / 3600000); // 1 hour = 3600000 milliseconds
    var minutes = Math.floor((difference % 3600000) / 60000); // 1 minute = 60000 milliseconds

    return { hours, minutes, days };
  }

  const DEFICIT = (d1, d2) => {
    const dt1 = new Date(d1);
    const dt2 = new Date(d2);
    const dataTime = getHoursAndMinutesDifference(dt1, dt2);
    const { hours, minutes } = dataTime;
    const hourUpdate = hours > 9 ? hours : "0" + hours;
    const minuteUpdate = minutes > 9 ? minutes : "0" + minutes;
    const sign = dt1 < dt2 ? "-" : "+";
    return (
      <td className="col-2">
        {sign + hourUpdate + ":" + minuteUpdate + ":00"}
      </td>
    );
  };

  const getSMBookType = (courses) => {
    var BookTypeArray = courses
      ?.map((item) => {
        return item.packageName;
      })
      .join(", ");
    return <td className="col-2">{BookTypeArray}</td>;
  };

  const getTrackingIdList = (trackingIdList) => {
    if (trackingIdList?.length > 0) {
      var TrackingIdArray = trackingIdList
        ?.map((subItem) => {
          return subItem.trackingId;
        })
        .join(", ");

      return <td className="col-2">{TrackingIdArray}</td>;
    }
  };

  const changeIntoIst = (date) => {
    date = new Date(date);
    date.setHours(date.getHours() + 5);
    date.setMinutes(date.getMinutes() + 30);
    return date;
  };

  const getBatchNames = (batchNames) => {
    var batchArray = batchNames
      .map((item) => {
        return item?.name
          ? item?.name
          : item?.fromDate
          ? item?.fromDate
          : item?.sessionDate;
      })
      .join(", ");
    return <td className="col-2">{batchArray}</td>;
  };

  const getSessions = (sessions) => {
    var filteredArray = sessions.filter((el) => {
      return el.isSelected;
    });
    var sessionArray = filteredArray
      .map((item) => {
        return item.name;
      })
      .join(", ");
    return <td className="col-2">{sessionArray}</td>;
  };

  const onstatusChanged = (event, examId) => {
    const postData = {
      examId: examId,
      isActive: event?.target?.checked ? event?.target?.checked : false,
    };
    dispatch(
      changeActiveStatusExamset(
        postData,
        () => {},
        () => {}
      )
    );
  };

  const getSeconds = (minutes) => {
    let seconds = minutes * 60;

    return <td className="col-2">{secondsToTimestamp(seconds)}</td>;
  };

  const getAuthorName = (authorList) => {
    var authorArray = authorList
      .map((item) => {
        return item;
      })
      .join(", ");
    return <td className="col-2">{authorArray}</td>;
  };

  const getExamNames = (examData) => {
    var examArray = examData
      .map((item) => {
        if (item?.subExamIds.length > 0) {
          const examName = [item?.examTypesId?.name];
          var subExamArray = item?.subExamIds
            .map((subItem) => {
              return subItem?.name;
            })
            .join(", ");
          return `${examName} - ${subExamArray}`;
        } else {
          return `${item?.examTypesId?.name}`;
        }
      })
      .join(",\n");
    return <td>{examArray}</td>;
  };

  const getDiscountPrice = (priceArray) => {
    let price = 0;
    priceArray.forEach((item) => {
      price = price + item?.couponDiscount;
    });
    return <td className="col-2">{price}</td>;
  };

  const getBatchName = (coursesList) => {
    var coursesArray = coursesList.map((item) => {
      return item?.courseData?.name ? <li>{item?.courseData?.name}</li> : null;
    });
    return <td className="col-2">{coursesArray}</td>;
  };

  const getSubscriptionMonths = (coursesList) => {
    var coursesArray = coursesList.map((item) => {
      return item?.subscriptionPlanMonths ? (
        <li>{item?.subscriptionPlanMonths}</li>
      ) : null;
    });
    return <td className="col-2">{coursesArray}</td>;
  };

  const getPrice = (coursesList) => {
    var coursesArray = coursesList.map((item) => {
      return item?.taxableAmount ? <li>{item?.taxableAmount}</li> : null;
    });
    return <td className="col-2">{coursesArray}</td>;
  };

  const getCoupon = (coursesList) => {
    var coursesArray = coursesList.map((item) => {
      return item?.couponName ? <li>{item?.couponName}</li> : null;
    });
    return <td className="col-2">{coursesArray}</td>;
  };

  const getDiscountedValue = (coursesList) => {
    var coursesArray = coursesList.map((item) => {
      return item?.couponDiscount ? <li>{item?.couponDiscount}</li> : null;
    });
    return <td className="col-2">{coursesArray}</td>;
  };

  const getTotalPrice = (priceArray) => {
    let price = 0;
    priceArray.forEach((item) => {
      price = price + item?.taxableAmount;
    });
    return <td className="col-2">{price}</td>;
  };

  return (
    <div className="container-fluid pt-3 pe-0 ps-0">
      <table className="w-100 mb-2">
        <thead>
          <tr>
            {tableHead?.map((data, index) => {
              return <th key={index}>{data}</th>;
            })}
          </tr>
        </thead>
        <tbody>
          {Array.isArray(tableData) && tableData.length ? (
            tableData?.map((data, index) => {
              return (
                <>
                  <tr key={index}>
                    {pagename === "studyMaterialOrders" ? (
                      data?._id ? (
                        <td>
                          <span>
                            <input
                              id={data?._id}
                              className="check"
                              type="checkbox"
                              checked={data.isSelected}
                              onChange={(data) => {
                                handleCheck(data, index);
                              }}
                            />
                          </span>
                        </td>
                      ) : (
                        <td>-</td>
                      )
                    ) : null}
                    <td>{index + 1}</td>

                    {pagename === "counsellorCode" ||
                    pagename === "edutechCounsellorCode" ||
                    pagename === "publicationCounsellorCode" ||
                    pagename === "copyCounsellorCode" ||
                    pagename === "counsellorCouponCode" ||
                    pagename === "publiCounsellorCode" ||
                    pagename === "publiCounsellorCouponCode" ? (
                      data?.counselorFirstName ? (
                        <td>{data?.counselorFirstName}</td>
                      ) : (
                        <td>-</td>
                      )
                    ) : null}
                    {pagename === "srcVideoData" ? (
                      <>
                        <td>
                          {data?.examTypeId ? data?.examTypeId?.name : "-"}
                        </td>
                        <td>
                          {data?.subExamTypeId
                            ? data?.subExamTypeId?.name
                            : "-"}
                        </td>
                        <td>
                          {data?.baseCourseId ? data?.baseCourseId?.name : "-"}
                        </td>
                        <td>{data?.uploadtype === 2 ? "Video" : "Image"}</td>
                        <td>
                          {data?.uploadurl
                            ? data?.uploadurl?.length > 60
                              ? `${data?.uploadurl.slice(0, 60)}..`
                              : data?.uploadurl
                            : "-"}
                        </td>
                      </>
                    ) : null}
                    {pagename === "inventoryManagement" ? (
                      <>
                        <td>{data?.company ? data?.company?.name : "-"}</td>
                        <td>{data?.asset ? data?.asset?.name : "-"}</td>
                        <td>
                          {data?.deviceModel ? data?.deviceModel?.name : "-"}
                        </td>
                        <td>
                          {data?.deviceSerialNo ? data?.deviceSerialNo : "-"}
                        </td>
                        <td>
                          {data?.purchesDate
                            ? moment(data?.purchesDate).format("DD-MM-YYYY")
                            : "-"}
                        </td>
                        <td>
                          {data?.status
                            ? data?.status === 1
                              ? "Available"
                              : data?.status === 2
                              ? "Assigned"
                              : data?.status === 3
                              ? "Damaged"
                              : data?.status === 4
                              ? "Repair"
                              : data?.status === 5
                              ? "Dead"
                              : data?.status === 6
                              ? "Office Use"
                              : data?.status === 7
                              ? "Studio Use"
                              : ""
                            : "-"}
                        </td>
                      </>
                    ) : null}
                    {pagename === "teacherLecture" ? (
                      data ? (
                        <>
                          <td>{data?.title ? data?.title : "-"}</td>
                          <td>
                            {data?.examTypeId?.name
                              ? data?.examTypeId?.name
                              : "-"}
                          </td>
                          <td>
                            {data?.subExamTypeId?.name
                              ? data?.subExamTypeId?.name
                              : "-"}
                          </td>
                          <td>
                            {data?.baseCourseId?.name
                              ? data?.baseCourseId?.name
                              : "-"}
                          </td>
                          <td>
                            {data?.teacherId?.name
                              ? data?.teacherId?.name
                              : "-"}
                          </td>
                          <td>{data?.order ? data?.order : "-"}</td>
                        </>
                      ) : (
                        <>
                          <td>-</td>
                          <td>-</td>
                          <td>-</td>
                          <td>-</td>
                          <td>-</td>
                        </>
                      )
                    ) : null}
                    {pagename === "ParentCourse" ||
                    pagename === "ExamName" ||
                    pagename === "SubExam" ||
                    pagename === "Language" ||
                    pagename === "subCatagory1" ||
                    pagename === "subCatagory2" ||
                    pagename === "subCatagory3" ||
                    pagename === "subCatagory4" ||
                    // pagename === "subject" ||
                    pagename === "books" ||
                    pagename === "assignbooks" ||
                    pagename === "maincategory" ||
                    pagename === "section" ||
                    pagename === "assignUnit" ||
                    pagename === "teacher" ||
                    pagename === "couponCode" ||
                    pagename === "edutechCounsellorCode" ||
                    pagename === "publicationCounsellorCode" ||
                    pagename === "copyCounsellorCode" ||
                    pagename === "topic" ||
                    pagename === "subTopic" ||
                    pagename === "liveRecordedVideo" ||
                    pagename === "package" ||
                    pagename === "packageExtend" ||
                    pagename === "jobType" ||
                    pagename === "counsellorCode" ||
                    pagename === "counsellorCouponCode" ||
                    pagename === "publiCounsellorCode" ||
                    pagename === "publiCounsellorCouponCode" ||
                    pagename === "SopPpt" ||
                    pagename === "referenceLeadCount" ||
                    pagename === "referenceTeamLeadCount" ||
                    pagename === "designations" ||
                    pagename === "company" ||
                    pagename === "department" ||
                    pagename === "lecture" ? (
                      data?.name ? (
                        <td>{data?.name}</td>
                      ) : (
                        <td>-</td>
                      )
                    ) : null}

                    {pagename === "subject" ? (
                      data?.name ? (
                        <td>{data?.name}</td>
                      ) : (
                        <td>-</td>
                      )
                    ) : null}

                    {pagename === "subject" || pagename === "topic" ? (
                      data?.order ? (
                        <td>{data?.order}</td>
                      ) : (
                        <td>-</td>
                      )
                    ) : null}

                    {pagename === "SubExam" ? (
                      data?.file ? (
                        <td>
                          <img
                            htmlFor="photo-upload"
                            src={data?.file}
                            style={{ width: "40px", height: "40px" }}
                            alt={""}
                          />
                        </td>
                      ) : (
                        <td>-</td>
                      )
                    ) : null}

                    {pagename === "SubExam" ? (
                      data?.seoName ? (
                        <td>{data?.seoName}</td>
                      ) : (
                        <td>-</td>
                      )
                    ) : null}
                    {pagename === "SubExam" ? (
                      data?.order != null ? (
                        <td>{data?.order}</td>
                      ) : (
                        <td>-</td>
                      )
                    ) : null}
                    {pagename === "editLivePremierVideo" ||
                    pagename === "editLiveVideo" ? (
                      data?.title ? (
                        <td>{data?.title}</td>
                      ) : (
                        <td>-</td>
                      )
                    ) : null}
                    {pagename === "editLiveFreeVideo" ? (
                      data?.lectureTitle ? (
                        <td>{data?.lectureTitle}</td>
                      ) : (
                        <td>-</td>
                      )
                    ) : null}
                    {pagename === "editLiveFreeVideo" ? (
                      data?.sessionsData ? (
                        <td>{data?.sessionsData?.name}</td>
                      ) : (
                        <td>-</td>
                      )
                    ) : null}
                    {pagename === "editLiveVideo" ||
                    pagename === "editLivePremierVideo" ? (
                      data?.sessionId ? (
                        <td>{data?.sessionId?.name}</td>
                      ) : (
                        <td>-</td>
                      )
                    ) : null}
                    {pagename === "editLivePremierVideo" ||
                    pagename === "editLiveFreeVideo" ? (
                      data?.teachersData ? (
                        <td>{data?.teachersData.name}</td>
                      ) : (
                        <td>-</td>
                      )
                    ) : null}
                    {pagename === "editLiveVideo" ? (
                      data?.teacherId ? (
                        <td>{data?.teacherId.name}</td>
                      ) : (
                        <td>-</td>
                      )
                    ) : null}
                    {pagename === "editLivePremierVideo" ||
                    pagename === "editLiveFreeVideo" ? (
                      data?.mettingId?.length > 0 ? (
                        <td>{data?.mettingId[0].email}</td>
                      ) : (
                        <td>-</td>
                      )
                    ) : null}
                    {pagename === "editLiveVideo" ? (
                      data?.mettingId ? (
                        <td>{data?.mettingId.email}</td>
                      ) : (
                        <td>-</td>
                      )
                    ) : null}
                    {pagename === "editLivePremierVideo" ||
                    pagename === "editLiveFreeVideo" ||
                    pagename === "editLiveVideo" ? (
                      type === 1 || type === 3 ? (
                        <td>{data?.isRTMPEnvironment ? "RTMP" : "BBB"}</td>
                      ) : (
                        <td>-</td>
                      )
                    ) : null}
                    {pagename === "editLiveVideo" ? (
                      data?.classSchedule ? (
                        <td>
                          {`${data?.classSchedule[0]?.fromDate} -
                            ${
                              data?.classSchedule[
                                data?.classSchedule?.length - 1
                              ]?.toDate
                            }`}
                        </td>
                      ) : (
                        <td>-</td>
                      )
                    ) : null}
                    {pagename === "editQuestionBank" ? (
                      data?.lecture?.name ? (
                        <td>{data?.lecture?.name}</td>
                      ) : (
                        <td>-</td>
                      )
                    ) : null}
                    {pagename === "editQuestion" ||
                    pagename === "editQuestionBank" ? (
                      data?.en?.title ? (
                        <td>{renderQuestions(data?.en?.title)}</td>
                      ) : (
                        <td>-</td>
                      )
                    ) : null}
                    {pagename === "editQuestion" ||
                    pagename === "editQuestionBank" ? (
                      data?.en?.options?.length > 0 && data?.range?.r1 ? (
                        <td>
                          {data?.range?.r1} {data?.range?.r2}
                        </td>
                      ) : (
                        <td className="fw-bold">
                          {renderCorrectAns(data?.en?.options)}
                        </td>
                      )
                    ) : null}
                    {pagename === "editQuestionBank" ? <td>-</td> : null}
                    {pagename === "question" || pagename === "examSet" ? (
                      data?.en || data?.name ? (
                        <td>{data?.en?.name ? data?.en?.name : data?.name}</td>
                      ) : (
                        <td>-</td>
                      )
                    ) : null}
                    {pagename === "question" || pagename === "examSet" ? (
                      data?.totalQuestions >= 0 &&
                      data?.totalAddedQuestion >= 0 ? (
                        <td>
                          {data?.totalAddedQuestion}/{data?.totalQuestions}
                        </td>
                      ) : (
                        <td>0/0</td>
                      )
                    ) : null}
                    {pagename === "question" ? (
                      data?.isSectionWise || !data?.isSectionWise ? (
                        <td>{data?.isSectionWise ? "Yes" : "No"}</td>
                      ) : (
                        <td>-</td>
                      )
                    ) : null}
                    {pagename === "package" ? (
                      data?.section ? (
                        <td>{data?.section}</td>
                      ) : (
                        <td>-</td>
                      )
                    ) : null}
                    {pagename === "packageExtend" ||
                    pagename === "packageExtendAndUpgrade" ? (
                      data?.sectionId ? (
                        <td>{data?.sectionId?.name}</td>
                      ) : (
                        <td>-</td>
                      )
                    ) : null}
                    {pagename === "teacher" ? (
                      data?.examTypesIds ? (
                        getExamNames(data?.examTypesIds)
                      ) : (
                        <td>-</td>
                      )
                    ) : null}
                    {pagename === "ParentCourse" ? (
                      data?.expertDetails && data?.expertDetails?.mobileNo ? (
                        <td>{data?.expertDetails?.mobileNo}</td>
                      ) : (
                        <td>-</td>
                      )
                    ) : null}
                    {pagename === "ParentCourse" ? (
                      data?.expertDetails && data?.expertDetails?.message ? (
                        <td>{data?.expertDetails?.message}</td>
                      ) : (
                        <td>-</td>
                      )
                    ) : null}
                    {/* {pagename === "ParentCourse" ? (
                      data?.CRMParentCourse ? (
                        <td>{data?.CRMParentCourse}</td>
                      ) : (
                        <td>-</td>
                      )
                    ) : null} */}
                    {pagename === "ParentCourse" ? (
                      data?.file ? (
                        <td>
                          <img
                            htmlFor="photo-upload"
                            src={data?.file}
                            style={{ width: "40px", height: "40px" }}
                            alt={""}
                          />
                        </td>
                      ) : (
                        <td>-</td>
                      )
                    ) : null}
                    {pagename === "ParentCourse" ? (
                      data?.seoName ? (
                        <td>{data?.seoName}</td>
                      ) : (
                        <td>-</td>
                      )
                    ) : null}
                    {pagename === "competitor" ? (
                      <>
                        <td>{data?.name}</td>
                      </>
                    ) : null}
                    {pagename === "Language" ? (
                      data?.abbreviation ? (
                        <td>{data?.abbreviation}</td>
                      ) : (
                        <td>-</td>
                      )
                    ) : null}
                    {pagename === "ExamName" ? (
                      data?.file ? (
                        <td>
                          <img
                            htmlFor="photo-upload"
                            src={data?.file}
                            style={{ width: "40px", height: "40px" }}
                            alt={""}
                          />
                        </td>
                      ) : (
                        <td>-</td>
                      )
                    ) : null}

                    {/* {pagename === "ExamName" ? (
                      data?.crmExamName ? (
                        <td>{data?.crmExamName}</td>
                      ) : (
                        <td>-</td>
                      )
                    ) : null} */}

                    {pagename === "ExamName" ? (
                      data?.seoName ? (
                        <td>{data?.seoName}</td>
                      ) : (
                        <td>-</td>
                      )
                    ) : null}

                    {pagename === "ExamName" ? (
                      data?.order != null ? (
                        <td>{data?.order}</td>
                      ) : (
                        <td>-</td>
                      )
                    ) : null}

                    {pagename === "SopPpt" ? (
                      data?.type !== 1 && data?.fullPath ? (
                        <td>
                          <img
                            htmlFor="photo-upload"
                            src={data?.fullPath}
                            style={{ width: "40px", height: "40px" }}
                            alt={""}
                          />
                        </td>
                      ) : (
                        <td className="d-flex">
                          <ImagePickerAndViewer
                            key={"pdf" + index}
                            value={data?.fullPath}
                            htmlFor={"pdf-upload"}
                            doctype={"pdf"}
                            removelabel={true}
                          />
                          <label className="label w-100">
                            {data?.file ? data?.file : ""}
                          </label>
                        </td>
                      )
                    ) : null}

                    {pagename === "Language" ||
                    pagename === "subCatagory1" ||
                    pagename === "subCatagory2" ||
                    pagename === "subCatagory3" ||
                    pagename === "subCatagory4" ? (
                      data?.icon ? (
                        <td>
                          <img
                            htmlFor="photo-upload"
                            src={data?.icon}
                            style={{ width: "30px", height: "30px" }}
                            alt={""}
                          />
                        </td>
                      ) : (
                        <td>-</td>
                      )
                    ) : null}
                    {pagename === "subCatagory1" ||
                    pagename === "subCatagory2" ||
                    pagename === "subCatagory3" ||
                    pagename === "subCatagory4" ? (
                      data?.isTaxable || !data?.isTaxable ? (
                        <td>{data?.isTaxable ? "Yes" : "No"}</td>
                      ) : (
                        <td>-</td>
                      )
                    ) : null}
                    {pagename === "subCatagory1" ? (
                      data?.time >= 0 ? (
                        <td>
                          {data?.time === 1 ? "Start Date" : "Video Duration"}
                        </td>
                      ) : (
                        <td>-</td>
                      )
                    ) : null}
                    {pagename === "subCatagory2" ||
                    pagename === "subCatagory4" ? (
                      data?.sellType >= 0 ? (
                        <td>
                          {data?.sellType === 1
                            ? "Single Book"
                            : data?.sellType === 2
                            ? "Combo Book"
                            : data?.sellType === 3
                            ? "Complete Study Material"
                            : "Without Study Material"}
                        </td>
                      ) : (
                        <td>-</td>
                      )
                    ) : null}
                    {pagename === "subCatagory1" ? (
                      data?.classType >= 0 ? (
                        <td>{data?.classType === 1 ? "Live" : "Premier"}</td>
                      ) : (
                        <td>-</td>
                      )
                    ) : null}
                    {pagename === "books" || pagename === "assignbooks" ? (
                      data?.coverImage ? (
                        <td>
                          <img
                            htmlFor="photo-upload"
                            src={data?.coverImage}
                            style={{
                              width: "50px",
                              height: "50px",
                            }}
                            alt={""}
                          />
                        </td>
                      ) : (
                        <td>-</td>
                      )
                    ) : null}
                    {pagename === "books" || pagename === "assignbooks" ? (
                      data?.price >= 0 ? (
                        <td>{data?.price}</td>
                      ) : null
                    ) : null}
                    {pagename === "books" ? (
                      <td>{data?.printingCost ? data?.printingCost : "-"}</td>
                    ) : null}
                    {pagename === "books" || pagename === "assignbooks" ? (
                      data?.author ? (
                        // <td>{data?.author}</td>
                        getAuthorName(data?.author)
                      ) : (
                        <td>-</td>
                      )
                    ) : null}
                    {pagename === "books" ||
                    pagename === "assignbooks" ||
                    pagename === "package" ||
                    pagename === "packageExtend" ? (
                      data?.language ? (
                        <td>{data?.language?.name}</td>
                      ) : (
                        <td>-</td>
                      )
                    ) : null}
                    {pagename === "books" || pagename === "assignbooks" ? (
                      data?.pdf ? (
                        <td>{data?.pdf !== "" ? "Yes" : "No"}</td>
                      ) : (
                        <td>No</td>
                      )
                    ) : null}
                    {pagename === "books" || pagename === "assignbooks" ? (
                      data?.videoUrl ? (
                        <td>{data?.videoUrl !== "" ? "Yes" : "No"}</td>
                      ) : (
                        <td>-</td>
                      )
                    ) : null}
                    {pagename === "maincategory" ? (
                      data?.isSession || !data?.isSession ? (
                        <td>{data?.isSession === true ? "Yes" : "No"}</td>
                      ) : (
                        <td>-</td>
                      )
                    ) : null}
                    {pagename === "maincategory" ? (
                      data?.hasInstallment || !data?.hasInstallment ? (
                        <td>{data?.hasInstallment === true ? "Yes" : "No"}</td>
                      ) : (
                        <td>-</td>
                      )
                    ) : null}
                    {pagename === "maincategory" ? (
                      data?.isLecture || !data?.isLecture ? (
                        <td>{data?.isLecture === true ? "Yes" : "No"}</td>
                      ) : (
                        <td>-</td>
                      )
                    ) : null}
                    {pagename === "maincategory" ? (
                      data?.primaryTypeId ? (
                        <td>{data?.primaryTypeId?.name}</td>
                      ) : (
                        <td>-</td>
                      )
                    ) : null}
                    {pagename === "maincategory" ? (
                      data?.subCategories ? (
                        getSubCategories(data?.subCategories)
                      ) : (
                        <td>-</td>
                      )
                    ) : null}
                    {pagename === "section" ||
                    pagename === "package" ||
                    pagename === "packageExtend" ? (
                      data?.order ? (
                        <td>{data?.order}</td>
                      ) : (
                        <td>-</td>
                      )
                    ) : null}
                    {pagename === "section" ? (
                      data?.type ? (
                        <td>
                          {data?.type === 1
                            ? "Course"
                            : data?.type === 2
                            ? "Study Material"
                            : data?.type === 3
                            ? "Test Series"
                            : data?.type === 4
                            ? "E-books"
                            : data?.type === 5
                            ? "Lectures"
                            : data?.type === 6
                            ? "Crash Course"
                            : data?.type === 7
                            ? "PYQ Course"
                            : data?.type === 8
                            ? "Practice Course"
                            : ""}
                        </td>
                      ) : (
                        <td>-</td>
                      )
                    ) : null}
                    {pagename === "batchType" ? (
                      data?.name ? (
                        <td>{data?.name}</td>
                      ) : (
                        <td>-</td>
                      )
                    ) : null}
                    {pagename === "bannertype" ||
                    pagename === "batchType" ||
                    pagename === "courseType" ? (
                      data?.type ? (
                        <td>{data?.type}</td>
                      ) : (
                        <td>-</td>
                      )
                    ) : null}
                    {pagename === "section" ||
                    pagename === "package" ||
                    pagename === "packageExtend" ? (
                      data?.displayOnHomePage || !data?.displayOnHomePage ? (
                        <td>
                          {data?.displayOnHomePage === true ? "Yes" : "No"}
                        </td>
                      ) : (
                        <td>-</td>
                      )
                    ) : null}
                    {pagename === "assignUnit" ? (
                      data?.bannerImage ? (
                        <td>
                          <img
                            htmlFor="photo-upload"
                            src={data?.bannerImage}
                            style={{
                              width: "40px",
                              height: "40px",
                            }}
                            alt={""}
                          />
                        </td>
                      ) : (
                        <td>-</td>
                      )
                    ) : null}
                    {pagename === "assignUnit" ? (
                      data?.languageId ? (
                        <td>{data?.languageId?.name}</td>
                      ) : (
                        <td>-</td>
                      )
                    ) : null}
                    {pagename === "assignUnit" ? (
                      data?.duration ? (
                        <td>
                          {
                            // getSeconds(data?.duration)
                            secondsToTimestamp(data?.duration)
                          }
                        </td>
                      ) : (
                        <td>-</td>
                      )
                    ) : null}
                    {pagename === "IssueType" ? (
                      data?.name ? (
                        <td>{data?.name}</td>
                      ) : (
                        <td>-</td>
                      )
                    ) : null}
                    {pagename === "IssueType" ? (
                      data?.price ? (
                        <td>{data?.price}</td>
                      ) : (
                        <td>-</td>
                      )
                    ) : null}
                    {pagename === "couponCode" ||
                    pagename === "edutechCounsellorCode" ||
                    pagename === "publicationCounsellorCode" ||
                    pagename === "copyCounsellorCode" ||
                    pagename === "stockManagement" ? (
                      data?.baseCourseId ? (
                        <td>{data?.baseCourseId?.name}</td>
                      ) : (
                        <td>-</td>
                      )
                    ) : null}
                    {pagename === "couponCode" ||
                    pagename === "edutechCounsellorCode" ||
                    pagename === "publicationCounsellorCode" ||
                    pagename === "copyCounsellorCode" ||
                    pagename === "stockManagement" ||
                    pagename === "seoExam" ||
                    pagename === "seoSubExam" ||
                    pagename === "productSchema" ? (
                      data?.examTypeId ? (
                        <td>{data?.examTypeId?.name}</td>
                      ) : (
                        <td>-</td>
                      )
                    ) : null}
                    {pagename === "couponCode" ||
                    pagename === "edutechCounsellorCode" ||
                    pagename === "publicationCounsellorCode" ||
                    pagename === "copyCounsellorCode" ||
                    pagename === "stockManagement" ||
                    pagename === "seoSubExam" ||
                    pagename === "productSchema" ? (
                      data?.subExamTypeId ? (
                        <td>{data?.subExamTypeId?.name}</td>
                      ) : (
                        <td>-</td>
                      )
                    ) : null}
                    {pagename === "seoSubExam" && courseTypeList?.length > 0 ? (
                      data?.courseType ? (
                        <>
                          <td>
                            {
                              courseTypeList.find(
                                (dataNew) => dataNew.type === data?.courseType
                              )?.name
                            }
                          </td>
                        </>
                      ) : (
                        <td>-</td>
                      )
                    ) : null}
                    {pagename === "dispatchData" ? (
                      data?._id ? (
                        <>
                          <td>
                            {data?.examTypeId?.name
                              ? data?.examTypeId?.name
                              : "-"}
                          </td>
                          <td>
                            {data?.subExamTypeId?.name
                              ? data?.subExamTypeId?.name
                              : "-"}
                          </td>
                          <td>
                            {data?.baseCourseId?.name
                              ? data?.baseCourseId?.name
                              : "-"}
                          </td>
                          <td>{data?.booksCount ? data?.booksCount : "-"}</td>
                          <td>
                            {data?.deliveryCharges
                              ? data?.deliveryCharges
                              : "-"}
                          </td>
                        </>
                      ) : null
                    ) : null}
                    {pagename === "seoExam" || pagename === "seoSubExam" ? (
                      data?.metaTitle ? (
                        <td>{data?.metaTitle}</td>
                      ) : (
                        <td>-</td>
                      )
                    ) : null}

                    {pagename === "seoExam" || pagename === "seoSubExam" ? (
                      data?.metaDescrition ? (
                        <td>{data?.metaDescrition}</td>
                      ) : (
                        <td>-</td>
                      )
                    ) : null}

                    {pagename === "seoExam" || pagename === "seoSubExam" ? (
                      data?.content ? (
                        <td>{data?.content}</td>
                      ) : (
                        <td>-</td>
                      )
                    ) : null}
                    {pagename === "productSchema" ? (
                      data?.sectionName ? (
                        <td>{data?.sectionName}</td>
                      ) : (
                        <td>-</td>
                      )
                    ) : null}
                    {pagename === "productSchema" ? (
                      data?.CourseName ? (
                        <td>{data?.CourseName}</td>
                      ) : (
                        <td>-</td>
                      )
                    ) : null}
                    {pagename === "productSchema" ? (
                      data?.rating ? (
                        <td>{data?.rating}</td>
                      ) : (
                        <td>-</td>
                      )
                    ) : null}
                    {pagename === "productSchema" ? (
                      data?.count ? (
                        <td>{data?.count}</td>
                      ) : (
                        <td>-</td>
                      )
                    ) : null}
                    {pagename === "examMapping" ? (
                      <>
                        <td>
                          {data?.toExamTypeName ? data?.toExamTypeName : "-"}
                        </td>
                        <td>
                          {data?.toBaseCourseName
                            ? data?.toBaseCourseName
                            : "-"}
                        </td>
                      </>
                    ) : null}
                    {pagename === "departmentMapping" ? (
                      <>
                        <td>
                          {data?.toExamTypeName ? data?.toExamTypeName : "-"}
                        </td>
                        <td>
                          {data?.toBaseCourseName
                            ? data?.toBaseCourseName
                            : "-"}
                        </td>
                      </>
                    ) : null}
                    {pagename === "couponCode" ||
                    pagename === "edutechCounsellorCode" ||
                    pagename === "publicationCounsellorCode" ||
                    pagename === "copyCounsellorCode" ||
                    pagename === "copyCounsellorCode" ||
                    pagename === "package" ||
                    pagename === "packageExtend" ? (
                      data?.mainCategoryId ? (
                        <td>{data?.mainCategoryId?.name}</td>
                      ) : (
                        <td>-</td>
                      )
                    ) : null}
                    {pagename === "couponCode" ||
                    pagename === "edutechCounsellorCode" ||
                    pagename === "publicationCounsellorCode" ||
                    pagename === "copyCounsellorCode" ||
                    pagename === "publiCounsellorCode" ||
                    pagename === "publiCounsellorCouponCode" ? (
                      data?.subCategoryId ? (
                        <td>{data?.subCategoryId?.name}</td>
                      ) : (
                        <td>-</td>
                      )
                    ) : null}
                    {pagename === "couponCode" ||
                    pagename === "edutechCounsellorCode" ? (
                      data?.subscriptionDurationId ? (
                        <td>{data?.subscriptionDurationId?.months}</td>
                      ) : (
                        <td>-</td>
                      )
                    ) : null}
                    {pagename === "couponCode" ||
                    pagename === "edutechCounsellorCode" ||
                    pagename === "publicationCounsellorCode" ||
                    pagename === "copyCounsellorCode" ||
                    pagename === "package" ||
                    pagename === "packageExtend" ||
                    pagename === "counsellorCode" ||
                    pagename === "counsellorCouponCode" ||
                    pagename === "publiCounsellorCode" ||
                    pagename === "publiCounsellorCouponCode" ? (
                      data?.startDate ? (
                        <td>
                          {data?.status === 0 ? (
                            <span className="fw-bold text-warning">
                              {data?.startDate.slice(0, 10)}
                            </span>
                          ) : data?.status === 1 ? (
                            <span className="fw-bold text-success">
                              {data?.startDate.slice(0, 10)}
                            </span>
                          ) : data?.status === 9 ? (
                            <span className="fw-bold text-danger">
                              {data?.startDate.slice(0, 10)}
                            </span>
                          ) : (
                            ""
                          )}
                        </td>
                      ) : (
                        <td>-</td>
                      )
                    ) : null}
                    {pagename === "couponCode" ||
                    pagename === "edutechCounsellorCode" ||
                    pagename === "publicationCounsellorCode" ||
                    pagename === "copyCounsellorCode" ||
                    pagename === "counsellorCode" ||
                    pagename === "counsellorCouponCode" ||
                    pagename === "publiCounsellorCode" ||
                    pagename === "publiCounsellorCouponCode" ? (
                      data?.endDate ? (
                        <td>
                          {data?.status === 0 ? (
                            <span className="fw-bold text-warning">
                              {data?.endDate.slice(0, 10)}
                            </span>
                          ) : data?.status === 1 ? (
                            <span className="fw-bold text-success">
                              {data?.endDate.slice(0, 10)}
                            </span>
                          ) : data?.status === 9 ? (
                            <span className="fw-bold text-danger">
                              {data?.endDate.slice(0, 10)}
                            </span>
                          ) : (
                            ""
                          )}
                        </td>
                      ) : (
                        <td>-</td>
                      )
                    ) : null}
                    {pagename === "couponCode" ||
                    pagename === "edutechCounsellorCode" ||
                    pagename === "publicationCounsellorCode" ||
                    pagename === "copyCounsellorCode" ||
                    pagename === "counsellorCode" ||
                    pagename === "counsellorCouponCode" ||
                    pagename === "publiCounsellorCode" ||
                    pagename === "publiCounsellorCouponCode" ? (
                      data?.discPer ? (
                        <td>{data?.discPer}</td>
                      ) : (
                        <td>-</td>
                      )
                    ) : null}
                    {pagename === "couponCode" ||
                    pagename === "edutechCounsellorCode" ||
                    pagename === "publicationCounsellorCode" ||
                    pagename === "copyCounsellorCode" ||
                    pagename === "counsellorCode" ||
                    pagename === "publiCounsellorCode" ? (
                      data?.status || data?.status >= 0 ? (
                        <td>
                          {data?.status === 0 ? (
                            <span className="fw-bold text-warning">
                              Deactive
                            </span>
                          ) : data?.status === 1 ? (
                            <span className="fw-bold text-success">Active</span>
                          ) : data?.status === 9 ? (
                            <span className="fw-bold text-danger">Deleted</span>
                          ) : (
                            ""
                          )}
                        </td>
                      ) : (
                        <td>-</td>
                      )
                    ) : null}
                    {pagename === "BBmeeting" ? (
                      data?.teacherId ? (
                        <td>{data?.teacherId?.name}</td>
                      ) : (
                        <td>-</td>
                      )
                    ) : null}
                    {pagename === "BBmeeting" ? (
                      data?.email ? (
                        <td>{data?.email}</td>
                      ) : (
                        <td>-</td>
                      )
                    ) : null}
                    {pagename === "BBmeeting" ? (
                      data?.accessCode ? (
                        <td>{data?.accessCode}</td>
                      ) : (
                        <td>-</td>
                      )
                    ) : null}
                    {pagename === "BBmeeting" ? (
                      data?.mettingId ? (
                        <td>{data?.mettingId}</td>
                      ) : (
                        <td>-</td>
                      )
                    ) : null}
                    {pagename === "BBmeeting" ? (
                      data?.altAccessCode ? (
                        <td>{data?.altAccessCode}</td>
                      ) : (
                        <td>-</td>
                      )
                    ) : null}
                    {pagename === "package" || pagename === "packageExtend" ? (
                      data?.status ? (
                        <td>{data?.status === 1 ? "Yes" : "No"}</td>
                      ) : (
                        <td>-</td>
                      )
                    ) : null}
                    {pagename === "youtubelive" ? (
                      data?.subjects ? (
                        <td>{data?.subjects?.name}</td>
                      ) : (
                        <td>-</td>
                      )
                    ) : null}
                    {pagename === "youtubeRecorded" ? (
                      data?.subjectId ? (
                        <td>{data?.subjectId?.name}</td>
                      ) : (
                        <td>-</td>
                      )
                    ) : null}
                    {pagename === "youtubelive" ||
                    pagename === "youtubeRecorded" ? (
                      data?.topicId ? (
                        <td>{data?.topicId?.name}</td>
                      ) : (
                        <td>-</td>
                      )
                    ) : null}
                    {pagename === "youtubeRecorded" ? (
                      data?.teacherId ? (
                        <td>{data?.teacherId?.name}</td>
                      ) : (
                        <td>-</td>
                      )
                    ) : null}
                    {pagename === "youtubelive" ? (
                      data?.banner ? (
                        <td>
                          <img
                            htmlFor="photo-upload"
                            src={data?.banner}
                            style={{
                              width: "40px",
                              height: "40px",
                            }}
                            alt={""}
                          />
                        </td>
                      ) : (
                        <td>-</td>
                      )
                    ) : null}
                    {pagename === "youtubelive" ||
                    pagename === "youtubeRecorded" ? (
                      data?.title ? (
                        <td>{data?.title}</td>
                      ) : (
                        <td>-</td>
                      )
                    ) : null}
                    {/* {pagename === "youtubeRecorded" ? (
                      data?.title ? (
                        <td>{data?.lectureTitle}</td>
                      ) : (
                        <td>-</td>
                      )
                    ) : null} */}
                    {pagename === "youtubelive" ? (
                      data?.teachers ? (
                        <td>{data?.teachers?.name}</td>
                      ) : (
                        <td>-</td>
                      )
                    ) : null}
                    {pagename === "youtubelive" ? (
                      data?.startTime ? (
                        <td>
                          {moment.utc(data?.startTime).format("DD/MM/YYYY LTS")}
                        </td>
                      ) : (
                        <td>-</td>
                      )
                    ) : null}
                    {pagename === "assignTeacher" ? (
                      data?.teacherId ? (
                        <td>{data?.teacherId?.name}</td>
                      ) : (
                        <td>-</td>
                      )
                    ) : null}
                    {pagename === "assignTeacher" ? (
                      data?.subjectdata ? (
                        <td>{data?.subjectdata[0]?.name}</td>
                      ) : (
                        <td>-</td>
                      )
                    ) : null}
                    {pagename === "examSyllabus" ? (
                      data?.subjectName ? (
                        <td>{data?.subjectName}</td>
                      ) : (
                        <td>-</td>
                      )
                    ) : null}
                    {pagename === "examSyllabus" ? (
                      data?.subjectVideo ? (
                        <td>{data?.subjectVideo}</td>
                      ) : (
                        <td>-</td>
                      )
                    ) : null}
                    {pagename === "examSyllabus" ? (
                      data?.priorityOrder || data?.priorityOrder === 0 ? (
                        <td>{data?.priorityOrder}</td>
                      ) : (
                        <td>-</td>
                      )
                    ) : null}
                    {pagename === "assignTeacher" ? (
                      data?.subjectdata[0] ? (
                        <td>{getSessions(data?.subjectdata[0]?.topicData)}</td>
                      ) : (
                        <td>-</td>
                      )
                    ) : null}
                    {pagename === "assignTeacher" ? (
                      data?.topicData ? (
                        data?.topicIdData[0]?.subtopicsData ? (
                          <td>
                            {getBatchNames(data?.topicIdData[0]?.subtopicsData)}
                          </td>
                        ) : (
                          <td>-</td>
                        )
                      ) : (
                        <td>-</td>
                      )
                    ) : null}
                    {pagename === "adBanner" ? (
                      data?.bannerTypeId ? (
                        <td>{data?.bannerTypeId?.type}</td>
                      ) : (
                        <td>-</td>
                      )
                    ) : null}
                    {pagename === "adBanner" || pagename === "jobType" ? (
                      data?.file ? (
                        <img
                          className="p-1"
                          htmlFor="photo-upload"
                          src={data?.file}
                          style={{
                            width: "50px",
                            height: "50px",
                          }}
                          alt={""}
                        />
                      ) : (
                        <td>-</td>
                      )
                    ) : null}

                    {pagename === "examSet" ? (
                      data?.order != null ? (
                        <td>{data?.order}</td>
                      ) : (
                        <td>-</td>
                      )
                    ) : null}
                    {pagename === "examSet" || pagename === "question" ? (
                      data?.isActive != null ? (
                        <td style={{ width: "5%" }} className="removelabel">
                          <ToggleSwitchBtn
                            id={data._id}
                            key={data._id}
                            isActive={data?.isActive ?? false}
                            getToggleval={(e) => onstatusChanged(e, data._id)}
                          />
                        </td>
                      ) : (
                        <td>-</td>
                      )
                    ) : null}
                    {pagename === "examSet" ? (
                      data?.duration != null ? (
                        <td>{getSeconds(data?.duration)}</td>
                      ) : (
                        <td>-</td>
                      )
                    ) : null}
                    {pagename === "whatsappShoot" ? (
                      <>
                        <td>{data?.firstName ? data?.firstName : "-"}</td>
                        <td>{data?.lastName ? data?.lastName : "-"}</td>
                        <td>{data?.examType ? data?.examType : "-"}</td>
                        <td>{data?.baseCourse ? data?.baseCourse : "-"}</td>
                        <td>
                          {data?.createdAt
                            ? moment(changeIntoIst(data?.createdAt)).format(
                                "DD-MM-YYYY HH:MM"
                              )
                            : "-"}
                        </td>
                        <td>{data?.sourceName ? data?.sourceName : "-"}</td>
                        <td>{data?.state ? data?.state : "-"}</td>
                      </>
                    ) : null}
                    {pagename === "notificationList" ? (
                      data?.courseId ? (
                        getSessions(data?.courseId)
                      ) : (
                        <td>-</td>
                      )
                    ) : null}
                    {pagename === "notificationList" ? (
                      data?.sessionId ? (
                        getSessions(data?.sessionId)
                      ) : (
                        <td>-</td>
                      )
                    ) : null}
                    {pagename === "notificationList" ? (
                      data?.notificationType ? (
                        <td>
                          {data?.notificationType === 1
                            ? "Class Schedule"
                            : data?.notificationType === 2
                            ? "Test Alert"
                            : data?.notificationType === 3
                            ? "Others"
                            : "Free User"}
                        </td>
                      ) : (
                        <td>-</td>
                      )
                    ) : null}
                    {pagename === "notificationList" ? (
                      data?.title ? (
                        <td>{data?.title}</td>
                      ) : (
                        <td>-</td>
                      )
                    ) : null}
                    {pagename === "notificationList" ? (
                      data?.notificationDate ? (
                        <td>
                          {moment
                            .utc(data?.notificationDate)
                            .format("DD/MM/YYYY LTS")}
                        </td>
                      ) : (
                        <td>-</td>
                      )
                    ) : null}
                    {pagename === "editPattern" ||
                    pagename === "stockManagement" ? (
                      data ? (
                        <td>{data?.name}</td>
                      ) : (
                        <td>-</td>
                      )
                    ) : null}
                    {pagename === "editSyllabusList" ? (
                      data ? (
                        <>
                          <td>{data?.subjectName}</td>
                          <td>
                            {data?.topicData?.map((tn, index) => {
                              return (
                                <tr
                                  key={index}
                                  style={{
                                    backgroundColor: "transparent",
                                    borderBottomWidth:
                                      tn?.topicData?.length !== index + 1
                                        ? 1
                                        : 0,
                                    display: "flex",
                                    paddingTop: 5,
                                    paddingBottom: 5,
                                  }}
                                >
                                  {tn?.topicName}
                                </tr>
                              );
                            })}
                          </td>
                          <td>
                            {data?.topicData?.map((tn) => {
                              return tn?.subTopicData?.map((std, index) => {
                                return (
                                  <tr
                                    key={index}
                                    style={{
                                      backgroundColor: "transparent",
                                      borderBottomWidth:
                                        std?.subTopicData?.length !== index + 1
                                          ? 1
                                          : 0,
                                      display: "flex",
                                      paddingTop: 5,
                                      paddingBottom: 5,
                                    }}
                                  >
                                    {std?.subTopicName}
                                  </tr>
                                );
                              });
                            })}
                          </td>
                          <td>
                            {data?.topicData?.map((tn) => {
                              return tn?.subTopicData?.map((ti) => {
                                return ti?.titleData?.map((tleData, index) => {
                                  return (
                                    <tr
                                      key={index}
                                      style={{
                                        backgroundColor: "transparent",
                                        borderBottomWidth:
                                          ti?.tleData?.length !== index + 1
                                            ? 1
                                            : 0,
                                        display: "flex",
                                        paddingTop: 5,
                                        paddingBottom: 5,
                                      }}
                                    >
                                      {tleData?.lectureTitle}
                                    </tr>
                                  );
                                });
                              });
                            })}
                          </td>
                          <td>
                            {data?.topicData?.map((tn) => {
                              return tn?.subTopicData?.map((ti) => {
                                return ti?.titleData?.map((tleData) => {
                                  return tleData?.teacherInfo.map(
                                    (tei, index) => {
                                      return (
                                        <tr
                                          key={index}
                                          style={{
                                            backgroundColor: "transparent",
                                            borderBottomWidth:
                                              tei?.teacherInfo?.length !==
                                              index + 1
                                                ? 1
                                                : 0,
                                            display: "flex",
                                            paddingTop: 5,
                                            paddingBottom: 5,
                                          }}
                                        >
                                          {tei?.preferredTeacher}
                                        </tr>
                                      );
                                    }
                                  );
                                });
                              });
                            })}
                          </td>
                          <td>
                            {data?.topicData?.map((tn) => {
                              return tn?.subTopicData?.map((ti) => {
                                return ti?.titleData?.map((tleData, index) => {
                                  return (
                                    <tr
                                      key={index}
                                      style={{
                                        backgroundColor: "transparent",
                                        borderBottomWidth:
                                          ti?.tleData?.length !== index + 1
                                            ? 1
                                            : 0,
                                        display: "flex",
                                        paddingTop: 5,
                                        paddingBottom: 5,
                                      }}
                                    >
                                      {tleData?.lectureDuration}
                                    </tr>
                                  );
                                });
                              });
                            })}
                          </td>
                          <td>
                            {data?.topicData?.map((tn) => {
                              return tn?.subTopicData?.map((ti) => {
                                return ti?.titleData?.map((tleData, index) => {
                                  return (
                                    <tr
                                      key={index}
                                      style={{
                                        backgroundColor: "transparent",
                                        borderBottomWidth:
                                          ti?.tleData?.length !== index + 1
                                            ? 1
                                            : 0,
                                        display: "flex",
                                        paddingTop: 5,
                                        paddingBottom: 5,
                                      }}
                                    >
                                      {tleData?.videoType}
                                    </tr>
                                  );
                                });
                              });
                            })}
                          </td>

                          <td>
                            {data?.topicData?.map((tn) => {
                              return tn?.subTopicData?.map((ti) => {
                                return ti?.titleData?.map((tleData) => {
                                  return (
                                    <tr
                                      key={index}
                                      style={{ backgroundColor: "transparent" }}
                                    >
                                      <span
                                        type="submit"
                                        className="text-primary fw-bold me-2 cursor"
                                        onClick={() =>
                                          handleEdit(tleData?.syllabusId)
                                        }
                                      >
                                        Edit
                                      </span>
                                      <span
                                        type="submit"
                                        className="text-danger fw-bold cursor"
                                        onClick={() =>
                                          handleDelete(tleData?.syllabusId)
                                        }
                                      >
                                        Delete
                                      </span>
                                    </tr>
                                  );
                                });
                              });
                            })}
                          </td>
                        </>
                      ) : (
                        <td>-</td>
                      )
                    ) : null}
                    {pagename === "viewSyllabusList" &&
                      (data ? (
                        <>
                          <td>{data?.subjectName}</td>
                          <td>
                            {data?.topicData?.map((tn, index) => {
                              return (
                                <tr
                                  key={index}
                                  style={{
                                    backgroundColor: "transparent",
                                    borderBottomWidth:
                                      tn?.topicData?.length !== index + 1
                                        ? 1
                                        : 0,
                                    display: "flex",
                                    paddingTop: 5,
                                    paddingBottom: 5,
                                  }}
                                >
                                  {tn?.topicName}
                                </tr>
                              );
                            })}
                          </td>
                          <td>
                            {data?.topicData?.map((tn) => {
                              return tn?.subTopicData?.map((std, index) => {
                                return (
                                  <tr
                                    key={index}
                                    style={{
                                      backgroundColor: "transparent",
                                      borderBottomWidth:
                                        std?.subTopicData?.length !== index + 1
                                          ? 1
                                          : 0,
                                      display: "flex",
                                      paddingTop: 5,
                                      paddingBottom: 5,
                                    }}
                                  >
                                    {std?.subTopicName}
                                  </tr>
                                );
                              });
                            })}
                          </td>
                          <td>
                            {data?.topicData?.map((tn) => {
                              return tn?.subTopicData?.map((ti) => {
                                return ti?.titleData?.map((tleData, index) => {
                                  return (
                                    <tr
                                      key={index}
                                      style={{
                                        backgroundColor: "transparent",
                                        borderBottomWidth:
                                          ti?.tleData?.length !== index + 1
                                            ? 1
                                            : 0,
                                        display: "flex",
                                        paddingTop: 5,
                                        paddingBottom: 5,
                                      }}
                                    >
                                      {tleData?.lectureTitle}
                                    </tr>
                                  );
                                });
                              });
                            })}
                          </td>
                          <td>
                            {data?.topicData?.map((tn) => {
                              return tn?.subTopicData?.map((ti) => {
                                return ti?.titleData?.map((tleData) => {
                                  return tleData?.teacherInfo.map(
                                    (tei, index) => {
                                      return (
                                        <tr
                                          key={index}
                                          style={{
                                            backgroundColor: "transparent",
                                            borderBottomWidth:
                                              tei?.teacherInfo?.length !==
                                              index + 1
                                                ? 1
                                                : 0,
                                            display: "flex",
                                            paddingTop: 5,
                                            paddingBottom: 5,
                                          }}
                                        >
                                          {tei?.preferredTeacher}
                                        </tr>
                                      );
                                    }
                                  );
                                });
                              });
                            })}
                          </td>
                          <td>
                            {data?.topicData?.map((tn) => {
                              return tn?.subTopicData?.map((ti) => {
                                return ti?.titleData?.map((tleData, index) => {
                                  return (
                                    <tr
                                      key={index}
                                      style={{
                                        backgroundColor: "transparent",
                                        borderBottomWidth:
                                          ti?.tleData?.length !== index + 1
                                            ? 1
                                            : 0,
                                        display: "flex",
                                        paddingTop: 5,
                                        paddingBottom: 5,
                                      }}
                                    >
                                      {tleData?.lectureDuration}
                                    </tr>
                                  );
                                });
                              });
                            })}
                          </td>
                          <td>
                            {data?.topicData?.map((tn) => {
                              return tn?.subTopicData?.map((ti) => {
                                return ti?.titleData?.map((tleData, index) => {
                                  return (
                                    <tr
                                      key={index}
                                      style={{
                                        backgroundColor: "transparent",
                                        borderBottomWidth:
                                          ti?.tleData?.length !== index + 1
                                            ? 1
                                            : 0,
                                        display: "flex",
                                        paddingTop: 5,
                                        paddingBottom: 5,
                                      }}
                                    >
                                      {tleData?.videoType}
                                    </tr>
                                  );
                                });
                              });
                            })}
                          </td>
                        </>
                      ) : (
                        <td>-</td>
                      ))}
                    {pagename === "coupon" ? (
                      data?._id ? (
                        <>
                          <td>
                            {data?.examTypeId ? data?.examTypeId?.name : "-"}
                          </td>
                          <td>
                            {data?.baseCourseId
                              ? data?.baseCourseId?.name
                              : "-"}
                          </td>
                          {data?.sectionType?.map((item, index) => {
                            return (
                              <tr
                                key={index}
                                style={{ backgroundColor: "transparent" }}
                              >
                                <td>{item === 1 ? "FALSE" : "TRUE"}</td>
                              </tr>
                            );
                          })}
                          <td>{data?.discPer ? `${data?.discPer}%` : "-"}</td>
                          <td>
                            {data?.validHours ? `${data?.validHours} Hr` : "-"}
                          </td>
                          <td>
                            <Link to={data?.link} target="_blank">
                              Pdf Link
                            </Link>
                          </td>
                          <td>{data?.templateId ? data?.templateId : "-"}</td>
                          {data?.sectionType?.map((item, index) => {
                            return (
                              <tr
                                key={index}
                                style={{ backgroundColor: "transparent" }}
                              >
                                <td>
                                  {item === 2
                                    ? "STUDY MATERIAL"
                                    : item === 8
                                    ? "PRACTICE COURSE"
                                    : item === 7
                                    ? "PYQ COURSE"
                                    : item === 5
                                    ? "LECTURE SERIES"
                                    : item === 3
                                    ? "TEST SERIES"
                                    : item === 6
                                    ? "CRASH COURSE"
                                    : item === 1
                                    ? "ONLINE COURSE"
                                    : "-"}
                                </td>
                              </tr>
                            );
                          })}
                        </>
                      ) : (
                        <td>-</td>
                      )
                    ) : null}
                    {pagename === "liveRecVideoLectList" ? (
                      data?.subjectId ? (
                        <td>{data?.subjectId?.name}</td>
                      ) : (
                        <td>-</td>
                      )
                    ) : null}
                    {pagename === "liveRecVideoLectList" ? (
                      data?.sessionId ? (
                        <td>{data?.sessionId?.name}</td>
                      ) : (
                        <td>-</td>
                      )
                    ) : null}
                    {pagename === "liveRecVideoLectList" ? (
                      data?.topicId ? (
                        <td>{data?.topicId?.name}</td>
                      ) : (
                        <td>-</td>
                      )
                    ) : null}
                    {pagename === "liveRecVideoLectList" ? (
                      data?.subTopicId ? (
                        <td>{data?.subTopicId[0]?.name}</td>
                      ) : (
                        <td>-</td>
                      )
                    ) : null}
                    {pagename === "liveRecVideoLectList" ||
                    pagename === "importRecVideoLectList" ||
                    pagename === "EditimportRecVideoLectList" ? (
                      data?.lectureTitle ? (
                        <td className="text-break">{data?.lectureTitle}</td>
                      ) : (
                        <td>-</td>
                      )
                    ) : null}
                    {pagename === "liveRecVideoLectList" ? (
                      data?.order || data?.order === 0 ? (
                        <td>{data?.order}</td>
                      ) : (
                        <td>-</td>
                      )
                    ) : null}
                    {pagename === "liveRecVideoLectList" ? (
                      data?.examId?.en ? (
                        <td>{data?.examId?.en?.name}</td>
                      ) : (
                        <td>-</td>
                      )
                    ) : null}
                    {pagename === "liveRecVideoLectList" ? (
                      data?.lectureTime ? (
                        <td>{moment.utc(data.lectureTime).format("LL")}</td>
                      ) : (
                        <td>-</td>
                      )
                    ) : null}
                    {pagename === "classroomBatch" ? (
                      data?.date ? (
                        <td>{moment.utc(data.date).format("LL")}</td>
                      ) : (
                        <td>-</td>
                      )
                    ) : null}
                    {pagename === "EditDeletePyqPdf" ? (
                      data?.batchName ? (
                        <td>{data?.batchName}</td>
                      ) : (
                        <td>-</td>
                      )
                    ) : null}
                    {pagename === "EditDeletePyqPdf" ? (
                      data?.order || data?.order === 0 ? (
                        <td>{data?.order}</td>
                      ) : (
                        <td>-</td>
                      )
                    ) : null}
                    {pagename === "classroomStudents" ||
                    pagename === "financeClassroomStudents" ||
                    pagename === "examResults" ? (
                      data?.userId?.firstName ? (
                        <td>{data?.userId?.firstName}</td>
                      ) : (
                        <td>-</td>
                      )
                    ) : null}
                    {pagename === "classroomStudents" ||
                    pagename === "financeClassroomStudents" ||
                    pagename === "examResults" ? (
                      data?.userId?.lastName ? (
                        <td>{data?.userId?.lastName}</td>
                      ) : (
                        <td>-</td>
                      )
                    ) : null}
                    {pagename === "classroomStudents" ||
                    pagename === "financeClassroomStudents" ? (
                      data?.createdAt ? (
                        <td>
                          {moment.utc(data.createdAt).format("DD-MM-YYYY")}
                        </td>
                      ) : (
                        <td>-</td>
                      )
                    ) : null}
                    {/* {
                      pagename === "classroomStudents" ||
                      pagename === "financeClassroomStudents" ||
                      pagename === "examResults" ? (
                        data?.userId?.mobileNo ? (
                          <td>{data?.userId?.mobileNo}</td>
                        ) : (
                          <td>-</td>
                        )
                      ) : null
                    } */}
                    {pagename === "examResults" ? (
                      data?.startDate ? (
                        <td>
                          {moment.utc(data.startDate).format("DD-MM-YYYY")}
                        </td>
                      ) : (
                        <td>-</td>
                      )
                    ) : null}
                    {/* {
                    pagename === "classroomStudents" ||
                    pagename === "financeClassroomStudents" ? (
                      data?.userId?.email ? (
                        <td>{data?.userId?.email}</td>
                      ) : (
                        <td>-</td>
                      )
                    ) : null} */}
                    {pagename === "classroomStudents" ||
                    pagename === "financeClassroomStudents" ? (
                      data?.userId?.stateId?.name ? (
                        <td>{data?.userId?.stateId?.name}</td>
                      ) : (
                        <td>-</td>
                      )
                    ) : null}
                    {pagename === "classroomStudents" ||
                    pagename === "financeClassroomStudents" ? (
                      data?.isClassRoomPayment === true ||
                      data?.isClassRoomPayment === false ? (
                        <td>{data?.isClassRoomPayment ? "Yes" : "No"}</td>
                      ) : (
                        <td>-</td>
                      )
                    ) : null}
                    {pagename === "classroomStudents" ||
                    pagename === "financeClassroomStudents" ? (
                      data?.amount ? (
                        <td>{data?.amount}</td>
                      ) : (
                        <td>-</td>
                      )
                    ) : null}
                    {pagename === "userFeedBack" ? (
                      data?.baseCourseName ? (
                        <td>{data?.baseCourseName}</td>
                      ) : (
                        <td>-</td>
                      )
                    ) : null}
                    {pagename === "userFeedBack" ? (
                      data?.examName ? (
                        <td>{data?.examName}</td>
                      ) : (
                        <td>-</td>
                      )
                    ) : null}
                    {pagename === "userFeedBack" ? (
                      data?.examName ? (
                        <td>{data?.subExamName}</td>
                      ) : (
                        <td>-</td>
                      )
                    ) : null}
                    {pagename === "userFeedBack" ? (
                      data?.courseName ? (
                        <td>{data?.courseName}</td>
                      ) : (
                        <td>-</td>
                      )
                    ) : null}
                    {pagename === "courseFeedBack" ||
                    pagename === "TeacherHoursList" ||
                    pagename === "editTeacherHoursList" ? (
                      data?.lectureName ? (
                        <td>{data?.lectureName}</td>
                      ) : (
                        <td>-</td>
                      )
                    ) : null}
                    {pagename === "courseFeedBack" ? (
                      data?.subjectName ? (
                        <td>{data?.subjectName}</td>
                      ) : (
                        <td>-</td>
                      )
                    ) : null}
                    {pagename === "courseFeedBack" ? (
                      data?.topicName ? (
                        <td>{data?.topicName}</td>
                      ) : (
                        <td>-</td>
                      )
                    ) : null}
                    {pagename === "courseFeedBack" ? (
                      data?.subtopicName ? (
                        <td>{data?.subtopicName}</td>
                      ) : (
                        <td>-</td>
                      )
                    ) : null}
                    {pagename === "userFeedBack" ||
                    pagename === "courseFeedBack" ? (
                      data?.reviewCount ? (
                        <td>{data?.reviewCount}</td>
                      ) : (
                        <td>-</td>
                      )
                    ) : null}
                    {pagename === "userFeedBack" ||
                    pagename === "courseFeedBack" ? (
                      data?.overallRating ? (
                        <td>
                          {Math.round(
                            (parseFloat(data?.overallRating) + Number.EPSILON) *
                              10
                          ) / 10}
                        </td>
                      ) : (
                        <td>-</td>
                      )
                    ) : null}
                    {pagename === "importRecVideoLectList" ||
                    pagename === "EditimportRecVideoLectList" ? (
                      data?.duration ? (
                        <td className="text-break">{data?.duration}</td>
                      ) : (
                        <td>-</td>
                      )
                    ) : null}
                    {pagename === "importRecVideoLectList" ||
                    pagename === "EditimportRecVideoLectList" ? (
                      data?.description ? (
                        <td className="text-break">{data?.description}</td>
                      ) : (
                        <td>-</td>
                      )
                    ) : null}
                    {pagename === "importRecVideoLectList" ||
                    pagename === "EditimportRecVideoLectList" ? (
                      data?.s3LinkHigh ? (
                        <td className="text-break">{data?.s3LinkHigh}</td>
                      ) : (
                        <td>-</td>
                      )
                    ) : null}
                    {pagename === "importRecVideoLectList" ||
                    pagename === "EditimportRecVideoLectList" ? (
                      data?.s3LinkLow ? (
                        <td className="text-break">{data?.s3LinkLow}</td>
                      ) : (
                        <td>-</td>
                      )
                    ) : null}
                    {pagename === "EditimportRecVideoLectList" ? (
                      data?.examId?.en?.name ? (
                        <td>{data?.examId?.en?.name}</td>
                      ) : (
                        <td>-</td>
                      )
                    ) : null}
                    {pagename === "importRecVideoLectList" ? (
                      data?.examDppName ? (
                        <td className="text-break">{data?.examDppName}</td>
                      ) : (
                        <td>-</td>
                      )
                    ) : null}
                    {pagename === "EditimportRecVideoLectList" ? (
                      data?.handout1Url ? (
                        <td>{data?.handout1Url}</td>
                      ) : (
                        <td>-</td>
                      )
                    ) : null}
                    {pagename === "importRecVideoLectList" ? (
                      data?.handout2Url ? (
                        <td className="text-break">
                          {data?.handout1Url},{data?.handout2Url}
                        </td>
                      ) : (
                        <td>-</td>
                      )
                    ) : null}
                    {pagename === "TeacherHoursList" ||
                    pagename === "editTeacherHoursList" ? (
                      data?.lectureDate ? (
                        <td>{moment.utc(data?.lectureDate).format("LL")}</td>
                      ) : (
                        <td>-</td>
                      )
                    ) : null}
                    {pagename === "TeacherHoursList" ||
                    pagename === "editTeacherHoursList" ? (
                      data?.lectureDuration ? (
                        <td>{Math.round(data?.lectureDuration * 100) / 100}</td>
                      ) : (
                        <td>-</td>
                      )
                    ) : null}
                    {pagename === "TeacherHoursList" ||
                    pagename === "editTeacherHoursList" ? (
                      data?.lectureType ? (
                        <td>
                          {data?.lectureType === 1
                            ? "Live Lecture"
                            : data?.lectureType === 2
                            ? "Recorded Lecture"
                            : data?.lectureType === 3
                            ? "Test Series"
                            : data?.lectureType === 4
                            ? "Book"
                            : "Youtube Live"}
                        </td>
                      ) : (
                        <td>-</td>
                      )
                    ) : null}
                    {pagename === "finTransEductech" &&
                      (data ? (
                        <>
                          <td>{data?.orderNo}</td>
                          <td>
                            {moment.utc(data.date).format("DD-MM-YYYY ; LT")}
                          </td>
                          <td>
                            {data?.userDetails?.firstName}{" "}
                            {data?.userDetails?.lastName}
                          </td>
                          {/* <td>{data?.userDetails?.mobileNo}</td> */}
                          <td>{getBatchName(data?.courses)}</td>
                          <td>{getSubscriptionMonths(data?.courses)}</td>
                          <td>{getPrice(data?.courses)}</td>
                          <td>{getCoupon(data?.courses)}</td>
                          <td>{getDiscountedValue(data?.courses)}</td>
                          {/* {data?.courses ? getDiscountPrice(data?.courses) : (<td>-</td>)} */}
                          <td>
                            {data?.status === 0
                              ? "Created"
                              : data?.status === 1
                              ? "In Progress"
                              : data?.status === 2
                              ? "Successful"
                              : data?.status === 9
                              ? "Cancelled"
                              : "-"}
                          </td>
                        </>
                      ) : (
                        <td>-</td>
                      ))}
                    {pagename === "subDepartment" ? (
                      data ? (
                        <>
                          <td>
                            {data?.departments?._id
                              ? data?.departments?.name
                              : "-"}
                          </td>
                          <td>{data?.name ? data?.name : "-"}</td>
                        </>
                      ) : null
                    ) : null}
                    {pagename === "finRefundTransEductech" &&
                      (data ? (
                        <>
                          <td>{data?.orderNo}</td>

                          <td>
                            {data?.userDetails?.firstName}{" "}
                            {data?.userDetails?.lastName}
                          </td>
                          {/* <td>{data?.userDetails?.mobileNo}</td> */}
                          <td>{data?.coursedata[0]?.name}</td>
                          <td>{data?.courses[0]?.subscriptionPlanMonths}</td>
                          <td>
                            {data?.courses ? getTotalPrice(data?.courses) : "-"}
                          </td>
                          <td>{data?.RefundDetails?.refundAmount}</td>
                          <td>
                            {moment
                              .utc(data.createdAt)
                              .format("DD-MM-YYYY ; LT")}
                          </td>
                          <td>
                            {data?.RefundDetails?.status === 2
                              ? "Refunded"
                              : ""}
                          </td>
                        </>
                      ) : (
                        <td>-</td>
                      ))}
                    {pagename === "paymentPublication" ? (
                      <>
                        <td>{data?.orderNo ? data?.orderNo : "-"}</td>
                        <td>
                          {data?.createdAt
                            ? moment
                                .utc(data?.date)
                                .format("DD-MM-YYYY hh:mm:ss A")
                            : "-"}
                        </td>
                        <td>
                          {data?.userDetails
                            ? `${data?.userDetails?.firstName} ${data?.userDetails?.lastName}`
                            : "-"}
                        </td>
                        <td>
                          {data?.cityDetails
                            ? `${data?.cityDetails?.name}`
                            : "-"}
                        </td>
                        <td>
                          {data?.stateDetails || data?.stateId
                            ? `${
                                data?.stateDetails?.name
                                  ? data?.stateDetails?.name
                                  : data?.stateId?.name
                              }`
                            : "-"}
                        </td>
                        <td>
                          {data?.coursedata
                            ? `${data?.coursedata[0]?.name}`
                            : "-"}
                        </td>
                        <td>
                          {data?.courses
                            ? `${data?.courses[0]?.subscriptionPlanMonths}`
                            : "-"}
                        </td>
                        <td>
                          {data?.courses ? getTotalPrice(data?.courses) : "-"}
                        </td>
                        <td>
                          {data?.courses
                            ? `${data?.courses[0]?.couponName}`
                            : "-"}
                        </td>
                        <td>
                          {data?.courses
                            ? getDiscountPrice(data?.courses)
                            : "-"}
                        </td>
                        <td>
                          {data
                            ? `${data?.paymentGatewayResponse[0]?.status}`
                            : "-"}
                        </td>
                      </>
                    ) : null}
                    {pagename === "transactionEdutech" &&
                      (data ? (
                        <>
                          <td>{data?.orderNo}</td>
                          <td>
                            {moment.utc(data.date).format("DD-MM-YYYY ; LT")}
                          </td>
                          {/* <td>{data?.userDetails?.mobileNo}</td> */}
                          <td>{getBatchName(data?.courses)}</td>
                          <td>{getSubscriptionMonths(data?.courses)}</td>
                          <td>{getPrice(data?.courses)}</td>
                          <td>{getCoupon(data?.courses)}</td>
                          <td>{getDiscountedValue(data?.courses)}</td>
                          <td>{data?.paymentGatewayResponse[0]?.status}</td>
                          <td>
                            {data?.status === 0
                              ? "Created"
                              : data?.status === 1
                              ? "In Progress"
                              : data?.status === 2
                              ? "Successful"
                              : data?.status === 9
                              ? "Cancelled"
                              : "-"}
                          </td>
                        </>
                      ) : (
                        <td>-</td>
                      ))}
                    {pagename === "transactionPublication" ||
                    pagename === "studyMaterialOrders" ||
                    pagename === "AllDetailsSMOrders" ||
                    pagename === "transactionEdutechVP" ||
                    pagename === "transactionEdutechStudyMaterialVP" ||
                    pagename === "transactionEdutechStudyMaterial" ? (
                      data?.orderNo ? (
                        <td>{data?.orderNo}</td>
                      ) : (
                        <td>-</td>
                      )
                    ) : null}
                    {pagename === "transactionPublication" ||
                    pagename === "transactionEdutechVP" ||
                    pagename === "transactionEdutechStudyMaterialVP" ||
                    pagename === "transactionEdutechStudyMaterial" ? (
                      data?.date ? (
                        <td>
                          {moment
                            .utc(data?.date)
                            .format("DD-MM-YYYY hh:mm:ss A")}
                        </td>
                      ) : (
                        <td>-</td>
                      )
                    ) : null}
                    {pagename === "transactionPublication" ? (
                      data?.userDetails ? (
                        <td>
                          {data?.userDetails[0]?.firstName}{" "}
                          {data?.userDetails[0]?.lastName}
                        </td>
                      ) : (
                        <td>-</td>
                      )
                    ) : null}
                    {pagename === "transactionEdutechVP" ||
                    // pagename === "transactionEdutechStudyMaterial" ||
                    pagename === "transactionEdutechStudyMaterialVP" ? (
                      data?.userDetails ? (
                        <td>
                          {data?.userDetails?.firstName}{" "}
                          {data?.userDetails?.lastName}
                        </td>
                      ) : (
                        <td>-</td>
                      )
                    ) : null}
                    {pagename === "transactionPublication" ? (
                      data?.userDetails ? (
                        <td>{data?.userDetails[0]?.mobileNo}</td>
                      ) : (
                        <td>-</td>
                      )
                    ) : null}
                    {pagename === "transactionEdutechVP" ||
                    // pagename === "transactionEdutechStudyMaterial" ||
                    pagename === "transactionEdutechStudyMaterialVP" ? (
                      data?.userDetails ? (
                        <td>{data?.userDetails?.mobileNo}</td>
                      ) : (
                        <td>-</td>
                      )
                    ) : null}
                    {pagename === "transactionEdutechVP" ||
                    // pagename === "transactionEdutechStudyMaterial"   ||
                    pagename === "transactionEdutechStudyMaterialVP" ? (
                      data?.cityDetails ? (
                        <td>{data?.cityDetails?.name}</td>
                      ) : (
                        <td>-</td>
                      )
                    ) : null}
                    {pagename === "transactionEdutechVP" ||
                    // pagename === "transactionEdutechStudyMaterial" ||
                    pagename === "transactionEdutechStudyMaterialVP" ? (
                      data?.stateDetails || data?.stateId ? (
                        <td>
                          {data?.stateDetails?.name
                            ? data?.stateDetails?.name
                            : data?.stateId?.name}
                        </td>
                      ) : (
                        <td>-</td>
                      )
                    ) : null}
                    {pagename === "transactionPublication" ? (
                      data?.netPayableAmount ? (
                        <td>{data?.netPayableAmount}</td>
                      ) : (
                        <td>-</td>
                      )
                    ) : null}
                    {pagename === "transactionEdutechVP" ||
                    pagename === "transactionEdutechStudyMaterialVP" ||
                    pagename === "transactionEdutechStudyMaterial" ? (
                      data?.coursedata ? (
                        <td>{data?.coursedata[0]?.name}</td>
                      ) : (
                        <td>-</td>
                      )
                    ) : null}
                    {pagename === "transactionEdutechStudyMaterial" ? (
                      data?.courses ? (
                        <td>{data?.courses[0]?.subscriptionPlanMonths}</td>
                      ) : (
                        <td>-</td>
                      )
                    ) : null}
                    {pagename === "transactionEdutechStudyMaterial" ? (
                      data?.courses ? (
                        <td>{data?.courses[0].taxFreeAmount}</td>
                      ) : (
                        // <td>{data?.netPayableAmount}</td>
                        // <td>{data?.netPayableAmount}</td>
                        <td>-</td>
                      )
                    ) : null}
                    {pagename === "transactionEdutechStudyMaterial" ? (
                      data?.courses ? (
                        <td>{data?.courses[0]?.taxableAmount}</td>
                      ) : (
                        <td>-</td>
                      )
                    ) : null}
                    {pagename === "transactionEdutechStudyMaterial" ? (
                      data?.courses ? (
                        <td>
                          {data?.courses[0]?.taxableAmount +
                            data?.courses[0].taxFreeAmount}
                        </td>
                      ) : (
                        <td>-</td>
                      )
                    ) : null}
                    {pagename === "transactionEdutechStudyMaterial" ? (
                      data?.courses ? (
                        <td>{data?.courses[0]?.couponName}</td>
                      ) : (
                        <td>-</td>
                      )
                    ) : null}
                    {pagename === "transactionEdutechStudyMaterial" ? (
                      data?.courses ? (
                        <td>{data?.courses[0]?.couponDiscount}</td>
                      ) : (
                        <td>-</td>
                      )
                    ) : null}
                    {pagename === "transactionEdutechStudyMaterial" ? (
                      data ? (
                        <td>{data?.paymentGatewayResponse[0]?.status}</td>
                      ) : (
                        <td>-</td>
                      )
                    ) : null}
                    {pagename === "transactionPublication" ||
                    pagename === "paymentPublication" ||
                    pagename === "transactionEdutechStudyMaterial" ? (
                      data?.status >= 0 ? (
                        <td>
                          {data?.status === 0
                            ? "Created"
                            : data?.status === 1
                            ? "In Progress"
                            : data?.status === 2
                            ? "Successful"
                            : data?.status === 9
                            ? "Cancelled"
                            : "-"}
                        </td>
                      ) : (
                        <td>-</td>
                      )
                    ) : null}
                    {pagename === "datewiseCount" ||
                    pagename === "studentCount" ||
                    pagename === "stateStudentCount" ? (
                      data?.name ? (
                        <td>{data?.name}</td>
                      ) : (
                        <td>-</td>
                      )
                    ) : null}

                    {pagename === "referenceGenerated" ? (
                      <>
                        <td>{data?.exam ? data?.exam : "-"}</td>
                        <td>{data?.examStream ? data?.examStream : "-"}</td>
                        <td>{data?.leads ? data?.leads : "-"}</td>
                      </>
                    ) : null}
                    {pagename === "seoBlogs" ? (
                      <>
                        <td>
                          {data?.examTypeId ? data?.examTypeId?.name : "-"}
                        </td>
                        <td>
                          {data?.subExamTypeId
                            ? data?.subExamTypeId?.name
                            : "-"}
                        </td>
                        <td>
                          {data?.baseCourseId ? data?.baseCourseId?.name : "-"}
                        </td>
                        <td>{data?.title ? data?.title : "-"}</td>
                        <td>{data?.author?._id ? data?.author?.name : "-"}</td>
                      </>
                    ) : null}

                    {pagename === "batchwiseCount" ||
                    pagename === "cxoBatchWiseCount" ||
                    pagename === "academicBatchWiseCount" ? (
                      data?.courseName ? (
                        <td>{data?.courseName}</td>
                      ) : (
                        <td>-</td>
                      )
                    ) : null}

                    {pagename === "batchwiseCount" ||
                    pagename === "cxoBatchWiseCount" ||
                    pagename === "academicBatchWiseCount" ? (
                      data?.addmissionCount ? (
                        <td>{data?.addmissionCount}</td>
                      ) : (
                        <td>-</td>
                      )
                    ) : null}

                    {pagename === "batchwiseCount" ||
                    pagename === "cxoBatchWiseCount" ? (
                      data?.revenue ? (
                        <td>{data?.revenue}</td>
                      ) : (
                        <td>-</td>
                      )
                    ) : null}
                    {pagename === "datewiseCount" ||
                    pagename === "studentCount" ||
                    pagename === "stateStudentCount" ? (
                      data ? (
                        <td>{data?.studentCount}</td>
                      ) : (
                        <td>-</td>
                      )
                    ) : null}
                    {pagename === "referenceLeadCount" ? (
                      data?.studentleadCount ? (
                        <td>{data?.studentleadCount}</td>
                      ) : (
                        <td>-</td>
                      )
                    ) : null}
                    {pagename === "referenceTeamLeadCount" ? (
                      data?.studentLeadsIds ? (
                        <td>{data?.studentLeadsIds}</td>
                      ) : (
                        <td>-</td>
                      )
                    ) : null}
                    {pagename === "studentCount" ? (
                      data ? (
                        <td>{data?.countPercentage}</td>
                      ) : (
                        <td>-</td>
                      )
                    ) : null}
                    {pagename === "stateStudentCount" ? (
                      data ? (
                        <td>{data?.countPercentage}</td>
                      ) : (
                        <td>-</td>
                      )
                    ) : null}
                    {pagename === "datewiseCount" ? (
                      data ? (
                        <td>{data?.amount}</td>
                      ) : (
                        <td>-</td>
                      )
                    ) : null}
                    {pagename === "revenueDetails" ? (
                      data ? (
                        <td>{data?.examName}</td>
                      ) : (
                        <td>-</td>
                      )
                    ) : null}
                    {pagename === "revenueDetails" ? (
                      data ? (
                        <td>{data?.edutech}</td>
                      ) : (
                        <td>-</td>
                      )
                    ) : null}
                    {pagename === "revenueDetails" ? (
                      data ? (
                        <td>{data?.publication}</td>
                      ) : (
                        <td>-</td>
                      )
                    ) : null}
                    {pagename === "assetList" ? (
                      <>
                        <td>{data?.name ? data?.name : "-"}</td>
                      </>
                    ) : null}
                    {pagename === "deviceCompany" ? (
                      <>
                        <td>{data?.name ? data?.name : "-"}</td>
                      </>
                    ) : null}
                    {pagename === "deviceModel" ? (
                      <>
                        <td>{data?.name ? data?.name : "-"}</td>
                      </>
                    ) : null}

                    {/* Order Date */}
                    {pagename === "studyMaterialOrders" ||
                    pagename === "AllDetailsSMOrders" ? (
                      data?.date ? (
                        <td>{moment.utc(data.date).format("DD-MM-YYYY")}</td>
                      ) : (
                        <td>-</td>
                      )
                    ) : null}
                    {/* Buyer Name */}
                    {pagename === "studyMaterialOrders" ||
                    pagename === "AllDetailsSMOrders" ? (
                      data?.usersData ? (
                        <td>
                          {data?.usersData?.firstName}{" "}
                          {data?.usersData?.lastName}
                        </td>
                      ) : (
                        <td>-</td>
                      )
                    ) : null}
                    {/* Address */}
                    {pagename === "AllDetailsSMOrders" ? (
                      data?.address ? (
                        <td>{`${data?.address?.line1}, ${data?.address?.line2}, ${data?.address?.landmark}, ${data?.address?.cityId?.name}, ${data?.address?.stateId?.name}- ${data?.address?.pincode}.`}</td>
                      ) : (
                        <td>-</td>
                      )
                    ) : null}
                    {/* City */}
                    {pagename === "AllDetailsSMOrders" ? (
                      data?.address?.cityId ? (
                        <td>{data?.address?.cityId?.name}</td>
                      ) : (
                        <td>-</td>
                      )
                    ) : null}
                    {/* state */}
                    {pagename === "studyMaterialOrders" ||
                    pagename === "AllDetailsSMOrders" ? (
                      data?.address?.stateId ? (
                        <td>{data?.address?.stateId?.name}</td>
                      ) : (
                        <td>-</td>
                      )
                    ) : null}
                    {/* pincode */}
                    {pagename === "AllDetailsSMOrders" ? (
                      data?.address?.pincode ? (
                        <td>{data?.address?.pincode}</td>
                      ) : (
                        <td>-</td>
                      )
                    ) : null}
                    {/* Mobile Number */}
                    {pagename === "studyMaterialOrders" ||
                    pagename === "AllDetailsSMOrders" ? (
                      data?.usersData?.mobileNo ? (
                        <td>{data?.usersData?.mobileNo}</td>
                      ) : (
                        <td>-</td>
                      )
                    ) : null}
                    {/* Price */}
                    {pagename === "studyMaterialOrders" ||
                    pagename === "AllDetailsSMOrders" ? (
                      data?.netPayableAmount ? (
                        <td>{data?.netPayableAmount}</td>
                      ) : (
                        <td>-</td>
                      )
                    ) : null}
                    {/* Mail */}
                    {pagename === "AllDetailsSMOrders" ? (
                      data?.usersData?.email ? (
                        <td>{data?.usersData?.email}</td>
                      ) : (
                        <td>-</td>
                      )
                    ) : null}
                    {/* book detail */}
                    {pagename === "AllDetailsSMOrders" ? (
                      data?.courses ? (
                        getSMBookList(data?.courses)
                      ) : (
                        <td>-</td>
                      )
                    ) : null}
                    {/* book type */}
                    {pagename === "AllDetailsSMOrders" ? (
                      data?.courses ? (
                        getSMBookType(data?.courses)
                      ) : (
                        <td>-</td>
                      )
                    ) : null}
                    {/* parent course */}
                    {pagename === "studyMaterialOrders" ||
                    pagename === "AllDetailsSMOrders" ||
                    pagename === "configQuestion" ? (
                      data?.baseCourse ? (
                        <td>{data?.baseCourse?.name}</td>
                      ) : (
                        <td>-</td>
                      )
                    ) : null}
                    {/* exam type */}
                    {pagename === "AllDetailsSMOrders" ||
                    pagename === "configQuestion" ? (
                      data?.examType ? (
                        <td>{data?.examType?.name}</td>
                      ) : (
                        <td>-</td>
                      )
                    ) : null}
                    {/* sub exam type */}
                    {pagename === "AllDetailsSMOrders" ||
                    pagename === "configQuestion" ? (
                      data?.subExamType ? (
                        <td>{data?.subExamType?.name}</td>
                      ) : (
                        <td>-</td>
                      )
                    ) : null}
                    {/* tracking ID  */}
                    {pagename === "AllDetailsSMOrders" ? (
                      data?.trackingIdDetails ? (
                        getTrackingIdList(data?.trackingIdDetails)
                      ) : (
                        <td>-</td>
                      )
                    ) : null}
                    {/* delivery Charge */}
                    {pagename === "AllDetailsSMOrders" ? (
                      data?.deliveryFee >= 0 ? (
                        <td>{data?.deliveryFee}</td>
                      ) : (
                        <td>-</td>
                      )
                    ) : null}
                    {/* Dispatch Date */}
                    {pagename === "studyMaterialOrders" ||
                    pagename === "AllDetailsSMOrders" ? (
                      data?.dispatchedDate ? (
                        <td>
                          {moment.utc(data.dispatchedDate).format("DD-MM-YYYY")}
                        </td>
                      ) : (
                        <td>-</td>
                      )
                    ) : null}
                    {/* order pendings */}
                    {pagename === "AllDetailsSMOrders" ? (
                      data?.remainingBookDetails ? (
                        getSubCategories(data?.remainingBookDetails)
                      ) : (
                        <td>-</td>
                      )
                    ) : null}
                    {/* next dispatched date */}
                    {pagename === "AllDetailsSMOrders" ? (
                      data?.nextDispatchedDate ? (
                        <td>
                          {moment
                            .utc(data.nextDispatchedDate)
                            .format("DD-MM-YYYY")}
                        </td>
                      ) : (
                        <td>-</td>
                      )
                    ) : null}
                    {pagename === "stockManagement" ? (
                      data?.quantity ? (
                        <td>{data?.quantity}</td>
                      ) : data?.quantity === 0 ? (
                        <td>{data?.quantity}</td>
                      ) : (
                        <td>-</td>
                      )
                    ) : null}
                    {pagename === "stockManagement" ? (
                      data?.status ? (
                        <td>
                          {data?.status === 1
                            ? "Available"
                            : data?.status === 2
                            ? "Out Of Stock"
                            : "-"}
                        </td>
                      ) : (
                        <td>-</td>
                      )
                    ) : null}
                    {pagename === "bookHistory" ? (
                      data?.createdAt ? (
                        <td>
                          {moment
                            .utc(data.createdAt)
                            .format("DD-MM-YYYY hh:mm:ss")}
                        </td>
                      ) : (
                        <td>-</td>
                      )
                    ) : null}
                    {pagename === "bookHistory" ? (
                      data?.lastQuantity ? (
                        <td>{data?.lastQuantity}</td>
                      ) : (
                        <td>-</td>
                      )
                    ) : null}
                    {pagename === "bookHistory" ? (
                      data?.quantity ? (
                        <td>{data?.quantity}</td>
                      ) : (
                        <td>-</td>
                      )
                    ) : null}
                    {pagename === "bookHistory" ? (
                      data?.currentQuantity ? (
                        <td>{data?.currentQuantity}</td>
                      ) : (
                        <td>-</td>
                      )
                    ) : null}
                    {pagename === "bookHistory" ? (
                      data?.printerName ? (
                        <td>{data?.printerName}</td>
                      ) : (
                        <td>-</td>
                      )
                    ) : null}
                    {pagename === "bookHistory" ? (
                      data?.totalAmount ? (
                        <td>{data?.totalAmount}</td>
                      ) : (
                        <td>-</td>
                      )
                    ) : null}
                    {pagename === "admissionHistory" ||
                    pagename === "academicHead" ||
                    pagename === "cxoBatchWiseCountDetail" ? (
                      data?.firstName ? (
                        <td>{data?.firstName}</td>
                      ) : (
                        <td>-</td>
                      )
                    ) : null}
                    {pagename === "admissionHistory" ||
                    pagename === "academicHead" ||
                    pagename === "cxoBatchWiseCountDetail" ? (
                      data?.lastName ? (
                        <td>{data?.lastName}</td>
                      ) : (
                        <td>-</td>
                      )
                    ) : null}
                    {pagename === "academicHead" ? (
                      data?.username ? (
                        <td>{data?.username}</td>
                      ) : (
                        <td>-</td>
                      )
                    ) : null}
                    {/* {pagename === "admissionHistory" ? (
                      data?.mobileNo ? (
                        <td>{data?.mobileNo}</td>
                      ) : (
                        <td>-</td>
                      )
                    ) : null} */}
                    {pagename === "admissionHistory" ||
                    pagename === "cxoBatchWiseCountDetail" ? (
                      data?.state ? (
                        <td>{data?.state}</td>
                      ) : (
                        <td>-</td>
                      )
                    ) : null}
                    {pagename === "admissionHistory" ||
                    pagename === "cxoBatchWiseCountDetail" ? (
                      data?.date ? (
                        <td>
                          {data?.date
                            ? moment.utc(data?.date).format("DD-MM-YYYY")
                            : moment.utc(data?.startDate).format("DD-MM-YYYY")}
                        </td>
                      ) : (
                        <td>-</td>
                      )
                    ) : null}
                    {pagename === "admissionHistory" ||
                    pagename === "cxoBatchWiseCountDetail" ? (
                      data?.endDate ? (
                        <td>
                          {data?.endDate
                            ? moment.utc(data?.endDate).format("DD-MM-YYYY")
                            : "-"}
                        </td>
                      ) : (
                        <td>-</td>
                      )
                    ) : null}

                    {pagename === "cxoBatchWiseCountDetail" ? (
                      data?.total ? (
                        <td>{data?.total}</td>
                      ) : (
                        <td>-</td>
                      )
                    ) : null}

                    {pagename === "registrationDetails" ||
                    pagename === "dataRegistrationDetails" ? (
                      data?.examData?.name ? (
                        <td>{data?.examData?.name}</td>
                      ) : (
                        <td>-</td>
                      )
                    ) : null}
                    {pagename === "registrationDetails" ||
                    pagename === "dataRegistrationDetails" ? (
                      data?.subExamTypeId?.name ? (
                        <td>{data?.subExamTypeId?.name}</td>
                      ) : (
                        <td>-</td>
                      )
                    ) : null}
                    {pagename === "registrationDetails" ||
                    pagename === "dataRegistrationDetails" ? (
                      data?.coursesData?.name ? (
                        <td>
                          {data?.coursesData?.name}
                          {data?.coursesData?.sectionType === 5
                            ? " - " + data?.name
                            : ""}
                        </td>
                      ) : (
                        <td>-</td>
                      )
                    ) : null}
                    {pagename === "registrationDetails" ||
                    pagename === "dataRegistrationDetails" ? (
                      data?.coursesData?.sectionType ? (
                        <td>
                          {data?.coursesData?.sectionType === 1
                            ? "COURSE"
                            : data?.coursesData?.sectionType === 2
                            ? "STUDY MATERIAL"
                            : data?.coursesData?.sectionType === 3
                            ? "TEST SERIES"
                            : data?.coursesData?.sectionType === 4
                            ? "E-BOOK"
                            : data?.coursesData?.sectionType === 5
                            ? "LECTURE SERIES"
                            : data?.coursesData?.sectionType === 6
                            ? "CRASH COURSE"
                            : data?.coursesData?.sectionType === 7
                            ? "PYQ COURSE"
                            : data?.coursesData?.sectionType === 8
                            ? "PRACTICE COURSE"
                            : data?.coursesData?.sectionType === 9
                            ? "CLASSROOM"
                            : "-"}
                        </td>
                      ) : (
                        <td>-</td>
                      )
                    ) : null}
                    {pagename === "registrationDetails" ||
                    pagename === "dataRegistrationDetails" ||
                    pagename === "configQuestion" ? (
                      data?.status >= 0 ? (
                        <td>{data?.status === 1 ? "Yes" : "No"}</td>
                      ) : (
                        <td>-</td>
                      )
                    ) : null}
                    {pagename === "registrationDetails" ||
                    pagename === "dataRegistrationDetails" ? (
                      data?.endDate ? (
                        <td>
                          {moment.utc(data?.endDate).format("DD-MM-YYYY")}
                        </td>
                      ) : (
                        <td>-</td>
                      )
                    ) : null}
                    {pagename === "registrationDetails" ||
                    pagename === "dataRegistrationDetails" ? (
                      data?.selectedPackage ? (
                        <td> {data.selectedPackage.name}</td>
                      ) : (
                        <td>-</td>
                      )
                    ) : null}
                    {pagename === "examResultsDetails" ? (
                      data?.examData[0]?.en?.name ? (
                        <td>{data?.examData[0]?.en?.name}</td>
                      ) : (
                        <td>-</td>
                      )
                    ) : null}
                    {pagename === "examResultsDetails" ? (
                      data?.examData[0]?.duration ? (
                        <td>{data?.examData[0]?.duration}</td>
                      ) : (
                        <td>-</td>
                      )
                    ) : null}
                    {pagename === "examResultsDetails" ? (
                      data?.examData[0]?.status >= 0 ? (
                        <td>
                          {data?.examData[0]?.status === 0
                            ? "Not Submitted"
                            : data?.examData[0]?.status === 1
                            ? "Submitted"
                            : "-"}
                        </td>
                      ) : (
                        <td>-</td>
                      )
                    ) : null}
                    {pagename === "examResultsDetails" ? (
                      data?.examData[0]?.totalAddedQuestion ? (
                        <td>{data?.examData[0]?.totalAddedQuestion}</td>
                      ) : (
                        <td>-</td>
                      )
                    ) : null}
                    {pagename === "examResultsDetails" ? (
                      data?.examStats?.correct ? (
                        <td>{data?.examStats?.correct}</td>
                      ) : (
                        <td>-</td>
                      )
                    ) : null}
                    {pagename === "examResultsDetails" ? (
                      data?.examStats?.incorrect ? (
                        <td>{data?.examStats?.incorrect}</td>
                      ) : (
                        <td>-</td>
                      )
                    ) : null}
                    {pagename === "examResultsDetails" ? (
                      data?.examStats?.skipped ? (
                        <td>{data?.examStats?.skipped}</td>
                      ) : (
                        <td>-</td>
                      )
                    ) : null}
                    {pagename === "examResultsDetails" ? (
                      data?.examStats?.marksObtained ? (
                        <td>{data?.examStats?.marksObtained}</td>
                      ) : (
                        <td>-</td>
                      )
                    ) : null}
                    {pagename === "career" || pagename === "Jobs" ? (
                      data?.designation ? (
                        <td>{data?.designation}</td>
                      ) : (
                        <td>-</td>
                      )
                    ) : null}
                    {pagename === "Jobs" ? (
                      data?.company ? (
                        <td>{data?.company}</td>
                      ) : (
                        <td>-</td>
                      )
                    ) : null}
                    {pagename === "career" || pagename === "Jobs" ? (
                      data?.experience ? (
                        <td>{data?.experience}</td>
                      ) : (
                        <td>-</td>
                      )
                    ) : null}
                    {pagename === "career" || pagename === "Jobs" ? (
                      data?.salary ? (
                        <td>{data?.salary}</td>
                      ) : (
                        <td>-</td>
                      )
                    ) : null}
                    {pagename === "career" || pagename === "Jobs" ? (
                      data?.postedOn ? (
                        <td>
                          {moment.utc(data?.postedOn).format("DD-MM-YYYY")}
                        </td>
                      ) : (
                        <td>-</td>
                      )
                    ) : null}
                    {pagename === "EditDeleteResults" ? (
                      data?.studentName ? (
                        <td>{data?.studentName}</td>
                      ) : (
                        <td>-</td>
                      )
                    ) : null}
                    {pagename === "EditDeleteResults" ? (
                      data?.rank ? (
                        <td>{data?.rank}</td>
                      ) : (
                        <td>-</td>
                      )
                    ) : null}
                    {pagename === "EditDeleteResults" ? (
                      data?.rollNo ? (
                        <td>{data?.rollNo}</td>
                      ) : (
                        <td>-</td>
                      )
                    ) : null}
                    {pagename === "EditDeleteResults" ? (
                      data?.qualifiedFor ? (
                        <td>{data?.qualifiedFor}</td>
                      ) : (
                        <td>-</td>
                      )
                    ) : null}
                    {pagename === "EditDeleteResults" ? (
                      data?.studentImg ? (
                        <img
                          className="p-1"
                          htmlFor="photo-upload"
                          src={data?.studentImg}
                          style={{
                            width: "50px",
                            height: "50px",
                          }}
                          alt={""}
                        />
                      ) : (
                        <td>-</td>
                      )
                    ) : null}
                    {pagename === "EditDeleteResults" ? (
                      data?.priorityOrder ? (
                        <td>{data?.priorityOrder}</td>
                      ) : (
                        <td>-</td>
                      )
                    ) : null}

                    {pagename === "DataTeacherRanking" ? (
                      data?.rank ? (
                        <td>{data?.rank}</td>
                      ) : (
                        <td>-</td>
                      )
                    ) : null}

                    {pagename === "DataTeacherRanking" ? (
                      data?.teacherId ? (
                        <td>{data?.teacherId?.name}</td>
                      ) : (
                        <td>-</td>
                      )
                    ) : null}

                    {pagename === "DataTeacherRanking" ? (
                      data?.baseCourseId ? (
                        <td>{data?.baseCourseId?.name}</td>
                      ) : (
                        <td>-</td>
                      )
                    ) : null}

                    {pagename === "DataTeacherRanking" ? (
                      totalExpectDuration != null ? (
                        <td>
                          {totalExpectDuration} {" Hrs"}
                        </td>
                      ) : (
                        <td>-</td>
                      )
                    ) : null}

                    {pagename === "DataTeacherRanking" ? (
                      data?.totalTaskDuration != null ? (
                        <td>
                          {Math.round(data?.totalTaskDuration)?.toFixed(2)}{" "}
                          {" Hrs"}
                        </td>
                      ) : (
                        <td>-</td>
                      )
                    ) : null}

                    {pagename === "DataTeacherRanking" ? (
                      data?.totalWorkDuration != null ? (
                        <td>
                          {Math.round(data?.totalWorkDuration)?.toFixed(2)}
                          {" Hrs"}
                        </td>
                      ) : (
                        <td>-</td>
                      )
                    ) : null}
                    {pagename === "DataTeacherRanking" ? (
                      data?.totalAvgWorkDuration != null ? (
                        <td>
                          {data?.totalPercentageAchieved
                            ? Math.round(
                                data?.totalPercentageAchieved
                              )?.toFixed(2)
                            : 0}{" "}
                          %
                        </td>
                      ) : (
                        <td>-</td>
                      )
                    ) : null}
                    {/* {pagename === "DataTeacherRanking" ? (
                      data?.teacherId != null ? (
                        <>
                          <td>{data?.teacherId?.name}</td>
                          <td>{totalExpectDuration}</td>
                          <td>{getSeconds(data?.taskDuration)}</td>
                          <td>
                            {data?.workDuration
                              ? getSeconds(data?.workDuration)
                              : getSeconds(0)}
                          </td>
                          {/* <td>{getSeconds(data?.taskDuration)}</td>
                          <td>{getSeconds(data?.workDuration)}</td> */}
                    {/* <td
                            style={{
                              fontWeight: "bold",
                              color:
                                (100 *
                                  (data?.workDuration
                                    ? data?.workDuration
                                    : 0)) /
                                  data?.taskDuration >=
                                100
                                  ? "#158833"
                                  : "#F23E36",
                            }}
                          >
                            {data?.workDuration
                              ? `${(
                                  (100 * data?.workDuration) /
                                  data?.taskDuration
                                ).toFixed(2)} %`
                              : "0.00%"} */}
                    {/* {`${(
                            (100 * (data?.workDuration ? data?.workDuration : 0)) /
                            data?.taskDuration
                          ).toFixed(2)} %`} */}
                    {/* </td>
                        </>
                      ) : (
                        <td>-</td>
                      )
                    ) : null} */}
                    {pagename === "teacherPerformance" ? (
                      <>
                        <td>
                          {data?.taskDate
                            ? moment
                                .utc(changeIntoIst(data.taskDate))
                                .format("DD-MM-YYYY")
                            : "-"}
                        </td>
                        <td>{data?.taskType ? data?.taskType : "-"}</td>
                        <td>
                          {data?.examTypeId?.name
                            ? data?.examTypeId?.name
                            : "-"}
                        </td>
                        <td>
                          {data?.baseCourseId?.name
                            ? data?.baseCourseId?.name
                            : "-"}
                        </td>
                        <td>
                          {data?.teacherId?.name ? data?.teacherId?.name : "-"}
                        </td>
                        <td>
                          <a href={data?.link} rel="noreferrer" target="_blank">
                            {data?.link ? data?.link : "-"}
                          </a>
                        </td>
                        <td>
                          {data?.youtubeDuration ? data?.youtubeDuration : "-"}
                        </td>
                        <td>{data?.viewCount ? data?.viewCount : "-"}</td>
                        <td>{data?.likeCount ? data?.likeCount : "-"}</td>
                        <td>
                          {data?.concurrentCount ? data?.concurrentCount : "-"}
                        </td>
                      </>
                    ) : null}
                    {pagename === "examWiseAnalysis" ? (
                      <>
                        <td>{data?.examType}</td>
                        <td>{data?.baseCourse}</td>
                        <td>{data?.total}</td>
                      </>
                    ) : null}
                    {pagename === "EditDeleteTeacherTasks" ||
                    pagename === "TeacherTasksPlan" ||
                    pagename === "DataTeacherTasksPlan" ? (
                      data?.taskDate ? (
                        <td>
                          {moment.utc(data.taskDate).format("DD-MM-YYYY")}
                        </td>
                      ) : (
                        <td>-</td>
                      )
                    ) : null}

                    {pagename === "EditDeleteTeacherTasks" ||
                    pagename === "TeacherTasksPlan" ||
                    pagename === "DataTeacherTasksPlan" ? (
                      data?.taskType ? (
                        <td>{data?.taskType}</td>
                      ) : (
                        <td>-</td>
                      )
                    ) : null}

                    {pagename === "EditDeleteTeacherTasks" ||
                    pagename === "TeacherTasksPlan" ||
                    pagename === "DataTeacherTasksPlan" ? (
                      data?.examTypeId ? (
                        <td>{data?.examTypeId?.name}</td>
                      ) : (
                        <td>-</td>
                      )
                    ) : null}

                    {pagename === "EditDeleteTeacherTasks" ||
                    pagename === "TeacherTasksPlan" ||
                    pagename === "DataTeacherTasksPlan" ? (
                      data?.taskTitle != null ? (
                        <td
                          style={{
                            overflow: "hidden",
                            textOverflow: "ellipsis",
                            whiteSpace: "wrap",
                            maxWidth: "20ch",
                          }}
                        >
                          {data?.taskTitle}
                        </td>
                      ) : (
                        <td>-</td>
                      )
                    ) : null}

                    {pagename === "EditDeleteTeacherTasks" ||
                    pagename === "DataTeacherTasksPlan" ? (
                      data?.teacherId != null ? (
                        <td>{data?.teacherId?.name}</td>
                      ) : (
                        <td>-</td>
                      )
                    ) : null}
                    {pagename === "EditDeleteTeacherTasks" ? (
                      data?.taskDate ? (
                        <td>
                          {moment
                            .utc(data.taskDate)
                            .format("DD-MM-YYYY hh:mm:ss")}
                        </td>
                      ) : (
                        <td>-</td>
                      )
                    ) : null}
                    {pagename === "EditDeleteTeacherTasks" ? (
                      data?.liveStartedAt ? (
                        <td>
                          {moment
                            .utc(data.liveStartedAt)
                            .format("DD-MM-YYYY hh:mm:ss")}
                        </td>
                      ) : (
                        <td>-</td>
                      )
                    ) : null}
                    {pagename === "EditDeleteTeacherTasks" ? (
                      data?.liveStartedAt ? (
                        DEFICIT(data?.taskDate, data?.liveStartedAt)
                      ) : (
                        <td>-</td>
                      )
                    ) : null}

                    {pagename === "EditDeleteTeacherTasks" ||
                    pagename === "TeacherTasksPlan" ||
                    pagename === "DataTeacherTasksPlan" ? (
                      data?.taskDuration != null ? (
                        <td>{getSeconds(data?.taskDuration)}</td>
                      ) : (
                        <td>-</td>
                      )
                    ) : null}
                    {pagename === "EditDeleteTeacherTasks" ? (
                      data?.remark != null ? (
                        <div className="d-flex align-items-center ">
                          <td
                            style={{
                              overflow: "hidden",
                              textOverflow: "ellipsis",
                              whiteSpace: "nowrap",
                              maxWidth: "15ch",
                            }}
                          >
                            {data?.remark}
                          </td>
                          {pagename === "EditDeleteTeacherTasks" ? (
                            <span
                              type="submit"
                              className="text-primary fw-bold me-2 cursor"
                              onClick={() => handleViewMore(data)}
                            >
                              View More
                            </span>
                          ) : null}
                        </div>
                      ) : (
                        <td>-</td>
                      )
                    ) : null}

                    {pagename === "DataTeacherTasksPlan" ? (
                      data?.remark != null ? (
                        <td>{data?.remark}</td>
                      ) : (
                        <td>-</td>
                      )
                    ) : null}

                    {pagename === "EditDeleteTeacherTasks" ||
                    pagename === "TeacherTasksPlan" ||
                    pagename === "DataTeacherTasksPlan" ? (
                      data?.taskType === "PAID_APP_LIVE" ||
                      data?.taskType === "PAID_APP_RECORDED" ? (
                        <td>N/A</td>
                      ) : data?.link || data?.file ? (
                        data?.taskType === "PAID_APP_LIVE" ||
                        data?.taskType === "PAID_APP_RECORDED" ? (
                          <td>N/A</td>
                        ) : (
                          <td
                            style={{
                              overflow: "hidden",
                              textOverflow: "ellipsis",
                              whiteSpace: "nowrap",
                              maxWidth: "15ch",
                            }}
                          >
                            {data?.link ? (
                              <a
                                href={data?.link}
                                target="_blank"
                                rel="noreferrer"
                              >
                                {data?.link}
                              </a>
                            ) : null}
                            {data?.file ? (
                              <img
                                className="chatimg"
                                src={data?.file}
                                alt={data?.file}
                              />
                            ) : null}
                          </td>
                        )
                      ) : (
                        <td>-</td>
                      )
                    ) : null}

                    {/*****************FAQ************** */}

                    {pagename === "courseFAQ" ? (
                      data?.question ? (
                        <td>{data?.question}</td>
                      ) : (
                        <td>-</td>
                      )
                    ) : null}
                    {pagename === "courseFAQ" ? (
                      data?.answer ? (
                        <td>{ReactHtmlParser(data?.answer)}</td>
                      ) : (
                        <td>-</td>
                      )
                    ) : null}

                    {pagename === "EditDeleteTeacherTasks" ||
                    pagename === "TeacherTasksPlan" ||
                    pagename === "DataTeacherTasksPlan" ? (
                      data?.status ? (
                        <div className="d-flex align-items-center ">
                          <td>
                            {data?.status === 1 ? (
                              <span className="text-danger fw-bold">
                                PENDING
                              </span>
                            ) : data?.status === 2 ? (
                              <span className="text-success fw-bold">
                                COMPLETE
                              </span>
                            ) : data?.status === 3 ? (
                              <span className="text-warning fw-bold">
                                MISCELLANEOUS
                              </span>
                            ) : data?.status === 4 ? (
                              <span className="text-info fw-bold">
                                ON LEAVE
                              </span>
                            ) : data?.status === 5 ? (
                              <span className="text-danger fw-bold">
                                NOT COMPLETED
                              </span>
                            ) : (
                              "-"
                            )}
                          </td>
                          {pagename === "EditDeleteTeacherTasks" ? (
                            <span
                              type="submit"
                              className="text-primary fw-bold me-2 cursor"
                              onClick={() => handleEditBatch(data, data?._id)}
                            >
                              Edit
                            </span>
                          ) : null}
                          {pagename === "DataTeacherTasksPlan" &&
                          data?.status === 1 &&
                          handleEditBatch ? (
                            <span
                              type="submit"
                              className="text-primary fw-bold me-2 cursor"
                              onClick={() => handleEditBatch(data, data?._id)}
                            >
                              Edit
                            </span>
                          ) : null}
                        </div>
                      ) : (
                        <td>-</td>
                      )
                    ) : null}
                    {/* {pagename === "EditDeleteTeacherTasks" ||
                    pagename === "TeacherTasksPlan" ||
                    pagename === "DataTeacherTasksPlan" ? (
                      data?.remark ? (
                        <td>{data?.remark}</td>
                      ) : (
                        <td>-</td>
                      )
                    ) : null} */}

                    {pagename === "TeacherTasksPlan" ||
                    pagename === "DataTeacherTasksPlan" ? (
                      data?.workDuration != null ? (
                        <td>
                          {data?.workDuration
                            ? getSeconds(data?.workDuration)
                            : "-"}
                        </td>
                      ) : (
                        <td>-</td>
                      )
                    ) : null}
                    {pagename === "EditDeleteTeacherTasks" ? (
                      data?.workDuration != null ? (
                        <>
                          <td>
                            {data?.workDuration
                              ? getSeconds(data?.workDuration)
                              : "-"}
                            <span
                              type="submit"
                              className="text-primary fw-bold me-2 cursor text-center"
                              onClick={() =>
                                handleEditDuration(data, data?._id)
                              }
                            >
                              Edit
                            </span>
                          </td>
                        </>
                      ) : (
                        <td>
                          <span
                            type="submit"
                            className="text-primary fw-bold me-2 cursor"
                            onClick={() => handleEditDuration(data, data?._id)}
                          >
                            Edit
                          </span>
                        </td>
                      )
                    ) : null}

                    {pagename === "TeacherTasksPlan" ||
                    pagename === "DataTeacherTasksPlan" ? (
                      data?.remarkBy ? (
                        <td>{data?.remarkBy}</td>
                      ) : (
                        <td>-</td>
                      )
                    ) : null}
                    {pagename === "EditDeleteTeacherTasks" ? (
                      <td>
                        {data?.remarkBy
                          ? data?.remarkBy
                          : data?.teacherId?.name}
                      </td>
                    ) : null}
                    {pagename === "EditDeleteTeacherTasks" ||
                    pagename === "DataTeacherTasksPlan" ? (
                      data?.updatedAt ? (
                        <td>
                          {moment
                            .utc(changeIntoIst(data.updatedAt))
                            .format("DD-MM-YYYY hh:mm:ss")}
                        </td>
                      ) : (
                        <td>-</td>
                      )
                    ) : null}

                    {pagename === "serverList" ? (
                      data?.instanceName ? (
                        <td>{data?.instanceName}</td>
                      ) : (
                        <td>-</td>
                      )
                    ) : null}

                    {pagename === "serverList" ? (
                      data?.instanceId ? (
                        <td>{data?.instanceId}</td>
                      ) : (
                        <td>-</td>
                      )
                    ) : null}

                    {pagename === "serverList" ? (
                      data?.instanceState ? (
                        <td>{data?.instanceState}</td>
                      ) : (
                        <td>-</td>
                      )
                    ) : null}
                    {pagename === "serverList" ? (
                      data?.region ? (
                        <td>{data?.region}</td>
                      ) : (
                        <td>-</td>
                      )
                    ) : null}
                    {pagename === "serverList" ? (
                      data?.lastUpdateTime ? (
                        <td>{data?.lastUpdateTime}</td>
                      ) : (
                        <td>-</td>
                      )
                    ) : null}
                    {pagename === "serverList" ? (
                      data?.env ? (
                        <td>{data?.env}</td>
                      ) : (
                        <td>-</td>
                      )
                    ) : null}

                    {pagename === "examDetails" ? (
                      data?.examTypeId ? (
                        <td>{data?.examTypeId?.name}</td>
                      ) : (
                        <td>-</td>
                      )
                    ) : null}

                    {pagename === "examDetails" ? (
                      data?.heading ? (
                        <td>{data?.heading}</td>
                      ) : (
                        <td>-</td>
                      )
                    ) : null}

                    {pagename === "examDetails" ? (
                      data?.headingBanner ? (
                        <td>
                          <img
                            htmlFor="photo-upload"
                            src={data?.headingBanner}
                            style={{
                              width: "40px",
                              height: "40px",
                            }}
                            alt={""}
                          />
                        </td>
                      ) : (
                        <td>-</td>
                      )
                    ) : null}
                    {pagename === "examDetails" ? (
                      data?.teacherId ? (
                        <td>{data?.teacherId?.name}</td>
                      ) : (
                        <td>-</td>
                      )
                    ) : null}
                    {pagename === "examDetails" ? (
                      data?.teacherURL ? (
                        <td>{data?.teacherURL}</td>
                      ) : (
                        <td>-</td>
                      )
                    ) : null}
                    {pagename === "examDetails" ? (
                      data?.updatedDateByTeacher ? (
                        <td>{data?.updatedDateByTeacher}</td>
                      ) : (
                        <td>-</td>
                      )
                    ) : null}

                    {pagename === "author" ? (
                      data?.name ? (
                        <td>{data?.name}</td>
                      ) : (
                        <td>-</td>
                      )
                    ) : null}
                    {pagename === "author" ? (
                      data?.designation ? (
                        <td>{data?.designation}</td>
                      ) : (
                        <td>-</td>
                      )
                    ) : null}
                    {pagename === "author" ? (
                      data?.uniqueName ? (
                        <td>{data?.uniqueName}</td>
                      ) : (
                        <td>-</td>
                      )
                    ) : null}
                    {pagename === "author" ? (
                      data?.uniqueLink ? (
                        <td>{data?.uniqueLink}</td>
                      ) : (
                        <td>-</td>
                      )
                    ) : null}

                    {pagename === "designations" ? (
                      data?.level ? (
                        <td>{data?.level}</td>
                      ) : (
                        <td>-</td>
                      )
                    ) : null}

                    <td>
                      {addOnButton ? (
                        <>
                          {pagename === "bookhistory" && data?.status === 9 ? (
                            <span
                              className="text-danger fw-bold me-2 cursor"
                              onClick={() => handleAddOnButton(data)}
                            >
                              Entry Deleted
                            </span>
                          ) : (
                            <>
                              <span
                                type="submit"
                                className={className}
                                onClick={() => handleAddOnButton(data)}
                              >
                                {addOnButtonLabel}{" "}
                              </span>
                              {adddeleteButton ? (
                                <span
                                  type="submit"
                                  className="text-danger fw-bold cursor"
                                  onClick={() => handleDelete(data)}
                                >
                                  Delete
                                </span>
                              ) : null}
                            </>
                          )}
                        </>
                      ) : (
                        <>
                          {edit ? (
                            pagename === "userFeedBack" ||
                            pagename === "examResults" ||
                            pagename === "courseFeedBack" ||
                            pagename === "batchwiseCount" ||
                            pagename === "cxoBatchWiseCount" ||
                            pagename === "academicBatchWiseCount" ||
                            pagename === "studentCount" ||
                            pagename === "serverList" ||
                            pagename === "referenceLeadCount" ||
                            pagename === "referenceTeamLeadCount" ? (
                              <span
                                type="submit"
                                className="text-success me-2 fw-bold cursor"
                                onClick={() => handleEdit(data)}
                              >
                                View Details
                              </span>
                            ) : (
                              <span
                                type="submit"
                                className="text-primary fw-bold me-2 cursor"
                                onClick={() => handleEdit(data)}
                              >
                                Edit
                              </span>
                            )
                          ) : null}
                          {pagename === "finTransEductech" ? (
                            <span
                              type="submit"
                              className="fw-bold cursor"
                              style={{ color: "green" }}
                              onClick={() => handleRefund(data)}
                            >
                              Refund Amount
                            </span>
                          ) : null}
                          {pagename !== "classroomStudents" &&
                          pagename !== "financeClassroomStudents" &&
                          pagename !== "userFeedBack" &&
                          pagename !== "courseFeedBack" &&
                          pagename !== "importRecVideoLectList" &&
                          pagename !== "TeacherHoursList" &&
                          pagename !== "transactionPublication" &&
                          pagename !== "transactionEdutech" &&
                          pagename !== "transactionEdutechVP" &&
                          pagename !== "transactionEdutechStudyMaterialVP" &&
                          pagename !== "transactionEdutechStudyMaterial" &&
                          pagename !== "studyMaterialOrders" &&
                          pagename !== "AllDetailsSMOrders" &&
                          pagename !== "revenueDetails" &&
                          pagename !== "batchwiseCount" &&
                          pagename !== "datewiseCount" &&
                          pagename !== "admissionHistory" &&
                          pagename !== "studentCount" &&
                          pagename !== "registrationDetails" &&
                          pagename !== "dataRegistrationDetails" &&
                          pagename !== "examResults" &&
                          pagename !== "examResultsDetails" &&
                          pagename !== "TeacherTasksPlan" &&
                          pagename !== "DataTeacherTasksPlan" &&
                          pagename !== "counsellorCouponCode" &&
                          pagename !== "publiCounsellorCouponCode" &&
                          pagename !== "serverList" &&
                          pagename !== "stateStudentCount" &&
                          pagename !== "DataTeacherRanking" &&
                          pagename !== "editSyllabusList" &&
                          pagename !== "viewSyllabusList" &&
                          pagename !== "paymentPublication" &&
                          pagename !== "referenceLeadCount" &&
                          pagename !== "referenceTeamLeadCount" &&
                          pagename !== "teacherPerformance" &&
                          pagename !== "examWiseAnalysis" &&
                          pagename !== "referenceGenerated" &&
                          pagename !== "finTransEductech" &&
                          pagename !== "whatsappShoot" &&
                          pagename !== "assetList" &&
                          pagename !== "cxoBatchWiseCount" &&
                          pagename !== "academicBatchWiseCount" &&
                          pagename !== "cxoBatchWiseCountDetail" &&
                          pagename !== "copyCounsellorCode" &&
                          pagename !== "inventoryManagement" &&
                          pagename !== "competitor" ? (
                            <span
                              type="submit"
                              className="text-danger fw-bold cursor"
                              onClick={() => {
                                setDeletePopup(true);
                                let deleteDataName = "";
                                {
                                  pagename === "ExamName" ||
                                  pagename === "SubExam" ||
                                  pagename === "ParentCourse" ||
                                  pagename === "Language" ||
                                  pagename === "subCatagory1" ||
                                  pagename === "subCatagory2" ||
                                  pagename === "subCatagory3" ||
                                  pagename === "subject" ||
                                  pagename === "maincategory" ||
                                  pagename === "section" ||
                                  pagename === "assignbooks" ||
                                  pagename === "packageExtend" ||
                                  pagename === "editPattern" ||
                                  pagename === "couponCode" ||
                                  pagename === "edutechCounsellorCode" ||
                                  pagename === "publicationCounsellorCode" ||
                                  pagename === "counsellorCode" ||
                                  pagename === "BBmeeting" ||
                                  pagename === "batchType" ||
                                  pagename === "SopPpt" ||
                                  pagename === "topic" ||
                                  pagename === "subTopic" ||
                                  pagename === "lecture" ||
                                  pagename === "editPattern" ||
                                  pagename === "publiCounsellorCode" ||
                                  pagename === "books" ||
                                  pagename === "IssueType" ||
                                  pagename === "subDepartment" ||
                                  pagename === "author" ||
                                  pagename === "company" ||
                                  pagename === "department" ||
                                  pagename === "designations"
                                    ? (deleteDataName = data?.name)
                                    : pagename === "bannertype" ||
                                      pagename === "courseType"
                                    ? (deleteDataName = data?.type)
                                    : pagename === "teacherLecture" ||
                                      pagename === "editLiveVideo" ||
                                      pagename === "editLivePremierVideo" ||
                                      pagename === "notificationList" ||
                                      pagename === "seoBlogs" ||
                                      pagename === "youtubelive" ||
                                      pagename === "youtubeRecorded"
                                    ? (deleteDataName = data?.title)
                                    : pagename === "editLiveFreeVideo"
                                    ? (deleteDataName = data?.lectureTitle)
                                    : pagename === "liveRecVideoLectList"
                                    ? (deleteDataName = data?.lectureTitle)
                                    : pagename === "packageExtendAndUpgrade"
                                    ? (deleteDataName = data?.sectionId?.name)
                                    : pagename === "assignTeacher"
                                    ? (deleteDataName = data?.teacherId?.name)
                                    : pagename === "EditDeleteTeacherTasks"
                                    ? (deleteDataName = data?.taskTitle)
                                    : pagename === "copyPackageList"
                                    ? (deleteDataName = data?.toCourseId?.name)
                                    : pagename === "examSyllabus"
                                    ? (deleteDataName = data?.subjectName)
                                    : pagename === "EditDeletePyqPdf"
                                    ? (deleteDataName = data?.batchName)
                                    : pagename === "EditDeleteResults"
                                    ? (deleteDataName = data?.studentName)
                                    : pagename === "seoSubExam"
                                    ? (deleteDataName = data?.metaTitle)
                                    : pagename === "courseFAQ"
                                    ? (deleteDataName = data?.question)
                                    : pagename === "productSchema"
                                    ? (deleteDataName = data?.CourseName)
                                    : pagename === "examDetails"
                                    ? (deleteDataName = data?.heading)
                                    : pagename === "classroomBatch"
                                    ? (deleteDataName = moment
                                        .utc(data?.date)
                                        .format("LL"))
                                    : pagename === "editQuestion" ||
                                      pagename === "editQuestionBank"
                                    ? (deleteDataName =
                                        data?.en.title[0].description)
                                    : pagename === "coupon"
                                    ? (deleteDataName = `${data?.examTypeId?.name} - ${data?.baseCourseId?.name}`)
                                    : pagename === "configQuestion"
                                    ? (deleteDataName = `${data?.examType?.name} - ${data?.baseCourse?.name}`)
                                    : pagename === "examMapping" ||
                                      pagename === "departmentMapping"
                                    ? (deleteDataName = `${data?.toExamTypeName} - ${data?.toBaseCourseName}`)
                                    : pagename === "srcVideoData"
                                    ? (deleteDataName = "Video")
                                    : pagename === "deviceCompany"
                                    ? (deleteDataName = `${data?.name}`)
                                    : pagename === "deviceModel"
                                    ? (deleteDataName = `${data?.name}`)
                                    : (deleteDataName = "");
                                }

                                setDeleteData({
                                  ...data,
                                  deleteDataName,
                                });
                              }}
                            >
                              {" "}
                              Delete
                            </span>
                          ) : null}
                          {pagename === "serverList" ? (
                            data?.instanceState === "RUNNING" ? (
                              <span
                                type="submit"
                                className="btn btn-danger rounded me-2 fw-bold cursor"
                                onClick={() => handleStatus(data)}
                              >
                                STOP
                              </span>
                            ) : data?.instanceState === "STOPPED" ? (
                              <span
                                type="submit"
                                className="btn btn-primary border rounded me-2 fw-bold cursor"
                                onClick={() => handleStatus(data)}
                              >
                                START
                              </span>
                            ) : data?.instanceState === "PENDING" ||
                              data?.instanceState === "STOPPING" ? (
                              <span
                                type="submit"
                                className="btn btn-secondary border rounded me-2 fw-bold cursor"
                                disabled={true}
                              >
                                WAIT
                              </span>
                            ) : null
                          ) : null}
                        </>
                      )}
                    </td>
                  </tr>
                  {/* {pagename === "EditDeleteTeacherTasks" ? (
                    <tr>
                      {data?.remarkBy ? (
                        <td colSpan={9}>
                          <div className="align-items-center">
                            <div className="d-flex">
                              <div className="fw-bold me-2">Remark : </div>
                              <span> {data?.remark}</span>
                            </div>
                            <div className="d-flex">
                              <div className="fw-bold me-2">Remark By: </div>
                              <div>{data?.remarkBy}</div>
                            </div>
                          </div>
                        </td>
                      ) : (
                        <td>-</td>
                      )}
                    </tr>
                  ) : null} */}
                  {pagename === "liveRecVideoLectList" ? (
                    <tr>
                      {data?.courseId ? (
                        <td colSpan={9}>
                          <div className="d-flex align-items-center">
                            <div className="fw-bold">Batch Name : </div>
                            <div>{getSessions(data?.courseId)}</div>
                            {pagename === "liveRecVideoLectList" ? (
                              <span
                                type="submit"
                                className="text-primary fw-bold me-2 cursor"
                                onClick={() =>
                                  handleEditBatch(data?.courseId, data?._id)
                                }
                              >
                                Edit
                              </span>
                            ) : null}
                          </div>
                        </td>
                      ) : (
                        <td>-</td>
                      )}
                    </tr>
                  ) : null}
                  {pagename === "editFreePremierVideo" &&
                  (type === 1 || type === 2) ? (
                    <tr>
                      {data?.coursesData ? (
                        <td colSpan={9}>
                          <div className="d-flex align-items-center">
                            <div className="fw-bold">Batch Name : </div>
                            <div>{getBatchNames(data?.coursesData)}</div>
                            {pagename === "editFreePremierVideo" ? (
                              <span
                                type="submit"
                                className="text-primary fw-bold me-2 cursor"
                                onClick={() =>
                                  handleEditBatch(data?.coursesData, data?._id)
                                }
                              >
                                Edit
                              </span>
                            ) : null}
                          </div>
                        </td>
                      ) : (
                        <td>-</td>
                      )}
                    </tr>
                  ) : null}

                  {pagename === "editLiveVideo" ||
                  (pagename === "editLivePremierVideo" &&
                    (type === 1 || type === 2)) ? (
                    <tr>
                      {data?.courseId ? (
                        <td colSpan={9}>
                          <div className="d-flex align-items-center">
                            <div className="fw-bold">Batch Name : </div>
                            <div>{getBatchNames(data?.courseId)}</div>
                            {pagename === "editLiveVideo" ||
                            pagename === "editLivePremierVideo" ? (
                              <span
                                type="submit"
                                className="text-primary fw-bold me-2 cursor"
                                onClick={() =>
                                  handleEditBatch(data?.courseId, data?._id)
                                }
                              >
                                Edit
                              </span>
                            ) : null}
                          </div>
                        </td>
                      ) : (
                        <td>-</td>
                      )}
                    </tr>
                  ) : null}

                  {pagename === "editLivePremierVideo" ||
                  pagename === "editLiveFreeVideo" ||
                  pagename === "courseId" ? (
                    <tr>
                      {data?.classSchedule ? (
                        <td colSpan={9}>
                          <div className="d-flex align-items-center">
                            <div className="fw-bold">Class Date: </div>
                            <div>{getBatchNames(data?.classSchedule)}</div>
                          </div>
                        </td>
                      ) : (
                        <td>-</td>
                      )}
                    </tr>
                  ) : null}
                </>
              );
            })
          ) : (
            <tr>
              <td>No record found.</td>
            </tr>
          )}
        </tbody>
      </table>
      <DeletePopup
        open={deletePopup}
        handleClose={() => setDeletePopup(false)}
        handleSubmit={() => {
          handleDelete(deleteData);
          setDeletePopup(false);
        }}
        data={deleteData.deleteDataName}
      />
    </div>
  );
}

export default AdminTable;
